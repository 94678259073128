import ScrollLoading from "../../components/Utilities/Scroll-loading"
import { ImageUrl } from "../media/ImageUrl"
import { Image } from "react-bootstrap"
import "./testPage.scss"
export function TestPage() {
    return (
        <div className="d-all-center" style={{ width: "100%" }}>
            <div style={{ width: 800 }}>
                <ScrollLoading>

                    <Image src={ImageUrl.ImgFirst_1}></Image>
                    <Image src={ImageUrl.ImgFirst_1}></Image>
                    <Image src={ImageUrl.ImgFirst_1}></Image>
                    <Image src={ImageUrl.ImgFirst_1}></Image>
                    <Image src={ImageUrl.ImgFirst_1}></Image>

                </ScrollLoading>
            </div>
        </div>
    )
}