import CryptoJS from 'crypto-js';


export function decryptString(encryptedText) {
    try {
        const encryptedKeyBase64 = process.env.REACT_APP_ENCRYPTED_KEY;
        const password = process.env.REACT_APP_ENCRYPTED_PASSPHRASE;

        // Step 1: Parse and decode the encrypted key components
        const [key_iv_b64, key_ct_b64, key_salt_b64] = encryptedKeyBase64.split(':');
        if (!key_iv_b64 || !key_ct_b64 || !key_salt_b64) {
            throw new Error('Invalid encrypted key format');
        }

        const key_iv = CryptoJS.enc.Base64.parse(key_iv_b64);
        const key_ct = CryptoJS.enc.Base64.parse(key_ct_b64);
        const key_salt = CryptoJS.enc.Base64.parse(key_salt_b64);

        // Step 2: Derive the key using PBKDF2
        const derivedKey = CryptoJS.PBKDF2(password, key_salt, {
            keySize: 256 / 32,
            iterations: 1000,
            hasher: CryptoJS.algo.SHA1
        });

        // Step 3: Decrypt the encrypted key to get the original encryption key
        const decryptedKeyBytes = CryptoJS.AES.decrypt(
            { ciphertext: key_ct },
            derivedKey,
            {
                iv: key_iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        const decryptedKey = CryptoJS.enc.Utf8.stringify(decryptedKeyBytes);

        // Step 4: Parse the IV and ciphertext from the encrypted text
        const [email_iv_b64, email_ct_b64] = encryptedText.split(':');
        if (!email_iv_b64 || !email_ct_b64) {
            throw new Error('Invalid encrypted text format');
        }

        const email_iv = CryptoJS.enc.Base64.parse(email_iv_b64);
        const email_ct = CryptoJS.enc.Base64.parse(email_ct_b64);

        // Step 5: Decrypt the ciphertext using AES
        const decryptedData = CryptoJS.AES.decrypt(
            { ciphertext: email_ct },
            CryptoJS.enc.Utf8.parse(decryptedKey),
            {
                iv: email_iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        // Step 6: Convert the decrypted data back to UTF-8
        const plainText = CryptoJS.enc.Utf8.stringify(decryptedData);

        return plainText;
    } catch (error) {
        // console.log('Decryption failed:', error.message);
        return null;
    }
}

export function encryptString(plainText) {
    try {
        const encryptedKeyBase64 = process.env.REACT_APP_ENCRYPTED_KEY;
        const password = process.env.REACT_APP_ENCRYPTED_PASSPHRASE;

        // Step 1: Parse and decode the encrypted key components
        const [key_iv_b64, key_ct_b64, key_salt_b64] = encryptedKeyBase64.split(':');
        if (!key_iv_b64 || !key_ct_b64 || !key_salt_b64) {
            throw new Error('Invalid encrypted key format');
        }

        const key_iv = CryptoJS.enc.Base64.parse(key_iv_b64);
        const key_ct = CryptoJS.enc.Base64.parse(key_ct_b64);
        const key_salt = CryptoJS.enc.Base64.parse(key_salt_b64);

        // Step 2: Derive the key using PBKDF2
        const derivedKey = CryptoJS.PBKDF2(password, key_salt, {
            keySize: 256 / 32,
            iterations: 1000,
            hasher: CryptoJS.algo.SHA1
        });

        // Step 3: Decrypt the encrypted key to get the original encryption key
        const decryptedKeyBytes = CryptoJS.AES.decrypt(
            { ciphertext: key_ct },
            derivedKey,
            {
                iv: key_iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        const decryptedKey = CryptoJS.enc.Utf8.stringify(decryptedKeyBytes);

        // Step 4: Generate a random IV for the encryption
        const email_iv = CryptoJS.lib.WordArray.random(16);

        // Step 5: Encrypt the plainText using AES
        const emailEncrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(decryptedKey), {
            iv: email_iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Step 6: Convert IV and ciphertext to Base64 and concatenate
        const email_iv_b64 = CryptoJS.enc.Base64.stringify(email_iv);
        const email_ct_b64 = emailEncrypted.ciphertext.toString(CryptoJS.enc.Base64);

        const encryptedEmailBase64 = `${email_iv_b64}:${email_ct_b64}`;
        
        return encryptedEmailBase64;
    } catch (error) {
        // console.log('Encryption failed:', error.message);
        return null;
    }
}