import { useState, useEffect } from "react"
import { Icon } from "@iconify/react/dist/iconify.js"
import { ReportDetails } from "./ReportDetails"
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer } from "../../assets/network/NetWork";
import { LoadingComponent } from "../Utilities/Loading";
import { ModalComponent } from "../Utilities/ModalComponent";
import { useTranslation } from "react-i18next";

const ReportOverview = ({ setPage }) => {

    const handleClick = () => {
        setPage(0)
    }

    return (
        <div className="ReportOverview">
            <label>Report Overview</label>
            <div className="InputDetails" onClick={handleClick}>
                <span>Input details</span>
                <Icon icon="iconamoon:arrow-right-2" />
            </div>
        </div>
    )
}

const MatchingOutcome = ({ schoolListData, setPage }) => {

    

    const ProgramCard = ({ setPage, page_code, data }) => {

        const i18n = useTranslation();
        const currentLanguage = i18n.language;

        return (
            <div className="ProgramCard" onClick={() => setPage(page_code)}>
                <div className="ProgramCard-info" >
                    <img src={data.program_icon} alt="school_Icon" />
                    <span>{data.course_name}</span>
                </div>
                <div className="ProgramCard-score">
                    <span>{data.report['en']["Total score"].toFixed(1)}</span>
                    <Icon icon="iconamoon:arrow-right-2" id='arrowIcon' />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="MatchingOutcome">
                <label>Matching Outcome</label>
                <div className="SchoolDetails">
                    {Object.entries(schoolListData).map(([key, value]) => {
                        return <ProgramCard key={`programreport_item_${key}`} setPage={setPage} data={value} page_code={key} />
                    })}
                </div>
            </div>
        </>
    )
}

const InputDetailsCard = ({ inputData }) => {

    const Tag = ({ content }) => {
        return (
            <div className="Tag">
                <span>{content.replaceAll("_"," ")}</span>
            </div>
        )
    }

    const Question = ({ title, answers }) => {
        return (
            <div className="question">
                <span>{title}</span>
                <div className="answers">
                    {answers.map((answer, index) => {
                        return <Tag key={index} content={answer} />
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="InputDetailsCard">
                <div className="InputDetailsCard-row">
                    <div className="InputDetailsCard-row-col">
                        <label>Name</label>
                        <span>{inputData.file_name}</span>
                    </div>
                </div>
                <div className="InputDetailsCard-row">
                    <div className="InputDetailsCard-row-col">
                        <label>TIME</label>
                        <span>{inputData.update_time}</span>
                    </div>
                    <div className="InputDetailsCard-row-col">
                        <label>Matching goal</label>
                        <span>{inputData.display_category}</span>
                    </div>
                    {/* {inputData.cv !== null && <div className="InputDetailsCard-row-col">
                        <label>Used CV</label>
                        <div className="cvFileCard">
                            <div className="cvIconContainer">
                                <Icon id="cvIcon" icon={"tabler:file-cv"} />
                            </div>
                            <div className="cvFileCard-info">
                                <span className="cvFileCard-info-filename">CV_Cynthia_1</span>
                                <span className="cvFileCard-info-fileDate">2024/01/01</span>
                            </div>
                        </div>
                    </div>} */}
                </div>
                <div className="InputDetailsCard-row2">
                    <label>Personalized information</label>
                    <div className="indicators">
                        <Question title='What’s your target major?' answers={inputData.report_meta_dict.indicator.major} />
                        <Question title='What’s your career plan? (optional)' answers={inputData.report_meta_dict.indicator.career} />
                        <Question title='Why do you want to study abroad?' answers={inputData.report_meta_dict.indicator.purpose} />
                        <Question title='What is your desired university ranking?' answers={[`Top ${inputData.report_meta_dict.indicator.uni_ranking[0]}-${inputData.report_meta_dict.indicator.uni_ranking[1]}`]} />
                        <Question title='Which is your desired location for study?' answers={inputData.report_meta_dict.indicator.location.length > 0 ? inputData.report_meta_dict.indicator.location : ["N/A"]} />
                    </div>
                </div>
            </div>
        </>
    )
}



export const ProgramGuideReport = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const i18n = useTranslation();
    const currentLanguge = i18n.language;


    useEffect(() => {
        // fetch data from api
        const id = localStorage.getItem('pgfilename');
        GetToServer(NetworkApi.programGuide.showSingleReport.concat('/', id), (re) => {
            if (re.success) {
                setData(re);
            } else {
                currentLanguge==='zh'?setModalContent(re.message.cn):setModalContent(re.message.en);
                setModalOpen(true)
            }
            setLoading(false);
        })
    }, [])

    const handleOk = () => {
        setModalOpen(false);
    }

    return (
        <>
            {loading ? <LoadingComponent /> :
                modalOpen ? <ModalComponent content={modalContent} onOk={handleOk} modalOpen={modalOpen} /> :
                    <div className="ProgramGuideReport">
                        <div className="LeftPad">
                            <ReportOverview setPage={setPage} />
                            <MatchingOutcome setPage={setPage} schoolListData={data.data.report} />
                        </div>
                        <div className="RightPad">
                            {page === 0 ? <InputDetailsCard inputData={data.report} /> : <ReportDetails data={data.data.report[page]} />}
                        </div>
                    </div>}
        </>
    )
}

