import React, { useState } from 'react';
import { PlanDetails } from './PlanDetails';
import { PaymentOptions } from './PaymentOptions';
import { PaymentQuitPopup } from './PaymentQuitPopup';
import { PaymentResultPopup } from './PaymentResultPopup';
import { CheckOrderPopup } from './CheckOrderPopup';
import { ImageUrl } from '../../assets/media/ImageUrl';
import { PostToServerWithJson } from "../../assets/network/NetWork"
import { NetworkApi } from "../../assets/network/NetworkApi"
import RequireText from "../../assets/i18n/TextDefiner";
import HeadNav from '../HomeComponent/HeadNav';

import { ModalComponent } from "../Utilities/ModalComponent";
import { LoadingComponent } from "../Utilities/Loading";
import { set } from 'animejs';

const ExitModal = ({ show, onClose, onConfirm }) => {
    if (!show) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Are you sure you want to exit?</h2>
                <button onClick={onConfirm}>Yes</button>
                <button onClick={onClose}>No</button>
            </div>
        </div>
    );
};

const PaymentPage = () => {
    const [showExitModal, setShowExitModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("Prime");
    const [paymentMethod, setPaymentMethod] = useState("WeChat");
    const [price, setPrice] = useState(1380);
    const [orderStatus, setOrderStatus] = useState(false);
    // const [showCheckOrderStatus, setShowCheckOrderStatus] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [showPaymentResultPopup, setShowPaymentResultPopup] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [lastConfirmedPlan, setLastConfirmedPlan] = useState(null); // 保存上一次确认的计划


    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [modelContent, setModalContent] = useState("");

    const handleSelectPlan = (plan, price) => {
        setSelectedPlan(plan);
        setPrice(price);
    };

    const handlePayment = () => {
        console.log(`Paid ${price} CNY for ${selectedPlan}`);
    };

    const handleExit = () => {
        setShowExitModal(true);
    };

    const handleOk = () => {
        setOpenModal(false);
    }

    const handleCloseModal = () => {
        setShowExitModal(false);
    };

    const handlePlanConfirm = async () => {
        if (selectedPlan === lastConfirmedPlan) {
            // console.log('Plan has not changed, not calling API');
            setOpenModal(true);
            setModalContent("所选方案无变化，请重新选择 Plan has not changed, please reselect");
            return;
        }

        setLastConfirmedPlan(selectedPlan);

        const invokeApi = `${NetworkApi.payment.invokePayment}/${paymentMethod}/${selectedPlan}`;

        PostToServerWithJson(invokeApi, null, (re) => {
            if (re.status === "success") {
                setPaymentUrl(re.payment_url);
                setPaymentId(re.payment_id);

                // 同一处理函数中完成状态更新，避免多个渲染路径
                handlePaymentStatus(re.payment_id);
            } else {
                setOrderStatus(false);
                setOpenModal(true);
                setModalContent(re.message.cn);
            }
        });
    };

    // 独立的函数处理支付状态，确保每次渲染路径一致
    const handlePaymentStatus = (paymentId) => {
        const queryApi = `${NetworkApi.payment.queryTransaction}/${paymentId}`;

        PostToServerWithJson(queryApi, null, (statusResult) => {
            if (statusResult.payment_status === 'Success') {
                setPaymentStatus(true);
                setShowPaymentResultPopup(true); // 确保popup逻辑单独处理
            } else if (statusResult.payment_status === 'Failed' || statusResult.payment_status === 'None') {
                setPaymentStatus(false);
                // setShowPaymentResultPopup(true); // 确保popup逻辑单独处理
            }
            setPaymentStatus(false);
        });
    };


    const handleTimeout = () => {
        setPaymentStatus(false);
        setShowPaymentResultPopup(true);
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <HeadNav></HeadNav>
            <div className="payment-page">
                <button onClick={handleExit} className="payment-page_exit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.9708 1.20887C15.5566 1.79466 15.5566 2.7444 14.9708 3.33019L4.12132 14.1797L14.9708 25.0292C15.5566 25.615 15.5566 26.5647 14.9708 27.1505C14.385 27.7363 13.4353 27.7363 12.8495 27.1505L0.93934 15.2403C0.353554 14.6546 0.353553 13.7048 0.93934 13.119L12.8495 1.20887C13.4353 0.623082 14.385 0.623082 14.9708 1.20887Z" fill="black" />
                    </svg>
                </button>
                {/* <div className="payment-page_header">
                    <a className="navbar-brand" href="/" onClick={handleExit}>
                        <img src={ImageUrl.brandIcon} width="140" height="32" />
                    </a>
                </div> */}
                <div className="payment-page_content">
                    <div className="payment-page_left">
                        <div className="payment-page_title">
                            {RequireText("payment.planDetails")}
                        </div>
                        <PlanDetails
                            onSelectPlan={handleSelectPlan}
                            handlePlanConfirm={handlePlanConfirm}
                        />
                    </div>
                    <div className="payment-page_right">
                        <div className="payment-page_title">
                            {RequireText("payment.payment")}
                        </div>
                        <PaymentOptions
                            paymentUrl={paymentUrl}
                            paymentId={paymentId}
                            onPayment={handlePayment}
                            paymentStatus={paymentStatus}
                            setPaymentStatus={setPaymentStatus}
                            setShowPaymentResultPopup={setShowPaymentResultPopup}
                            onTimeout={handleTimeout}
                        />
                    </div>
                </div>
            </div>

            {showExitModal && (<PaymentQuitPopup exit={handleCloseModal} />)}
            {/* {showCheckOrderStatus && <CheckOrderPopup close={() => setShowCheckOrderStatus(false)} />} */}
            {showPaymentResultPopup && (paymentStatus != null) && <PaymentResultPopup paymentStatus={paymentStatus} />}
            {/* <PaymentResultPopup paymentStatus={true} /> */}

            {/* {loading && <LoadingComponent />} */}
            {openModal && <ModalComponent modalOpen={openModal} content={modelContent} onOk={handleOk} />}

        </div>
    );
};

export default PaymentPage;
