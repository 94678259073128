import { useState, useEffect, useCallback } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Focus from '@tiptap/extension-focus';
import TextStyle from '@tiptap/extension-text-style';
import Heading from '@tiptap/extension-heading';
import TextAlign from '@tiptap/extension-text-align';
import CharacterCount from '@tiptap/extension-character-count'
import {
    AddExperienceNode, AddSectionNode, Section, Div, CustomBulletList,
    CustomHeading, CustomParagraph, CustomSpan, DeleteSectionNode, DeleteItemNode
} from './EditorNodes';
import MenuBar from '../Utilities/Editor/EditorMenubar';
import FloatingMenu from '../Utilities/Editor/EditorFloatingMenu';
import { convertHTMLToJSON } from './CVHTMLToJSON';
import { NetworkApi } from "../../assets/network/NetworkApi";
import { PostToServerWithJson, PutToServerWithJson } from "../../assets/network/NetWork";
import { useNavigate } from "react-router-dom";
import RequireText from '../../assets/i18n/TextDefiner';
import { ModalComponent } from '../Utilities/ModalComponent';
import { LoadingComponent } from '../Utilities/Loading';

import html2pdf from 'html2pdf.js'

//--------------------------------------------------------------------
//Remaining: 
//1.
//--------------------------------------------------------------------


//Convert HTML to String for PS and RL
function convertHTMLToString(html) {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Recursive function to extract text and preserve formatting (like line breaks)
    function extractText(node) {
        let text = '';

        node.childNodes.forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE) {
                // Get the text content of the node
                text += child.textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                if (child.tagName === 'P') {
                    // If it's a paragraph and has content, add its text and follow it with double line breaks
                    const paragraphText = extractText(child).trim(); // Remove extra whitespace within the paragraph
                    if (paragraphText) {
                        text += paragraphText + '\n\n';
                    }
                } else if (child.tagName === 'BR') {
                    // Convert <br> to a single line break
                    text += '\n';
                } else {
                    // Recursively handle other types of elements (like div, span, etc.)
                    text += extractText(child);
                }
            }
        });

        return text;
    }

    // Get the text content from the parsed HTML
    let result = extractText(tempDiv);

    // Trim any extra spaces or newlines at the beginning or end of the result
    result = result.trim();

    // Ensure there are no more than two consecutive newlines
    result = result.replace(/\n{3,}/g, '\n\n');

    return result;
}



//Editor function
const Editor = ({ setSelectedContent, setPage, open, setOpen, setSelectedDom, content, type, pageType }) => {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [menuVisible, setMenuVisible] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const navito = useNavigate()


    const handleCvSubmit = () => {
        //const editorContent = editor.getJSON();
        setLoading(true);
        const fileName = localStorage.getItem('cv_name');
        const fileType = localStorage.getItem('cvfile_type');
        const cvid = localStorage.getItem('cv_id');
        const htmlContent = editor.getHTML();
        const jsonContent = convertHTMLToJSON(htmlContent);
        // Save the content to the backend
        // if (fileType !== 'json' && (cvid !== "" && cvid !== null)) {
        //     const body = { filename: fileName, cv: jsonContent.CV };
        //     PostToServerWithJson(NetworkApi.cv.createCV, body, (re) => {
        //         if (re.success === true) {
        //             setModalContent('CV saved successfully');
        //             setOpenModal(true);
        //             setSaveSuccess(true);
        //         } else {
        //             setModalContent(re.message.cn);
        //             setOpenModal(true);
        //             setSaveSuccess(false);
        //         }
        //         setLoading(false);
        //     });
        // } else 
        if (cvid === "" || cvid === null) {
            const date = new Date().toLocaleString().replace(',', '');
            const body = { filename: `CV_${date}`, cv: jsonContent.CV };
            PostToServerWithJson(NetworkApi.cv.createCV, body, (re) => {
                if (re.success === true) {
                    setModalContent('CV saved successfully');
                    setOpenModal(true);
                    setSaveSuccess(true);
                } else {
                    setModalContent(re.message.cn);
                    setOpenModal(true);
                    setSaveSuccess(false);
                }
                setLoading(false);
            });
        } else {
            const body = { cv_id: cvid, cv: jsonContent.CV };
            PutToServerWithJson(NetworkApi.cv.updateCV, body, (re) => {
                if (re.success === true) {
                    setModalContent('CV saved successfully');
                    setOpenModal(true);
                    setSaveSuccess(true);
                } else {
                    setModalContent(re.message.cn);
                    setOpenModal(true);
                    setSaveSuccess(false);
                }
                setLoading(false);
            });
        }
    }

    const handleCvOk = () => {
        if (saveSuccess) {
            navito('/dashboard/cv');
        } else {
            setOpenModal(false);
        }
    }

    const handlePsSubmit = () => {
        setLoading(true);
        const htmlContent = editor.getHTML();
        const body = { ps_id: localStorage.getItem('ps_id'), ps_statement: convertHTMLToString(htmlContent) };
        PutToServerWithJson(NetworkApi.ps.updatePS, body, (re) => {
            if (re.success === true) {
                setModalContent('PS updated successfully');
                setOpenModal(true);
                setSaveSuccess(true);
            } else {
                setModalContent(re.message.cn);
                setOpenModal(true);
                setSaveSuccess(false);
            }
            setLoading(false);
        });
    }

    const handlePsOk = () => {
        if (saveSuccess) {
            navito('/dashboard/ps');
        } else {
            setOpenModal(false);
        }
    }

    const handleRlSubmit = () => {
        setLoading(true);
        const htmlContent = editor.getHTML();
        const body = { rl_id: localStorage.getItem('rl_id'), RL: { letter: convertHTMLToString(htmlContent) } };
        PutToServerWithJson(NetworkApi.rl.updateRl, body, (re) => {
            if (re.success === true) {
                setModalContent('RL updated successfully');
                setOpenModal(true);
                setSaveSuccess(true);
            } else {
                setModalContent(re.message.cn);
                setOpenModal(true);
                setSaveSuccess(false);
            }
            setLoading(false);
        });
    }

    const handleRlOk = () => {
        if (saveSuccess) {
            navito('/dashboard/rl');
        } else {
            setOpenModal(false);
        }
    }

    // 获取选中的文字,设置floating menu的位置
    const handleClick = () => {
        const focusedElement = document.querySelector(type === 'cv' ? 'ul.has-focus' : '.paragraphhas-focus');
        setSelectedDom(focusedElement);
        if (focusedElement) {
            const extractedContent = focusedElement.innerText;
            // Get position of the focused element relative to its parent
            const extractPosition = focusedElement.getBoundingClientRect();
            const container = document.querySelector('.cveditor-container');
            const parentPosition = container.getBoundingClientRect();

            const menuWidth = 230; // Approximate width of your floating menu
            const left = extractPosition.right - menuWidth - parentPosition.left; // Adjust based on the parent's position
            const top = extractPosition.bottom - parentPosition.top + container.scrollTop + 3; // Adjust based on the parent's position and scroll offset

            setSelectedContent(extractedContent);
            setMenuPosition({ top: top, left: left });
            setMenuVisible(true);
        } else {
            setSelectedContent('');
            setMenuVisible(false);
        }
    }
    //打开右边的bar重新计算floating menu 位置
    useEffect(() => {
        const focusedElement = document.querySelector(type === 'cv' ? 'ul.has-focus' : '.paragraphhas-focus');
        if (focusedElement) {
            // Get position of the focused element relative to its parent
            const extractPosition = focusedElement.getBoundingClientRect();
            const container = document.querySelector('.cveditor-container');
            const parentPosition = container.getBoundingClientRect();

            const menuWidth = 230; // Approximate width of your floating menu
            const left = extractPosition.right - menuWidth - parentPosition.left; // Adjust based on the parent's position
            const top = extractPosition.bottom - parentPosition.top + container.scrollTop + 3; // Adjust based on the parent's position and scroll offset
            setMenuPosition({ top: top, left: left });
        };
    }, [open])

    //同步选中文字的更改
    const handleUpdate = useCallback(() => {
        const focusedElement = document.querySelector(type === 'cv' ? 'ul.has-focus' : '.paragraphhas-focus');
        if (focusedElement) {
            const extractedContent = focusedElement.innerText;
            setSelectedContent(extractedContent);
        }
    }, [setSelectedContent]);

    //tiptap 编辑器 Configuration
    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                heading: false,  // Disable the default heading
                paragraph: false,  // Disable the default paragraph
                bulletList: false,  // Disable the default bullet list
            }),
            CustomHeading,
            CustomParagraph,
            CustomBulletList,
            TextStyle,
            Heading,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Focus.configure({
                className: `${type === 'cv' ? '' : 'paragraph'}has-focus`,
                mode: 'all',
            }),
            AddExperienceNode,
            AddSectionNode,
            Section,
            Div,
            CustomSpan,
            DeleteSectionNode,
            DeleteItemNode,
            CharacterCount,
        ],
        content,
        autofocus: true,
        onUpdate: handleUpdate,
    });


    const saveAsPDF = async () => {
        setLoading(true);
        try {
            // Hide elements we don't want in the PDF
            const elementsToHide = document.querySelectorAll('.AddNewButton, .DeleteItemButton, .DeleteSectionButton');
            elementsToHide.forEach((element) => {
                element.style.display = 'none';
            });

            const editorContent = document.querySelector('.tiptap.ProseMirror');

            // Create a wrapper div to control the width
            const wrapper = document.createElement('div');
            wrapper.style.width = '210mm'; // A4 width
            wrapper.style.margin = '0 auto';

            // Clone the editor content to avoid modifying the original
            const contentClone = editorContent.cloneNode(true);
            wrapper.appendChild(contentClone);

            // Temporarily append to document for proper rendering
            document.body.appendChild(wrapper);

            // Configure PDF options
            const options = {
                margin: [15, 10, 15, 10], // Adjust margins for more balance
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: {
                    scale: 3,
                    useCORS: true,
                    logging: true,
                    letterRendering: true,
                    windowWidth: wrapper.scrollWidth
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait',
                    compress: true
                },
                pagebreak: {
                    mode: 'css',
                    avoid: ['h3', 'h4', 'h5', 'h6', 'li', '.section-title', '.contact-info'] // Avoid breaking within headers and list items
                },
            };

            // Keep the original CSS that preserves layout while handling page breaks
            const styleSheet = document.createElement('style');
            styleSheet.textContent = `
                .tiptap {
                    padding: 10px 20px 20px 20px
                    border-radius: 10px;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    font-family: "Times New Roman", Times, serif;
                    height: auto !important; /* Override fixed height */
                }
    
                /* Preserve all your original styles */
                
                .tiptap section {
                    margin-top: 8px;
                    margin-bottom: 2px;
                }
                
                .tiptap h3 {
                    height: fit-content;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    font-size: 16px;    /* Increased from 14px */
                    margin-top: 5px;
                }
                
                .tiptap h5 {
                    height: fit-content;
                    margin: 5px 0;
                    break-after: avoid;
                    page-break-after: avoid;
                }
                
                .tiptap h5 {
                    height: fit-content;
                    margin: 5px 0;
                    break-after: avoid;
                    page-break-after: avoid;
                }

    
                
    
                .tiptap h6 {
                    height: fit-content;
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    font-size: 16px;    /* Increased from 14px */
                    margin-top: 5px;
                }
    
                .tiptap p {
                    height: fit-content;
                    text-align: left;
                    margin: 0;
                    font-size: 14px;    /* Increased from 12px */
                }
    
                .tiptap hr {
                    border-bottom: 1px solid #ccc;
                    margin: 0 0 2px 0;
                }
    
                .tiptap ul {
                    padding-left: 15px;
                    list-style: disc;
                    font-size: 14px;    /* Increased from 12px */
                }
    
                .tiptap span {
                    height: fit-content;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    font-size: 14px;    /* Increased from 12px */
                }
    
                /* Minimal page break rules that won't affect layout */
                @media print {
                    /* Ensure content can flow across pages */
                    .tiptap {
                        overflow: visible !important;
                    }
    
                    /* Allow breaks inside larger sections */
                    .tiptap div, .tiptap p {
                        page-break-inside: auto;  /* Allow breaks for flexibility */
                    }
    
                    li {
                        page-break-inside: avoid; /* Prevent breaking list items across pages */
                    }
    
                    /* Handle orphans and widows */
                    p {
                        orphans: 3;
                        widows: 3;
                    }
                    
                    /* Allow natural breaks between major sections */
                    section {
                        height: auto !important;
                        overflow: visible !important;
                    }
    
                    /* Keep headers with their content, but be less strict */
                    h3, h4, h5, h6 {
                        page-break-after: auto;  /* Allow more natural breaks */
                    }
    
                    /* Remove any fixed heights that might interfere with page breaks */
                    div, section {
                        height: auto !important;
                    }
                }
            `;

            // Apply styles directly to the wrapper
            wrapper.style.cssText = `
            background-color: white;
            padding: 10mm;
            box-sizing: border-box;
            height: auto !important;
            `;

            // Add the stylesheet to wrapper
            wrapper.appendChild(styleSheet);

            // Pre-process content to ensure proper page flow
            const sections = wrapper.querySelectorAll('section, div');
            sections.forEach(section => {
                // Remove any fixed heights
                section.style.height = 'auto';
                // Ensure content can flow
                section.style.overflow = 'visible';
            });

            // // Generate PDF
            // await html2pdf()
            //     .set(options)
            //     .from(wrapper)
            //     .save();


            // Generate PDF with promise handling
            await new Promise((resolve, reject) => {
                html2pdf()
                    .set(options)
                    .from(wrapper)
                    .save()
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });

            // Clean up
            document.body.removeChild(wrapper);

            // Restore hidden elements
            elementsToHide.forEach((element) => {
                element.style.display = '';
            });

            setModalContent('File downloaded successfully');
            setOpenModal(true);
        } catch (error) {
            console.error('PDF generation error:', error);
            setModalContent('Error generating PDF: ' + error.message);
            setOpenModal(true);
        } finally {
            setLoading(false);
        }
    };






    return (
        <>
            <div className='cveditor-container'
                style={{
                    height: pageType === "progress" ? 'calc(100vh - 200px)' : 'calc(100vh - 110px)',
                    width: open ? 'calc(100% - 410px)' : 'calc(100% - 54px)', //根据侧边栏是否打开调整宽度
                    overflow: 'scroll',
                    scrollbarWidth: 'none',
                }} >
                <div className="editor-menubarContainer" >
                    <MenuBar editor={editor} type={type} />
                    {type !== 'cv' &&
                        <div className='editor-characterCount'>
                            <span>{editor.storage.characterCount.words()} words</span>
                            <span>{editor.storage.characterCount.characters()} characters</span>
                        </div>}
                    <button onClick={saveAsPDF} className='editor-saveButton'>{RequireText("AIwriting.download")}</button>
                    {type === 'cv' && <button onClick={handleCvSubmit} className='editor-saveButton'>{RequireText("AIwriting.save")}</button>}
                    {type === 'ps' && <button onClick={handlePsSubmit} className='editor-saveButton'>{RequireText("AIwriting.save")}</button>}
                    {type === 'rl' && <button onClick={handleRlSubmit} className='editor-saveButton'>{RequireText("AIwriting.save")}</button>}
                </div>
                <div className="editor-content-container" style={{ display: 'flex', alignItems: "start", justifyContent: "center", backgroundColor: '#F3F3F3' }} >
                    <EditorContent editor={editor} className="content-container" onClick={() => handleClick()} onBlur={() => setMenuVisible(false)} />
                </div>
                {menuVisible && <FloatingMenu menuPosition={menuPosition} editor={editor} setOpen={setOpen} setPage={setPage} type={type} />}
            </div>
            {openModal && type === "cv" && <ModalComponent content={modalContent} modalOpen={openModal} onOk={handleCvOk} />}
            {openModal && type === "ps" && <ModalComponent content={modalContent} modalOpen={openModal} onOk={handlePsOk} />}
            {openModal && type === "rl" && <ModalComponent content={modalContent} modalOpen={openModal} onOk={handleRlOk} />}
            {loading && <LoadingComponent />}
        </>
    );
};

export default Editor;


