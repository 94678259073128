import { ImageUrl } from "../../assets/media/ImageUrl";
import { useNavigate } from "react-router-dom";
import RequireText from "../../assets/i18n/TextDefiner";

export default function FootNav() {
    const navito = useNavigate();
    const footNavLinks = {
        List1: [
            { name: RequireText("footnav.ul1.il1"), link: "/application-preparation" },
            // { name: RequireText("footnav.ul1.il2"), link: "/study-support" },
            // { name: RequireText("footnav.ul1.il3"), link: "/career-development" }
        ],
        List2: [
            { name: RequireText("footnav.ul2.il1"), link: "/about-us" },
            { name: RequireText("footnav.ul2.il2"), link: "/pricing" },
            { name: RequireText("footnav.ul2.il3"), link: "/partnership" }
        ],
        List3: [
            { name: RequireText("footnav.ul3.il1"), link: "/contact" },
            { name: RequireText("footnav.ul3.il2"), link: "/terms-of-use" },
            { name: RequireText("footnav.ul3.il3"), link: "/privacy-policy" }
        ]
    }



    function NavItem({ title, link }) {

        function handleClick() {
            navito(link);
        }
        return (
            <div onClick={handleClick} className="footNav-item">
                <div>{title}</div>
            </div>
        )
    }



    return (
        <>
            <div className="footNav">
                <div className="footNav-logo-container">
                    <img src={ImageUrl.brandIcon_only} alt="logo" className="footNav-logo"/>
                </div>
                <div className="footNav-links-container">
                    <div className="footNav-slogan">{RequireText("footnav.slogan1")}
                        <br/> {RequireText("footnav.slogan2")}</div>
                    <div className="footNav-linkLists">
                        <div className="footNav-linkList">
                            {footNavLinks.List1.map((item, index) => (
                                <NavItem key={index} title={item.name} link={item.link}/>
                            ))}
                        </div>
                        <div className="footNav-linkList">
                            {footNavLinks.List2.map((item, index) => (
                                <NavItem key={index} title={item.name} link={item.link}/>
                            ))}
                        </div>
                        <div className="footNav-linkList">
                            {footNavLinks.List3.map((item, index) => (
                                <NavItem key={index} title={item.name} link={item.link}/>
                            ))}
                        </div>
                    </div>

                </div>

                <div className="footNav-socials">
                    <a href="https://www.linkedin.com/company/applyu-ai/" target="_blank" rel="noopener noreferrer" style={{ color: "#0077b5", textDecoration: "none" }}>
                        <i className="bi bi-linkedin"></i>
                    </a>
                    <a href="https://x.com/ApplyU_AI" target="_blank" rel="noopener noreferrer" style={{ color: "black", textDecoration: "none" }}>
                        <i className="bi bi-twitter-x"></i>
                    </a>
                    <a href="https://www.xiaohongshu.com/user/profile/64377dee000000000d0195c2?xhsshare=CopyLink&appuid=5c170f6f000000000703ba08&apptime=1731627399"
                       target="_blank" rel="noopener noreferrer">
                        <img alt="xiaohongshu" src={ImageUrl.Xiaohongshu_Icon}/>
                    </a>
                </div>

            </div>
        </>
    )
}