import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../../assets/media/ImageUrl";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer, PostToServerWithFile } from "../../assets/network/NetWork";
import { LoadingComponent } from "../Utilities/Loading";
import { ModalComponent } from "../Utilities/ModalComponent";

export function QuickStart({ filling, router }) {

    // 定义一个状态变量isDragging，用于判断是否正在拖动
    const [isDragging, setIsDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const navito = useNavigate()

    function ClickStart() {
        GetToServer(NetworkApi.cv.cvgetPermission, (re) =>{
            if(re.success === true){
                localStorage.setItem('cv_id', '')
                navito(router)
            }else{
                setOpenModal(true)
                setModalContent(re.message.en)
            }
        })
    }

    function handleOk() {
        setOpenModal(false);
        setLoading(false);
    }

    // 移除默认的拖动和放置监听器
    useEffect(() => {
        const preventDefault = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        window.addEventListener('dragover', preventDefault);
        window.addEventListener('drop', preventDefault);

        return () => {
            window.removeEventListener('dragover', preventDefault);
            window.removeEventListener('drop', preventDefault);
        };
    }, []);


    // 定义一个函数handleDragOver，用于处理拖动事件
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    // 定义一个函数handleDragLeave，用于处理拖动离开事件
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    // 定义一个函数handleDrop，用于处理放置事件
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        setLoading(true);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            // 在这里处理文件
            const formData = new FormData();
            formData.append('resumeFile', files[0]);
            formData.append('resume_upload_type', 'resume');

            const urlWithQuery = `${NetworkApi.cv.uploadParseCV}?resume_upload_type=resume`;
            GetToServer(NetworkApi.cv.cvgetPermission, (re) => {
                if (re.success === true) {
                    PostToServerWithFile(urlWithQuery, formData, (re) => {
                        if (re.success === true) {
                            localStorage.setItem('cv_id', re.cv_id)
                            localStorage.setItem("cv_name", re.filename)
                            navito("/dashboard/cv/cvEditor")
                        } else {
                            setModalContent(re.message.en)
                            setOpenModal(true)
                        }
                        setLoading(false);
                    })
                } else {
                    setLoading(false);
                    setModalContent(re.message.en)
                    setOpenModal(true)
                }
                
            }
            )
        }
    };

    // 定义一个函数handleFileSelect，用于处理文件选择事件
    const handleFileSelect = (e) => {
        setLoading(true);
        // 在这里处理文件选择
        const formData = new FormData();
        formData.append('resumeFile', e.target.files[0]);
        formData.append('resume_upload_type', 'resume');

        // check if the file is pdf
        const pattern = /.pdf/

        if (!pattern.test(e.target.files[0].name)) {
            setModalContent("Please upload a PDF file")
            setOpenModal(true)
            setLoading(false)
            //empty the input
            e.target.value = null
            //empty the formdata
            formData.delete('resumeFile')
            return
        }

        // check if the file size is less than 1.5MB
        if (e.target.files[0].size > 1.5 * 1024 * 1024){
            setModalContent("File size should be less than 1.5MB")
            setOpenModal(true)
            setLoading(false)
            //empty the input
            e.target.value = null
            //empty the formdata
            formData.delete('resumeFile')
            return
        }

        const urlWithQuery = `${NetworkApi.cv.uploadParseCV}?resume_upload_type=resume`;

        GetToServer(NetworkApi.cv.cvgetPermission, (re) => {
            if (re.success === true) {
                PostToServerWithFile(urlWithQuery, formData, (re) => {
                    if (re.success === true) {
                        localStorage.setItem('cv_id', re.cv_id)
                        localStorage.setItem("cv_name", re.filename)
                        navito("/dashboard/cv/cvEditor")
                    } else {
                        setModalContent(re.message.en)
                        setOpenModal(true)
                    }
                    setLoading(false);
                })
            } else {
                setLoading(false);
                setModalContent(re.message.en)
                setOpenModal(true)
            }
            
        }
        )
    };

    return (
        <>
            <div className="CvQuickStart">
                <label className="CvQuickStart-label">{filling.cardTitle}</label>
                <div className="CvQuickStart-contents">
                    <div
                        className={`CvQuickStart-contents-container ${isDragging ? 'dragging' : ''}`}
                        onClick={() => document.getElementById('fileInput').click()}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <img src={ImageUrl.CvBasket} alt="upload" />
                        <label>{filling.box1.title}</label>
                        <p>{filling.box1.description}</p>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            // accept=".pdf,.docx,.doc"
                            accept=".pdf"
                            onChange={handleFileSelect} //could add logic for uploading multiple data and API
                        />
                    </div>
                    <div className="CvQuickStart-contents-container" onClick={ClickStart}>
                        <img src={ImageUrl.CvRocket} alt="cv_template" />
                        <label>{filling.box2.title}</label>
                        <p>{filling.box2.description}</p>
                    </div>
                </div>
            </div>
            {loading && <LoadingComponent />}
            {openModal && <ModalComponent content={modalContent} modalOpen={openModal} onOk={handleOk} />}
        </>
    )
}