export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];

    // Generate page numbers based on the current page
    if (totalPages <= 10) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        if (currentPage <= 4) {
            pageNumbers.push(1, 2, 3, 4, 5, '...', totalPages);
        } else if (currentPage > 4 && currentPage < totalPages - 3) {
            pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
        } else {
            pageNumbers.push(1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
        }
    }

    return (
        <div className="pagination">
            <span
                className="prev"
                onClick={() => onPageChange(currentPage - 1)}
                style={{ visibility: currentPage === 1 ? 'hidden' : 'visible' }}
            >
                &lt;
            </span>
            {pageNumbers.map((number, index) => (
                <span
                    key={index}
                    onClick={() => typeof number === 'number' && onPageChange(number)}
                    className={number === currentPage ? 'active' : number === '...' ? 'ellipsis' : ''}
                >
                    {number}
                </span>
            ))}
            <span
                className="next"
                onClick={() => onPageChange(currentPage + 1)}
                style={{ visibility: currentPage === totalPages ? 'hidden' : 'visible' }}
            >
                &gt;
            </span>
        </div>
    );
};