import { QAUnitSelectTextBox, QAUnitSlider, QAUnitTextBox } from "../Utilities/DashboardItems";
import RequireText from "../../assets/i18n/TextDefiner";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { GetExGroup, GetExGroupNum, GetFuturePlan, GetPsMaxNum, SetExGroup, StoreExGroup } from "./_psProgressData";
import { Detect } from "../DashboardComponent/AIprogress";


export function PSpersonal() {
    const [questionExNum, SetQuestionExNum] = useState(1)
    const QuestionEx = ({ id = "" }) => {
        return (
            <div className="question">
                <QAUnitSelectTextBox id={id} data={{
                    nameQ: "ps-personalQ", nameA: "ps-personalA",
                    options: [RequireText("psGeneration.exOption1"), RequireText("psGeneration.exOption2"), RequireText("psGeneration.exOption3"), RequireText("psGeneration.exOption4")]
                }}
                onBlur={() => StoreExGroup(questionExNum)}>
                </QAUnitSelectTextBox>
            </div>
        )
    }
    const QuestionExGroup = () => {
        let group = []
        for (let i = 0; i < questionExNum; i++) {
            group.push(i)
        }
        return (
            group.map((item) => <QuestionEx key={item} id={"questionEx" + item}></QuestionEx>)
        )
    }
    function ChangeQuestionNum(num) {
        SetQuestionExNum(num)
        localStorage.setItem("ExGroupNum", num)
        StoreExGroup(num)
    }
    function AddEx() {
        if (questionExNum < 5)
            ChangeQuestionNum(questionExNum + 1)
    }
    function DeleteEx() {
        if (questionExNum > 1)
            ChangeQuestionNum(questionExNum - 1)
    }
    if (questionExNum !== GetExGroupNum()) { SetQuestionExNum(GetExGroupNum()) }

    Detect("/dashboard/ps")


    useEffect(() => {
        const ExData = GetExGroup()
        if (ExData) {
            SetExGroup(ExData, questionExNum)
        }
    }, [questionExNum])

    return (
        <div className="psPersonal">
            <div className="questionGroup">
                <div className="title">{RequireText("psGeneration.tellExperience")}</div>
                <div className="questionList">
                    <QuestionExGroup />
                    <div className="ExOperateBar">
                        <div onClick={() => AddEx()} className="add">
                            <PlusOutlined />
                            <span>Add Question</span>
                        </div>
                        <div onClick={() => DeleteEx()} className="delete">
                            <MinusOutlined />
                            <span>Delete</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className="statement">
                <div className="title">{RequireText("psGeneration.futurePlan")}</div>
                <div className="question">
                    <QAUnitTextBox id="futurePlan" data={{
                        name: "psStatement",
                        question: ""
                    }}></QAUnitTextBox>
                </div>
            </div>
            <div className="else">
                <div className="title">{RequireText("psGeneration.other")}</div>
                <div className="question">
                    <QAUnitSlider id="psMaxNum" sliderData={{ question: RequireText("psGeneration.selectMaximumWord"), min: 200, max: 2000 }} />
                </div>

            </div>
        </div>
    )
}