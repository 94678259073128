
import HeadNav from "../components/HomeComponent/HeadNav"
import FootNav from "../components/HomeComponent/FootNav"
import { useEffect } from "react";

const PrivacyPolicy = () => {

    //Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    })


    return (
        <>
            <HeadNav></HeadNav>
            <div className="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>Last updated: Oct 18, 2024</p>

                <p>
                    Apply U ("we," "our," "us") is committed to protecting your privacy. This
                    Privacy Policy explains how we collect, use, disclose, and safeguard your
                    information when you use our platform, services, and products, including
                    AI-powered educational tools (collectively, the “Services”). By using our
                    Services, you agree to the terms of this Privacy Policy.
                </p>

                <p>
                    Please read this policy carefully. If you have any questions or concerns,
                    feel free to contact us at <a href="mailto:service@apply-u.com">service@apply-u.com</a>.
                </p>

                <h2>1. Information We Collect</h2>
                <p>
                    We collect various types of information to provide and improve our
                    Services, enhance user experience, and ensure compliance with applicable
                    regulations.
                </p>

                <h3>a. Personal Information</h3>
                <p>Personal information refers to data that can identify an individual. We may collect the following personal information:</p>
                <ul>
                    <li>Contact details (e.g., name, email address, phone number)</li>
                    <li>Account details (e.g., username, password)</li>
                    <li>Profile information (e.g., institution, role such as student or educator)</li>
                    <li>Payment information (when applicable, e.g., credit card details, billing address)</li>
                    <li>Demographic information (e.g., location)</li>
                </ul>

                <h3>b. AI-Generated Data</h3>
                <p>
                    When you use our AI-powered educational tools, we collect data generated by the AI algorithms for the following purposes:
                </p>
                <ul>
                    <li>improving the accuracy and performance of the AI</li>
                    <li>Monitoring system performance and troubleshooting issues</li>
                </ul>

                <h3>c. Automatically Collected Information</h3>
                <p>When you use our Services, we automatically collect certain information, including:</p>
                <ul>
                    <li>Device information (e.g., IP address, browser type, operating system)</li>
                    <li>Usage data (e.g., pages viewed, time spent on the platform, features used)</li>
                    <li>Cookies and tracking technologies (e.g., web beacons, pixel tags) to collect information about your interaction with the Services.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p>We use the collected information to provide, improve, and personalize our Services, as well as to comply with legal obligations. Specific uses include:</p>
                <ul>
                    <li>Providing educational tools and resources, including personalized learning experiences through AI algorithms.</li>
                    <li>Improving our Services by analyzing usage trends, feedback, and performance.</li>
                    <li>Managing accounts and authentication, including password recovery and technical support.</li>
                    <li>Communication via emails or notifications regarding updates, promotions, or policy changes.</li>
                    <li>Compliance with applicable laws and regulations, including protecting student data under FERPA and COPPA.</li>
                    <li>Security purposes, including detecting and preventing fraud, unauthorized access, or illegal activities.</li>
                </ul>

                <h2>3. Data Sharing and Disclosure</h2>
                <p>We do not sell, rent, or trade your personal information to third parties. However, we may share your information under the following circumstances:</p>

                <h3>a. Service Providers</h3>
                <p>
                    We may share your information with trusted third-party service providers that help us operate the Services, such as:
                </p>
                <ul>
                    <li>Payment processors</li>
                    <li>Cloud storage providers</li>
                    <li>Analytics and performance monitoring services</li>
                </ul>

                <h3>b. Educational Institutions</h3>
                <p>If you are a student or educator using our Services through a school or institution, we may share data with the institution for the purpose of facilitating learning and tracking progress.</p>

                <h3>c. Legal Compliance</h3>
                <p>We may disclose your information if required by law, such as in response to a court order, subpoena, or other legal processes, or to protect our rights, users, and systems from illegal activity or fraud.</p>

                <h2>4. Student Data Protection</h2>
                <p>We take the privacy and security of student data seriously. Our Services are designed to comply with the following regulations:</p>
                <ul>
                    <li>FERPA (Family Educational Rights and Privacy Act)</li>
                    <li>COPPA (Children’s Online Privacy Protection Act)</li>
                </ul>

                <h2>5. Data Retention</h2>
                <p>We will retain personal information and student data for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When we no longer need to retain your information, we will securely delete or anonymize it.</p>

                <h2>6. Security of Your Information</h2>
                <p>We implement appropriate technical and organizational security measures to protect your personal information and student data from unauthorized access, disclosure, or loss. These measures include encryption, secure data storage, and regular security assessments.</p>

                <h2>7. Your Data Privacy Rights</h2>
                <p>Depending on your location and the applicable privacy laws, you may have certain rights regarding your personal information, including:</p>
                <ul>
                    <li>Access</li>
                    <li>Correction</li>
                    <li>Deletion</li>
                    <li>Data Portability</li>
                    <li>Opt-Out</li>
                </ul>

                <p>To exercise your rights, please contact us at <a href="mailto:service@apply-u.com">service@apply-u.com</a>. We may need to verify your identity before processing your request.</p>

                <h2>8. International Data Transfers</h2>
                <p>Apply U is based in the UK, and our services may involve the processing of your data in locations outside of your country of residence, including areas outside of the UK and EEA. We are committed to implementing all necessary safeguards to protect your data wherever it is processed in accordance with applicable data protection laws.</p>

                <h2>9. Cookies and Tracking Technologies</h2>
                <p>We use cookies and similar tracking technologies to enhance your experience and collect data about how you interact with our Services. You can control your cookie settings through your browser or device.</p>

                <h2>10. Third-Party Links</h2>
                <p>Our Services may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party services, and we are not responsible for their privacy practices.</p>

                <h2>11. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. Your continued use of the Services after any changes constitutes your acceptance of the updated policy.</p>

                <h2>12. Contact Us</h2>
                <p className="contact-info">
                    If you have any questions or concerns about this Privacy Policy or how we handle your information, please contact us at:
                    <br />
                    Apply U
                    <br />
                    128 City Road, London, United Kingdom, EC1V 2NX
                    <br />
                    <a href="mailto:service@apply-u.com">service@apply-u.com</a>
                </p>
            </div>
        </>
    );
};

export default PrivacyPolicy;
