import { useState, useEffect } from "react"
import { ImageUrl } from "../../assets/media/ImageUrl";
import { Icon } from "@iconify/react/dist/iconify.js";
import { GetToServer } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useNavigate } from "react-router-dom";
import RequireText from "../../assets/i18n/TextDefiner";
import { JsonToList } from "./ProgramGuideProgressData";
import { ModalComponent } from "../Utilities/ModalComponent";



const WishListDetailCard = ({ data, type, isSelected, onToggleProgram }) => {
    const [expanded, setExpanded] = useState(false);

    const overview = RequireText("findProgram.overview");
    const learning = RequireText("findProgram.learning");
    const career = RequireText("findProgram.career");
    const admission = RequireText("findProgram.admission");

    const handleAddWishlist = (event) => {
        event.stopPropagation();
        onToggleProgram(data);
    };

    const toggleExpand = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <div className="WhislistPickDetail-card" onClick={handleAddWishlist}>
            <div className="program-header">
                <img src={data.program_icon === '' ? ImageUrl.image1 : data.program_icon} alt="School Logo" className="logo" />
                <div className="school-info">
                    <h2 className="course-name">{data.course_name}</h2>
                    <p className="school-name">{data.uni_name}</p>
                </div>
                {type === 'left' && <div className={`PickCircle ${isSelected ? 'picked' : ''}`}>
                    {isSelected && (
                        <Icon icon="mdi:tick" width="30px" height="30px" />
                    )}
                </div>}
            </div>
            <div className="tags">
                <span className="tag">{RequireText("findProgram.justranking")}: {data.university_ranking}</span>
                <span className="tag">{data.course_mode}</span>
                <span className="tag">{data.course_country}</span>
                <span className="tag">{data.course_duration}</span>
            </div>
            {type === 'left' && (
                <>
                    <button className="expand-toggle" onClick={toggleExpand}>
                        {expanded ? 'See less' : 'See more'}
                    </button>
                    {expanded && (
                        <>
                            <section className="overview">
                                <h2>{overview}</h2>
                                <p>
                                    {data.course_overview}
                                </p>
                            </section>
                            <div className="details">
                                <section className="learning">
                                    <h2>{learning}</h2>
                                    <ul>
                                        {data.course_learning.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </section>
                                <section className="career">
                                    <h2>{career}</h2>
                                    <ul>
                                        {data.course_career.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </section>
                            </div>
                            <section className="admission">
                                <h2>{admission}</h2>
                                <ol>
                                    {data.course_entry_requirements.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ol>
                            </section>
                        </>
                    )}
                </>
            )}
        </div>
    );
};


export const ProgramWishlistPick = () => {
    // Ensure selectedPrograms initializes from localStorage if available, and it's always an array
    const [selectedPrograms, setSelectedPrograms] = useState(() => {
        const storedWishlist = localStorage.getItem("pgwishlist");
        try {
            const parsedWishlist = JSON.parse(storedWishlist);
            return parsedWishlist ? Object.values(parsedWishlist) : [];
        } catch (e) {
            return [];
        }
    });

    const [data, setData] = useState([]);
    const [loading, SetLoading] = useState(true);
    const [modalContent, setModalContent] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const navito = useNavigate();

    function ClickStart() {
        navito("/dashboard/browseporgram");
    }

    useEffect(() => {
        GetToServer(NetworkApi.programResource.get_wishlist, (re) => {
            if (re.success === true && re.wishlist_item !== null) {
                setData(re.wishlist_item);
            } else {
                setModalContent(re.message.cn);
                setModalOpen(true);
            }
            SetLoading(false);
        });
    }, []);

    useEffect(() => {
        if (selectedPrograms.length >= 0) {
            const selectedProgramsIds = {};
            for (let i = 0; i < selectedPrograms.length; i++) {
                selectedProgramsIds[i.toString()] = selectedPrograms[i];
            }
            localStorage.setItem("pgwishlist", JSON.stringify(selectedProgramsIds));
        }
    }, [selectedPrograms]);

    const handleToggleProgram = (program) => {
        if (selectedPrograms.some((selectedProgram) => selectedProgram.program_id === program.program_id)) {
            // Remove from selected
            setSelectedPrograms((prevSelectedPrograms) =>
                prevSelectedPrograms.filter((selectedProgram) => selectedProgram.program_id !== program.program_id)
            );
            localStorage.setItem('pgwishlistPicked', 'true');
            return; // Stop executing further
        }

        // Check if we have less than 10 before adding
        if (selectedPrograms.length < 10) {
            setSelectedPrograms((prevSelectedPrograms) => [...prevSelectedPrograms, program]);
        } else {
            setModalContent("最多只能选择10个");
            setModalOpen(true);
        }
    };

    const handleOk = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    return (
        <>
            <div className="programWishlistPick" style={{ display: "flex", flexDirection: "row" }}>
                <div className="LeftPad">
                    <div className="LeftPad-heading">
                        <label>{RequireText("findProgram.chooseFromWishlist")}</label>
                        <span onClick={ClickStart}>{RequireText("findProgram.programResource")}</span>
                    </div>
                    <div className="LeftPad-content">
                        {!loading &&
                            data.map((program, index) => (
                                <WishListDetailCard
                                    key={index}
                                    data={program.program}
                                    type="left"
                                    isSelected={selectedPrograms.some(
                                        (selectedProgram) =>
                                            selectedProgram.program_id === program.program.program_id
                                    )}
                                    onToggleProgram={handleToggleProgram}
                                />
                            ))}
                    </div>
                </div>
                <div className="Rightpad">
                    <div className="Rightpad-heading">
                        <label>{RequireText("findProgram.selected")}</label>
                        <span>{selectedPrograms.length}/10</span>
                    </div>
                    <div className="Rightpad-content">
                        {!loading &&
                            selectedPrograms.map((program, index) => (
                                <WishListDetailCard
                                    key={index}
                                    data={program}
                                    type="right"
                                    isSelected={true}
                                    onToggleProgram={handleToggleProgram}
                                />
                            ))}
                    </div>
                </div>
            </div>
            {modalOpen && (
                <ModalComponent onOk={handleOk} modalOpen={modalOpen} content={modalContent} />
            )}
        </>
    );
};
