
import { useNavigate } from "react-router-dom";
import { FileCard, FunctionCard } from "../Utilities/DashboardItems";
import RequireText from "../../assets/i18n/TextDefiner";
import { GetToServer } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useEffect, useState } from "react";
import { ImageUrl } from "../../assets/media/ImageUrl";
import { ModalComponent } from "../Utilities/ModalComponent";
import { DeleteToServerWithJson,PutToServerWithJson } from "../../assets/network/NetWork";
import { RenamePopup } from "../CvComponent/RenamePopup";

export function PShome() {
    const navito = useNavigate()

    function ClickStart() {
        navito("/dashboard/ps/step1")
    }

    const [existPs, SetExistPs] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [rename, setRename] = useState(false)

    // const FileCardGroup = () => {
    //     return (
    //         <>
    //             <FileCard fileData={FileData}></FileCard>
    //             <FileCard fileData={FileData}></FileCard>
    //             <FileCard fileData={FileData}></FileCard>
    //         </>

    //     )
    // }

    useEffect(() => {
        GetToServer(NetworkApi.ps.getExistPs, (re) => {
            if (re.success === true) {
                SetExistPs(re.ps);
            }else{
                setModalContent(re.message.cn)
                setModalOpen(true);
            }
        })
    }, [rename])

    const handleClick = (id) => {
        localStorage.setItem("ps_id", id);
        navito("/dashboard/ps/psEditor")
    }

    const handleOk = () => {
        setModalOpen(false)
        navito('/user/login')
    } 

    const handleDelete = (id) => {
        DeleteToServerWithJson(NetworkApi.ps.deletePS.concat('/', id), {}, (res) => {
            if (res.success === true) {
                SetExistPs(prev => {
                    const newFiles = prev.filter(item => item.ps_id !== id);
                    return newFiles;
                });
            } else {
                setModalContent(res.message.cn)
                setModalOpen(true)
            }
        })

    };

    const handleRename = (id) => {
        localStorage.setItem("ps_id", id);
        setRename(true);
    }

    return (
        <div className="psHome">
            <div onClick={ClickStart} className="quickStart">
                <div className="title">{RequireText("AIwriting.quick")}</div>
                <FunctionCard tips={RequireText("psGeneration.createStatement")} imgUrl={ImageUrl.PsHomeChart}></FunctionCard>
                <div className="lineDivider"></div>
            </div>
            <div className="prevFiles">
                <div className="title">{RequireText("AIwriting.prevFile")}</div>
                <div className="cardGroup">
                    {existPs.length > 0 && existPs.map((item, index) => {
                        return (<FileCard key={index} fileData={item} onclick={() => handleClick(item.ps_id)} onDelete={() => handleDelete(item.ps_id)} onRename={() => handleRename(item.ps_id)}></FileCard>)
                    })
                    }
                </div>
            </div>
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
            {rename && <RenamePopup setRename={setRename} type={'ps'} />}
        </div>
    )
}