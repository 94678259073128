import { EditorPage } from "../EditorComponent/_EditorPage";
import { Template1 } from "../EditorComponent/CVTemplate";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer } from "../../assets/network/NetWork";
import { useEffect, useState } from "react";
import { LoadingComponent } from "../Utilities/Loading";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";

export function CVEditor() {
    const [cvcontent, setCvContent] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modelMessage, setModalMessage] = useState("");

    const navito = useNavigate();

    const handleOk = () => {
        setModalOpen(false);
        navito("/dashboard/cv");
    }

    useEffect(() => {
        var cvID = localStorage.getItem('cv_id');
        if (cvID === "") {
            const temp = Template1();
            setCvContent(temp);
        } else {
            GetToServer(NetworkApi.cv.getSingleCV.concat("/", cvID), (re) => {
                if (re.success === true) {
                    setCvContent(Template1(re.data))
                } else {
                    setModalOpen(true);
                    setModalMessage(re.message.cn);
                }
            })
        }
    }, [])


    return (
        <div>
            {cvcontent ? <EditorPage content={cvcontent} type="cv" /> : <LoadingComponent />}
            {modalOpen &&
                <Modal title="Message" style={{ top: 400 }} open={modalOpen} footer={[
                    <Button key='closeModal' type="primary" onClick={handleOk}>
                        Close
                    </Button>
                ]}>
                    <p>{modelMessage ? modelMessage : "服务器错误，请联系管理员寻求帮助"}</p>
                </Modal>
            }
        </div>
    );
};