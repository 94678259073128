import { easeIn, motion, useScroll, useSpring, useTransform } from "framer-motion"
import anime from "animejs";
import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import RequireText from "../../assets/i18n/TextDefiner";
import { ImageUrl } from "../../assets/media/ImageUrl";
import { useNavigate } from "react-router-dom";

export default function TitleFirst() {
    const navito = useNavigate();
    const { scrollYProgress } = useScroll();
    const rectConifg = [{
        id: 1,
        x0: -400, y0: 0,
        x1: -300, y1: 680
    },
    {
        id: 2,
        x0: 400, y0: 0,
        x1: 300, y1: 680
    },
    {
        id: 3,
        x0: -300, y0: 360,
        x1: -300, y1: 800
    },
    {
        id: 4,
        x0: 300, y0: 360,
        x1: 300, y1: 800
    },
    {
        id: 5,
        x0: -500, y0: 520,
        x1: -300, y1: 800
    },
    {
        id: 6,
        x0: 500, y0: 520,
        x1: 300, y1: 800
    },]
    const springConfig = {
        mass: 0.7, stiffness: 84, damping: 10
    }
    const RectItem = ({ id, itemConfig }) => {
        const tx = useTransform(scrollYProgress, [0, .05], [itemConfig.x0, itemConfig.x1], easeIn)
        const ty = useTransform(scrollYProgress, [0, .05], [itemConfig.y0, itemConfig.y1])
        const sx = useSpring(tx, springConfig)
        const sy = useSpring(ty, springConfig)
        const ro = useSpring(useTransform(scrollYProgress, [0, .05], [15 * (((id % 2) * 2) - 1), 0]), springConfig)
        return (
            <motion.div
                className={`rect-${id}`}
                style={{ x: sx, y: sy, rotateZ: ro }}
            >
                <img src={ImageUrl[`Rect_${id + 1}`]} alt="" />

            </motion.div>
        )
    }

    const Background = () => {
        const [init, setInit] = useState(false);

        const options = {
            "background": {
                "color": {
                    "value": "#00000"
                },
                "position": "50% 50%",
                "repeat": "no-repeat",
                "size": "cover"
            },
            // 帧数，越低越卡,默认60
            "fpsLimit": 120,
            "fullScreen": {
                "zIndex": 1
            },
            "interactivity": {
                "events": {
                    "onClick": {
                        "enable": false,
                        "mode": "push"
                    },
                    "onHover": {
                        "enable": true,
                        "mode": "slow"
                    }
                },
                "modes": {
                    "push": {
                        //点击是添加1个粒子
                        "quantity": 3,
                    },
                    "bubble": {
                        "distance": 200,
                        "duration": 2,
                        "opacity": 0.8,
                        "size": 20,
                        "divs": {
                            "distance": 200,
                            "duration": 0.4,
                            "mix": false,
                            "selectors": []
                        }
                    },
                    "grab": {
                        "distance": 400
                    },
                    //击退
                    "repulse": {
                        "divs": {
                            //鼠标移动时排斥粒子的距离
                            "distance": 200,
                            //翻译是持续时间
                            "duration": 0.4,
                            "factor": 100,
                            "speed": 1,
                            "maxSpeed": 50,
                            "easing": "ease-out-quad",
                            "selectors": []
                        }
                    },
                    //缓慢移动
                    "slow": {
                        //移动速度
                        "factor": 2,
                        //影响范围
                        "radius": 200,
                    },
                    //吸引
                    "attract": {
                        "distance": 200,
                        "duration": 0.4,
                        "easing": "ease-out-quad",
                        "factor": 3,
                        "maxSpeed": 50,
                        "speed": 1

                    },
                }
            },
            //  粒子的参数
            "particles": {
                //粒子的颜色
                "color": {
                    "value": "#000000"
                },
                //是否启动粒子碰撞
                "collisions": {
                    "enable": true,
                },
                //粒子之间的线的参数
                "links": {
                    "color": {
                        "value": "rgb(170, 170, 170)"
                    },
                    "distance": 100,
                    "enable": true,
                    "warp": true
                },
                "move": {
                    "attract": {
                        "rotate": {
                            "x": 600,
                            "y": 1200
                        }
                    },
                    "enable": true,
                    "outModes": {
                        "bottom": "out",
                        "left": "out",
                        "right": "out",
                        "top": "out"
                    },
                    "speed": 2,
                    "warp": true
                },
                "number": {
                    "density": {
                        "enable": true
                    },
                    //初始粒子数
                    "value": 20
                },
                //透明度
                "opacity": {
                    "value": 0.2,
                    "animation": {
                        "speed": 3,
                        "minimumValue": 0.1
                    }
                },
                //大小
                "size": {
                    "random": {
                        "enable": true
                    },
                    "value": {
                        "min": 1,
                        "max": 5
                    },
                    "animation": {
                        "speed": 18,
                        "minimumValue": 0.1
                    }
                }
            }
        }
        useEffect(() => {
            initParticlesEngine(async (engine) => {
                await loadFull(engine);
            }).then(() => {
                setInit(true)
            })
        }, [])
        return (<>
            <div className="background">
                <div className="layers">
                    <div className="layer-1"></div>
                    <div className="layer-2"></div>
                    <div className="layer-3"></div>
                    <div className="layer-4"></div>
                    <div className="layer-5"></div>
                    <div className="layer-6"></div>
                    <div className="layer-7"></div>
                </div>
                <div className="dots">
                    <div className="dot-1"></div>
                    <div className="dot-2"></div>
                    <div className="dot-3"></div>
                    <div className="dot-4"></div>
                    <div className="dot-5"></div>
                    <div className="dot-6"></div>
                    <div className="dot-7"></div>
                    <div className="dot-8"></div>
                    <div className="dot-9"></div>
                    <div className="dot-10"></div>
                    <div className="dot-11"></div>
                    <div className="dot-12"></div>
                </div>
                <div className="rects">
                    {rectConifg.map((item) =>
                    (
                        <RectItem key={item.id} id={item.id} itemConfig={item}>
                        </RectItem>
                    ))
                    }
                </div>
                {/* <div className="particles">
                    {init ? <Particles id="tsparticles" options={options} ></Particles> : <></>}
                </div> */}



            </div>
        </>)
    }

    useEffect(() => {
        // 文本动画
        const textWrapper = document.querySelector('.titletext');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        const t1 = anime.timeline({ loop: false })
            .add({
                targets: '.titletext .letter',
                translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 300, // 动画时间
                delay: (el, i) => 100 * i
            })
            // .add({
            //     targets: '.titletext',
            //     opacity: 0,
            //     duration: 1000,
            //     easing: "easeOutExpo",
            //     delay: 5000
            // })
            ;

        return () => t1.restart();
    }, []);

    return (
        <>
            <div className="d-x-center titleFirst">
                <div className="title">{RequireText("home.slogan1")}<br /> <span className="titletext highlight">{RequireText("home.slogan2")}</span></div>
                <div className="text">{RequireText("home.subtitle")}</div>
                <div className="btn" onClick={() => navito("/dashboard")}>{RequireText("home.ctaButton")}</div>
                <Background></Background>

            </div>
        </>
    )
}