import { ImageUrl } from "../../assets/media/ImageUrl";
import { Image } from "react-bootstrap";
import RequireText from "../../assets/i18n/TextDefiner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserNode } from "../UserComponent/UserNode";
import { SetLanguage } from "../../assets/i18n/SetLanguage";
import { Icon } from '@iconify/react';

export default function HeadNav() {
    const navito = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const [isMidScreen, setIsMidScreen] = useState(window.innerWidth < 1080);
    const [menuOpen, setMenuOpen] = useState(false);

    // 初始化导航项
    const navitems = {
        navitem1: {
            title: RequireText("headnav.item3"),
            url: "/pricing",
        },
        navitem3: {
            title: RequireText("headnav.item4"),
            url: "/",
        },
        navitem4: {
            title: RequireText("headnav.item5"),
            url: "/contact",
        },
        buttonText: RequireText("headnav.button"),
    };

    // Sticky 监听
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                entry.target.classList.toggle("headnav-sticking", entry.intersectionRatio < 1);
            },
            { root: null, threshold: [1] }
        );

        const el = document.querySelector(".headnav");
        if (el) observer.observe(el);

        return () => observer.disconnect();
    }, []);

    // 监听窗口大小
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
            setIsMidScreen(window.innerWidth < 1080);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // 点击事件处理
    function handleClick(url) {
        navito(url);
        setMenuOpen(false);
    }

    // 中央导航项渲染
    function NavItems() {
        return (
            <div className="headnav-items">
                <div onClick={() => handleClick(navitems.navitem1.url)} className="headnav-item">{navitems.navitem1.title}</div>
                {/* <div onClick={() => handleClick(navitems.navitem3.url)} className="headnav-item">{navitems.navitem3.title}</div> */}
                <div onClick={() => handleClick(navitems.navitem4.url)} className="headnav-item">{navitems.navitem4.title}</div>
            </div>
        );
    }

    return (
        <div className="d-x-center headnav">
            <div className="headnav-container">
                <div className="headnav-container-icon" onClick={() => navito("/")} style={{ maxWidth: 160 }}>
                    <Image height={"35px"} src={isMidScreen ? ImageUrl.brandIcon_only : ImageUrl.brandIcon} />
                </div>

                {/* Only render navigation items if not on small screens */}
                {!isSmallScreen && <NavItems />}

                <div className="applicationNode">
                    <div className="languageNode"><SetLanguage /></div>
                    <div className="userArea"><UserNode /></div>

                    {/* Only render "Enter Application" button if not on small screens */}
                    {!isSmallScreen && (
                        <div className="button-normal R-hidden" onClick={() => handleClick("/dashboard")}>
                            {navitems.buttonText}
                        </div>
                    )}

                    {/* Render the menu icon only on small screens */}
                    {isSmallScreen && (
                        <div className="menu-icon" onClick={() => setMenuOpen(prev => !prev)}>
                            <Icon icon="mdi:menu" width="30" height="30" />
                        </div>
                    )}
                </div>

            </div>

            {/* Render dropdown menu if open and on small screens */}
            {menuOpen && isSmallScreen && (
                <div className="dropdown-menu">
                    <div className="dropdown-item" onClick={() => handleClick(navitems.navitem1.url)}>
                        {navitems.navitem1.title}
                    </div>
                    {/* <div className="dropdown-item" onClick={() => handleClick(navitems.navitem3.url)}>
                        {navitems.navitem3.title}
                    </div> */}
                    <div className="dropdown-item" onClick={() => handleClick(navitems.navitem4.url)}>
                        {navitems.navitem4.title}
                    </div>
                    <div className="dropdown-item" onClick={() => handleClick("/dashboard")}>
                        {navitems.buttonText}
                    </div>
                </div>
            )}
        </div>
    );
}
