import { useState, useEffect, memo } from "react";
import majors from '../ProgramResourceComponents/Major';
import career from '../ProgramResourceComponents/Career';
import { Range } from 'react-range';
import { Icon } from '@iconify/react/dist/iconify.js';
import RequireText from "../../assets/i18n/TextDefiner";
import { useTranslation } from "react-i18next";

export const PersonalizationPage = () => {
    const { t } = useTranslation();

    // A memoized TagsComponent to avoid unnecessary re-renders
    const TagsComponent = memo(({ content, isSelected, onClick }) => (
        <div
            className="tagContainer"
            style={{ backgroundColor: isSelected ? '#DAE4F9' : '' }}
            onClick={onClick}
        >
            {content}
        </div>
    ));

    const LeftItem = ({ question, answer, questionid }) => {
        const handleSelect = () => setSelectedQuestion(questionid);

        return (
            <div className={`leftpadItem ${questionid === selectedQuestion ? 'selected' : ''}`} onClick={handleSelect}>
                <label>{question}</label>
                <div className="answerContainer">
                    {answer.length === 0 ? (
                        <span>Click & answer</span>
                    ) : questionid !== 3 ? (
                        answer.map((content, index) => (
                            <TagsComponent
                                content={content}
                                key={index}
                                isSelected={true}
                                onClick={() => updateSelection(questionid, content)}
                            />
                        ))
                    ) : (
                        <span className="rankingAnswer">Top: {selectedAnswer.uni_ranking[0]} - {selectedAnswer.uni_ranking[1]}</span>
                    )}
                </div>
            </div>
        );
    };

    const LongCheckListComponent = ({ title, data, questionid }) => (
        <div className="LongCheckListComponent">
            <div className="LongCheckListComponent-title">
                <label className="mainTitle">{title.main}</label>
                <label className="subTitle">{title.sub}</label>
            </div>
            <div className="LongCheckListComponent-body">
                {page === 0 ? (
                    Object.entries(data).map(([key, value]) => (
                        <div key={key} className="LongCheckListComponent-body-majorMianType">
                            <span
                                className="majorMianTypelabel"
                                onClick={() => {
                                    setMainMajor({ id: key, en_name: value.en_name });
                                    setPage(1);
                                }}
                            >
                                {value.en_name}
                            </span>
                            {selectedAnswer[Object.keys(selectedAnswer)[questionid]].filter((item) => Object.keys(value.en).includes(item)).length !== 0 &&
                                <div className="countingBlock">{selectedAnswer[Object.keys(selectedAnswer)[questionid]].filter((item) => Object.keys(value.en).includes(item)).length}</div>}
                        </div>
                    ))
                ) : (
                    <>
                        <div className="LongCheckListComponent-body-majorsubType-title" onClick={() => setPage(0)}>
                            <Icon icon="mingcute:left-fill" />
                            <span>{mainMajor.en_name}</span>
                            {selectedAnswer[Object.keys(selectedAnswer)[questionid]].filter((item) => Object.keys(data[mainMajor.id].en).includes(item)).length !== 0 &&
                                <div className="countingBlock">{selectedAnswer[Object.keys(selectedAnswer)[questionid]].filter((item) => Object.keys(data[mainMajor.id].en).includes(item)).length}</div>}
                        </div>
                        <div className="LongCheckListComponent-body-majorsubType-body">
                            {Object.keys(data[mainMajor.id].en).map((disciplineKey) => (
                                <TagsComponent
                                    key={disciplineKey}
                                    content={disciplineKey}
                                    isSelected={selectedAnswer[Object.keys(selectedAnswer)[questionid]].includes(disciplineKey)}
                                    onClick={() => updateSelection(questionid, disciplineKey)}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    const ShortChecklistComponent = ({ title, data, questionid }) => (
        <div className="LongCheckListComponent">
            <div className="LongCheckListComponent-title">
                <label className="mainTitle">{title.main}</label>
                <label className="subTitle">{title.sub}</label>
            </div>
            <div className="LongCheckListComponent-body-majorsubType-body">
                {Array.isArray(data) ? (
                    // If data is an array, map directly over its items
                    data.map((item) => (
                        <TagsComponent
                            key={item}
                            content={item}
                            isSelected={selectedAnswer[Object.keys(selectedAnswer)[questionid]].includes(item)}
                            onClick={() => updateSelection(questionid, item)}
                        />
                    ))
                ) : (
                    // If data is an object, map over its key-value pairs
                    Object.entries(data).map(([key, label]) => (
                        <TagsComponent
                            key={key}
                            content={label}  // Display the user-friendly label
                            isSelected={selectedAnswer[Object.keys(selectedAnswer)[questionid]].includes(key)}
                            onClick={() => updateSelection(questionid, key)} // Send the key to the backend
                        />
                    ))
                )}
            </div>
        </div>
    );


    const SliderComponent = ({ title, min, max }) => {
        const [localRanking, setLocalRanking] = useState(selectedAnswer.uni_ranking || [1, 200]);

        useEffect(() => {
            setLocalRanking(selectedAnswer.uni_ranking);
        }, [selectedAnswer.uni_ranking]);

        return (
            <div className="SliderComponent">
                <div className="LongCheckListComponent-title">
                    <label className="mainTitle">{title.main}</label>
                    <label className="subTitle">{title.sub}</label>
                </div>
                <div className="SliderComponent-body">
                    <div className="slider-container">
                        <Range
                            values={localRanking}
                            step={1}
                            min={min}
                            max={max}
                            onChange={setLocalRanking}
                            onFinalChange={updateRanking}
                            renderTrack={({ props, children }) => (
                                <div {...props} className="track">
                                    {children}
                                </div>
                            )}
                            renderThumb={({ index, props }) => (
                                <div {...props} className="program-thumb">
                                    <div className="program-thumb-label">{localRanking[index]}</div>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const getStoredIndicators = () => {
        try {
            return JSON.parse(localStorage.getItem('PgIndicators')) || {
                major: [],
                career: [],
                purpose: [],
                uni_ranking: [1, 200],
                location: []
            };
        } catch (e) {
            console.error("Error parsing PgIndicators from localStorage:", e);
            return {
                major: [],
                career: [],
                purpose: [],
                uni_ranking: [1, 200],
                location: []
            };
        }
    };

    const [selectedQuestion, setSelectedQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(getStoredIndicators());
    const [page, setPage] = useState(0);
    const [mainMajor, setMainMajor] = useState({ id: '', en_name: '' });

    // Save selectedAnswer to localStorage with debounce effect
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            localStorage.setItem('PgIndicators', JSON.stringify(selectedAnswer));
        }, 500); // Debounce updates to avoid excessive writes

        return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
    }, [selectedAnswer]);

    // Reset page and mainMajor whenever selectedQuestion changes
    useEffect(() => {
        setPage(0);
        setMainMajor({ id: '', en_name: '' });
    }, [selectedQuestion]);

    const updateSelection = (questionid, item) => {
        const categoryMap = {
            0: 'major',
            1: 'career',
            2: 'purpose',
            4: 'location'
        };
        const category = categoryMap[questionid];

        setSelectedAnswer(prevState => {
            const currentSelection = [...prevState[category]];
            const itemIndex = currentSelection.indexOf(item);

            if (itemIndex === -1) {
                currentSelection.push(item);
            } else {
                currentSelection.splice(itemIndex, 1);
            }

            return {
                ...prevState,
                [category]: currentSelection
            };
        });
    };

    const updateRanking = (newRanking) => {
        setSelectedAnswer(prevState => ({
            ...prevState,
            uni_ranking: newRanking
        }));
    };

    return (
        <div className="PersonalizationPage" style={{ display: "flex", flexDirection: "row" }}>
            <div className="LeftPad">
                <div className="LeftPad-heading">
                    <label>{RequireText("findProgram.personalization")}</label>
                </div>
                <div className="LeftPad-content">
                    <LeftItem
                        question={RequireText("findProgram.targetMajor")}
                        questionid={0}
                        answer={selectedAnswer.major}
                    />
                    <LeftItem
                        question={RequireText("findProgram.careerPlan")}
                        questionid={1}
                        answer={selectedAnswer.career}
                    />
                    <LeftItem
                        question={RequireText("findProgram.studyAbroad")}
                        questionid={2}
                        answer={selectedAnswer.purpose}
                    />
                    <LeftItem
                        question={RequireText("findProgram.universityRanking")}
                        questionid={3}
                        answer={selectedAnswer.uni_ranking}
                    />
                    <LeftItem
                        question={RequireText("findProgram.whichlocation")}
                        questionid={4}
                        answer={selectedAnswer.location}
                    />
                </div>
            </div>
            <div className="Rightpad">
                <div className="Rightpad-heading">
                    <label>Personalization</label>
                </div>
                <div className="Rightpad-content">
                    {selectedQuestion === 0 && (
                        <LongCheckListComponent
                            data={majors}
                            questionid={0}
                            title={{ main: RequireText("findProgram.targetMajor"), sub: RequireText("findProgram.selectAnswer") }}
                        />
                    )}
                    {selectedQuestion === 1 && (
                        <LongCheckListComponent
                            data={career}
                            questionid={1}
                            title={{ main: RequireText("findProgram.careerPlan"), sub: RequireText("findProgram.selectAnswer") }}
                        />
                    )}
                    {selectedQuestion === 2 && (
                        <ShortChecklistComponent
                            questionid={2}
                            data={['Apply for a PhD', 'Find a Job', 'Improvement']}
                            title={{ main: RequireText("findProgram.studyAbroad"), sub: RequireText("findProgram.selectAnswer") }}
                        />
                    )}
                    {selectedQuestion === 3 && (
                        <SliderComponent
                            min={1}
                            max={500}
                            title={{ main: RequireText("findProgram.universityRanking"), sub: RequireText("findProgram.selectAnswer") }}
                        />
                    )}
                    {selectedQuestion === 4 && (
                        <ShortChecklistComponent
                            questionid={4}
                            data={['Australia', 'New Zealand', 'United Kingdom', 'Hong Kong SAR', 'Singapore','United States', 'Canada', 'Denmark', 'Germany', 'Switzerland','Sweden', "Ireland", 'Finland', 'Macau SAR']}
                            title={{ main: RequireText("findProgram.location"), sub: RequireText("findProgram.selectAnswer") }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
