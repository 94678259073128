import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../../assets/media/ImageUrl";
import {LoadingComponent} from "../Utilities/Loading"
import { ModalComponent } from "../Utilities/ModalComponent";
import { GetToServer } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useTranslation } from "react-i18next";



export function ProgramQuickStart({filling}) {

    // 定义一个状态变量isDragging，用于判断是否正在拖动
    const [isDragging, setIsDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false)
    const [modalContent, setModalContent] = useState('')
    const navito = useNavigate()
    function ClickStart(router) {
        setLoading(true)
        GetToServer(NetworkApi.user.userQuota,(re)=>{
            console.log(re)
            if(re.success===true){
                if(re.quota.program_guide>0){
                    navito(router)
                }else{
                    setModal(true)
                    setModalContent('Sorry, your program guide quota has been used up. Please purchase more quota to continue.')
                }
            }else{
                setModal(true)
                setModalContent(re.message.cn)
            }
            setLoading(false)
        })
    }

    const handleOk = () => {
        setModal(false)
    }


    return (
        <>
            <div className="CvQuickStart">
                <label className="CvQuickStart-label">{filling.cardTitle}</label>
                <div className="CvQuickStart-contents">
                    <div
                        className={`CvQuickStart-contents-container`}
                        onClick={() => ClickStart('/dashboard/programGuide/match/step1')}
                    >
                        <img src={ImageUrl.CvBasket} alt="Matching" />
                        <label>{filling.box1.title}</label>
                        <p>{filling.box1.description}</p>
                    </div>
                    <div className="CvQuickStart-contents-container" onClick={() => ClickStart('/dashboard/programGuide/recom/step1')}>
                        <img src={ImageUrl.CvRocket} alt="Recommend" />
                        <label>{filling.box2.title}</label>
                        <p>{filling.box2.description}</p>
                    </div>
                </div>
            </div>
            {loading && <LoadingComponent />}
            {modal && <ModalComponent content={modalContent} modalOpen={modal} onOk={handleOk}/>}
        </>
    )
}