import { ProgramDetailCard } from "./ProgramDetailCard";
import { useState,useEffect } from "react";
import { GetToServer } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useNavigate } from "react-router-dom";

export function ProgramWishlistPage() {

    const [sortedData, setSortedData] = useState([]); // Initial state with the unsorted data

    const navito = useNavigate()
    function ClickStart() {
        navito("/dashboard/browseporgram")
    }

    useEffect(() => {
         GetToServer(NetworkApi.programResource.get_wishlist, (re) => {
            if (re.success === true && re.wishlist_item.length > 0) {
                setSortedData(re.wishlist_item)
            } else {
                //错误处理
            }
        });

    },[])

    const handleSelect = (e) => {
        const value = e.target.value;
        let sortedArray =  [...sortedData];

        switch (value) {
            case 'Name':
                sortedArray.sort((a, b) => a.program.course_name.localeCompare(b.program.course_name));
                break;
            case 'Ranking':
                sortedArray.sort((a, b) => a.program.university_ranking - b.program.university_ranking);
                break;
            case 'Date Added':
                sortedArray.sort((a, b) => new Date(b.wishlist_id) - new Date(a.wishlist_id));
                break;
            default:
                break;
        }

        setSortedData(sortedArray);
    };

    return (
        <>
            <div className="programWishlistPage" style={{ maxHeight: '100vh' }}>
                <div className="programWishlistPage-Head">
                    <label className="programWishlistLabel">Program Wishlist</label>
                    <span onClick={ClickStart}>Back to browse programs</span>
                </div>
                <div className="programWishlistPage-control">
                    <label>Sorted by:</label>
                    <select className="sortSelect" onChange={handleSelect}>
                        <option value='Date Added'>Date Added</option>
                        <option value='Name'>Name</option>
                        <option value='Ranking'>Ranking</option>
                    </select>
                </div>
                <div className="programWishlistPage-Body">
                    {sortedData.map((program, index) => (
                        <ProgramDetailCard key={index} data={program} type={'wishlist'} setSortedData={setSortedData}/>
                    ))}
                </div>
            </div>
        </>
    );
};


