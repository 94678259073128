
import { useEffect, useState } from "react";
import { EditorPage } from "../EditorComponent/_EditorPage";
import { Detect } from "../DashboardComponent/AIprogress";
import { convertToHTML } from "../RLComponent/_rlProgressData";

export function PSoverview() {
    let default_content = ``
    //获取最新content
    const content = localStorage.getItem("d_ps")
    if (content !== null && content !== undefined) {
        default_content=content
    }
    const [currentContent, SetCurrentContent] = useState(default_content)
    const definer = convertToHTML(currentContent)
    // `<h1 style='text-align:center'>Personal Statement</h1>
    // <p>${currentContent}</p>`
    Detect("/dashboard/ps")
    return (
        <div className="psOverview">
            <EditorPage type={'ps'} content={definer} pageType={'progress'}/>
        </div>
    )
}