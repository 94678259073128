import HeadNav from "../components/HomeComponent/HeadNav"
import FootNav from "../components/HomeComponent/FootNav"
import { useEffect } from "react";

const TermsOfUse = () => {

    //Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <>
            <HeadNav />
            <div className="terms-of-use">
                <h1>Terms of Use</h1>
                <p>Last updated: Oct 18, 2024</p>

                <p>
                    Welcome to Apply U! These Terms of Use (“Terms”) govern your access to and use of Apply U's platform, services, and products, including our AI-based educational tools (collectively, the “Services”). By using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Services.
                </p>

                <p>
                    Please read these Terms carefully. If you have any questions, feel free to contact us at <a href="mailto:service@apply-u.com">service@apply-u.com</a>.
                </p>

                <h2>1. Acceptance of Terms</h2>
                <p>By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our <a href="/privacy-policy">Privacy Policy</a>. These Terms apply to all users, including students, educators, institutions, and other individuals or entities.</p>

                <h2>2. Changes to the Terms</h2>
                <p>We may update these Terms from time to time. We will notify you of any material changes by posting the new Terms on the platform or via email. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.</p>

                <h2>3. Eligibility</h2>
                <ul>
                    <li>You agree to comply with all applicable laws and regulations while using the Services.</li>
                </ul>

                <h2>4. Account Registration</h2>
                <p>To access certain features of our Services, you may need to create an account. You agree to:</p>
                <ul>
                    <li>Provide accurate and complete information during registration.</li>
                    <li>Maintain the confidentiality of your account and password.</li>
                    <li>Notify us immediately of any unauthorized use of your account.</li>
                </ul>

                <h2>5. Use of Services</h2>
                <p>You agree to use the Services solely for educational purposes and in compliance with these Terms. You agree not to:</p>
                <ul>
                    <li>Use the Services for any illegal or unauthorized purpose.</li>
                    <li>Modify, reverse-engineer, or attempt to extract the source code of our AI tools.</li>
                    <li>Disrupt the integrity or performance of the Services.</li>
                    <li>Upload or transmit any harmful or malicious content, such as viruses or malware.</li>
                </ul>

                <h2>6. AI and Educational Content</h2>
                <p>
                    Apply U provides AI-powered educational tools that assist with education tasks. While our AI strives for accuracy, the educational content generated may not always be error-free or suitable for all learning contexts. You acknowledge that:
                </p>
                <ul>
                    <li>Our AI is a tool to assist learning or decision-making, not a substitute for personalized instruction or official judgment.</li>
                    <li>Apply U is not responsible for any errors, omissions, or inaccuracies in the content generated.</li>
                    <li>You should verify the information and use your judgment when applying it to educational contexts.</li>
                </ul>

                <h2>7. User-Generated Content</h2>
                <p>
                    Certain features may allow you to upload, submit, or share content, including student data, assignments, or feedback (collectively, “User Content”). By submitting User Content, you grant Apply U a non-exclusive, worldwide, royalty-free license to use, store, reproduce, and display the content to provide and improve our Services.
                </p>

                <h2>8. Privacy and Data Protection</h2>
                <p>
                    Our collection and use of personal data are governed by our <a href="/privacy-policy">Privacy Policy</a>, which explains how we collect, process, and protect your personal information. By using the Services, you agree to the collection and use of information in accordance with our Privacy Policy.
                </p>

                <h2>9. Intellectual Property</h2>
                <p>All content provided by Apply U, including but not limited to data, AI algorithms, software, graphics, and trademarks, is the property of Apply U or its licensors. You agree not to use, reproduce, or distribute any such content without our prior written consent, except as expressly permitted by these Terms.</p>

                <h2>10. Payment and Subscriptions</h2>
                <p>Some features of the Services may require payment of fees or subscription charges. By selecting a paid plan, you agree to pay all applicable fees and charges as outlined at the time of purchase. All payments are non-refundable unless otherwise stated.</p>

                <h2>11. Third-Party Services</h2>
                <p>Our Services may integrate with third-party platforms or services. Your use of such third-party services is subject to their respective terms and conditions. Apply U is not responsible for the availability or accuracy of third-party services.</p>

                <h2>12. Termination</h2>
                <p>
                    We reserve the right to suspend or terminate your access to the Services at any time if we believe you are in violation of these Terms, or for any other reason, at our sole discretion. Upon termination, you must cease using the Services and delete any copies of our content in your possession.
                </p>

                <h2>13. Disclaimers and Limitation of Liability</h2>
                <p>Disclaimers:</p>
                <ul>
                    <li>The Services are provided on an “as-is” and “as available” basis, without warranties of any kind, either express or implied.</li>
                    <li>Apply U does not guarantee that the Services will be error-free, secure, or available at all times.</li>
                </ul>

                <p>Limitation of Liability:</p>
                <ul>
                    <li>To the fullest extent permitted by law, Apply U and its affiliates shall not be liable for any indirect, incidental, special, or consequential damages, including but not limited to loss of data, revenue, or profits, arising out of your use or inability to use the Services.</li>
                </ul>

                <h2>14. Indemnification</h2>
                <p>
                    You agree to indemnify and hold harmless Apply U, its affiliates, and their officers, directors, employees, and agents from any claims, liabilities, damages, or expenses (including legal fees) arising from your use of the Services or violation of these Terms.
                </p>

                <h2>15. Governing Law</h2>
                <p>These Terms shall be governed by and construed in accordance with the laws of jurisdiction. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in London, UK.</p>

                <h2>16. Contact Information</h2>
                <p className="contact-info">
                    If you have any questions or concerns about these Terms or the Services, please contact us at:
                    <br />
                    Apply U
                    <br />
                    128 City Road, London, United Kingdom, EC1V 2NX
                    <br />
                    <a href="mailto:service@apply-u.com">service@apply-u.com</a>
                </p>
            </div>
        </>
    );
};

export default TermsOfUse;
