import { useSpring, useTransform, motion, inView } from "framer-motion"
import { useEffect } from "react"
import RequireText from "../../assets/i18n/TextDefiner";

export default function DataSection() {
    const DataCard = ({ id, number, textKey, showPlus = false }) => {

        const sNum = useSpring(0, { mass: 0.8, stiffness: 75, damping: 15 })
        const display = useTransform(sNum, num => {
            const formattedNum = Math.round(num).toLocaleString();
            return showPlus && num > 0 ? formattedNum + '+' : formattedNum;
        })
        //数字动画
        useEffect(() => {
            const numbox = document.getElementById(id);
            inView(numbox, () => {
                sNum.set(number);
                return () => sNum.set(0);
            });
        }, [id, number, sNum]);

        return (
            <div className="dataSection-card">
                <motion.div id={id} className="number">{display}</motion.div>
                <div className="tip" style={{ flexWrap: 'wrap', textAlign: 'center' }}>{RequireText(textKey)}</div> {/* 使用中英对照的文本 */}
            </div>
        );
    }
    const Divider = () => {
        return (
            <div className="R-hidden divider">
            </div>
        )
    }

    return (
        <>
            <div className="d-x-center dataSection">
                <DataCard id="datanum1" number={30000} textKey="home.data1" showPlus={true} />
                <Divider />
                <DataCard id="datanum2" number={100000} textKey="home.data2" showPlus={true} />
                <Divider />
                <DataCard id="datanum3" number={10} textKey="home.data3" showPlus={false} />
            </div>
        </>
    )
}