import { HomeText } from "./Texts/HomeText"
import { elseText } from "./Texts/ElseText"


export const en_text = {
    translation:
        { ...HomeText.en, ...elseText.en }
}




export const zh_text = {
    translation:
        HomeText.zh
}
