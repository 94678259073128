export const Template1 = (json) => {

    if (!json) {
        return (` 
            <!-- Full Name and Contact Information -->
            <h2 class="full-name" style="text-align: center;">Full Name</h2>
            <h5 class="contact-info" style="text-align: center;">Phone | Email | LinkedIn</h5>


        <!-- Education Section -->

    <section class="education-section">
        <h3 className="section-title">EDUCATION <delete-section></delete-section></h3>
        <hr className="section-divider" />
      <!-- Iterate over Education_section array -->
      <div class="education-item">
          <h6 class="school-info">
            <strong><span class="school-name">School Name <delete-item></span></strong>
            <span class="school-location">City, State</span>
        </h6>
        <h6 class="degree-info">
            <em><span class="degree-type">Degree Type</span></em>
            <span class="graduation-date">Graduation Date: MM/YYYY</span>
        </h6>
        <ul class="education-details">
            <li>Course or Detail 1</li>
            <li>Course or Detail 2</li>
            <li>Course or Detail 3</li>
        </ul>
    </div>
    <!-- Repeat for more education items -->
    <AddExperience section='education'></AddExperience>
</section>



<!-- Work Experience Section -->

<section class="work-section">
    <h3 class="section-title">WORK EXPERIENCE <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <!-- Iterate over Work_section array -->
    <div class="work-item">
        <h6 class="company-info">
            <strong><span class="company-name">Company Name <delete-item></span></strong>
            <span class="company-location">City, State</span>
        </h6>
        <h6 class="position-info">
            <em><span class="job-title">Job Title</span></em>
            <span class="work-dates float-right">MM/YYYY - MM/YYYY</span>
        </h6>
        <ul class="work-details">
            <li>Job Detail 1</li>
            <li>Job Detail 2</li>
            <li>Job Detail 3</li>
        </ul>
    </div>
    <!-- Repeat for more work items -->
    <AddExperience section='work'></AddExperience>
</section>


<!-- Project Experience Section -->

<section class="project-section">
    <h3 class="section-title">PROJECT EXPERIENCE <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <!-- Iterate over Project_section array -->
    <div class="project-item" style="margin-bottom: 20px;">
        <h6 class="project-info">
            <strong><span class="project-name">Project Name <delete-item></span></strong>
            <span class="project-location float-right">City, State</span>
        </h6>
        <h6 class="project-role-info">
            <em><span class="project-role">Project Role</span></em>
            <span class="project-dates float-right">MM/YYYY - MM/YYYY</span>
        </h6>
        <ul class="project-details">
            <li>Project Detail 1</li>
            <li>Project Detail 2</li>
            <li>Project Detail 3</li>
        </ul>
    </div>
    <!-- Repeat for more project items -->
    <AddExperience section='project'></AddExperience>
</section>



<!-- Activities Section -->

<section class="activity-section">
    <h3 class="section-title">ACTIVITIES <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <!-- Iterate over Activity_section array -->
    <div class="activity-item">
        <h6 class="organisation-info">
            <strong><span class="organisation-name">Organisation Name <delete-item></span></strong>
            <span class="organisation-position float-right">Position</span>
        </h6>
        <h6 class="activity-role-info">
            <em><span class="activity-role">Activity Role</span></em>
            <span class="activity-dates float-right">MM/YYYY - MM/YYYY</span>
        </h6>
        <ul class="activity-details">
            <li>Activity Detail 1</li>
            <li>Activity Detail 2</li>
            <li>Activity Detail 3</li>
        </ul>
    </div>
    <!-- Repeat for more activity items -->
    <AddExperience section='activity'></AddExperience>
</section>

<!-- Publications Section -->

<section class="publication-section">
    <h3 class="section-title">PUBLICATIONS <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <!-- Iterate over Publication_section array -->
    <div class="publication-item">
        <h6 class="publication-info">
            <strong><span class="publication-name">Publication Title <delete-item></span></strong>
            <span class="publication-date">Date: MM/YYYY</span>
        </h6>
        <p class="publication-url"><a href="Publication URL">Publication URL</a></p>
    </div>
    <!-- Repeat for more publication items -->
    <AddExperience section='publication'></AddExperience>
</section>



<!-- Skills Section -->

<section class="skill-section">
    <h3 class="section-title">SKILLS <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <!-- Iterate over Skill_section array -->
    <ul class="skill-item">
        <li class="skill-title"><strong>Skills</strong>:</li>
        <li class="skill-title"><strong>Languages</strong>:</li>
        <li class="skill-title"><strong>More skills</strong>: ... </li>
    </ul>
    <!-- Repeat for more skill categories -->
</section>

<!-- Awards Section -->

<section class="award-section">
    <h3 class="section-title">AWARDS <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <!-- Iterate over Award_section array -->
    <ul class="award-item">
        <li>Award Name - Date</li>
    </ul>
    <!-- Repeat for more award items -->
</section>

<AddSection></AddSection>


      `)
    }else if(Object.keys(json).includes('HTML')){
        return json.HTML
    }

    return (` 
      <!-- Full Name and Contact Information -->
        <h2 class="full-name" style="text-align: center;">${json.personal_info_section.first_name||'Full Name'} ${json.personal_info_section.last_name}</h2>
        <h5 class="contact-info" style="text-align: center;">${json.personal_info_section.phone_number||'Phone'} | ${json.personal_info_section.email||'Email'} | ${json.personal_info_section.linkedin||'LinkedIn'}</h5>
        <!-- Education Section -->
        <section class="education-section">
          <h3 className="section-title">EDUCATION <delete-section></delete-section></h3>
          <hr className="section-divider" />
          ${json.education_section && Object.keys(json.education_section).length > 0 ? Object.keys(json.education_section).map(key => `
          <div class="education-item">
            <h6 class="school-info">
              <strong><span class="school-name">${json.education_section[key].School.split(',')[0] || 'School Name'} <delete-item></span></strong>
              <span class="school-location">City, State</span>
            </h6>
            <h6 class="degree-info">
              <em><span class="degree-type">${json.education_section[key].Degree || 'Degree Type'}</span></em>
              <span class="graduation-date">Graduation Date: ${json.education_section[key].Graduation_Date || 'MM/YYYY'}</span>
            </h6>
            <ul class="education-details">
              <li>GPA: ${json.education_section[key].GPA || 'GPA'}</li>
              <li>Average Score: ${json.education_section[key].Avg_score || 'Average Score'}</li>
            </ul>
          </div>
          `).join('') : '<p>No education details available.</p>'}
          <AddExperience section='education'></AddExperience>
        </section>
  
        <!-- Work Experience Section -->
        <section class="work-section">
          <h3 class="section-title">WORK EXPERIENCE <delete-section></delete-section></h3>
          <hr class="section-divider" />
          ${json.work_section && Object.keys(json.work_section).length > 0 ? Object.keys(json.work_section).map(key => `
          <div class="work-item">
            <h6 class="company-info">
              <strong><span class="company-name">${json.work_section[key].Company || 'Company Name'} <delete-item></span></strong>
              <span class="company-location">${json.work_section[key].Location || 'City, State'}</span>
            </h6>
            <h6 class="position-info">
              <em><span class="job-title">${json.work_section[key].Position || 'Job Title'}</span></em>
              <span class="work-dates float-right">${json.work_section[key].Start_date || 'MM/YYYY'} - ${json.work_section[key].End_date || 'MM/YYYY'}</span>
            </h6>
            <ul class="work-details">
              <li>${json.work_section[key].Description || 'Job Description'}</li>
            </ul>
          </div>
          `).join('') : '<p>No work experience available.</p>'}
          <AddExperience section='work'></AddExperience>
        </section>
  
        <!-- Project Experience Section -->
        <section class="project-section">
          <h3 class="section-title">PROJECT EXPERIENCE <delete-section></delete-section></h3>
          <hr className="section-divider" />
          ${json.project_section && Object.keys(json.project_section).length > 0 ? Object.keys(json.project_section).map(key => `
          <div class="project-item" style="margin-bottom: 20px;">
            <h6 class="project-info">
              <strong><span class="project-name">${json.project_section[key].Project_name || 'Project Name'} <delete-item></span></strong>
              <span class="project-location float-right">Location</span>
            </h6>
            <h6 class="project-role-info">
              <em><span class="project-role">${json.project_section[key].Project_type || 'Project Role'}</span></em>
              <span class="project-dates float-right">${json.project_section[key].Start_date || 'MM/YYYY'} - ${json.project_section[key].End_date || 'MM/YYYY'}</span>
            </h6>
            <ul class="project-details">
              <li>${json.project_section[key].Description || 'Project Description'}</li>
            </ul>
          </div>
          `).join('') : '<p>No project experience available.</p>'}
          <AddExperience section='project'></AddExperience>
        </section>
  
        <!-- Skills Section -->
        <section class="skill-section">
          <h3 class="section-title">SKILLS <delete-section></delete-section></h3>
          <hr class="section-divider" />
          <ul class="skill-item">
            ${json.skill_section && Object.keys(json.skill_section).length > 0 ? Object.keys(json.skill_section).map(key => `
              <li class="skill-title"><strong>Skills</strong>: ${json.skill_section[key].join(', ')}</li>
            `).join('') : '<p>No skills available.</p>'}
          </ul>
        </section>
  
        <!-- Awards Section -->
        <section class="award-section">
          <h3 class="section-title">AWARDS <delete-section></delete-section></h3>
          <hr class="section-divider" />
          <ul class="award-item">
            ${json.award_section && Object.keys(json.award_section).length > 0 ? Object.keys(json.award_section).map(key => `
              <li>${json.award_section[key].award_name || 'Award Name'} - ${json.award_section[key].date || 'MM/YYYY'}</li>
            `).join('') : '<p>No awards available.</p>'}
          </ul>
        </section>
  
        <!-- Publications Section -->
        <section class="publication-section">
          <h3 class="section-title">PUBLICATIONS <delete-section></delete-section></h3>
          <hr class="section-divider" />
          ${json.publication_section && Object.keys(json.publication_section).length > 0 ? Object.keys(json.publication_section).map(key => `
          <div class="publication-item">
            <h6 class="publication-info">
              <strong><span class="publication-name">${json.publication_section[key].publication_name || 'Publication Title'} <delete-item></span></strong>
              <span class="publication-date">Date: ${json.publication_section[key].date || 'MM/YYYY'}</span>
            </h6>
            <p class="publication-url"><a href="${json.publication_section[key].publication_url || '#'}">Publication URL</a></p>
          </div>
          `).join('') : '<p>No publications available.</p>'}
          <AddExperience section='publication'></AddExperience>
        </section>
  
        <!-- Activities Section -->
        <section class="activity-section">
          <h3 class="section-title">ACTIVITIES <delete-section></delete-section></h3>
          <hr class="section-divider" />
          ${json.volunteer_section && Object.keys(json.volunteer_section).length > 0 ? Object.keys(json.volunteer_section).map(key => `
          <div class="activity-item">
            <h6 class="organisation-info">
              <strong><span class="organisation-name">${json.volunteer_section[key].Organization || 'Organization Name'} <delete-item></span></strong>
              <span class="organisation-position float-right">${json.volunteer_section[key].Location || 'Position'}</span>
            </h6>
            <h6 class="activity-role-info">
              <em><span class="activity-role">${json.volunteer_section[key].Position || 'Activity Role'}</span></em>
              <span class="activity-dates float-right">${json.volunteer_section[key].Start_date || 'MM/YYYY'} - ${json.volunteer_section[key].End_date || 'MM/YYYY'}</span>
            </h6>
            <ul class="project-details">
                <li>${json.volunteer_section[key].Description || 'Activity Description'}</li>
            </ul>
          </div>
          `).join('') : '<p>No activities available.</p>'}
          <AddExperience section='activity'></AddExperience>
        </section>
  
    `);
}

