import React, { useEffect, useState } from 'react';
import { QRCode } from 'antd';
// import '../styles/PaymentOptions.scss';
// import PaymentWechat from '../assets/payment-wechat.png';
import { Icon } from '@iconify/react/dist/iconify';
import RequireText from "../../assets/i18n/TextDefiner";
import { PostToServerWithJson } from "../../assets/network/NetWork"
import { NetworkApi } from "../../assets/network/NetworkApi"


export const PaymentOptions = ({ paymentUrl, onPayment, onTimeout, paymentId, paymentStatus, setPaymentStatus, setShowPaymentResultPopup }) => {

    const [countdown, setCountdown] = useState(null); // 5分钟倒计时
    const [paymentMethod, setPaymentMethod] = useState('WeChat Pay');
    // const [paymentStatus, setPaymentStatus] = useState(null);

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    // 当 paymentUrl 更新时，重置倒计时并启动倒计时逻辑
    useEffect(() => {
        if (paymentUrl) {
            setCountdown(300); // 5 分钟倒计时
        }
    }, [paymentUrl]);

    // 倒计时逻辑
    useEffect(() => {
        if (countdown === null || countdown <= 0) return;

        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [countdown]);

    // 当倒计时结束时触发
    useEffect(() => {
        if (countdown === 0) {
            onTimeout();
        }
    }, [countdown, onTimeout]);

    // 支付状态轮询
    useEffect(() => {
        if (!paymentId) return;

        const pollPaymentStatus = () => {
            // console.log('Polling payment status...');
            // console.log(paymentId);
            const queryApi = `${NetworkApi.payment.queryTransaction}/${paymentId}`;
            PostToServerWithJson(queryApi, null, (statusResult) => {
                if (statusResult.payment_status === 'Success') {
                    setPaymentStatus(true);
                    onPayment(true);
                    setShowPaymentResultPopup(true);
                } else if (statusResult.payment_status === 'Failed' || statusResult.payment_status === 'None') {
                    setPaymentStatus(false);
                    onPayment(false);
                    setShowPaymentResultPopup(false);
                }
            });
        };

        const pollTimeout = setInterval(pollPaymentStatus, 5000); // 每5秒轮询一次
        return () => clearInterval(pollTimeout);
    }, [paymentId, onPayment, onTimeout, paymentStatus]);



    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className="payment-options">
            <div className="payment-options_card" onClick={() => handlePaymentMethodChange('WeChat Pay')}>
                <div className="payment-options_card_header"
                    style={{ border: paymentMethod === "WeChat Pay" ? '2px solid #287EFF' : '2px solid #AAA' }}>
                    <div className="payment-options_card_header_title">{RequireText("payment.paymentMethods.wechat")}{RequireText("payment.pay")}</div>
                    <div className="payment-options_card_header_img">
                        {countdown !== null
                            ? <div className="payment-options_countdown">{RequireText("payment.timeLeft")}: {formatCountdown(countdown)}</div>
                            : <div className="payment-options_countdown" style={{ opacity: 0 }}>{RequireText("payment.timeLeft")}: {formatCountdown(countdown)}</div>
                        }
                        <Icon icon={'ic:baseline-wechat'} />
                    </div>
                </div>
            </div>

            {paymentMethod === "WeChat Pay" && (
                <div className="payment-options_qrcode" style={{ borderRadius: "0 0 12px 12px", height: 350, padding: "80px 10px" }}>
                    {paymentUrl && <QRCode value={paymentUrl} />}
                    <div className="payment-options_qrcode_info">{paymentUrl ? RequireText("payment.wechatInfo") : RequireText("payment.confirmInfo")}</div>
                </div>
            )}

            {paymentMethod === 'Credit Card' && (
                <button className="payment-options_btnPay" onClick={onPayment}>
                    Pay Now
                </button>
            )}
        </div>
    );
};
