import RequireText from "../../assets/i18n/TextDefiner"
import { useNavigate } from "react-router-dom";

// const defaultText = {
//     title: <>{RequireText("pricing.bigTitle1")},<br />{RequireText("pricing.bigTitle2")}</>,
//     text: RequireText("pricing.titleContent"),
//     btnText: RequireText("pricing.chooseButton"),
// }
export default function TitleCenter({ }) {
    const navito = useNavigate();
    return (
        <div className="d-x-center titleFirst">
            <div className="title"><>{RequireText("pricing.bigTitle1")}<br />{RequireText("pricing.bigTitle2")}</></div>
            <div className="text">{RequireText("pricing.titleContent")}</div>
            <div className="btn" onClick={() => navito("/payment")} >{RequireText("pricing.chooseButton")}</div>
            <div className="background">
                <div className="layers">
                    <div className="layer-1"></div>
                    <div className="layer-2"></div>
                    <div className="layer-3"></div>
                    <div className="layer-4"></div>
                    <div className="layer-5"></div>
                    <div className="layer-6"></div>
                    <div className="layer-7"></div>
                </div>
                <div className="dots">
                    <div className="dot-1"></div>
                    <div className="dot-2"></div>
                    <div className="dot-3"></div>
                    <div className="dot-4"></div>
                    <div className="dot-5"></div>
                    <div className="dot-6"></div>
                    <div className="dot-7"></div>
                    <div className="dot-8"></div>
                    <div className="dot-9"></div>
                    <div className="dot-10"></div>
                    <div className="dot-11"></div>
                    <div className="dot-12"></div>
                </div>
            </div>
        </div>
    )
}