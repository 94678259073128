import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { TranslatorFormCard, RewriteFormCard, GeneratorFormCard, ResultCard,FormHeader } from "../Utilities/Editor/EditorRightBarForms";
import { type } from "@testing-library/user-event/dist/type";

//--------------------------------------------------------------------
//Remaining: 
//1. Add API details for real translator, rewrite and generator functions
//2. Add a loading animation while the API is processing
//--------------------------------------------------------------------

export default function CVRightBar({ page, setPage, open, setOpen, selectedContent,setSelectedContent, selectedDom,type, pageType}) {
    const [loading, setLoading] = useState(false);
    const [resulttext, setResulttext] = useState('');

    return (
        <>
            <div className='cvRightbar' style={{height: pageType==="progress"?'calc(100vh - 200px)':'calc(100vh - 110px)',}}>
                <div className="cvRightbar-top">
                    <div className="rightbarIcon-container" >
                        {/* 这里open和close相反的，因为在右边 */}
                        {open ? <Icon icon={"lucide:sidebar-open"} id="sidebarbutton" onClick={() => setOpen(false)} /> :
                            <Icon icon={"lucide:sidebar-close"} id="sidebarbutton" onClick={() => setOpen(true)} />}
                    </div>
                    {open &&
                        <div className="rightbarForm-container">
                            <FormHeader page={page} setPage={setPage} type={type} setLoading={setLoading} setResulttext={setResulttext} />
                            <div>
                                {page === 0 ? <TranslatorFormCard selectedContent={selectedContent} setSelectedContent={setSelectedContent} setResulttext={setResulttext} /> : 
                                type==='cv' && page === 1  ? <RewriteFormCard selectedContent={selectedContent} setSelectedContent={setSelectedContent} setResulttext={setResulttext} /> :
                                type==='cv' && page === 2?<GeneratorFormCard selectedContent={selectedContent} setSelectedContent={setSelectedContent} setResulttext={setResulttext} />:
                                        <></>}
                            </div>
                        </div>}
                </div>
                {open && (resulttext !== '' || loading === true) &&
                    <div className="cvRightbar-bottom">
                        <ResultCard page={page} resulttext={resulttext} setResulttext={setResulttext} selectedDom={selectedDom} />
                    </div>}

            </div>


        </>
    )
}