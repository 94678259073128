import { ProgramQuickStart } from "./ProgramQuickStart"
import { ProgramPrevFile } from "./ProgramPrevFiles"
import RequireText from "../../assets/i18n/TextDefiner"
import { RenamePopup } from "../CvComponent/RenamePopup"
import { useState } from "react"

export const ProgramGuideHomePage = () => {

    const [rename, setRename] = useState(false);

    const filling = {
        cardTitle: RequireText("findProgram.startGuide"),
        box1: {
            title: RequireText("findProgram.match"),
            description: `${RequireText("findProgram.step1")} > ${RequireText("findProgram.step2")} > ${RequireText("findProgram.step3")}`,
        },
        box2: {
            title: RequireText("findProgram.recommend"),
            description: `${RequireText("findProgram.step2")} > ${RequireText("findProgram.step3")}`,
        }
    }

    return (
        <div>
            <ProgramQuickStart filling={filling} />
            <ProgramPrevFile rename={rename} setRename={setRename}/>
            {rename && <RenamePopup rename={rename} setRename={setRename} type={'pg'}/>}
        </div>
    )
}

