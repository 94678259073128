export const ImageUrl = {
    brandIcon: require("../../static/Image/LOGO_full.png"),
    brandIcon_only: require("../../static/Image/LOGO_icon.png"),
    ImgFirst_1: require("../../static/Image/ApplyU_HomePage_1.png"),
    ImgFirst_2: require("../../static/Image/ApplyU_HomePage_2.png"),
    ImgFirst_3: require("../../static/Image/ApplyU_HomePage_3.png"),
    ImgFirst_4: require("../../static/Image/ApplyU_HomePage_4.png"),
    Rect_1: require("../../static/Image/Rect_1.png"),
    Rect_2: require("../../static/Image/Rect_2.png"),
    Rect_3: require("../../static/Image/Rect_3.png"),
    Rect_4: require("../../static/Image/Rect_4.png"),
    Rect_5: require("../../static/Image/Rect_5.png"),
    Rect_6: require("../../static/Image/Rect_6.png"),
    Rect_7: require("../../static/Image/Rect_1.png"),
    Xiaohongshu_Icon: require("../../static/Image/xiaohongshu-logo-red.png"),
    CvBasket: require("../../static/Image/CvBasket.png"),
    CvRocket: require("../../static/Image/CvRocket.png"),
    FuncCardBackground_1: require("../../static/Image/FuncCardBack_1.png"),
    FuncCardBackground_2: require("../../static/Image/FuncCardBack_1.png"),
    FuncCardBackground_3: require("../../static/Image/FuncCardBack_1.png"),
    FuncCardBackground_4: require("../../static/Image/FuncCardBack_1.png"),
    FuncCard_1_CN: require("../../static/Image/FuncCard_CV_CN.gif"),
    FuncCard_2_CN: require("../../static/Image/FuncCard_ProgramGuide_CN.gif"),
    FuncCard_3_CN: require("../../static/Image/FuncCard_PS_CN.gif"),
    FuncCard_4_CN: require("../../static/Image/FuncCard_Tutor_CN.gif"),
    FuncCard_1_EN: require("../../static/Image/FuncCard_CV_EN.gif"),
    FuncCard_2_EN: require("../../static/Image/FuncCard_ProgramGuide_EN.gif"),
    FuncCard_3_EN: require("../../static/Image/FuncCard_PS_EN.gif"),
    FuncCard_4_EN: require("../../static/Image/FuncCard_Tutor_EN.gif"),
    timelineImg_1: require("../../static/Image/timeline_1.jpg"),
    timelineImg_2: require("../../static/Image/timeline_2.jpg"),
    timelineImg_3: require("../../static/Image/timeline_5.jpg"),
    timelineImg_4: require("../../static/Image/timeline_4.jpg"),
    timelineImg_5: require("../../static/Image/timeline_5.jpg"),
    TutorPic: require("../../static/Image/TutotPic.png"),
    PsHomeChart: require("../../static/Image/PSHomeChart.png"),
    wechatChannel: require("../../static/Image/wechat_qr_sm.jpg"),
}