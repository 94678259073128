import React, { useEffect } from 'react';
// import '../styles/PaymentQuitPopup.scss';
import { Link } from 'react-router-dom';
import RequireText from "../../assets/i18n/TextDefiner";
import { useTranslation } from "react-i18next";

export const PaymentQuitPopup = ({ exit }) => {
    const { i18n } = useTranslation();
    return (
        <div className="payment_popup">
            <div className="payment_popup_container">
                <div className="payment_popup_content">
                    <div className="payment_popup_content_title">
                        {RequireText(`payment.quitTitle`)}
                    </div>
                    <div className="payment_popup_content_btns">
                        <Link to="/pricing" ><button className="back-button">{RequireText(`payment.quitButton`)}</button></Link>
                        <button onClick={exit} className="submit-button">{RequireText(`payment.quitCancel`)}</button>
                    </div>
                </div>
            </div>

        </div>
    );
};
