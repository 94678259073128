import { Icon } from "@iconify/react/dist/iconify.js";
import { IconUrl } from "../media/IconUrl";
import { useEffect, useState } from "react";
import i18next from "i18next";

export function SetLanguage() {
    const nowLan = localStorage.getItem("currentLanguage");
    const [currentLan, setCurrentLan] = useState(nowLan ? nowLan : 0)
    const iconGroup = [IconUrl.language_en, IconUrl.language_zh]
    useEffect(() => {

        if (nowLan !== null) {
            if (nowLan !== currentLan)
                setCurrentLan(localStorage.getItem("currentLanguage"))

        }
        switch (currentLan) {
            case 0: i18next.changeLanguage("en"); console.log("en"); break;
            case 1: i18next.changeLanguage("zh"); console.log("zh"); break;

            default: break;

        }

    }, [currentLan])
    function ToggleLanguage() {
        localStorage.setItem("currentLanguage", 1 - currentLan)
        setCurrentLan(1 - currentLan)

    }

    return (
        <div onClick={() => ToggleLanguage()}>
            <Icon icon={iconGroup[currentLan]}></Icon>
        </div>
    )
}