import { PostToServerWithJson } from "../network/NetWork";
import { NetworkApi } from "../network/NetworkApi";
import { useNavigate } from "react-router-dom";


//停止使用
export function LoginToUser(username, password, navito, callback) {
    const api = NetworkApi.user.userLogin
    const userdata = {
        "uep": username,
        "password": password,
        "brandid": 1,
    }
    
    PostToServerWithJson(api, userdata, (re) => {
        if (re.success === true) {
            alert("success")
            navito("/dashboard")
        } else {
            alert(re.message.cn)
        }
    })


}

//停止使用
export function SignUpToUser(username, password, callback) {
    const api = NetworkApi.user.userSignup
    const userdata = {
        "user_name": username,
        "password": password,
        // "account_type_id": 1,
        // "brand_id": 1,
        // "reg_mode_id": 1,
        // "country_id": 1,
        "email": username,
        "phone": username
    }
    PostToServerWithJson(api, userdata, (re) => {
        if (re.success === true) {
            alert("恭喜! 注册成功")
            callback()
        }
        else {
            alert(re.message.cn)
        }
    })
}

//偶尔使用
export function SendCode(username, callback, failcallBack) {
    const api = NetworkApi.user.userSendCode
    PostToServerWithJson(api, { "email": username, "code": "" }, (re) => {
        if (re.success === true) {
            callback()
        }
        else {
            // alert(re.message.cn)
            failcallBack(re)
        }
    })
}

//停止使用
export function VerifyCodeToServer(username, code, callback) {
    const api = NetworkApi.user.userVerifyCode
    const userData = {
        "email": username,
        "code": code
    }
    PostToServerWithJson(api, userData, (re) => {
        if (re.success === true) {
            callback()
        } else {
            alert(re.message.cn)
        }
    })
}