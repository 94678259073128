import { NavbarDashboard } from "./Navbar";

import { ContentRouter } from "./Content_router";
import { useEffect, useState } from "react";
import { Struct_DashContent } from "./_StructDefine";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export function Dashboard() {
    //定义dashboard功能结构
    const ContentStruct = Struct_DashContent
    //定义content内容渲染
    const [contentjsx, setContentjsx] = useState(ContentStruct.home)
    const [routeArray, setRouteArray] = useState([])

    const navito = useNavigate()
    //切换content内容渲染
    function switchContent(content) {
        setContentjsx(content)

        localStorage.setItem("dashboard_route_array", JSON.stringify(content.route))
        setRouteArray(content.route)
        const url = "/dashboard" + content.url
        navito(url)

    }
    
    useEffect(() => {
        if (localStorage.getItem("dashboard_route_array")) {
            const routeStorage = JSON.parse(localStorage.getItem("dashboard_route_array"))

            if (routeStorage && (JSON.stringify(routeStorage) !== JSON.stringify(routeArray))) {
                setRouteArray(routeStorage)
            }
        }

    })
    //导航栏
    const sidebar = <NavbarDashboard clickItem={switchContent} />
    const loc = useLocation()
    return (
        <>
            <div className="dashboard">
                <div className="navBar">
                    {sidebar}
                </div>
                <div className="mainPage">
                    {["/dashboard/", "/dashboard"].includes(loc.pathname) ? <></> : <div className="router"><ContentRouter routename={routeArray}></ContentRouter></div>}
                    <div id="DashboardContent" className="content">
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </>
    )
}