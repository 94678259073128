import { Icon } from "@iconify/react/dist/iconify.js";
import { IconUrl } from "../../assets/media/IconUrl";
import { ImageUrl } from "../../assets/media/ImageUrl";
import { Image } from "react-bootstrap";
import { useState } from "react";

const FileData = {
    name: "PS_2",
    date: "2024/01/01",
}
export const FileCard = ({ fileData = FileData, onclick = () => { }, onDelete = () => { }, onRename = () => {} }) => {
    const [drop, setDrop] = useState(false)

    
    return (
        <div className="fileCard">
            <div className="icon">
                <Icon icon={IconUrl.ps}></Icon>
                {/* <Icon icon={IconUrl.star}></Icon> */}
            </div>
            <div>
                <div className="name" onClick={onclick}>{fileData.file_name}</div>
                <div className="date">{fileData.update_time}</div>
            </div>
            <div className="option">
                    <Icon id="optionIcon" icon={"mage:dots"} onClick={() => setDrop(!drop)} />
            </div>
            {drop &&
                <div className="dropDown-list">
                    <div className="dropDown-item" onClick={() => {
                        onDelete();
                        setDrop(false)
                    }}>
                        <Icon icon={'material-symbols:delete-outline'} />
                        <span>Delete</span>
                    </div>
                    {/* <div className="dropDown-item" onClick={handleDownload}>
                        <Icon icon={'material-symbols:download'} />
                        <span>Download</span>
                    </div> */}
                    <div className="dropDown-item" onClick={() =>{
                            onRename();
                            setDrop(false)
                        }}>
                        <Icon icon={'gg:rename'} />
                        <span>Rename</span>
                    </div>
                </div>
            }
        </div>
    )
};

export const FunctionCard = ({ title, tips, imgUrl = ImageUrl.CvRocket, onclick, isUpload = false, onupload }) => {
    const [filename, SetFilename] = useState("")
    return (
        isUpload ? <div onClick={() => onclick} className="boxCard-function">
            <div className="img"><Image src={imgUrl}></Image></div>
            <div className="txt">
                <div className="title"> {filename ? filename : title}</div>
                <div className="tips">
                    {filename ? "" : tips}
                </div>
            </div>
            <div className="inputFile">
                <input id="uploadCVInput" accept=".pdf" onChange={(e) => { let str = e.currentTarget.files[0]; if (str) { SetFilename(e.currentTarget.files[0].name); onupload() } else { SetFilename("") } }} type="file"></input>
            </div>
        </div> :
            <div onClick={() => onclick} className="boxCard-function">
                <div className="img"><Image src={imgUrl}></Image></div>
                <div className="txt">
                    <div className="title">{title}</div>
                    <div className="tips">
                        {tips}
                    </div>
                </div>
            </div>
    )
}
const QAUnitTextBoxData = {
    name: "QA",
    question: "default"
}
const QAUnitSelectTextBoxData = {
    nameQ: "select",
    nameA: "inputQAUnitSelectTextBoxData",
    options: ["xxx", "yyy", "zzz"],
}
const QAUnitSliderData = {
    name: "QASlider",
    min: "200",
    max: "2000",
    question: "default",
    num: "300"
}
export const QAUnitTextBox = ({ id = "", data = QAUnitTextBoxData, defaultAnswer = "", onExit = () => { } }) => {
    const questionText = data.question
    const name = data.name
    return (
        <div className="QAInputUnit-textbox">
            <div className="Q ">{questionText}</div>
            <div className="A">
                <textarea onBlur={onExit} id={id} name={name} className="inputText" placeholder="在此输入您的主要文本..." defaultValue={defaultAnswer} maxLength={300} ></textarea>
            </div>
        </div>
    )
}
export const QAUnitSelectTextBox = ({ data = QAUnitSelectTextBoxData, defaultAnswer = "", id = "", onBlur }) => {
    return (
        <div className="QAInputUnit-selectTextBox">
            <div className="Q">
                <select id={id + "Q"} name={data.nameQ} className="inputText"  >
                    <option value={data.options[0]}>{data.options[0]}</option>
                    <option value={data.options[1]}>{data.options[1]}</option>
                    <option value={data.options[2]}>{data.options[2]}</option>
                </select>
            </div>
            <div className="A">
                <textarea id={id + "A"} className="textArea" name={data.nameA} placeholder="在此输入您的主要文本" defaultValue={""} onBlur={onBlur} maxLength={300}></textarea>
            </div>
        </div>
    )

}
export const QAUnitSlider = ({ id = "", sliderData = QAUnitSliderData }) => {
    function updateValue(value) {
        document.getElementById(sliderData.name + 'show').innerHTML = value
    }
    return (
        <div className="QAInputUnit-slider">
            <div className="Q starTag">{sliderData.question}</div>
            <div className="A">
                <label >{sliderData.min}</label>
                <input id={id} type="range" className="inputSlider" name={sliderData.name} min={sliderData.min} max={sliderData.max} step={10} onInput={(e) => updateValue(e.target.value)} ></input>
                <label >{sliderData.max}</label>
                <label className="showNumber" id={sliderData.name + "show"}></label>
            </div>
        </div>
    )
}