import { Icon } from '@iconify/react/dist/iconify.js';
import RequireText from '../../../assets/i18n/TextDefiner';

export default function MenuBar ({ editor, type }) {
    if (!editor) {
        return null;
    }

    return (
        <div className="control-group">
            <div className="button-group">
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    <Icon icon={'octicon:bold-24'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is-active' : ''}
                >
                    <Icon icon={'lucide:italic'} id='editor-icon' />
                </button>
                {type !== 'cv' && 
                <>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    <Icon icon={'ci:heading-h1'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    <Icon icon={'ci:heading-h2'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                    <Icon icon={'ci:heading-h3'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                    className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
                >
                    <Icon icon={'ci:heading-h4'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                    className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
                >
                    <Icon icon={'ci:heading-h5'} id='editor-icon' />
                </button>
                </>}
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    <Icon icon={'fe:list-bullet'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    <Icon icon={'fe:list-order'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('left').run()}
                    className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
                >
                    <Icon icon={'ph:text-align-left'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('center').run()}
                    className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
                >
                    <Icon icon={'ph:text-align-center'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('right').run()}
                    className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
                >
                    <Icon icon={'ph:text-align-right'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                    className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
                >
                    <Icon icon={'ph:text-align-justify'} id='editor-icon' />
                </button>
                <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                    {RequireText("AIwriting.insert")}
                </button>
                <button
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={!editor.can().chain().focus().undo().run()}
                >
                    <Icon icon={'material-symbols:undo'} id='editor-icon' />
                </button>
                <button
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={!editor.can().chain().focus().redo().run()}
                >
                    <Icon icon={'material-symbols:redo'} id='editor-icon' />
                </button>
            </div>
        </div>
    );
};