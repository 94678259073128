//import { PersonalizationPage } from "../components/ProgramGuideComponents/_PersonalizationPage";
//import { ProgramWishlistPick } from "../components/ProgramGuideComponents/_ProgramWishlistPick";
//import {ProgramGuideHomePage} from "../components/ProgramGuideComponents/_ProgramGuideHomePage";
// import { ProgramResourcePage } from "../components/ProgramResourceComponents/_ProgramResourcePage";
// import { ProgramWishlistPage } from "../components/ProgramResourceComponents/_WishlistPage";
// import { EditorPage } from "../components/EditorComponent/_EditorPage";
// import { Template1 } from "../components/EditorComponent/CVTemplate";
import { ProgramGuideReport } from "../components/ProgramGuideComponents/_ProgramGuideReport";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfService";
import PaymentPage from "../components/PaymentComponent/_Payment";
// import { TutorPage } from "../components/TutorComponent/_TutorPage";

export function AaronBuild() {

    return (
        <div>
            <PaymentPage />
        </div>
    );
};