import React from "react";
import { RouterProvider } from "react-router-dom";
import { _router } from "./assets/router/router";
import CookieConsent from "./components/HomeComponent/CookiesAsk.js";

import "./assets/i18n/i18n.js"
import i18next from "i18next";
import Aos from "aos";

function LocalStorageInit() {
  //添加setitem监听事件
  let oldSetItem = localStorage.setItem
  localStorage.setItem = function (key, newValue) {
    let event = new Event("setItemEvent")
    event.key = key
    event.newValue = newValue
    window.dispatchEvent(event)
    oldSetItem.apply(this, arguments)
  }
}

function App() {
  Aos.init();




  return (
    <>
      <RouterProvider router={_router}></RouterProvider>
      <CookieConsent></CookieConsent>
    </>
  );
}

export default App;
