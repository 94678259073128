import { useState, useEffect } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { useTranslation } from 'react-i18next';

const RadarChartComponent = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <RadarChart data={data} cx="50%" cy="50%" outerRadius="65%">
                <PolarGrid />

                {/* Adjusted PolarAngleAxis for cleaner labels with tickFormatter */}
                <PolarAngleAxis
                    dataKey="subject"
                    tick={{ fontSize: "11px", fontWeight: 500, fill: '#000000', fontFamily: 'Poppins', width: '20px' }}
                />

                {/* Radar Area and Lines */}
                <Radar
                    name="Analysis"
                    dataKey="score"
                    stroke="#287EFF"
                    fill="rgba(40, 126, 255, 0.30)"
                    fillOpacity={0.7}
                />

                {/* Customized Tooltip to show 'subject: score' format */}
                <Tooltip
                    formatter={(value, name, entry) => [`Score: ${value}`]}
                />
            </RadarChart>
        </ResponsiveContainer>
    );
};


export const ReportDetails = ({ data }) => {

    const { i18n } = useTranslation(); // Use i18n instead of t to access the language
    const currentLanguage = i18n.language;

    useEffect(() => {
        console.log(currentLanguage);
    }, [currentLanguage]); // Add currentLanguage as a dependency

    return (
        <>
            <div className="reportprogramDetails">
                <div className="reportprogramDetails-headerRow">
                    <div className="reportprogramDetails-headerRow-left">
                        <img alt="school_log" src={data.program_icon} className='school_logo'/>
                        <div className="schoolname">
                            <label className="schoolname-label1" onClick={() => window.open(data.link, '_blank')} style={{cursor:'pointer'}}>{data.course_name}</label>
                            <label className="schoolname-label2">{data.uni_name}</label>
                        </div>
                    </div>
                    <div className="reportprogramDetails-headerRow-right">
                        <label className="scorelabel1">TOTAL SCORE</label>
                        <label className="scorelabel2">{data.report.en['Total score'].toFixed(1)}</label>
                    </div>
                </div>
                <div className="reportprogramDetails-overviewRow">
                    <div className="reportprogramDetails-overviewRow-left">
                        <label className="overviewlabel1">ANALYSIS OVERVIEW</label>
                        <div className="overviewDetails">
                            {DictToParagraph(data.report[currentLanguage]?.Matching?.sub_fields || data.report['en']?.Matching?.sub_fields)}
                        </div>
                    </div>
                    <div className="reportprogramDetails-overviewRow-right">
                        <label className="overviewlabel1">ANALYSIS SCORE</label>
                        {/* Use the RadarChartComponent here and pass the radarData */}
                        <RadarChartComponent data={raderDatagene(data.report[currentLanguage] || data.report['en'])} />
                    </div>
                </div>
                <div className="reportprogramDetails-DetailsRow">
                    <ScoreDetails data={sectionDataGene(data.report[currentLanguage] || data.report['en'])} />
                </div>
            </div>
        </>
    );
};


const ScoreDetails = ({ data }) => {
    const [activeSection, setActiveSection] = useState(Object.keys(data)[0]);

    useEffect(() => {
        console.log(data);
    })

    return (
        <div className="score-details">
            <div className="score-nav">
                {Object.keys(data).map((section) => (
                    <div
                        key={section} // key should be on the outermost element
                        className={`score-nav-item ${section === activeSection ? 'active' : ''}`}
                        onClick={() => setActiveSection(section)}
                    >
                        <span className="score-nav-item-title">
                            {section}
                        </span>
                    </div>
                ))}
            </div>
            <div className="score-content">
                <p>Score: {data[activeSection].score}</p>
                <div>{ListToUl(data[activeSection].description)}</div>
            </div>
        </div>
    );
};

const DictToParagraph = (json) => {
    const list = [];
    Object.entries(json).forEach(([key, value]) => {
        if (typeof value[0] === 'string') {
            list.push(value[0]);
        } else if (typeof value[0] === 'object' && value[0].answer) {
            list.push(value[0].answer); // If it's an object with `answer`, use `answer`
        }
    });
    return (
        <div>
            {list.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </div>
    );
};

const raderDatagene = (json) => {
    var radarData = [];
    Object.entries(json).forEach(([key, value]) => {
        if (key === 'Academic achievements') {
            radarData.push({
                subject: "ACADEMIC",
                score: value.sum_score
            })
        } else if (key === 'Work Experience') {
            radarData.push({
                subject: "WORK",
                score: value.sum_score
            })
        } else if (key === 'Project Experience') {
            radarData.push({
                subject: "PROJECT",
                score: value.sum_score
            })
        } else if (key === 'Skills') {
            radarData.push({
                subject: "SKILLS",
                score: value.sum_score
            })
        } else if (key === 'Discipline Relevance') {
            radarData.push({
                subject: "DISCIPLINE RELEVANCE",
                score: value.sum_score
            })
        } else if (key === 'Career preference' && (value.score !== "" || value.score !== null || value.score !== undefined || value.score !== 0)) {
            radarData.push({
                subject: "CAREER PREFERENCE",
                score: value.score
            })
        }
    })

    return radarData;
}

const sectionDataGene = (json) => {
    const sectionData = {};

    // Helper function to recursively process the sections and extract array content
    const extractContent = (value) => {
        let extracted = [];
        if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([key, subValue]) => {
                if (key === 'answer' && typeof subValue === 'string') {
                    extracted.push(subValue);
                }else if (key === 'content' && typeof subValue === 'string'){
                    extracted.push(subValue)
                } 
                else if (Array.isArray(subValue) && typeof subValue[0] === 'string') {
                    extracted.push(subValue[0]);
                } else if (typeof subValue === 'object') {
                    extracted = extracted.concat(extractContent(subValue));
                }
            });
        }
        return extracted;
    };

    // Process the JSON object and extract data including score
    Object.entries(json).forEach(([key, value]) => {
        switch (key) {
            case 'Academic achievements':
                sectionData.Academic = {
                    score: value.sum_score,
                    description: extractContent(value),
                };
                break;
            case 'Work Experience':
                sectionData.Work = {
                    score: value.sum_score,
                    description: extractContent(value),
                };
                break;
            case 'Project Experience':
                sectionData.Projects = {
                    score: value.sum_score,
                    description: extractContent(value),
                };
                break;
            case 'Skills':
                sectionData.Skills = {
                    score: value.sum_score,
                    description: extractContent(value),
                };
                break;
            case 'Discipline Relevance':
                sectionData['Discipline Relevance'] = {
                    score: value.sum_score,
                    description: extractContent(value),
                };
                break;
            case 'Career preference':
                if (value.score && value.score !== 0) {  // Check if the score is not null, 0, or empty
                    sectionData['Career preference'] = {
                        score: value.score,
                        description: extractContent(value),
                    };
                }
                break;
            default:
                break;
        }
    });

    return sectionData;
};


const ListToUl = (list) => {
    return (
        <ul>
            {list.map((paragraph, index) => (
                typeof paragraph === 'string' ? (
                    <li key={index}>{paragraph}</li>
                ) : (
                    <li key={index}>Invalid content</li> // Handle non-string content
                )
            ))}
        </ul>
    );
};
