import { useState, useEffect } from "react";
import { GetToServer, PostToServerWithJson } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

export const TutorPop = ({ setTutorPop, selectedTutor, setModalContent, setModalOpen }) => {

    const [sendTo, setSendTo] = useState(selectedTutor.tutor_name);
    const [sendFrom, setSendFrom] = useState('');
    const [emailBody, setEmailBody] = useState();
    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // useEffect(() => {
    GetToServer(NetworkApi.user.userProfile, (re) => {
        if (re.message.en.includes('Successfully') || re.message.cn.includes('成功')) {
            // setName(re.user_profile.first_name)
            setSendFrom(re.user_profile.first_name)
        } else {
            
        }
    })
    // }, [sendFrom])

    useEffect(() => {
        if (currentLanguage === 'en'){
            setEmailBody(`Dear ${sendTo},\n\nI hope you're doing well. I'm currently in the process of preparing my application for studying abroad and hoping to seek your guidance and support. Your expertise in this area would be invaluable to me, and I would greatly appreciate your advice on how to strengthen my application.\n\nCould we schedule a time to meet and discuss this further? Please let me know your availability.\n\nThank you in advance for your help!\n\nBest regards,\n${sendFrom}`)
        } else if (currentLanguage === 'zh') {
            setEmailBody(`我正在规划留学申请，目前对[申请国家/专业]和材料准备[如文书写作/推荐信/面试准备]的要求有些疑惑，想请问您是否可以提供一些建议或指导？`)
        }
    }, [sendFrom])

    const handleSend = () => {
        setLoading(true)
        const body = {
            "tutor_id": selectedTutor.tutor_id,
            "send_content": emailBody
        }
        PostToServerWithJson(NetworkApi.tutor.sendEmail, body, (re) => {
            //console.log(re)
            setModalContent(re.message.cn)
            setModalOpen(true);
            setTutorPop(false);
            setLoading(false);
        })
    }



    return (
        <>
            <div className="TutorEmailPopup">
                <div className="TutorEmailPopup_EmailContainer">
                    <label className="TutorEmailPopup_EmailContainer_Label">Contact Tutor</label>
                    <div className="EmailSendInfo">
                        <div className="EmailSendInfo_To">
                            To : <input defaultValue={sendTo} onChange={(e) => setSendTo(e.target.value)} />
                        </div>
                        <div className="EmailSendInfo_From">
                            From : <input defaultValue={sendFrom} onChange={(e) => setSendFrom(e.target.value)} />
                        </div>
                        <textarea
                            className="EmailSendInfo_EmailBody"
                            defaultValue={emailBody}
                            rows={8}
                            onChange={(e) => setEmailBody(e.target.value)}
                        />
                    </div>
                    <div className="SendButton">
                        <button onClick={() => setTutorPop(false)}>Cancel</button>
                        <button onClick={handleSend}>{loading ? <Spin /> : "Send from my email"}</button>
                    </div>
                </div>
            </div>
        </>
    )
}