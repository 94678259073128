import { Outlet } from "react-router-dom";
import { BrandNode } from "../Utilities/Utilities";
export function UserPageContainer() {

    return (
        <div className="userPageContainer">
            <div className="brand">
                <BrandNode></BrandNode>
            </div>
            <div className="main">
                <Outlet></Outlet>
            </div>

        </div>
    )
}