import { EditorPage } from "../EditorComponent/_EditorPage";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer } from "../../assets/network/NetWork";
import { useEffect, useState } from "react";
import { convertToHTML } from "../RLComponent/_rlProgressData";
import { LoadingComponent } from "../Utilities/Loading";
import { ModalComponent } from "../Utilities/ModalComponent";

export function PsEditor() {
    const [psContent, setPsContent] = useState("");
    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState("")
 
    useEffect(() => {
        var psID = localStorage.getItem('ps_id');
        if (psID !== "") {
            GetToServer(NetworkApi.ps.getSinglePs.concat("/", psID), (re) => {
                if (re.success === true) {
                    var tmp = re.data.ps_statement;
                    setPsContent(convertToHTML(re.data));
                } else {
                    setModalContent(re.message.cn);
                    setModalOpen(true);
                }
            }
            )
        }
    }, [])

    const handleOk = () => {
        setModalOpen(false)
    } 
   
    
    return (
        <div>
            {psContent ? <EditorPage content={psContent} type="ps" /> : <LoadingComponent/>}
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
        </div>
    );
};