
import { useNavigate } from "react-router-dom";
import { FileCard, FunctionCard } from "../Utilities/DashboardItems";
import RequireText from "../../assets/i18n/TextDefiner";
import { GetToServer, DeleteToServerWithJson } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useEffect, useState } from "react";
import { ModalComponent } from "../Utilities/ModalComponent";
import { RenamePopup } from "../CvComponent/RenamePopup";


export function RLhome() {

    const [modalContent,setModalContent] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [rename, setRename] = useState(false)
    

    const navito = useNavigate()

    function ClickStart() {
        navito("/dashboard/rl/step1")
    }

    const handleOk = () => {
        navito("/user/login")
    };

    const FileCardGroup = ({rename, setRename}) => {
        const [fileData, setFileData] = useState([]);

        useEffect(() => {
            GetToServer(NetworkApi.rl.getExistRl,
                (re) => {
                    if (re.success === true) {
                        setFileData(re.rl)
                    } else {
                        setModalContent(re.message.cn);
                        setModalOpen(true);
                    }
                }
            )
        }, [rename])

        const handleClick = (id) => {
            localStorage.setItem('rl_id', id);
            navito("/dashboard/rl/rleditor")
        }

        const handleDelete = (id) => {
            DeleteToServerWithJson(NetworkApi.rl.deleteRl.concat('/', id), {}, (res) => {
                if (res.success === true) {
                    setFileData(prev => {
                        const newFiles = prev.filter(item => item.rl_id !== id);
                        return newFiles;
                    });
                } else {
                    setModalContent(res.message.cn)
                    setModalOpen(true)
                }
            })
    
        };
    
        const handleRename = (id) => {
            localStorage.setItem("rl_id", id);
            setRename(true);
        }


        return (
            <>
                {fileData.map((item) => <FileCard key={item.rl_id} fileData={item} onclick={() => handleClick(item.rl_id)} onDelete={() => handleDelete(item.rl_id)} onRename={() => handleRename(item.rl_id)}></FileCard>)}
            </>
        )
    }

    return (
        <div className="rlHome">
            <div className="quickStart">
                <div className="title">{RequireText("AIwriting.quick")}</div>
                <div onClick={ClickStart} style={{ width: 'fit-content' }}>
                    <FunctionCard tips={RequireText("rlGeneration.createLetter")}></FunctionCard>
                </div>
                <div className="lineDivider"></div>
            </div>
            <div className="prevFiles">
                <div className="title">{RequireText("AIwriting.prevFile")}</div>
                <div className="cardGroup">
                    <FileCardGroup rename={rename} setRename={setRename}></FileCardGroup>
                </div>
            </div>
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
            {rename && <RenamePopup setRename={setRename} />}
        </div>
    )
}