import React from 'react';

const ICPFooter = () => {
    return (
        <div style={styles.footerContainer}>
            <p style={styles.footerText}>
                备案号：<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>粤ICP备2024279000号</a>
            </p>
        </div>
    );
};

const styles = {
    footerContainer: {
        width: '100%',
        textAlign: 'center',
        padding: '0 0',
        backgroundColor: "transparent",
        position: 'absolute',
        bottom: 0,
        left: 0,
        fontSize: '14px',
    },
    footerText: {
        color: '#555',
    },
    footerLink: {
        color: '#0066cc',
        textDecoration: 'none',
    },
};

export default ICPFooter;