import React, { useState } from 'react';
import CVRightBar from "./EditorRightBar";
import Editor from "./EditorComponent";


export function EditorPage({content,type,pageType} ) {
    const [selectedContent, setSelectedContent] = useState('');
    const [selectedDom, setSelectedDom] = useState(null); // State to hold the selected DOM element
    //page: 0-translator; 1-rewrite; 2-generator
    const [page, setPage] = useState(0);
    //State Control if right-bar open
    const [open, setOpen] = useState(false);


    return (
        <div className='CVEditorPageContainer'>
            <Editor
                selectedContent={selectedContent}
                setSelectedContent={setSelectedContent}
                open={open}
                setOpen={setOpen}
                setPage={setPage}
                setSelectedDom={setSelectedDom}
                content={content}
                type={type}
                pageType={pageType}
            />
            <CVRightBar
                page={page}
                setPage={setPage}
                open={open}
                setOpen={setOpen}
                selectedContent={selectedContent}
                setSelectedContent={setSelectedContent}
                selectedDom={selectedDom}
                type={type}
                pageType={pageType}
            />
        </div>
    );
}
