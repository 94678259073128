import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetToServer } from '../../assets/network/NetWork';
import { NetworkApi } from '../../assets/network/NetworkApi';
export function UserNode() {

    const navito = useNavigate()
    const [userState, setUserState] = useState("Login")
    const [username, setUsername] = useState("")

    useEffect(() => {
        getUserName();
    }, []);

    const getUserName = () => {
        if (localStorage.getItem('user_name')) {
            setUserState("Logout");
            setUsername(localStorage.getItem('user_name'))
        } else {
            setUserState("Login");
        }
    }



    function handleClick() {
        if (userState === "Login") {
            navito("/user/login")
        } else {
            logout();
            navito("/")
        }
    }

    function logout() {
        GetToServer(
            NetworkApi.user.userLogout,
            (re) => {
                if (re.success) {
                    localStorage.removeItem('user_name');
                }
            }
        )
        setUserState("Login");
        setUsername("");
    }

    return (
        <div onClick={handleClick} className="userNode">
            <div className="userIcon">
                {/* <Image src={ImageUrl.brandIcon_only}></Image> */}
                {/* <span>Login</span> */}
            </div>
            <div className="username">
                {/* <span style={{ fontWeight: 400, marginRight: "5px" }}>{username}</span> */}
                {userState}
            </div>
        </div>
    )
}