import { useNavigate } from "react-router-dom";
import { RouteBar } from "./_StructDefine";

export function ContentRouter({ routename }) {
    
    return (
        <div className="contentRouter">
            <div className="content"><RouteBar routeArray={routename} /></div>
            <div className="lineDivider"></div>
        </div>
    )
}