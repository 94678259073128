import { QuickStart } from './QuickStart'
import { CVPrevFile } from './CVPrevFiles'
import { RenamePopup } from './RenamePopup'
import { useState } from 'react'
import RequireText from '../../assets/i18n/TextDefiner'

export const CVHome = () => {

    const [rename, setRename] = useState(false);


    const QuickStartfilling = {
        cardTitle: RequireText("AIwriting.quick"),
        box1: {
            title: RequireText("AIwriting.dragdrop"),
            description: RequireText("AIwriting.upload"),
        },
        box2: {
            title: RequireText("AIwriting.start"),
            description: RequireText("AIwriting.create"),
        }
    }

    return (
        <>
            <div className="contentCV-container">
                <QuickStart filling={QuickStartfilling} router={"/dashboard/cv/cvEditor"}/>
                <CVPrevFile rename={rename} setRename={setRename}/>
                {rename && <RenamePopup rename={rename} setRename={setRename} type={'cv'}/>}
            </div>
        </>
    )
}
