import RequireText from "../../assets/i18n/TextDefiner";
import { QAUnitTextBox } from "../Utilities/DashboardItems";
import { SelectWithOther } from "../Utilities/InputComponents";
import { RLpersonalInfo } from "./RL_personal_info";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FunctionCard } from "../Utilities/DashboardItems";
import { IconUrl } from "../../assets/media/IconUrl";
import { ProgressBar } from "react-bootstrap";
import { useContext, useState } from "react";
import { SmileTwoTone, SyncOutlined, } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { ClearCVIDinRL, GetRLRecommenderExGroup, GetRLRecommenderExGroupNum, SetRLRecommenderExGroup, StoreCVIDinRL, StoreRLRecommenderExGroup } from "./_rlProgressData";
import { PostToServerWithFile } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useEffect } from "react";
import { NextableContext } from "../DashboardComponent/AIprogress";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { ModalComponent } from "../Utilities/ModalComponent";



export function RLrecommenderInfo() {
    const selectData = [
        { value: "Academic Professor", text: "Academic Professor" },
        { value: "Research Professor", text: "Research Professor" },
        { value: "Employer", text: "Employer" },
        { value: "Other", text: "Other" },
    ]
    const [now, setNow] = useState(0);
    const [filename, SetFilename] = useState("")
    const navito = useNavigate()
    const [uploaded, SetUploaded] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loading, SetLoading] = useState(false);

    const handleOk = () => {
        setModalOpen(false);
    }
    //上传cv
    function UploadCV() {
        SetUploaded(false)
        const ele = document.getElementById("uploadCVInput")
        if (ele) {
            const file = ele.files[0]
            if (file) {
                SetFilename(file.name)
                const pattern = /.pdf/
                //扩展名判断是否为pdf

                if (!pattern.test(file.name)) {
                    setModalContent("Please upload a PDF file")
                    setModalOpen(true)
                    ele.value = null
                    SetFilename("")
                } else if (file.size > 1.5 * 1024 * 1024){
                    setModalContent("File size should be less than 1.5MB")
                    setModalOpen(true);
                    ele.value = null;
                    SetFilename("");
                } else {
                    ClearCVIDinRL();
                    let formdata = new FormData();
                    formdata.append('resumeFile', file);
                    setNow(10);

                    const urlWithQuery = `${NetworkApi.cv.uploadParseCV}?resume_upload_type=statement`;

                    PostToServerWithFile(urlWithQuery, formdata, (re) => {
                        if (re !== undefined) {
                            if (re.success === false) {
                                setModalContent(re.message.cn)
                                setModalOpen(true)
                            }
                            if (re.cv_id !== undefined) {
                                StoreCVIDinRL(re.cv_id);

                                setNow(100);
                                SetUploaded(true)
                            }
                            else {
                                setModalContent("Something Wrong,refresh again")
                                setModalOpen(true)
                                document.location.reload()
                            }

                        } else {
                            setModalContent("Server Error");
                            setModalOpen(true)
                        }

                    })
                }
            }
        }
    }
    useEffect(() => {
        localStorage.setItem("ps_next", uploaded)
    }, [uploaded])

    //经历填写
    const ExInputGroup = () => {
        const [exNum, setExNum] = useState(1)
        function ChangeQuestionNum(num) {
            setExNum(num)
            localStorage.setItem("RecommenderExNum", num)
            StoreRLRecommenderExGroup(num)
        }
        function AddEx() {
            ChangeQuestionNum(exNum + 1)
        }
        function DeleteEx() {
            if (exNum > 1)
                ChangeQuestionNum(exNum - 1)
        }
        let group = []
        for (let i = 0; i < exNum; i++) {
            group.push(i)
        }
        if (exNum !== GetRLRecommenderExGroupNum()) { setExNum(GetRLRecommenderExGroupNum()) }
        const exQuestion = RequireText("rlGeneration.title3")
        function StoreRLRExGroup() {
            StoreRLRecommenderExGroup(exNum)
        }
        useEffect(() => {
            const ExData = GetRLRecommenderExGroup()
            if (ExData) {
                SetRLRecommenderExGroup(ExData, exNum)
            }
        }, [exNum])
        return (
            <div className="exInputGroup">
                {group.map(
                    (item) =>
                        <QAUnitTextBox
                            key={"rl" + item}
                            id={"recommenderEx" + item}
                            data={{ question: `${item + 1}. ` + exQuestion }}
                            onExit={() => {
                                StoreRLRExGroup()
                            }}
                        >
                        </QAUnitTextBox>
                )
                }
                <div className="ExOperateBar">
                    <div onClick={() => AddEx()} className="add">
                        <PlusOutlined />
                        <span>Add Experience</span>
                    </div>
                    <div onClick={() => DeleteEx()} className="delete">
                        <MinusOutlined />
                        <span>Delete</span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="psCV">
                <div className="uploadCV">
                    <div className="title">
                        {RequireText("AIwriting.upload")}
                        <span className="tag"> *</span>
                    </div>
                    <div className="boxGroup">
                        <div className="box">
                            {/* 上传已有cv文件 */}
                            <FunctionCard isUpload onupload={UploadCV} title={RequireText("AIwriting.dragdrop")} tips={RequireText("AIwriting.upload")}></FunctionCard>
                        </div>
                        <div className="textDivider">OR</div>
                        {/* 跳转到cv */}
                        <div onClick={() => {
                            localStorage.setItem("dashboard_route_array", JSON.stringify(["AIwrite", "content_cv"]))
                            navito("/dashboard/cv")
                        }} className="box">
                            <FunctionCard title={RequireText("AIwriting.start")} tips={RequireText("AIwriting.create")}></FunctionCard>
                        </div>
                    </div>
                </div>
                {filename === "" ? <></> : <div className="uploadProgress">
                    <div className="bgBar">
                        <div className="icon"><Icon icon={IconUrl.cv}></Icon></div>
                        <div className="content">
                            <div className="title">
                                {filename}
                                {now === 100 ? <SmileTwoTone /> : <SyncOutlined spin />}
                            </div>
                            <div className="progressBar">
                                <ProgressBar now={now} label={`${now}%`} animated style={{ height: 12 }} />
                            </div>
                        </div>


                    </div>
                </div>}
            </div>
            <div className="rlRecommenderInfo">
                <div className="title">{RequireText("rlGeneration.title1")}</div>
                <div className="recommenderUnit">
                    <SelectWithOther id="recommenderTitle" question={RequireText("rlGeneration.title2")} optionData={selectData}></SelectWithOther>
                    <ExInputGroup></ExInputGroup>
                </div>
            </div>
            <RLpersonalInfo></RLpersonalInfo>
            {modalOpen && <ModalComponent modalOpen={modalOpen} content={modalContent} onOk={handleOk} />}
        </>

    )
}