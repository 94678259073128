import { useState,useEffect } from "react";
import { ImageUrl } from "../../assets/media/ImageUrl";
import { Icon } from "@iconify/react/dist/iconify.js";
import { PostToServerWithJson, DeleteToServerWithJson } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import RequireText from "../../assets/i18n/TextDefiner";

export const ProgramDetailCard = ({ data, type, setSortedData, setModalOpen, setModalContent }) => {
    const [expanded, setExpanded] = useState(false);
    const [picked, setPicked] = useState();


    //处理不同的语言
    const overview = RequireText("findProgram.overview");
    const learning = RequireText("findProgram.learning");
    const career = RequireText("findProgram.career");
    const admission = RequireText("findProgram.admission");

    useEffect(() => {
        if (data.wishlist_status === 1) {
            setPicked(true)
        }else {
            setPicked(false)
        }
    },[data])

   
    //处理不同的返回格式
    const prefix = type === 'wishlist' ? data.program : data.program_item;

    var courseEntryRequirements = [];
    var courseCareer = [];
    var courseLearning = [];
    
    if (type === 'wishlist') {
        courseEntryRequirements = prefix.course_entry_requirements;
        courseCareer = prefix.course_career;
        courseLearning = prefix.course_learning;
    } else if (type === 'resource') {
        courseEntryRequirements = prefix.course_entry_requirements;
        courseCareer = prefix.course_career;
        courseLearning = prefix.course_learning;
    }


    const handleAddWishlist = () => {
        PostToServerWithJson(NetworkApi.programResource.add_wishlist, { "course_id": [prefix.program_id] }, (re) => {
            if (re.success === true && re.message.en.includes("success")) {
                setPicked(!picked)
            } else {
                setModalContent(re.message.cn)
                setModalOpen(true)
            }
        })
    }

    const handleDeleteWishlist_courseId = async () => {
        // 删除文件的API
        DeleteToServerWithJson(NetworkApi.programResource.delete_wishlist_program.concat('?',`course_id_to_remove=${prefix.program_id}`), {}, (re) => {
            if (re.success === true && re.message.en.includes("success")) {
                setPicked(!picked)
            } else {
                setModalContent(re.message.cn)
                setModalOpen(true)
            }
        })
    }

    const handleDeleteWishlist2_wishlistId = async () => {
        // 删除文件的API
        DeleteToServerWithJson(NetworkApi.programResource.delete_wishlist, { "wishlist_ids": [data.wishlist_id] }, (re) => {
            if (re.success === true && re.message.en.includes("success")) {
                setSortedData(prev => prev.filter(item => item.wishlist_id !== data.wishlist_id))
            } else {
                setModalContent(re.message.cn)
                setModalOpen(true)
            }
        })
    }


    const toggleExpand = () => {
        setExpanded(!expanded);
    };


    return (
        <div className="programDetail-card">
            <div className="program-header">
                <img src={prefix.program_icon === '' ? ImageUrl.image1 : prefix.program_icon} alt="School Logo" className="logo" />
                <div className="school-info">
                    <h2 className="course-name">{prefix.course_name}</h2>
                    <p className="school-name">{prefix.uni_name}</p>
                </div>
                {type === 'resource' &&
                    <div className='wishlistbookmark-container'>
                        {picked ? <Icon id="wishlist-icon" icon={'carbon:bookmark-filled'} onClick={handleDeleteWishlist_courseId} /> :
                            <Icon id="wishlist-icon" icon={'carbon:bookmark'} onClick={handleAddWishlist} />}
                    </div>
                }
                {
                    type === 'wishlist' &&
                    <div className='wishlistClose-container' onClick={handleDeleteWishlist2_wishlistId}>
                        <Icon icon={'material-symbols:close'} id='wishlist-icon' />
                    </div>
                }
            </div>
            <div className="tags">
                <span className="tag">{RequireText("findProgram.justranking")}: {prefix.university_ranking}</span>
                <span className="tag">{prefix.course_mode}</span>
                <span className="tag">{prefix.course_country}</span>
                <span className="tag">{prefix.course_duration}</span>
                <a className="programLink" href={prefix.university_link} target="_blank">Prgram Page</a>
            </div>
            <button className="expand-toggle" onClick={toggleExpand}>
                {expanded ? 'See less' : 'See more'}
            </button>
            {expanded && (
                <>
                    <section className="overview">
                        <h2>{overview}</h2>
                        <p>
                            {prefix.course_overview}
                        </p>
                    </section>
                    <div className="details">
                        <section className="learning">
                            <h2>{learning}</h2>
                            <ul>
                                {courseLearning.map((item) => (
                                    <li>{item}</li>
                                ))}
                            </ul>
                        </section>
                        <section className="career">
                            <h2>{career}</h2>
                            <ul>
                                {courseCareer.map((item) => (
                                    <li>{item}</li>
                                ))}
                            </ul>
                        </section>
                    </div>
                    <section className="admission">
                        <h2>{admission}</h2>
                        <ol>
                            {courseEntryRequirements.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ol>
                    </section>
                </>
            )}
        </div>
    );
};

