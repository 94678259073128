import { useEffect } from "react";
import DataSection from "../components/HomeComponent/DataSection";
// import Cases from "../components/HomeComponent/Cases";
import HeadNav from "../components/HomeComponent/HeadNav";
// import ImageFirst from "../components/HomeComponent/ImageFirst";
import FunctionCard from "../components/HomeComponent/FuncionCard";
import TitleFirst from "../components/HomeComponent/TitleFirst";
import FAQ from "../components/HomeComponent/FAQ";
import FootNav from "../components/HomeComponent/FootNav";
import UserReview from "../components/HomeComponent/UserReview";
// import { Timeline } from "../components/HomeComponent/Timeline";
import ICPFooter from "../components/HomeComponent/Beian";

export default function HomePage() {

    useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = `
            window.__lc = window.__lc || {};
            window.__lc.license = 18914567;
            window.__lc.integration_name = "manual_channels";
            window.__lc.product_name = "livechat";
            (function(n,t,c){
                function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}
                var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},
                once:function(){i(["once",c.call(arguments)])},
                off:function(){i(["off",c.call(arguments)])},
                get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");
                return i(["get",c.call(arguments)])},
                call:function(){i(["call",c.call(arguments)])},
                init:function(){var n=t.createElement("script");
                n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",
                t.head.appendChild(n)}};
                !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e
            }(window,document,[].slice));
        `;
        document.body.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
            <a href="https://www.livechat.com/chat-with/18914567/" rel="nofollow">Chat with us</a>, powered by 
            <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
        `;
        document.body.appendChild(noscript);
    }, []);

    return (
        <div className="bgpage layout-home" style={{ minHeight: "120vh" }}>
            <HeadNav></HeadNav>
            <TitleFirst></TitleFirst>
            {/* <ImageFirst></ImageFirst> */}
            <FunctionCard></FunctionCard>
            {/* <Cases></Cases> */}
            <DataSection></DataSection>
            {/* <Timeline></Timeline> */}
            <UserReview></UserReview>
            <FAQ></FAQ>
            <FootNav></FootNav>
            <ICPFooter />
        </div>

    )
}