import { Icon } from "@iconify/react/dist/iconify.js"
import { IconUrl } from "../../assets/media/IconUrl"
import RequireText from "../../assets/i18n/TextDefiner"
import { useEffect, useState } from "react"
import { GetStoryline, GetStorylineIndex, StoreStorylineIndex } from "./_psProgressData"
import { useNavigate } from "react-router-dom"
import { Detect } from "../DashboardComponent/AIprogress";

const storyData = {
    0: {
        title: "From Software Engineering to AI Research",
        content: "Share your journey from a solid foundation in software engineering, where you gained practical experience in building scalable and efficient applications."
    },
    1: {
        title: "From Software Engineering to AI Research",
        content: "Share your journey from a solid foundation in software engineering, where you gained practical experience in building scalable and efficient applications."
    },
    2: {
        title: "From Software Engineering to AI Research",
        content: "Share your journey from a solid foundation in software engineering, where you gained practical experience in building scalable and efficient applications."
    }
}

const StoryUnit = ({ id = "", data = storyData[0], onclick = () => { } }) => {
    return (
        <div onClick={onclick} id={id} className="storyUnit card-display">
            {/* <div className="title">{data.title}</div> */}
            <div className="content">
                <ul className="textUnit">{data.content.map(
                    (item) => <li key={item}>{item}</li>
                )}</ul>
            </div>
            <div className="chooseTag"><Icon icon={IconUrl.checkTag}></Icon></div>
        </div>
    )
}

export function PSstoryline() {
    const stoData = GetStoryline()
    const [chooseIndex, SetChooseIndex] = useState(0)
    const navito = useNavigate()

    useEffect(() => {
        for (let i = 0; i < 3; i++) {
            const prev = document.getElementById("psStoryline" + i)
            prev?.classList.toggle("choosed", false)
        }
        const ele = document.getElementById("psStoryline" + chooseIndex)
        ele?.classList.toggle("choosed", true)

        if (chooseIndex !== GetStorylineIndex()) {
            SetChooseIndex(GetStorylineIndex())
        }
        if (stoData === null || stoData === undefined) {
            navito("/dashboard/ps")
        }
        Detect("/dashboard/ps")

    }, [chooseIndex])

    function SetIndex(index) {
        SetChooseIndex(index)
        StoreStorylineIndex(index)
    }
    return (
        <div className="psStoryline">
            <div className="title starTag">{RequireText("psGeneration.chooseStoryline")}</div>
            <div className="storyGroup">
                <StoryUnit onclick={() => SetIndex(0)} id="psStoryline0" data={{ content: stoData === undefined ? [""] : stoData[0] }}></StoryUnit>
                <StoryUnit onclick={() => SetIndex(1)} id="psStoryline1" data={{ content: stoData === undefined ? [""] : stoData[1] }}></StoryUnit>
                <StoryUnit onclick={() => SetIndex(2)} id="psStoryline2" data={{ content: stoData === undefined ? [""] : stoData[2] }}></StoryUnit>
            </div>
        </div>
    )
}