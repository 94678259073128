import { useState } from "react";
import HomePage from "../../pages/HomePage";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { TestPage } from "../test/testPage";
import { AaronBuild } from "../../pages/aaron-build-page"
import { PShome } from "../../components/PSComponent/0PS_home";
import { Dashboard } from "../../components/DashboardComponent/_Dashboard";
import { ContentPS } from "../../components/DashboardComponent/Content_PS";
import { ContentHome } from "../../components/DashboardComponent/Content_home";
import { PScv } from "../../components/PSComponent/1PS_cv";
import { PSstoryline } from "../../components/PSComponent/2PS_storyline";
import { PSpersonal } from "../../components/PSComponent/3PS_personal";
import { PSoverview } from "../../components/PSComponent/4PS_overview";
import { ContentCV } from "../../components/DashboardComponent/Content_CV";
import { AIprogress } from "../../components/DashboardComponent/AIprogress";
import { LoginPage } from "../../pages/LoginPage";
import { ResetPassportPage } from "../../pages/ResetPasswordPage";
import { ContentRL } from "../../components/DashboardComponent/Content_RL";
import { RLhome } from "../../components/RLComponent/RL_home";
import { psProgressData, PsStep1Storyline, PsStep3PsGenerate } from "../../components/PSComponent/_psProgressData";
import { rlProgressData, RLStep1Generate } from "../../components/RLComponent/_rlProgressData";
import { RLpersonalInfo } from "../../components/RLComponent/RL_personal_info";
import { RLrecommenderInfo } from "../../components/RLComponent/RL_recommender_info";
import { RLoverview } from "../../components/RLComponent/RL_overview";
import { SignupPage } from "../../pages/SignupPage";
import { UserPageContainer } from "../../components/UserComponent/UserPageContainer";
import { Survey_first } from "../../components/DashboardComponent/_Survey_first";
import PricingPage from "../../pages/PricingPage";
import { CVHome } from "../../components/CvComponent/_CVHome";
import { CVEditor } from "../../components/CvComponent/_CVEditor";
import { ContentProgramResource } from "../../components/DashboardComponent/Content_PR";
import { ProgramResourcePage } from "../../components/ProgramResourceComponents/_ProgramResourcePage";
import { ProgramWishlistPage } from "../../components/ProgramResourceComponents/_WishlistPage";
import { ContentProgramGuide } from "../../components/DashboardComponent/Content_PG";
import { ProgramGuideHomePage } from "../../components/ProgramGuideComponents/_ProgramGuideHomePage";
import { ContentMatch } from "../../components/ProgramGuideComponents/Content_Match";
import { ContentRecom } from "../../components/ProgramGuideComponents/Content_recom";
import { MatchProgressData, RecomProgressData, PGMatchSubmitAPI, PgStep1, PgStep2 } from "../../components/ProgramGuideComponents/ProgramGuideProgressData";
import { PGcv } from "../../components/ProgramGuideComponents/_ProgramGuideCV";
import { ProgramWishlistPick } from "../../components/ProgramGuideComponents/_ProgramWishlistPick";
import { PersonalizationPage } from "../../components/ProgramGuideComponents/_PersonalizationPage";
import { VerifyCode, SetPassword} from "../../components/UserComponent/Signup";
import { ContentTutor } from "../../components/TutorComponent/Content_tutor";
import { TutorPage } from "../../components/TutorComponent/_TutorPage";
import { ProgramGuideReport } from "../../components/ProgramGuideComponents/_ProgramGuideReport";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsOfUse from "../../pages/TermsOfService";
import { PsEditor } from "../../components/PSComponent/_PsEditor";
import ContactUs from "../../pages/ContactUs";
import { RlEditor } from "../../components/RLComponent/_RlEditor";
import PaymentPage from "../../components/PaymentComponent/_Payment";
import { ReSetPassword, VerifyCodeForRest } from "../../components/UserComponent/ResetPassword";

export const _router = createBrowserRouter(
    [
        {
            path: "/",
            // element: <ProtectTest></ProtectTest>,
            children: [
                {
                    index: true,
                    element: <HomePage />
                },
                {
                    path: "*",
                    element: <Navigate to="/" replace />
                },
                {
                    path: "dashboard",
                    element: <Dashboard />,
                    children: [
                        {
                            index: true,
                            element: <ContentHome></ContentHome>
                        }
                        , {
                            path: "ps",
                            element: <ContentPS></ContentPS>,
                            children: [{
                                index: true,
                                element: <PShome></PShome>
                            },
                            {
                                path: "step1",
                                element: <AIprogress nowStep={0} keyStep={0} progressData={psProgressData} nextcall={PsStep1Storyline} waitingTime={'1 min'}><PScv></PScv></AIprogress>
                            },
                            {
                                path: "step2",
                                element: <AIprogress nowStep={1} keyStep={0} progressData={psProgressData} waitingTime={'1 min'}><PSstoryline></PSstoryline></AIprogress>
                            },
                            {
                                path: "step3",
                                element: <AIprogress nowStep={2} keyStep={0} progressData={psProgressData} nextcall={PsStep3PsGenerate} waitingTime={'1 min'}><PSpersonal></PSpersonal></AIprogress>
                            },
                            {
                                path: "step4",
                                element: <AIprogress nowStep={3} keyStep={0} progressData={psProgressData} waitingTime={'1 min'}><PSoverview></PSoverview></AIprogress>
                            },
                            {
                                path: "psEditor",
                                element: <PsEditor></PsEditor>
                            }
                            ]
                        },
                        {
                            path: "cv",
                            element: <ContentCV></ContentCV>,
                            children: [{
                                index: true,
                                element: <CVHome></CVHome>,
                            },
                            {
                                path: "cvEditor",
                                element: <CVEditor></CVEditor>,

                            }
                            ]
                        },
                        {
                            path: "rl",
                            element: <ContentRL></ContentRL>,
                            children: [{
                                index: true,
                                element: <RLhome></RLhome>
                            },
                            {
                                path: "step1",
                                element: <AIprogress keyStep={0} nowStep={0} progressData={rlProgressData} nextcall={RLStep1Generate} waitingTime={"30s"}><RLrecommenderInfo /></AIprogress>
                            },
                            {
                                path: "step2",
                                element: <AIprogress keyStep={0} nowStep={1} progressData={rlProgressData} waitingTime={"30s"}><RLoverview /></AIprogress>
                            },
                            {
                                path: "rleditor",
                                element: <RlEditor></RlEditor>,

                            },
                            ]
                        },
                        {
                            path: 'browseporgram',
                            element: <ContentProgramResource></ContentProgramResource>,
                            children: [{
                                index: true,
                                element: <ProgramResourcePage></ProgramResourcePage>
                            },
                            {
                                path: "wishlist",
                                element: <ProgramWishlistPage></ProgramWishlistPage>
                            }
                            ]
                        },
                        {
                            path: "programguide",
                            element: <ContentProgramGuide></ContentProgramGuide>,
                            children: [{
                                index: true,
                                element: <ProgramGuideHomePage></ProgramGuideHomePage>
                            },
                            {
                                path: 'report',
                                element: <ProgramGuideReport></ProgramGuideReport>
                            },
                            {
                                path: "match",
                                element: <ContentMatch></ContentMatch>,
                                children: [{
                                    path: "step1",
                                    element: <AIprogress nowStep={0} progressData={MatchProgressData} nextcall={PgStep1} type={'pg'}><ProgramWishlistPick></ProgramWishlistPick></AIprogress>
                                },
                                {
                                    path: "step2",
                                    element: <AIprogress nowStep={1} nextcall={PgStep2} progressData={MatchProgressData} type={'pg'}><PGcv></PGcv></AIprogress>
                                },
                                {
                                    path: "step3",
                                    element: <AIprogress nowStep={2} keyStep={2} progressData={MatchProgressData} nextcall={PGMatchSubmitAPI} type={'pg'}><PersonalizationPage></PersonalizationPage></AIprogress>
                                }]
                            },
                            {
                                path: "recom",
                                element: <ContentRecom></ContentRecom>,
                                children: [{
                                    path: "step1",
                                    element: <AIprogress nowStep={0} progressData={RecomProgressData} nextcall={PgStep2}><PGcv></PGcv></AIprogress>
                                },
                                {
                                    path: "step2",
                                    element: <AIprogress nowStep={1} keyStep={1} progressData={RecomProgressData} nextcall={PGMatchSubmitAPI} type={'pg'}><PersonalizationPage></PersonalizationPage></AIprogress>
                                }
                                ]
                            }
                            ]
                        },
                        {
                            path: "tutor",
                            element: <ContentTutor></ContentTutor>,
                            children: [{
                                index: true,
                                element: <TutorPage></TutorPage>
                            }]
                        },
                    ]
                },
                {
                    path: "test",
                    element: <TestPage></TestPage>
                },
                {
                    path: "aaron",
                    element: <AaronBuild></AaronBuild>
                },
                {
                    path: "user",
                    element: <UserPageContainer></UserPageContainer>,
                    children: [
                        {
                            index: true,
                            element: <LoginPage></LoginPage>,
                        },
                        {
                            path: "login",
                            element: <LoginPage></LoginPage>
                        },
                        {
                            path: "signup",
                            element: <SignupPage></SignupPage>
                        },
                        {
                            path: "verify",
                            element: <VerifyCode></VerifyCode>
                        },
                        {
                            path: "verifyreset",
                            element: <VerifyCodeForRest></VerifyCodeForRest>
                        },
                        {
                            path: "resetpassword",
                            element: <ResetPassportPage></ResetPassportPage>
                        },
                        {
                            path: "rsetpassword",
                            element: <ReSetPassword></ReSetPassword>
                        },
                        {
                            path:"setpassword",
                            element:<SetPassword></SetPassword>
                        }
                    ]
                },
                {
                    path: "pricing",
                    element: <PricingPage></PricingPage>
                },
                {
                    path: "privacy-policy",
                    element: <PrivacyPolicy></PrivacyPolicy>
                },
                {
                    path: "terms-of-use",
                    element: <TermsOfUse></TermsOfUse>
                },
                {
                    path: "contact",
                    element: <ContactUs></ContactUs>
                },
                {
                    path: "payment",
                    element: <PaymentPage></PaymentPage>
                }
            ]
        },

    ]
)
// function ProtectTest() {
//     const [state, setState] = useState(false)
//     const password = "applyu"
//     function comfirmPassword(e) {
//         e.preventDefault();
//         const input = document.getElementById("testpassword").value

//         if (input === password) {
//             setState(true)
//             localStorage.setItem("testPassword", input)
//         }
//         else { alert("fail") }
//     }

//     if (state || localStorage.getItem("testPassword") === password) {
//         return (
//             <>
//                 <Outlet />
//             </>
//         )
//     } else return (
//         <div className="d-all-center y-screen">
//             <form onSubmit={(e) => comfirmPassword(e)}>
//                 <input type="password" name="password" id="testpassword"></input>
//                 <input type="submit" name="submit" value={"登录"}></input>
//             </form>
//         </div>
//     )





// }

