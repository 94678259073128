import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import '../styles/PaymentResultPopup.scss';
import RequireText from "../../assets/i18n/TextDefiner";
import { useTranslation } from "react-i18next";

export const PaymentResultPopup = ({ paymentStatus }) => {
    const { i18n } = useTranslation();
    const [redirecting, setRedirecting] = useState(false);
    const [countdown, setCountdown] = useState(10); // 倒计时秒数

    useEffect(() => {
        setRedirecting(true);
    }, []);

    useEffect(() => {
        let timerId;

        if (redirecting && countdown > 0) {
            timerId = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else if (countdown === 0) {
            handleRedirect();
        }

        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, [redirecting, countdown]);

    const handleRedirect = () => {
        const destination = paymentStatus ? "/dashboard" : "/pricing";
        window.location.href = destination;
    };

    const handleClick = () => {
        handleRedirect();
    };

    return (
        <div className="payment_result_popup">
            <div className="payment_result_popup_container">
                <div className="payment_result_popup_content">
                    <div className="payment_result_popup_content_title">
                        {paymentStatus ? RequireText("payment.result.successTitle") : RequireText("payment.result.failureTitle")}
                    </div>
                    <div className="payment_result_popup_content_text">
                        {paymentStatus
                            ? RequireText("payment.result.successMessage")
                            : RequireText("payment.result.failureMessage")
                        }
                    </div>
                    <div className="payment_result_popup_content_btns">
                        <button className="payment_result_popup_submit-button" onClick={handleClick} disabled={countdown === 0}>
                            {paymentStatus ? RequireText("payment.result.successButton") : RequireText("payment.result.failureButton")}
                        </button>
                        <p>{RequireText("payment.result.redirectMessage")}{countdown}{RequireText("payment.result.redirectMessage2")}</p>
                    </div>

                </div>
            </div>
        </div>
    );
};
