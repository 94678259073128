import { Icon } from "@iconify/react/dist/iconify.js";
import { FunctionCard } from "../Utilities/DashboardItems";
import { IconUrl } from "../../assets/media/IconUrl";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RequireText from "../../assets/i18n/TextDefiner";
import { useEffect, useState } from "react";
import { PostToServerByFormData, PostToServerWithFile } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import {
    SmileTwoTone,
    LoadingOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { InputTextArea } from "../Utilities/InputComponents";
import { ClearCVIDinPS, StoreCVIDinPS } from "./_psProgressData";
import { ModalComponent } from "../Utilities/ModalComponent";



export function PScv() {
    const navito = useNavigate()
    const [now, setNow] = useState(0);
    const [filename, SetFilename] = useState("")
    const [uploaded, SetUploaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const handleOk = () => {
        setModalOpen(false);
    }

    //上传cv
    function UploadCV() {
        SetUploaded(false)
        const ele = document.getElementById("uploadCVInput")
        if (ele) {
            const file = ele.files[0]
            if (file) {
                SetFilename(file.name)
                const pattern = /.pdf/
                //扩展名判断是否为pdf

                if (!pattern.test(file.name)) {
                    setModalContent("Please upload a PDF file")
                    setModalOpen(true)
                    ele.value = null
                    SetFilename("")
                } else if (file.size > 1.5 * 1024 * 1024){
                    setModalContent("File size should be less than 1.5MB")
                    setModalOpen(true);
                    ele.value = null;
                    SetFilename("");
                } else {
                    ClearCVIDinPS();
                    let formdata = new FormData();
                    formdata.append('resumeFile', file);
                    setNow(10);

                    const urlWithQuery = `${NetworkApi.cv.uploadParseCV}?resume_upload_type=statement`;

                    PostToServerWithFile(urlWithQuery, formdata, (re) => {
                        if (re !== undefined) {
                            if (re.success === false) {
                                setModalContent(re.message.cn)
                                setModalOpen(true)
                            }
                            if (re.cv_id !== undefined) {
                                StoreCVIDinPS(re.cv_id);
                                setNow(100);
                                SetUploaded(true);
                            }
                            else {
                                setModalContent("Something Wrong,refresh again")
                                setModalOpen(true)
                                document.location.reload()
                            }

                        } else {
                            setModalContent("Server Error")
                            setModalOpen(true)
                        }

                    })
                }
            }
        }
    }

    useEffect(() => {
        localStorage.setItem("ps_next", uploaded)

    }, [uploaded])

    return (
        <div className="psCV">
            <div className="uploadCV">
                <div className="title">
                    {RequireText("AIwriting.upload")}
                    <span className="tag"> *</span>
                </div>
                <div className="boxGroup">
                    <div className="box">
                        {/* 上传已有cv文件 */}
                        <FunctionCard isUpload onupload={UploadCV} title={RequireText("AIwriting.dragdrop")} tips={RequireText("AIwriting.upload")}></FunctionCard>
                    </div>
                    <div className="textDivider">OR</div>
                    {/* 跳转到cv */}
                    <div onClick={() => {
                        localStorage.setItem("dashboard_route_array", JSON.stringify(["AIwrite", "content_cv"]))
                        navito("/dashboard/cv")
                    }} className="box">
                        <FunctionCard title={RequireText("AIwriting.start")} tips={RequireText("AIwriting.create")}></FunctionCard>
                    </div>
                </div>
            </div>
            {filename === "" ? <></> : <div className="uploadProgress">
                <div className="bgBar">
                    <div className="icon"><Icon icon={IconUrl.cv}></Icon></div>
                    <div className="content">
                        <div className="title">
                            {filename}
                            {now === 100 ? <SmileTwoTone /> : <SyncOutlined spin />}
                        </div>
                        <div className="progressBar">
                            <ProgressBar now={now} label={`${now}%`} animated style={{ height: 12 }} />
                        </div>
                    </div>


                </div>
            </div>}

            <div className="QAInputGroup">
                <div className="QAInputUnit">
                    <div className="Q starTag">{RequireText("psGeneration.targetSchool")}</div>
                    <div className="A">
                        <input id="targetSchool" name="targetSchool" className="inputText" type="text" defaultValue={""} maxLength={100} ></input>
                        <Icon className="iconR" icon={IconUrl.pencil}></Icon>
                    </div>
                </div>
                <div className="QAInputUnit">
                    <div className="Q starTag">{RequireText("psGeneration.targetMajor")}</div>
                    <div className="A">
                        <input id="targetMajor" name="targetMajor" className="inputText" type="text" defaultValue={""} maxLength={100}></input>
                        <Icon className="iconR" icon={IconUrl.pencil}></Icon>
                    </div>
                </div>
                <div className="QAInputUnit">
                    <div className="Q starTag">{RequireText("psGeneration.describeMajor")}</div>
                    <div className="A">
                        <InputTextArea id="programDescription" maxlen={500} />
                    </div>
                </div>
                <div className="QAInputUnit">
                    <div className="Q starTag">{RequireText("psGeneration.emphasizeAspect")}</div>
                    <div className="A">
                        <select id="targetHighlight" name="stressPoint" className="inputText"  >
                            <option value={"Academic Interests and Research Experience"}>{RequireText("psGeneration.statementOption1")}</option>
                            <option value={"Internships and Practical Experience"}>{RequireText("psGeneration.statementOption2")}</option>
                            <option value={"Leadership and Teamwork Skills"}>{RequireText("psGeneration.statementOption3")}</option>
                            <option value={"Interdisciplinary Background and Innovative Thinking"}>{RequireText("psGeneration.statementOption4")}</option>
                        </select>
                        {/* <Icon className="iconR" icon={IconUrl.selectDown}></Icon> */}
                    </div>
                </div>
            </div>
            {modalOpen && <ModalComponent modalOpen={modalOpen} content={modalContent} onOk={handleOk} />}
        </div>
    )
}