import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if the user has already made a choice
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true); // Show the banner if no choice has been made
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false); // Hide the banner
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setIsVisible(false); // Hide the banner
    };

    if (!isVisible) return null; // Do not render if the banner is not visible

    return (
        <div style={styles.banner}>
            <p style={styles.text}>
                We use cookies to enhance your experience. By continuing to visit this site, you accept our use of cookies.
            </p>
            <button onClick={handleAccept} style={styles.button}>Accept</button>
            <button onClick={handleReject} style={styles.button}>Reject</button>
        </div>
    );
};

const styles = {
    banner: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'gray',
        opacity: 1,
        color: 'White',
        padding: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    text: {
        margin: '16px 10px 16px 0',
    },
    button: {
        marginLeft: '10px',
        padding: '10px 20px',
        backgroundColor: 'rgba(40, 126, 255, 1)',
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
    },
};

export default CookieConsent;
