import RequireText from "../../assets/i18n/TextDefiner";
import { QAUnitSlider } from "../Utilities/DashboardItems";
import { InputTextSingle, SelectMultiple } from "../Utilities/InputComponents";

export function RLpersonalInfo() {
    const skillOptionData = [
        { name: "Analytical Skills", value: RequireText("rlGeneration.tag1") },
        { name: "Innovative Thinking", value: RequireText("rlGeneration.tag2") },
        { name: "Team Collaboration", value: RequireText("rlGeneration.tag3") },
        { name: "Leadership", value: RequireText("rlGeneration.tag4") },
        { name: "Problem-Solving Skills", value: RequireText("rlGeneration.tag5") },
        { name: "Communication Skills", value: RequireText("rlGeneration.tag6") },
        { name: "Organizational Skills", value: RequireText("rlGeneration.tag7") },
        { name: "Adaptability", value: RequireText("rlGeneration.tag8") },
        { name: "Critical Thinking", value: RequireText("rlGeneration.tag9") },
        { name: "Research Skills", value: RequireText("rlGeneration.tag10") },
        { name: "Teaching Proficiency", value: RequireText("rlGeneration.tag11") },
        { name: "Technical Proficiency", value: RequireText("rlGeneration.tag12") },
        { name: "Creativity", value: RequireText("rlGeneration.tag13") },
        { name: "Time Management", value: RequireText("rlGeneration.tag14") },
        { name: "Cross-cultural Communication", value: RequireText("rlGeneration.tag15") },

    ]
    return (
        <div className="rlPersonalInfo">
            <div className="baseUnit">
                <div className="title">{RequireText("rlGeneration.tellExperiences")}</div>
                <div><SelectMultiple id="rlPersonalSkills" question={RequireText("rlGeneration.skillTags")} optionData={skillOptionData}></SelectMultiple></div>
                <div><InputTextSingle id="targetSchool" question={RequireText("rlGeneration.targetSchool")} ></InputTextSingle></div>
                <div><InputTextSingle id="targetMajor" question={RequireText("rlGeneration.targetMajor")} ></InputTextSingle></div>
            </div>
            <div className="other">
                <div className="title">{RequireText("rlGeneration.other")}</div>
                <div><QAUnitSlider id="rlMaxNum" sliderData={{ name: "rlLetterNum", min: 300, max: 500, question: RequireText("rlGeneration.wordCount") }}></QAUnitSlider></div>
            </div>

        </div>
    )
}

export function Store_RL_Input_Highlights(value) {
    localStorage.setItem("rl_highlight", JSON.stringify(value))
}
export function Get_RL_Input_Highlights() {
    if (localStorage.key("rl_highlight"))
        return JSON.parse(localStorage.getItem("rl_highlight"))
    else return null
}