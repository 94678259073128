import { PostToServerWithJson } from "../../assets/network/NetWork"
import { NetworkApi } from "../../assets/network/NetworkApi"
import { generateHTML } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

export const psProgressData = [
    { id: 0, text: "psGeneration.step1", url: "ps/step1" },
    { id: 1, text: "psGeneration.step2", url: "ps/step2" },
    { id: 2, text: "psGeneration.step3", url: "ps/step3" },
    { id: 3, text: "psGeneration.step4", url: "ps/step4" },
]
//调用函数
export function PsStep1Storyline(callback = () => { }, failCall = () => { }) {
    if (GetCVIDinPS() === null) {
        //alert("Please Choose A CV")
        failCall("Please Choose A CV First")
        return
    }
    //console.log(document.getElementById("uploadCVInput")?.files[0])
    if (document.getElementById("uploadCVInput")?.files[0] === undefined) {
        //alert("Please Choose A CV2")
        failCall("Please Choose A CV First")
        return
    }
    const api = NetworkApi.ps.storyline + "/" + GetCVIDinPS()
    const s = document.getElementById("targetSchool")?.value
    const m = document.getElementById("targetMajor")?.value
    const h = document.getElementById("targetHighlight")?.value
    const d = document.getElementById("programDescription")?.value

    if (s === "" || m === "" || h === "" || d === "") {
        //alert("Please Fill All The Information")
        failCall("Please Required Information")
        return
    }

    const data = {
        "target_school": s,
        "target_major": m,
        "highlights": [h],
        "program_requirements_description": d
    }
    StoreCVInfo(data)

    PostToServerWithJson(api, data, (re) => {
        SetStoryline(JSON.stringify(re.bullet_points))
        localStorage.setItem("stroyID", re.storyline_id)
        callback(re);
        //生成ps开端
    })

}
export function PsStep3PsGenerate(callback = () => { }, failCall = () => { }) {

    //发送请求

    //exgroup
    StoreExGroup(localStorage.getItem("ExGroupNum"))
    //futurePlan
    const f = document.getElementById("futurePlan")
    if (f) StoreFuturePlan(f.value)
    //psMaxNum
    const n = document.getElementById("psMaxNum")
    StorePsMaxNum(n.value)
    //cvinfo
    const cvinfo = GetCVInfo()
    //id
    const cvID = GetCVIDinPS()
    //storyline
    // const storyline = GetStoryline()[GetStorylineIndex()]
    const storyline = GetStorylineIndex()
    //storyline_id
    const storyline_id = localStorage.getItem("stroyID")
    //exGroup
    const exgroup = GetExGroup()
    //futurePlan
    const futurePlan = GetFuturePlan()
    //psMaxNum
    const psMaxNum = GetPsMaxNum()
    // console.log(cvinfo)
    // console.log(cvID)
    // console.log(storyline)
    // console.log(exgroup)
    // console.log(futurePlan)
    // console.log(psMaxNum)
    const api = NetworkApi.ps.generation + "/" + cvID
    const data = {
        "target_school": cvinfo.target_school,
        "target_major": cvinfo.target_major,
        "highlights": cvinfo.highlights,
        "program_requirements_description": cvinfo.program_requirements_description,
        "storyline_chosen_index": storyline,
        "storyline_id":storyline_id,
        "personal_experience_dict": {
            "additionalProp1": {
                "key": exgroup.length >= 1 ? exgroup[0].Q : "",
                "value": exgroup.length >= 1 ? exgroup[0].A : "",
            },
            "additionalProp2": {
                "key": exgroup.length >= 2 ? exgroup[1].Q : "",
                "value": exgroup.length >= 2 ? exgroup[1].A : "",
            },
            "additionalProp3": {
                "key": exgroup.length >= 3 ? exgroup[2].Q : "",
                "value": exgroup.length >= 3 ? exgroup[2].A : "",
            },
        },
        "future_impact_in_the_field": cvID.futurePlan,
        "max_word_count": parseInt(psMaxNum)
    }
    PostToServerWithJson(api, data, (re) => {
        if (re.status === "success") {
            localStorage.setItem("d_ps", re.draft_statement)
            localStorage.setItem('ps_id', re.ps_id.ps_id)
            callback(re)
        }else{
            failCall(re.message.cn)
        }
    })

}


//存储函数
//id、cvInfo、storylineindex、exgroup、futurePlan、num
//id
export function StoreCVIDinPS(value) {
    localStorage.setItem("current_PS_CV_ID", value)
}

export function GetCVIDinPS() {
    return localStorage.getItem("current_PS_CV_ID")
}
//cvinfo
export function StoreCVInfo(data) {
    localStorage.setItem("cvInfo", JSON.stringify(data))
}
export function GetCVInfo() {
    if (localStorage.getItem("cvInfo"))
        return JSON.parse(localStorage.getItem("cvInfo"))
}
//storylineIndex
export function StoreStorylineIndex(value) {
    localStorage.setItem("storylineIndex", value)
}
export function GetStorylineIndex() {
    if (localStorage.getItem("storylineIndex") !== null)
        return localStorage.getItem("storylineIndex")
}
//exGroup
export function StoreExGroup(num) {
    if (num <= 0) return
    let data = []
    const preStr = "questionEx"
    for (let i = 0; i < num; i++) {
        let Qele = document.getElementById(preStr + i + "Q")
        let Aele = document.getElementById(preStr + i + "A")
        const Q = Qele?.value
        const A = Aele?.value
        const dataUnit = { "Q": Q, "A": A }
        data.push(dataUnit)
    }
    const name = "PsQuestionExGroup"
    localStorage.removeItem(name)
    localStorage.setItem(name, JSON.stringify(data))
}
export function GetExGroup() {
    return JSON.parse(localStorage.getItem("PsQuestionExGroup"))
}
export function SetExGroup(data = [], currentNum) {

    const num = data.length
    if (num <= 0) return
    if (data === null) return

    const preStr = "questionEx"
    for (let i = 0; i < num; i++) {
        if (i >= currentNum) return
        let Qele = document.getElementById(preStr + i + "Q")
        let Aele = document.getElementById(preStr + i + "A")
        if (data[i].Q) Qele.value = data[i].Q
        if (data[i].A) Aele.value = data[i].A
    }
}
export function GetExGroupNum() {
    if (localStorage.getItem("PsQuestionExGroup") !== null)
        return JSON.parse(localStorage.getItem("PsQuestionExGroup")).length
    else return 1
}
//futurePlan
export function StoreFuturePlan(value) {
    localStorage.setItem("psFuturePlan", value)
}
export function GetFuturePlan() {
    return localStorage.getItem("psFuturePlan")
}
//psMaxNum
export function StorePsMaxNum(value) {
    localStorage.setItem("psMaxNum", value)
}
export function GetPsMaxNum() {
    if (localStorage.getItem("psMaxNum") !== null)
        return localStorage.getItem("psMaxNum")
    else return null
}

export function ClearCVIDinPS() {
    localStorage.removeItem("current_PS_CV_ID")
}
export function SetStoryline(value) {
    localStorage.setItem("psStoryline", value)
}
export function GetStoryline() {

    if (localStorage.key("psStoryline") === null)
        return null
    else if (localStorage.getItem("psStoryline") !== null)
        return JSON.parse(localStorage.getItem("psStoryline"))
}

function formatStringToTiptapContent(text) {
    if (!text) return null;

    // Split the text by double newlines for paragraphs and single newlines for line breaks
    const paragraphs = text.split('\n\n').map(para => {
        // Inside each paragraph, split by single newlines for soft breaks
        const content = para.split('\n').map(line => ({
            type: 'text',
            text: line
        }));

        // Add soft breaks between lines, except after the last one
        for (let i = 0; i < content.length - 1; i++) {
            content.splice(i * 2 + 1, 0, { type: 'hardBreak' });
        }

        return {
            type: 'paragraph',
            content: content
        };
    });

    return {
        type: 'doc',
        content: paragraphs
    };
}

// Step 2: Convert Tiptap JSON to HTML
export function convertStringToHtml(text) {
    const jsonContent = formatStringToTiptapContent(text);

    // Use Tiptap's generateHTML function to convert JSON to HTML
    const html = generateHTML(jsonContent, [StarterKit]);
    return html;
}

