import React, { useState } from 'react';
import RequireText from "../../assets/i18n/TextDefiner";
const PlanOptions = ({ selectedPlan, onPlanChange }) => {
    const plans = [
        { name: 'Entry', price: 898 },
        { name: 'Prime', price: 1380 },
        { name: 'Premium', price: 2480 },
        { name: 'VIP', price: 5888 },
    ];

    return (
        <div className="plan-details_choices">
            {plans.map((plan) => (
                <button
                    key={plan.name}
                    onClick={() => onPlanChange(plan.name, plan.price)}
                    className={selectedPlan === plan.name ? 'plan-details_choice_selected' : 'plan-details_choice'}
                >
                    <div className="plan-details_choice_title">
                        {RequireText(`payment.plans.${plan.name.toLowerCase()}`)}
                    </div>
                    <div className="plan-details_choice_price">
                        {plan.price} <span>{RequireText(`payment.currency`)}</span>
                    </div>
                </button>
            ))}
        </div>
    );
};

const FeatureList = ({ selectedPlan }) => (
    <div className="plan-details_intros">
        {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="plan-details_intros_list">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 22 22" fill="none">
                        <path d="M6 11.1081L9.94872 15L17 7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div>{RequireText(`payment.features.${selectedPlan.toLowerCase()}.feature${index + 1}`)}</div>
            </div>
        ))}
    </div>
);

const PaymentSummary = ({ price, onConfirm }) => (
    <div className="plan-details_price">
        <div>
            <span>{RequireText(`payment.total`)}</span> <span>{price}</span> {RequireText(`payment.currency`)}
        </div>
        <button onClick={onConfirm} className="submit-button">
            {RequireText(`payment.confirmButton`)}
        </button>
    </div>
);

export const PlanDetails = ({ onSelectPlan, handlePlanConfirm }) => {
    const [selectedPlan, setSelectedPlan] = useState("Prime");
    const [price, setPrice] = useState(1380);

    const handlePlanChange = (plan, price) => {
        setSelectedPlan(plan);
        setPrice(price);
        onSelectPlan(plan, price);
    };

    return (
        <div className="plan-details">
            <PlanOptions selectedPlan={selectedPlan} onPlanChange={handlePlanChange} />
            <FeatureList selectedPlan={selectedPlan} />
            <PaymentSummary price={price} onConfirm={handlePlanConfirm} />
        </div>
    );
};


// export const PlanDetails = ({ onSelectPlan, handlePlanConfirm }) => {
//     const [selectedPlan, setSelectedPlan] = useState("Prime");
//     const [price, setPrice] = useState(1380);
//     const { i18n } = useTranslation();
//     // const [orderStatus, setOrderStatus] = useState<boolean>(false);
//     // const [showCheckOrderStatus, setShowCheckOrderStatus] = useState<boolean>(false);

//     // const planContents = {
//     //     'Entry': [
//     //         [RequireText('payment.descriptions.entry')],
//     //         'Browse 10000+ master programs',
//     //         'Organize your professional profile',
//     //         'Personal tutor assistance 24/7',
//     //     ],
//     //     'Prime': [
//     //         'Get one personalized program guide report (each includes 10 recommended programs)',
//     //         'Browse 10000+ master programs',
//     //         'Organize your professional profile',
//     //         'Personal tutor assistance 24/7',
//     //     ],
//     //     'Premium': [
//     //         'Get five personalized program guide reports, each containing 10 individually tailored program recommendations, allowing for nuanced adjustments to your targeted major, career plans, and experiences.',
//     //         'Browse 10000+ master programs',
//     //         'Organize your professional profile',
//     //         'Personal tutor assistance 24/7',
//     //     ],
//     //     'VIP': [
//     //         'Get five personalized program guide reports, each containing 10 individually tailored program recommendations, allowing for nuanced adjustments to your targeted major, career plans, and experiences.',
//     //         'Browse 10000+ master programs',
//     //         'Organize your professional profile',
//     //         'Personal tutor assistance 24/7',
//     //     ]
//     // };

//     // const infoContents = {
//     //     'Entry': [
//     //         'Experience our service with one personalized program guide report at a special introductory price.'
//     //     ],
//     //     'Prime': [
//     //         'Optimize your application with one personalized program report.'
//     //     ],
//     //     'Premium': [
//     //         'Enhance your choices with five personalized reports in the Primer plan.'
//     //     ],
//     //     'VIP': [
//     //         'Enhance your choices with five personalized reports in the Primer plan.'
//     //     ],
//     // };


//     const handlePlanChange = (plan, price) => {
//         setSelectedPlan(plan);
//         setPrice(price);
//         onSelectPlan(plan, price);
//     };

//     return (
//         <div className="plan-details">
//             <div className="plan-details_choices">
//                 <button onClick={() => handlePlanChange('Entry', 898)}
//                     className={selectedPlan === 'Entry' ? 'plan-details_choice_selected' : 'plan-details_choice'}
//                 >
//                     <div className="plan-details_choice_title">
//                         {RequireText(`payment.plans.entry`)}
//                     </div>
//                     <div className="plan-details_choice_price">
//                         898 <span>{RequireText(`payment.currency`)}</span>
//                     </div>
//                 </button>
//                 <button onClick={() => handlePlanChange('Prime', 1380)}
//                     className={selectedPlan === 'Prime' ? 'plan-details_choice_selected' : 'plan-details_choice'}
//                 >
//                     <div className="plan-details_choice_title">
//                         {RequireText(`payment.plans.prime`)}
//                     </div>
//                     <div className="plan-details_choice_price">
//                         1380 <span>{RequireText(`payment.currency`)}</span>
//                     </div>
//                 </button>
//                 <button onClick={() => handlePlanChange('Premium', 2480)}
//                     className={selectedPlan === 'Premium' ? 'plan-details_choice_selected' : 'plan-details_choice'}
//                 >
//                     <div className="plan-details_choice_title">
//                         {RequireText(`payment.plans.premium`)}
//                     </div>
//                     <div className="plan-details_choice_price">
//                         2480 <span>{RequireText(`payment.currency`)}</span>
//                     </div>
//                 </button>
//                 <button onClick={() => handlePlanChange('VIP', 5888)}
//                     className={selectedPlan === 'VIP' ? 'plan-details_choice_selected' : 'plan-details_choice'}
//                 >
//                     <div className="plan-details_choice_title">
//                         {RequireText(`payment.plans.vip`)}
//                     </div>
//                     <div className="plan-details_choice_price">
//                         5888 <span>{RequireText(`payment.currency`)}</span>
//                     </div>
//                 </button>

//             </div>
//             {/* <div className="plan-details_intro">
//                 {RequireText(`payment.descriptions.${selectedPlan.toLowerCase()}`)}
//             </div> */}
//             <div className="plan-details_intros">
//                 {Array.from({ length: 6 }, (_, index) => (
//                     <div key={index} className="plan-details_intros_list">
//                         <div>
//                             <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 22 22" fill="none">
//                                 <path d="M6 11.1081L9.94872 15L17 7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
//                             </svg>
//                         </div>
//                         <div>{RequireText(`payment.features.${selectedPlan.toLowerCase()}.feature${index + 1}`)}</div>
//                     </div>
//                 ))}
//             </div>
//             <div className="plan-details_price">
//                 <div>
//                     <span>{RequireText(`payment.total`)}</span>     <span>{price}</span> {RequireText(`payment.currency`)}
//                 </div>
//                 <button onClick={handlePlanConfirm} className="submit-button">{RequireText(`payment.confirmButton`)}</button>
//             </div>
//         </div>
//     );
// };