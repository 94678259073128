export const IconUrl = {
    home: "iconoir:home-alt-slim-horiz",
    AIwriting: "jam:write",
    cv: "tabler:file-cv",
    ps: "tabler:file-cv",
    rl: "tabler:file-cv",
    findProgram: "mdi:report-box-plus-outline",
    resources: "solar:pin-list-linear",
    guide: "solar:clipboard-list-linear",
    tutor: "material-symbols:perm-contact-calendar-outline-rounded",
    workplace: "hugeicons:resources-add",
    calendar: "uil:calendar-alt",
    messages: "tabler:message-2",
    settings: "fluent:settings-48-filled",
    payment: "solar:wallet-money-bold",
    help: "solar:info-square-bold",
    logout: "bxs:exit",
    star: "tabler:star",
    arrowR: "material-symbols:arrow-forward-ios-rounded",
    arrowL: "material-symbols:arrow-back-ios-rounded",
    circle: "mdi:checkbox-blank-circle-outline",
    circleDone: "mdi:checkbox-marked-circle-outline",
    pencil: "ph:pencil-simple-line-bold",
    selectDown: "iconamoon:arrow-down-2-fill",
    checkTag: "material-symbols:check-circle-rounded",
    littleCircle: "material-symbols-light:circle",
    timeline: 'material-symbols-light:line-end',
    doubleArrowR: "uiw:d-arrow-right",
    survey_school: "hugeicons:school-01",
    survey_job: "hugeicons:school-01",
    survey_explore: "hugeicons:building-04",
    language_en: "clarity:language-line",
    language_zh: "clarity:language-solid",
    logout:'material-symbols:logout'


}