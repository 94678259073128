import { upload } from "@testing-library/user-event/dist/upload"

export const ServerIP = "https://apply-u.com/applyu_backend"

//调用的api
export const NetworkApi = {
    user: {
        userLogin: "/user-service/login",
        userLogout: "/user-service/logout",
        userSignup: "/user-service/signup",
        userResetPassword: "/user-service/reset-password",
        userGetStats: "/user-service/whoami",
        userSendCode: "/user-service/send-code",
        userSendCodeForReset: "/user-service/send-code-for-reset-passoword",
        userVerifyCode: "/user-service/code-validation",
        userVerifyCodeForReset: "/user-service/code-validation-for-reset-password",
        userProfile: "/user-service/get_profile",
        userQuota: "/base-service/show-user-quota",
        logout: "/user-service/logout"


    },

    programResource: {
        total_Program_Page: "/base-service/total-program-pages",
        show_Programs: "/base-service/show-programs",
        show_Uni: "/base-service/show-universities",
        add_wishlist: "/student-service/add-wishlist",
        delete_wishlist: "/student-service/remove-from-wishlist-wishlist-ids",
        delete_wishlist_program: "/student-service/remove-item-wishlist-course-id",
        get_wishlist: "/student-service/get-user-wishlist",
    },

    programGuide: {
        submitReport: "/student-service/submit-guide-request",
        PGuploadCV: "/student-service/upload-CV-for-report",
        showAllReport: "/student-service/show-student-all-reports",
        showSingleReport: "/student-service/read-single-student-reports",
        deleteReport: "/student-service/delete-single-student-reports",
        renameReport: "/student-service/update-report-file-name"
    },

    cv: {
        createCV: "/student-service/create-CV",
        updateCV: "/student-service/update-CV",
        uploadParseCV: "/student-service/upload-parser-CV",
        getSingleCV: "/student-service/get-single-CV",
        getAllCV: "/student-service/get-user-CV",
        deleteSingleCV: "/student-service/delete-CV",
        cvRename: '/student-service/update-CV-filename',
        getSystemCv: "/student-service/get-user-CV-for-submit-student-report",
        cvgetPermission: "/student-service/get-user-permission-cv"
    },

    editorAI: {
        Translate: "/base-service/translation",
        CVGeneration: "/student-service/ai-cv-generation",
        CVRewrite: "/student-service/ai-cv-rewrite",
    },

    tutor: {
        total_tutor_page: "/base-service/total-tutor-pages",
        show_tutor: "/base-service/show-tutors",
        sendEmail: "/base-service/contact-tutor/",
    },
    ps: {
        uploadCV: "/student-service/upload-parser-CV",
        storyline: "/student-service/upload-cv-storyline-generation",
        generation: "/student-service/ai-ps-generation",
        getExistPs: "/student-service/get-user-PS",
        getSinglePs: "/student-service/get-single-PS",
        updatePS: "/student-service/update-PS",
        deletePS: "/student-service/delete-PS",
        renamePS: "/student-service/update-PS-filename"
    },
    rl: {
        generation: "/student-service/ai-rl-generation",
        getExistRl: "/student-service/get-user-RL",
        getSingleRl: "/student-service/get-single-RL",
        updateRl: "/student-service/update-RL",
        deleteRl: "/student-service/delete-Rl",
        renameRl: "/student-service/update-RL-filename"
    },
    payment: {
        invokePayment: "/payment-service/invoke_payment",
        queryTransaction: "/payment-service/query_transaction"
    },
}