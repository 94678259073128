import React from 'react';
import { Spin } from 'antd';


export const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <Spin size="large" />
    </div>
  );
};
