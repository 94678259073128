import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer, DeleteToServerWithJson } from "../../assets/network/NetWork";
import RequireText from "../../assets/i18n/TextDefiner";
import { ModalComponent } from "../Utilities/ModalComponent";




export const FileCard = ({ filename, fileDate, type, event_id, setData, setOpenModal, setModalContent, setRename }) => {
    const [drop, setDrop] = useState(false)
    const navito = useNavigate()

    //点击文件名
    const handleClick = () => {
        localStorage.setItem("pgfilename", event_id)
        navito("/dashboard/programguide/report")
    }

    const handleDelete = () => {
        DeleteToServerWithJson(NetworkApi.programGuide.deleteReport.concat('/', event_id), {}, (res) => {
            if (res.success === true) {
                setData(prev => {
                    const newFiles = prev.filter(item => item.event_id !== event_id);
                    return newFiles;
                });
            } else {
                setModalContent(res.message.cn)
                setOpenModal(true)
            }
        })
        setDrop(!drop); //关闭下拉菜单
    };

    const handleRename = () => {
        setRename(true);
        setDrop(!drop);
        localStorage.setItem("pgfilename", event_id)
    }


    return (
        <div className="CvPrevFile-fileCard">
            <div className="CvPrevFile-fileCard-container">
                <div className="fileCard-row1">
                    <div className="fileCard-CvIconContainer">
                        <Icon id="cvIcon" icon={type === "match" ? "tabler:file-cv" : "tabler:file-cv"} />
                    </div>
                </div>
                <div className="fileCard-row2">
                    <div className="fileCard-filename" style={{ fontSize: filename.length > 20 ? "14px" : "16px" }} onClick={handleClick}>{filename}</div>
                    <div className="fileCard-fileDate">{fileDate}</div>
                </div>
                <div className="fileCard-row3" onClick={() => setDrop(!drop)}>
                    <Icon id="optionIcon" icon={"mage:dots"} style={{ display: "hiden" }} />
                </div>
            </div>
            {drop &&
                <div className="dropDown-list">
                    <div className="dropDown-item" onClick={handleDelete}>
                        <Icon icon={'material-symbols:delete-outline'} />
                        <span>Delete</span>
                    </div>
                    {/* <div className="dropDown-item" onClick={handleDownload}>
                        <Icon icon={'material-symbols:download'} />
                        <span>Download</span>
                    </div> */}
                    <div className="dropDown-item" onClick={handleRename}>
                        <Icon icon={'gg:rename'} />
                        <span>Rename</span>
                    </div>
                </div>
            }
        </div>
    );
};

export function ProgramPrevFile({ rename, setRename }) {

    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState("")

    useEffect(() => {
        GetToServer(NetworkApi.programGuide.showAllReport, (re) => {
            if (re.success === true) {
                if (re.student_reports !== null) setData(re.student_reports);
            } else {
                setModalContent(re.message.cn)
                setModalOpen(true);
            }
        })
    }, [rename])

    const handleOk = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div className="CvPrevFile">
                <label className="CvPrevFile-label">{RequireText("AIwriting.prevFile")}</label>
                <div className="CvPrevFile-container">
                    {data.length > 0 && data.map((item, index) => {
                        return <FileCard key={`report_${index}`}
                            filename={item.report_name}
                            fileDate={item.update_time}
                            setData={setData}
                            event_id={item.event_id}
                            setModalContent={setModalContent}
                            setOpenModal={setModalOpen}
                            setRename={setRename}
                            type="match" />
                    })
                    }
                </div>
            </div>
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
        </>
    )
}