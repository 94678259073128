import { Icon } from "@iconify/react/dist/iconify.js";
import { NetworkApi } from "../../../assets/network/NetworkApi";
import { PostToServerWithJson } from "../../../assets/network/NetWork";
import { useState } from "react";
import RequireText from "../../../assets/i18n/TextDefiner";
import { Spin } from "antd";
import { set } from "animejs";

//form-0
export const TranslatorFormCard = ({ selectedContent, setSelectedContent, setResulttext }) => {

    const [selectedLanguage, setSelectedLanguage] = useState('zh');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (selectedLanguage === "en" && !selectedContent.match(/[\u3400-\u9FBF]/)) {
            setResulttext(selectedContent);
            setLoading(false);
            return;
        };
        const body = {
            text: selectedContent,
            "source_language": selectedLanguage === 'zh' ? 'en' : 'zh',
            "target_language": selectedLanguage,
        }

        PostToServerWithJson(NetworkApi.editorAI.Translate, body, (re) => {
            if (re.status === 'success') {
                setResulttext(re.translated_text);
            } else {
                alert(re.message.cn);
            }
            setLoading(false);
        })
    }

    const handleDiscard = (event) => {
        event.preventDefault();
        setResulttext('');
        setSelectedContent('');
    }

    const translation = RequireText("AIwriting.translate");



    return (
        <>
            <form className="SubForm" onSubmit={handleSubmit}>
                <label className="SubForm-question">{RequireText("AIwriting.inputText")} <span style={{ color: 'red' }}>*</span></label>
                <div className="SubForm-textareaContainer">
                    <textarea className="SubForm-textarea"
                        value={selectedContent}
                        onChange={(event) => setSelectedContent(event.target.value)}
                        rows={10}
                        maxLength={500}
                        required></textarea>
                    <div className="SubForm-selectContainer">
                        <label className="SubForm-label">{RequireText("AIwriting.targetLanguage")}<span style={{ color: 'red' }}>*</span>:</label>
                        <select className="SubForm-select" defaultValue={'zh'} onChange={(event) => setSelectedLanguage(event.target.value)}>
                            <option value="zh">中文</option>
                            <option value="en">English</option>
                        </select>
                    </div>
                </div>
                <div className='SubForm-buttonContainer'>
                    <button className="SubForm-button" type="submit" disabled={loading} style={{ backgroundColor: loading ? '#ccc' : '#287EFF' }}>
                        {loading ? <Spin /> : translation}
                        {!loading && <Icon icon={'iconamoon:check-bold'} id='buttonIcon' />}
                    </button>
                    <button className="SubForm-button2" onClick={handleDiscard}>
                        {RequireText("AIwriting.discard")}
                        <Icon icon={'iconamoon:close-bold'} id='buttonIcon' />
                    </button>
                </div>
            </form>
        </>
    )
};

//form-1
export const RewriteFormCard = ({ selectedContent, setSelectedContent, setResulttext }) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const body = {
            text: selectedContent,
        }
        PostToServerWithJson(NetworkApi.editorAI.CVRewrite, body, (re) => {
            if (re.status === 'success') {
                setResulttext(re.rewritten_text);
            } else {
                alert(re.message.cn);
            }
            setLoading(false)
        })
    }

    const handleDiscard = (event) => {
        event.preventDefault();
        setResulttext('');
        setSelectedContent('');
        const form = event.target;

    }

    const rewrite = RequireText("AIwriting.rewriteContent");
    return (
        <>
            <form className="SubForm" onSubmit={handleSubmit}>
                <label className="SubForm-question">{RequireText("AIwriting.rewriteHere")} <span style={{ color: 'red' }}>*</span></label>
                <textarea className="SubForm-textarea"
                    value={selectedContent}
                    onChange={(event) => setSelectedContent(event.target.value)}
                    rows={10}
                    maxLength={500}
                    required></textarea>
                <div className='SubForm-buttonContainer'>
                    <button className="SubForm-button" type="submit" disabled={loading} style={{ backgroundColor: loading ? '#ccc' : '#287EFF' }}>
                        {loading ? <Spin /> : rewrite}
                        {!loading && <Icon icon={'iconamoon:check-bold'} id='buttonIcon' />}
                    </button>
                    <button className="SubForm-button2" onClick={handleDiscard}>
                        {RequireText("AIwriting.discard")}
                        <Icon icon={'iconamoon:close-bold'} id='buttonIcon' />
                    </button>
                </div>
            </form>
        </>
    )
};

//form-2
export const GeneratorFormCard = ({ selectedContent, setSelectedContent, setResulttext }) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formdata = new FormData(event.target);
        const body = {
            "target_major": formdata.get('target_major'),
            "major_description": formdata.get('major_description'),
            "experience_description": selectedContent,
        }
        PostToServerWithJson(NetworkApi.editorAI.CVGeneration, body, (re) => {
            if (re.status === 'success') {
                if (Array.isArray(re.resume)) {
                    setResulttext(re.resume.join('\n\n'));
                } else {
                    setResulttext(re.resume);
                }
            } else {
                alert(re.message.cn);
            }
            setLoading(false)
        })
    }

    const handleDiscard = (event) => {
        event.preventDefault();
        setResulttext('');
        setSelectedContent('');
        const form = document.querySelector(".SubForm");
        if (form) {
            form.reset();
        }
    };

    const generate = RequireText("AIwriting.generatePoints");

    return (
        <>
            <form className="SubForm" onSubmit={handleSubmit}>
                <label className="SubForm-question">{RequireText("AIwriting.enterMajorRequired")} <span style={{ color: 'red' }}>*</span></label>
                <input className="SubForm-text" type="text" name="target_major" maxLength={100} required />
                <label className="SubForm-question">{RequireText("AIwriting.enterMajorDescription")}</label>
                <textarea className="SubForm-textarea" name="major_description" placeholder={RequireText("AIwriting.enterMainText")} rows={5} maxLength={500} ></textarea>
                <label className="SubForm-question">{RequireText("AIwriting.describeExperience")} <span style={{ color: 'red' }}>*</span></label>
                <textarea className="SubForm-textarea"
                    placeholder={RequireText("AIwriting.enterMainText")}
                    value={selectedContent}
                    onChange={(event) => setSelectedContent(event.target.value)}
                    rows={10}
                    maxLength={500}
                    required></textarea>
                <div className='SubForm-buttonContainer'>
                    <button className="SubForm-button" type="submit" disabled={loading} style={{ backgroundColor: loading ? '#ccc' : '#287EFF' }}>
                        {loading ? <Spin /> : generate}
                        {!loading && <Icon icon={'iconamoon:check-bold'} id='buttonIcon' />}
                    </button>
                    <button className="SubForm-button2" onClick={handleDiscard} >
                        {RequireText("AIwriting.discard")}
                        <Icon icon={'iconamoon:close-bold'} id='buttonIcon' />
                    </button>
                </div>
            </form>
        </>
    )
};

// result part
export const ResultCard = ({ page, resulttext, setResulttext, selectedDom }) => {

    const handleClick = () => {
        if (!Array.isArray(resulttext)) {
            const splitData = resulttext.split('\n\n');
            const ul = document.createElement('ul'); // Create a <ul> to wrap the <li> elements

            for (let i = 0; i < splitData.length; i++) {
                const li = document.createElement('li');
                li.textContent = splitData[i]; // Set the text content for each <li>
                ul.appendChild(li); // Append each <li> to the <ul>
            }

            selectedDom.innerHTML = ''; // Clear the editor content first
            selectedDom.appendChild(ul); // Append the <ul> to the editor element}
        } else {
            const ul = document.createElement('ul');
            for (let i = 0; i < resulttext.length; i++) {
                const li = document.createElement('li');
                li.textContent = resulttext[i]; // Set the text content for each <li>
                ul.appendChild(li); // Append each <li> to the <ul>
            }
            selectedDom.innerHTML = ''; // Clear the editor content first
            selectedDom.appendChild(ul); // Append the <ul> to the editor element}
        }
    }

    const handleChange = (event) => {
        setResulttext(event.target.value);
    }

    return (
        <>
            <div className="CvResultCard">
                <label className="CvResultCard-label">
                    {page === 0 ? "Translated Text" : page === 1 ? "Revised Text" : "Generated Text"}
                </label>
                <textarea className="CvResultCard-textarea" uppressContentEditableWarning onChange={handleChange} value={resulttext} rows={10}></textarea>
                <button className="CvResultCard-button" onClick={handleClick}>
                    Auto Fill-in
                    <Icon icon={'iconamoon:check-bold'} id='buttonIcon' />
                </button>
            </div>

        </>
    )
};

export const FormHeader = ({ type, page, setPage, setResulttext, setLoading }) => {
    return (
        <div className="rightbarForm-header">
            <label>{`Polishing Your ${type.toUpperCase()}`}</label>
            <div className="formchooser">
                <div className={`formchooser-item ${page === 0 ? 'is-active' : ''}`} onClick={() => { setPage(0); setResulttext(''); setLoading(false); }}>
                    <Icon icon={"carbon:ibm-watson-language-translator"} id='chooserIcon' />
                    <span>{RequireText("AIwriting.translate")}</span>
                </div>
                {type === 'cv' &&<div className={`formchooser-item ${page === 1 ? 'is-active' : ''}`} onClick={() => { setPage(1); setResulttext(''); setLoading(false); }}>
                    <Icon icon={"carbon:ibm-watson-language-translator"} id='chooserIcon' />
                    <span>{RequireText("AIwriting.rewriting")}</span>
                </div>}
                {type === 'cv' && <div className={`formchooser-item ${page === 2 ? 'is-active' : ''}`} onClick={() => { setPage(2); setResulttext(''); setLoading(false); }}>
                    <Icon icon={"carbon:ibm-watson-language-translator"} id='chooserIcon' />
                    <span>{RequireText("AIwriting.generator")}</span>
                </div>}
            </div>
        </div>
    )
}