import { ImageUrl } from "../../assets/media/ImageUrl";
import { Image } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { memo, useRef } from "react";
import { Struct_DashContent } from "./_StructDefine";
import { useNavigate } from "react-router-dom";
import { IconUrl } from "../../assets/media/IconUrl";
import RequireText from "../../assets/i18n/TextDefiner";
import { useTranslation } from "react-i18next";
import { GetToServer } from '../../assets/network/NetWork';
import { DeleteToServerWithJson } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { LoadingComponent } from "../Utilities/Loading";
import { ModalComponent } from "../Utilities/ModalComponent";
import { useState } from "react";

export const NavbarDashboard = memo(function NavbarDashboard(props) {
    const navito = useNavigate();
    const { i18n } = useTranslation();
    const NavItemStats = {
        "home": "home",
        "CV": "CV",
        "PS": "PS",
        "RL": "RL",
        "PR": "Browseprogram",
        "PG": "ProgramGuide",
        "tutor": "Tutor",
    }
    //获取内容结构
    const ContentStruct = Struct_DashContent;
    var currentNavItem = null;
    // function ToggleNavItem(newItem) {
    //     if (currentNavItem) {
    //         currentNavItem.classList.toggle("choosed")
    //     }
    //     currentNavItem = newItem;
    //     currentNavItem.classList.toggle("choosed", true);
    // }
    //nav菜单主对象
    const NavItem = ({ onClick, SubItem = SubItemList.default, iconUrl = "iconoir:home-alt-slim-horiz" }) => {
        const tagRef = useRef(null)
        function handleClick() {
            if (onClick) onClick();
            var ele = tagRef.current;
            ele.classList.toggle("navItem-expand");

            if (currentNavItem) {
                currentNavItem.classList.toggle("choosed")
            }
            currentNavItem = ele;
            currentNavItem.classList.toggle("choosed", true)

        }
        return (
            <div onClick={handleClick} className="navItem">
                <div ref={tagRef} className="navItemTag "></div>
                <div className="mainItem">
                    <div className="icon"><Icon icon={iconUrl} /></div>
                    <div className="text">{SubItem.name ? SubItem.name : RequireText("dashboard.home")}</div>
                    <div className="arrow"><Icon icon={"iconamoon:arrow-left-2"}></Icon></div>
                </div>
                <div className="subsItem" >
                    {SubItem.SubitemJSX}
                </div>


            </div>
        )
    }
    //子级菜单对象
    const SubItemUnit = ({ icon, text, clickID = "" }) => {
        const subTagRef = useRef(null)
        function ClickToToggle() {
            if (currentNavItem) {
                currentNavItem.classList.toggle("choosed")
            }
            currentNavItem = subTagRef.current;
            currentNavItem.classList.toggle("choosed", true)

        }
        return (
            <>
                <div ref={subTagRef} onClick={(e) => { e.stopPropagation(); ClickToToggle(); handleClick(clickID) }}>
                    <div><Icon icon={icon}></Icon></div>
                    <div className="submitText">{text}</div>
                </div>

            </>
        )


    }
    //子级菜单对象组定义
    const SubItemList = {
        "default": {
            SubitemJSX: "none",
        },
        "AI_write": {
            SubitemJSX: <>
                <SubItemUnit icon={IconUrl.cv} text={RequireText("dashboard.cv")} clickID={NavItemStats.CV}></SubItemUnit>
                <SubItemUnit icon={IconUrl.ps} text={RequireText("dashboard.ps")} clickID={NavItemStats.PS}></SubItemUnit>
                <SubItemUnit icon={IconUrl.rl} text={RequireText("dashboard.rl")} clickID={NavItemStats.RL}></SubItemUnit>
            </>,
            "name": RequireText("dashboard.AIwriting"),
        },
        "FindProgram": {
            SubitemJSX: <>
                <SubItemUnit icon={IconUrl.resources} text={RequireText("findProgram.resources")} clickID={NavItemStats.PR}></SubItemUnit>
                <SubItemUnit icon={IconUrl.guide} text={RequireText("findProgram.guide")} clickID={NavItemStats.PG}></SubItemUnit>
                {/* <SubItemUnit icon={IconUrl.rl} text={"RL"} clickID=""></SubItemUnit> */}
            </>,
            "name": RequireText("dashboard.findProgram"),
        },

    }
    //根据点击id参数分发事件
    function handleClick(id) {
        let to;
        switch (id) {
            default: to = ContentStruct.home; break;
            case NavItemStats.home: to = ContentStruct.home; break;
            case NavItemStats.CV: to = ContentStruct.AIwrite.content_cv; break;
            case NavItemStats.PS: to = ContentStruct.AIwrite.content_ps; break;
            case NavItemStats.RL: to = ContentStruct.AIwrite.content_rl; break;
            case NavItemStats.PR: to = ContentStruct.Program.content_programResource; break;
            case NavItemStats.PG: to = ContentStruct.Program.content_programGuide; break;
            case NavItemStats.tutor: to = ContentStruct.tutor; break;
        }
        props.clickItem(to)



        return false;
    }
    //切换语言

    const toggleLanguage = () => {
        // Check the current language and toggle to the other
        const newLanguage = i18n.language === 'en' ? 'zh' : 'en';
        i18n.changeLanguage(newLanguage);
    };

    const [loading, setLoading] = useState(false);
    const [modalContent,setModalContent] = useState("")
    const [modalOpen, setModalOpen] = useState(false);

    const handleLogout = () => {
        setLoading(true);
        DeleteToServerWithJson(NetworkApi.user.logout, {}, (re)=>{
            if(re.success === true){
                navito('/')
            }else{
                setModalContent(re.message.cn);
                setModalOpen(true);
            }
            setLoading(false);
        } )
    }

    const handleOK = () => {
        setModalOpen(false);
    }

    const logout = () => {
        GetToServer(
            NetworkApi.user.userLogout,
            (re) => {
                if (re.success) {
                    localStorage.removeItem('user_name');
                    navito("/");
                }
            }
        )
    }

    return (

        <div key={"navBar"} className="nav-container">
            <div className="head">
                <div onClick={() => navito("/")} className="brand">
                    <Image className="R-hidden" width={"100%"} src={ImageUrl.brandIcon} />
                    <Image className="R-show brand-icon" width={"50%"} src={ImageUrl.brandIcon_only} />
                </div>
                <div className="icon">
                    <div className="checkboxContainer">
                        <Icon icon="lucide:sidebar-close"></Icon>
                        <input id="navFoldCheck" className="checkboxTag foldCheckBox" type="checkbox"></input>
                    </div>

                </div>
            </div>
            <div className="body">
                <div className="core">
                    <NavItem ID={NavItemStats.home} onClick={() => handleClick("home")} iconUrl={IconUrl.home} />
                    <NavItem ID={NavItemStats.CV} SubItem={SubItemList.AI_write} iconUrl={IconUrl.AIwriting} />
                    <NavItem ID={NavItemStats.PS} SubItem={SubItemList.FindProgram} iconUrl={IconUrl.findProgram} />
                </div>
                <div className="lineDivider "></div>
                <div className="extra">
                    <NavItem ID={NavItemStats.tutor} onClick={() => handleClick(NavItemStats.tutor)} SubItem={{ name: RequireText("dashboard.tutor") }} iconUrl={IconUrl.tutor} />
                    {/* <NavItem SubItem={{ name: RequireText("dashboard.workplace") }} iconUrl={IconUrl.workplace} />
                    <NavItem SubItem={{ name: RequireText("dashboard.calendar") }} iconUrl={IconUrl.calendar} />
                    <NavItem SubItem={{ name: RequireText("dashboard.message") }} iconUrl={IconUrl.messages} /> */}
                </div>
            </div>
            <div className="foot">
                <div className="system">
                    <NavItem SubItem={{ name: RequireText("dashboard.language") }} iconUrl={IconUrl.language_en} onClick={toggleLanguage} />
                    <NavItem SubItem={{ name: RequireText("dashboard.payment") }} iconUrl={IconUrl.payment} onClick={() => window.open('/pricing', "_blank")} />
                    <NavItem SubItem={{ name: RequireText("dashboard.logout") }} iconUrl={IconUrl.logout} onClick={logout} />
                    {/* <NavItem SubItem={{ name: RequireText("dashboard.help") }} iconUrl={IconUrl.help} /> */}
                </div>
                <div className="lineDivider"></div>
                {/* <div className="usernode">user</div> */}
            </div>
            {loading && <LoadingComponent />}
            {modalOpen && <ModalComponent content={modalContent} handleOK={handleOK} modalOpen={modalOpen} />}



        </div>
    )
})