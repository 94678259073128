import { PostToServerWithJson } from "../../assets/network/NetWork"
import { NetworkApi } from "../../assets/network/NetworkApi"
import { Get_RL_Input_Highlights } from "./RL_personal_info"

export const rlProgressData = [
    { id: 0, text: "rlGeneration.recommenderInfo", url: "rl/step1" },
    { id: 1, text: "rlGeneration.overview", url: "rl/step2" },
]
export function RLStep1Generate(callback = () => { }, failCall = () => { }) {

    if (GetCVIDinRL() === null) {
        //alert("Please Choose A CV")
        failCall("Please Choose A CV First")
        return
    }
    //console.log(document.getElementById("uploadCVInput")?.files[0])
    if (document.getElementById("uploadCVInput")?.files[0] === undefined) {
        //alert("Please Choose A CV2")
        failCall("Please Choose A CV First")
        return
    }
    let r = Get_RL_Input_Recommender_Title()
    if (r === "other" || r === "Other") {
        r = document.getElementById("recommenderTitle-other").value
    }
    const e = GetRLRecommenderExGroup()
    const hl = Get_RL_Input_Highlights()
    const ti = document.getElementById("targetSchool").value
    const tp = document.getElementById("targetMajor").value
    const l = document.getElementById("rlMaxNum").value

    //存储数据，发送请求
    const cv_id = GetCVIDinRL()
    const Data = {
        "role_of_recommender": r,
        "experience_summary": e,
        "highlights": hl,
        "target_institution": ti,
        "target_program": tp,
        "letter_word_range": [299,
            l
        ]
    }


    const api = NetworkApi.rl.generation + "/" + cv_id
    PostToServerWithJson(api, Data, (re) => {
        if (re.status === "success") {
            localStorage.setItem('rl_id',re.rl.rl_id)
            StoreRL(re.letter)
            callback(re)
        } else {
            failCall(re.message.cn)
        }
    })

}
export function StoreCVIDinRL(value) {
    localStorage.setItem("current_RL_CV_ID", value)
}

export function GetCVIDinRL() {
    return localStorage.getItem("current_RL_CV_ID")
}
export function ClearCVIDinRL() {
    localStorage.removeItem("current_RL_CV_ID")
}
//recommender title
export function Store_RL_Input_Recommender_Title(value) {
    localStorage.setItem("current_RL_Recommender_Title", value)
}
export function Get_RL_Input_Recommender_Title() {
    return localStorage.getItem("current_RL_Recommender_Title")
}
//recommender generate
export function StoreRL(value) {
    localStorage.setItem("d_RL", JSON.stringify(value))
}
export function GetRL() {
    return JSON.parse(localStorage.getItem("d_RL"))
}
//recommender ExGroup
export function StoreRLRecommenderExGroup(num) {
    if (num <= 0) return
    let data = []
    const preStr = "recommenderEx"
    for (let i = 0; i < num; i++) {
        let Qele = document.getElementById(preStr + i)

        const Q = Qele?.value
        const dataUnit = Q
        data.push(dataUnit)
    }
    const name = "RlRecommenderExGroup"
    localStorage.removeItem(name)
    localStorage.setItem(name, JSON.stringify(data))
}
export function GetRLRecommenderExGroup() {
    if (localStorage.getItem("RlRecommenderExGroup") !== null)
        return JSON.parse(localStorage.getItem("RlRecommenderExGroup"))
    else return null
}
export function GetRLRecommenderExGroupNum() {
    // console.log(localStorage.getItem("RlRecommenderExGroup") !== null)
    if (localStorage.getItem("RlRecommenderExGroup") !== null)
        return JSON.parse(localStorage.getItem("RlRecommenderExGroup")).length
    else return 1
} export function SetRLRecommenderExGroup(data = [], currentNum) {

    const num = data.length
    if (num <= 0) return
    if (data === null) return

    const preStr = "recommenderEx"
    for (let i = 0; i < num; i++) {
        if (i >= currentNum) return
        let Qele = document.getElementById(preStr + i)

        if (data[i]) Qele.value = data[i]

    }
}

export function convertToHTML(input) {
    // Split the string by double line breaks to get paragraphs
    const paragraphs = input.split('\n\n');

    // Process each paragraph
    const htmlParagraphs = paragraphs.map(paragraph => {
        // Convert any single line breaks within the paragraph to <br> tags
        const formattedParagraph = paragraph.split('\n').join('<br>');

        // Wrap the paragraph in <p> tags
        return `<p>${formattedParagraph}</p>`;
    });

    // Join all the paragraphs with an extra <p></p> to represent a blank line between paragraphs
    return htmlParagraphs.join('<p></p>\n');
}

