import Password from "antd/es/input/Password";

export const HomeText = {
        "en": {
                headnav: {

                        "item1": "Solutions",
                        "item2": "Resources",
                        "item3": "Pricing",
                        "item4": "Partnership",
                        "item5": "Contact Us",
                        "item6": "Login",
                        "button": "Dashboard",
                },
                contact:{
                        contactinfo: "Contact Information",
                        description:"Please reach out to us with any questions, feedback, or inquiries you may have. Our team is available during the listed hours, and we strive to respond as promptly as possible.",
                        email:"Email",
                        operatingHours:"Operating Hours",
                        contactUs:"Contact Us",
                        workinghours:"Monday to Friday, 9:00 AM - 5:00 PM",
                },
                pricing: {
                        bigTitle1: "Easy plans,",
                        bigTitle2: "Powerful features",
                        titleContent: "Whether you're exploring programs or perfecting your application, we offering cutting-edge features and personalized services that empower you at every stage of your journey.",
                        chooseButton: "choose your subscription package",
                        startButton: "Get Started",
                        feature1: "Write without mistakes",
                        feature2: "Seamless English Translation",
                        plans: {
                                entry: "Entry",
                                premium: "Premium",
                                prime: "Prime",
                                vip: "VIP",
                        },
                        features: {
                                priceRMB: "RMB",
                                programResources: "Program Resources",
                                // unlimitedSearch: "Unlimited program resource search",
                                unlimitedSearch: {
                                        // text: "Unlimited search across 10,000+ master programs",
                                        entry: "Unlimited program resource search",
                                        prime: "Includes all features in the Entry package",
                                        premium: "Includes all features in the Prime package",
                                        vip: "All-inclusive service with every feature in the Premium package",
                                },
                                programGuide: {
                                        text: "Program Guide",
                                        entry: "3 personalized program guide reports (each contain up to 10 master programs)",
                                        prime: "5 personalized program guide reports (each contain up to 10 master programs)",
                                        premium: "10 personalized program guide reports (each contain up to 10 master programs",
                                        vip: "10 personalized program guide reports (each contain up to 10 master programs",
                                },
                                cvGenerator: {
                                        text: "CV Generator",
                                        entry: "Free 18 AI-augmented application materials including 6 resumes, 6 personal statements, and 6 recommendation letters.",
                                        prime: "Free 30 AI-augmented application materials including up to 10 resumes, 10 personal statements, and 10 recommendation letters.",
                                        premium: "Free 60 AI-augmented application materials including up to 20 resumes, 20 personal statements, and 20 recommendation letters.",
                                        vip: "Free 60 AI-augmented application materials including up to 20 resumes, 20 personal statements, and 20 recommendation letters.",
                                },
                                // personalStatementGenerator: {
                                //         text: "Personal Statement Generator",
                                //         entry: "6 files in total",
                                //         prime: "10 files in total",
                                //         premium: "20 files in total",
                                //         vip: "20 files in total",
                                // },
                                // recommendLetterGenerator: {
                                //         text: "Recommend Letter Generator",
                                //         entry: "6 files in total",
                                //         prime: "10 files in total",
                                //         premium: "20 files in total",
                                //         vip: "20 files in total",
                                // },
                                personalTutoring: {
                                        text: "Personal Tutoring",
                                        entry: "Free 30 minutes of personal consultation.",
                                        prime: "Free 30 minutes of personal consultation.",
                                        premium: "Free 30 minutes of personal consultation",
                                        vip: "Free personal guidance for whole application process",
                                }
                        }
                },
                payment: {
                        planDetails: "Plan Details",
                        plans: {
                                entry: "Entry",
                                prime: "Prime",
                                premium: "Premium",
                                vip: "VIP",
                        },
                        descriptions: {
                                entry: "Get started with essential tools and a personalized program guide.",
                                prime: "Enhanced support with additional tools and personalized guidance for focused applications.",
                                premium: "Comprehensive resources and reports for a polished and competitive application.",
                                vip: "Exclusive access to top-tier resources with continuous support for application success.",
                        },
                        features: {
                                entry: {
                                        feature1: "Unlimited program resource search",
                                        feature2: "3 personalized program guide reports (each contain 10 master programs)",
                                        feature3: "18 AI-augmented application materials including 6 resumes, 6 personal statements, and 6 recommendation letters",
                                        feature4: "60 minutes of personal consultation",
                                },
                                prime: {
                                        feature1: "Includes all features in the Entry package",
                                        feature2: "5 personalized program guide reports (each contain 10 master programs)",
                                        feature3: "AI-augmented application materials for up to 10 resumes, 10 personal statements, and 10 recommendation letters",
                                        feature4: "90 minutes of personal consultation",
                                },
                                premium: {
                                        feature1: "Includes all features in the Prime package",
                                        feature2: "10 personalized program guide reports (each contain 10 master programs)",
                                        feature3: "AI-augmented application materials for up to 20 resumes, 20 personal statements, and 20 recommendation letters",
                                        feature4: "120 minutes of personal consultation",
                                },
                                vip: {
                                        feature1: "All-inclusive service with every feature in the Premium package",
                                        feature2: "10 personalized program guide reports (each contain 10 master programs)",
                                        feature3: "AI-augmented application materials for up to 20 resumes, 20 personal statements, and 20 recommendation letters",
                                        feature4: "Full-process personal guidance",
                                }
                        },
                        currency: "CNY",
                        total: "Total",
                        confirmButton: "Confirm",
                        payment: "Payment Methods",
                        paymentMethods: {
                                wechat: "WeChat",
                                alipay: "Alipay",
                                visa: "Credit Card Visa",
                                paypal: "Paypal",
                        },
                        pay: " Pay",
                        confirmInfo: "Please confirm your plan",
                        wechatInfo: "Please scan the QR code to pay with WeChat",
                        timeLeft: "Time left",
                        quitTitle: "Are you sure you want to quit this payment?",
                        quitButton: "Quit",
                        quitCancel: "Continue",
                        result: {
                                "successTitle": "Payment Successful",
                                "failureTitle": "Payment Failed",
                                "successMessage": "Thank you for upgrading to the VIP package! \n Your payment has been processed successfully. \n Enjoy your enhanced features and benefits.",
                                "failureMessage": "We were unable to process your payment for the VIP package. Please check your payment details and try again. If the issue persists, contact customer support for assistance.",
                                "successButton": "Navigate to dashboard",
                                "failureButton": "Return to pricing page",
                                "redirectMessage": "Redirecting in ",
                                "redirectMessage2": " seconds...",
                        }
                },

                // login: {
                //         welcome: "Welcome",
                //         email: "Email Address",
                //         password: "Password",
                //         login: "Login",
                //         noAccount: "Don't have an account?",
                //         signup: "Sign Up",
                //         createAccount: "Create Your Account",
                //         firstName: "First Name",
                //         lastName: "Last Name",
                //         nextStep: "Next Step",
                //         verifyCodeInfo: "Verification code has been sent to your email. Please confirm and enter it below.",
                //         verifyCode: "Verification Code",
                //         passwordWarning: "The password must start with a letter, be 6 to 18 characters long, and contain only letters, numbers, and underscores.",
                //         accountCredentials: "Set your account credentials",
                //         submitSignUp: "Submit Sign Up",
                //         agree: "I agree to the",
                //         terms: "Terms of Use",
                //         and: "and",
                //         privacy: "Privacy Policy",

                // },
                login: {
                        welcome: "Welcome",
                        email: "Email Address",
                        password: "Password",
                        login: "Login",
                        noAccount: "Don't have an account?",
                        haveAccount: "Already have an account?",
                        forgotPassword: "Forgot Password",
                        signup: "Sign Up",
                        createAccount: "Create Your Account",
                        firstName: "First Name",
                        lastName: "Last Name",
                        nextStep: "Next Step",
                        verifyCodeInfo: "Verification code has been sent to your email. Please confirm and enter it below.",
                        verifyCode: "Verification Code",
                        passwordWarning: "The password must start with a letter, be 6 to 18 characters long, and contain only letters, numbers, and underscores.",
                        accountCredentials: "Set your account credentials",
                        submitSignUp: "Submit Sign Up",
                        submitReset: "Submit Reset",
                        agree: "I agree to the",
                        terms: "Terms of Use",
                        and: "and",
                        privacy: "Privacy Policy",
                        promotion: 'Sign up for emails to get updates from Apply U on products, offers and your benefits.',
                        resetPassword: "Reset Password",
                },
                dashboard: {
                        home: "Home",
                        AIwriting: "Writing with AI",
                        findProgram: "Find program",
                        tutor: "Tutor",
                        workplace: "Workplace",
                        calendar: "Calendar",
                        message: "Message",
                        setting: "Setting",
                        language: "Language",
                        payment: "Payment",
                        help: "Help",
                        logout: "Log out",
                        welcome: "Welcome Back",
                        welcomeSentence1: "Everyday is A New Opportunity to Grow and Improve",
                        welcomeSentence2: "One Step at A Time",
                        recentFile: "Recent Files",
                        services: "Application Services",
                        cvgeneration: "CV Generation",
                        psgeneration: "PS Generation",
                        rlgeneration: "RL Generation",
                        programReport: "Program Guide Report",
                        more: "More",
                        select: "Select",
                        cv: "CV",
                        ps: "Personal Statement",
                        rl: "Recommendation Letter",
                        remainFile: "Remaining Files",
                        programGuide: "Program Guide Report",
                        cv: "Curriculum Vitae (CV)",
                        ps: "Personal Statement",
                        rl: "Recommendation Letter",


                },
                AIwriting: {
                        AIwrite: "Write with AI",
                        content: "content_cv",
                        quick: "Quick Start",
                        dragdrop: "Drag & Drop",
                        upload: "Upload Your CV Here or Browse (.pdf)",
                        start: "Start from Template",
                        create: "Create New CV with Template",
                        prevFile: "Prev File",
                        insert: "insert line",
                        translate: "Translate",
                        rewriting: "Rewriting",
                        polishing: "Polishing",
                        generator: "Generator",
                        cvtool: "CV Tools",
                        inputText: "Please input your text here",
                        description: "Description……",
                        discard: "Discard Changes",
                        translateText: "Translated Text",
                        example: "Example Output",
                        autoFill: "Auto Fill-in",
                        rewriteHere: "Please select the content you want to rewrite here:",
                        rewriteContent: "Rewrite the Content",
                        enterMajorRequired: "Please enter the major you are targeting",
                        enterMajorDescription: "Please enter the major description you are targeting.",
                        describeExperience: "Describe your experience in 2-3 sentences",
                        generatePoints: "Generate Bullet Points",
                        enterMainText: "Enter your main text here...",
                        save: "Save",
                        download: "Download",
                        prev: "Prev",
                        next: "Next",
                        targetLanguage: "Target",
                        submit: "Submit",

                },
                psGeneration: {
                        ps: "PS generator",
                        createStatement: "Create New Personal Statement in 2 min",
                        targetSchool: "What is your target school?",
                        targetMajor: "What is your target major?",
                        describeMajor: "Please describe your target major in 2-3 sentences.",
                        emphasizeAspect: "Which aspect would you like to emphasize the most in your personal statement?",
                        statementOption1: "Academic Interests and Research Experience",
                        statementOption2: "Internships and Practical Experience",
                        statementOption3: "Leadership and Teamwork Skills",
                        statementOption4: "Interdisciplinary Background and Innovative Thinking",
                        storyline: "Storyline",
                        chooseStoryline: "Choose Your Favorite Storyline",
                        personalDetail: "Personal Detail",
                        tellExperience: "Tell Us Something about Your Experiences…",
                        exOption1: "intern experience",
                        exOption2: "project experience",
                        exOption3: "academic/research experience",
                        exOption4: "other",
                        addQuestion: "Add Question",
                        Q1: "1. What challenges have you encountered?",
                        Q2: "2. What's your motivation for choosing this major?",
                        futurePlan: "Tell Us about Your Future Plan with This Master Program…",
                        exAnswer: "By pursuing a Master‘s in Machine Learning at UCL, I aim to advance smart transportation solutions through cutting-edge research in deep learning and computer vision",
                        other: "Other",
                        selectMaximumWord: "Please Select the Maximum Word Count for The Personal Statement:",
                        step1: "1. Choose CV",
                        step2: "2. Storyline",
                        step3: "3. Personal Detail",
                        step4: "4. Overview"


                },
                rlGeneration: {
                        createLetter: "Create New Recommendation Letter in 2 min",
                        recommenderInfo: "1. Recommender Info",
                        personalInfo: "2. Personal Info",
                        overview: "2. Overview",
                        title1: "Tell Us Something about Your Recommender:",
                        title2: "What is the job title or role of your recommender?",
                        title3: "What was your experience with your recommender during your time working together?",
                        tellExperiences: "Tell Us Something about Your Experiences:",
                        skillTags: "What specific skills, abilities, or contributions of yours would you like to highlight in the recommendation letter?",
                        tag1: "Analytical Skills",
                        tag2: "Innovative Thinking",
                        tag3: "Team Collaboration",
                        tag4: "Leadership",
                        tag5: "Problem-Solving Skills",
                        tag6: "Communication Skills",
                        tag7: "Organizational Skills",
                        tag8: "Adaptability",
                        tag9: "Critical Thinking",
                        tag10: "Research Skills",
                        tag11: "Teaching Proficiency",
                        tag12: "Technical Proficiency",
                        tag13: "Creativity",
                        tag14: "Time Management",
                        tag15: "Cross-cultural Communication",
                        targetSchool: "What is your target school?",
                        targetMajor: "What is your target major?",
                        wordCount: "What is the word count you want for the recommendation letter?",
                        other: "Others:"

                },
                findProgram: {
                        resources: "Program Resources",
                        guide: "Program Guide",
                        search: "Search",
                        filter: "Filter",
                        clear: "Clear All",
                        applysearch: "Apply Search",
                        apply: "Apply Filter",
                        major: "Major",
                        region: "Region",
                        ranking: "University ranking",
                        fullPart: "Full-time/Part-time",
                        fullPartOption: {
                                "Full Time": "Full-time",
                                "Part Time": "Part-time"
                        },
                        country: {
                                "AU": "Australia",
                                "NZ": "New Zealand",
                                "SG": "Singapore",
                                "UK": "United Kingdom",
                                "HK": "Hong Kong",
                                "US": "United States",
                                "HK SAR": "Hong Kong SAR",
                                "CA": "Canada",
                                "DK": "Denmark",
                                "DE": "Germany",
                                "CH": "Switzerland",
                                "SE": "Sweden",
                                "IE": "Ireland",
                                "FI": "Finland",
                                "MO": "Macau SAR"
                        },
                        confirmInfo: {
                                schoolName: "School Name",
                                degree: "Degree",
                                degreeType: "Degree Type",
                                duration: "Duration",
                                gpa: "GPA",
                                gpaScale: "GPA Scale",
                                AvgScore: "Average Score",
                                graduactionDate: "Graduation Date",
                                module: "Modules",
                        },
                        preference: "Career Preference",
                        wishlist: "Program Wishlist",
                        duration: "Duration",
                        location: "Location",
                        justranking: "Ranking",
                        fee: "Fee",
                        overview: "Overview",
                        learning: "Learning & Academic",
                        career: "Career Outcomes",
                        admission: "Admission",
                        rankin: "Rank in",
                        date: "Date Added",
                        manage: "Manage",
                        name: "Name",
                        startGuide: "Start Your Guide from",
                        match: "Match for Me ",
                        matchForMe: "Already have tagret programs in the wishlish, match for me.",
                        step1: "Select programs",
                        step2: "Upload CV",
                        step3: "Personalize path",
                        recommend: "Recommend for Me",
                        chooseForMe: "Don't have target program, please choose for me.",
                        uploadCV: "Upload CV",
                        path: "Personalize path",
                        chooseFromWishlist: "Choose From Program Wishlist",
                        programResource: "Go to Program Resources",
                        selected: "Selected",
                        personalization: "Personalization",
                        targetMajor: "What’s your target major?",
                        careerPlan: "What’s your career plan? (Optional)",
                        studyAbroad: "Why do you want to study abroad?",
                        universityRanking: "What is your desired university ranking?",
                        whichlocation: "Which is your desired location for study?",
                        selectAnswer: "Select your answer",
                        forPhD: "Apply for a PhD",
                        findJob: "Find a Job",
                        governmentJob: "Find a Government Relevant Job",
                        improvement: "Improvement",
                        searchPlaceholder: "Search university",
                        searchPlaceholder2: "Search program",
                },
                tutor: {
                        tutorResource: "Tutor Resources",
                        connect: "Let's connect",
                        student: "students",
                        description: "Description",
                        country: "Specialized Country"
                },
                home: {
                        slogan1: "Empower Your",
                        slogan2: "Application with AI",
                        subtitle: "Your passport to Academic and Professional Success",
                        ctaButton: "Get Started",
                        func0: {
                                "tip": "Step0 Instant Profile Creation",
                                "title": "Academic profile—ready in a flash!",
                                "tipTitle": "Provide AI-powered generation and optimization of CV, PS, RL.",
                                "description": "AI-Enhanced Resumes: Let our AI polish your CV to perfection.\nTailored Fit: Customize your resume to match your dream schools' criteria.\nUniquely Yours: Get a CV designed specifically for your background.",
                                "button": "Start Now"
                        },
                        func1: {
                                "tip": "Step1 Program Guide",
                                "title": "Find your ideal programs in just 2 minutes!",
                                "tipTitle": "Covering over 30,000 academic programs worldwide",
                                "description": "Personalized Recommendations: Discover universities that are the perfect match for you.\nDream School Match: See how you stack up against your top choices.\nData-Driven Insights: Make informed decisions backed by real success stories.\nCareer Alignment: Connect your school selection with your future career goals.\nIn-Depth Analysis: Evaluate your compatibility from 5 Dimensions.",
                                "button": "Start Now"
                        },
                        func2: {
                                "tip": "Step2 AI Writing Assistant",
                                "title": "Craft stellar application documents in a flash!",
                                "tipTitle": "Based on over 100,000+ successful admission cases",
                                "description": "Top-Notch Quality: Access over 100,000 exemplary samples to refine your content.\nPersonal Touch: Create statements tailored to your unique goals and background.\nOne-Stop Writing Hub: From Personal Statements to Recommendation Letters—we've got you covered.\nBilingual Support: Write and translate seamlessly in English and Chinese.",
                                "button": "Start Now"
                        },
                        func3: {
                                "tip": "Step3 Expert Guidance",
                                "title": "AI + Human = Dream Offer",
                                "tipTitle": "Personalized guidance from experienced advisors",
                                "description": "Seasoned Advisors: Work with experienced consultants specializing in popular destinations and majors.\nCustomized Strategies: Receive application plans crafted just for you.\nConnect Your Way: Choose from chat, phone, or video consultations.\nFree Professional Advice: Enjoy high-quality, one-on-one consulting at no cost.\nPrompt Assistance: Get quick answers to all your application questions.",
                                "button": "Start Now"
                        },
                        data1: "Academic Programs Worldwide",
                        data2: "Admission Cases Support",
                        data3: "Times Speed Improved",
                        company: "Our Company",
                        service: "Professional Service",
                        review: "Reviews",
                        reviewTitle: "Our clients knows the value we provide",
                        faq0: "F&Q",
                        faqTitle: "You might want to know",
                        faq: {
                                "question1": {
                                        "questionText": "How does ApplyU’s AI writing improve the quality of application documents? ",
                                        "answer": "- ApplyU’s AI writing tool is purpose-built for master’s application support, offering intelligent generation and optimization of resumes, personal statements, and recommendation letters. It produces high-quality documents within just five minutes, reducing the writing workload for users.\n1. Resume(CV): Utilizes AI algorithms to comprehensively analyze academic background, personal interests, and career goals, intelligently matching suitable schools and majors.It combines multidimensional data and an extensive information database to ensure content accuracy and professionalism.\n2. Personal Statement(PS): ApplyU's AI algorithm analyzes students’ backgrounds and goals to create personalized content that meets application requirements. The platform crafts three storylines based on the student's background, expanding it into a complete PS to ensure a distinct writing style and avoid uniformity.The content is optimized to match the specific requirements of target schools and programs.\n3. Recommendation Letter(RL): Automatically generates recommendation letters based on the applicant's multidimensional data and career development needs, enhancing the quality and effectiveness of the recommendation."
                                },
                                "question2": {
                                        "questionText": "How does ApplyU help me choose the most suitable programs?",
                                        "answer": "ApplyU’s program guide offers a  dual-channel approach: users can either calculate compatibility with specific wishlist programs or receive recommendations tailored to their personal profile. Our platform leverages a robust project database and advanced recommendation algorithms to deliver precise matches, ensuring students receive optimal advice. \n By integrating academic and career pathways, ApplyU allows users to make informed decisions based on future career aspirations.The system’s personalized tagging system accommodates a wide range of student backgrounds, including those pursuing cross- disciplinary applications."
                                },
                                "question3": {
                                        "questionText": "How does ApplyU’s 'AI + human expert' collaboration model work?",
                                        "answer": "ApplyU adopts a collaborative model that combines AI and human expert to address potential concerns students may have when using AI. While our AI tool efficiently optimizes application materials and personalized program guide, the highlight of our service is the inclusion of free expert consultation within the AI package. Experienced experts provide comprehensive guidance throughout the process, ensuring every step is supported by expert oversight."
                                },
                                "question4": {
                                        "questionText": "What are the specific steps for using ApplyU's services?",
                                        "answer": "- The process of using ApplyU's services is divided into four main steps:  \n1. Register and create a personal profile: Enter personal information to generate a personalized 'study abroad profile.'\n2. AI writing preparation: Use the AI writing tool to quickly generate and optimize personal statements, resumes, and recommendation letters, enhancing document quality and personalization.\n3. Program guide: Activate the intelligent school selection system to obtain a list of schools and majors that match the user's background, helping students make informed choices.\n4. Consult with a personal tutor: Communicate with a human expert, refine application materials, and ensure a smooth submission."
                                },
                                "question5": {
                                        "questionText": "How does ApplyU charge for its services? ",
                                        "answer": "ApplyU offers a range of service packages to suit different needs and budgets:\n1. Entry Package – 898 RMB\n                                                - Unlimited program resource search.\n  - 3 personalized program guide reports(each contain 10 master programs).\n  - 18 AI- augmented application materials including 6 resumes, 6 personal statements, and 6 recommendation letters.\n  - 30 minutes of personal consultation.\n2. Prime Package – 1380 RMB\n                                        - Includes all features in the Prime package.\n- 5 personalized program guide reports(each contain 10 master programs).\n  - AI - augmented application materials for up to 10 resumes, 10 personal statements, and 10 recommendation letters.\n  - 30 minutes of personal consultation.3. Premium Package – 2480 RMB\n                                        - Includes all features in the Premium package.\n  - 10 personalized program guide reports(each contain 10 master programs).\n  - AI - augmented application materials for up to 20 resumes, 20 personal statements, and 20 recommendation letters.\n  - 30 minutes of personal consultation.\n4. VIP Package – 5888 RMB\n                                        - All - inclusive service with every feature in the VIP package.\n  - 10 personalized program guide reports(each contain 10 master programs).\n  - AI - augmented application materials for up to 20 resumes, 20 personal statements, and 20 recommendation letters.\n  - Full - process personal guidance."
                                },
                                "question6": {
                                        "questionText": "What should I do if I encounter issues while using the service?",
                                        "answer": "You can contact our support team via email or WeChat, we will response to you as soon as possible."
                                },
                                "question7": {
                                        "questionText": "How is my personal information kept secure?  ",
                                        "answer": "ApplyU strictly adheres to data protection regulations and uses the latest security technologies to safeguard users' personal information. We commit not sharing your data with any third party without your explicit consent."
                                }
                        }
                },
                footnav: {
                        slogan1: "Your All-in-One Study Abroad Solution",
                        slogan2: "Start Your Success Story Today",
                        ul1: {
                                "il1": "Application Preparation",
                                "il2": "Study Support",
                                "il3": "Career Development",
                        },
                        ul2: {
                                "il1": "About Us",
                                "il2": "Pricing & Plan",
                                "il3": "Partnership",
                        },
                        ul3: {
                                "il1": "Contact Us",
                                "il2": "Terms of Use",
                                "il3": "Privacy Policy",
                        }
                },
                review: {
                        review1: "Personal Tutor really knows how to connect students with the right help. It’s super personalized and gets results!",
                        review2: "ApplyU's Program Guide makes finding the right master's program easy. It’s a must-have for guiding students!",
                        review3: "ApplyU is a game-changer! It’s quick, efficient, and has all the info I need in one place. Saved me so much time!",
                        review4: "ApplyU’s recommendations helped me focus on what I needed. It suggested an internship, and it worked out great!",
                        review5: "ApplyU’s tool makes advising easier. It’s fast, detailed, and really helps me guide students better!",
                        review6: "ApplyU nailed it! Found the perfect AI master’s program for my career. Highly recommended!",
                },


        },
        "zh": {
                headnav: {
                        "item1": "方案",
                        "item2": "资源",
                        "item3": "定价",
                        "item4": "合作",
                        "item5": "联系我们",
                        "item6": "登录",
                        "button": "开始",
                },
                contact:{
                        contactinfo: "联系信息",
                        description:"如果您有任何问题、反馈或咨询，请随时联系我们。我们的团队将在列出的工作时间内为您提供服务，并尽可能快速地回复您的请求。",
                        email:"邮箱",
                        operatingHours:"工作时间",
                        workinghours:"周一至周五，上午9:00至下午5:00",
                        contactUs:"联系我们",
                },
                pricing: {
                        bigTitle1: "轻松制定,",
                        bigTitle2: "事半功倍",
                        titleContent: "无论您是在探索各类项目，还是在完善您的申请，我们都提供最前沿的功能和个性化的服务，助您在旅程的每个阶段取得卓越成果",
                        chooseButton: "选择您的订阅方案",
                        startButton: "选择",
                        feature1: "精准写作辅助",
                        feature2: "流畅英语翻译",
                        plans: {
                                entry: "入门",
                                premium: "高级",
                                prime: "优选",
                                vip: "VIP",
                        },
                        features: {
                                priceRMB: "元",
                                programResources: "项目资源",
                                // unlimitedSearch: "无限搜索",
                                unlimitedSearch: {
                                        entry: "无限次项目资源搜索",
                                        prime: "包含入门套餐的所有功能",
                                        premium: "包含优选套餐的所有功能",
                                        vip: "全方位服务，包含高级套餐的所有功能",
                                },
                                programGuide: {
                                        text: "项目指南",
                                        entry: "3份个性化项目指南报告（每份含最多10个硕士项目）",
                                        prime: "5份个性化项目指南报告（每份含最多10个硕士项目）",
                                        premium: "10份个性化项目指南报告（每份含最多10个硕士项目）",
                                        vip: "10份个性化项目指南报告（每份最多含10个硕士项目）",
                                },
                                cvGenerator: {
                                        text: "简历生成器",
                                        entry: "附赠18份AI辅助生成的申请材料，包括6份简历、6份个人陈述和6封推荐信",
                                        prime: "附赠30份AI辅助生成的申请材料，申请材料生成上限为10份简历、10份个人陈述和10封推荐信",
                                        premium: "附赠60份AI辅助生成的申请材料，申请材料生成上限为20份简历、20份个人陈述和20封推荐信",
                                        vip: "附赠60份AI辅助生成的申请材料，申请材料生成上限为20份简历、20份个人陈述和20封推荐信",
                                },
                                // personalStatementGenerator: {
                                //         text: "个人陈述生成器",
                                //         entry: "共6份文件",
                                //         prime: "共10份文件",
                                //         premium: "共20份文件",
                                //         vip: "共20份文件",
                                // },
                                // recommendLetterGenerator: {
                                //         text: "推荐信生成器",
                                //         entry: "共6份文件",
                                //         prime: "共10份文件",
                                //         premium: "共20份文件",
                                //         vip: "共20份文件",
                                // },
                                personalTutoring: {
                                        text: "个人辅导",
                                        entry: "附送30分钟的个人咨询服务",
                                        prime: "附送30分钟的个人咨询服务",
                                        premium: "附送30分钟的个人咨询服务",
                                        vip: "一对一申请全程辅导老师指导",
                                }
                        }
                },
                payment: {
                        planDetails: "方案详情",
                        plans: {
                                entry: "入门",
                                prime: "优选",
                                premium: "高级",
                                vip: "VIP",
                        },
                        descriptions: {
                                entry: "入门级工具和个性化项目指南，帮助您迈出第一步。",
                                prime: "提供增强支持、额外工具和定制指导，助您专注申请。",
                                premium: "全面资源和报告，帮助您优化竞争力。",
                                vip: "专享顶级资源，全程支持，助您成功完成申请。",
                        },
                        features: {
                                entry: {
                                        feature1: "无限次项目资源搜索",
                                        feature2: "3份个性化项目指南报告（每份含10个硕士项目）",
                                        feature3: "18份AI辅助生成的申请材料，包括6份简历、6份个人陈述和6封推荐信",
                                        feature4: "30分钟的个人咨询服务",
                                },
                                prime: {
                                        feature1: "包含入门套餐的所有功能",
                                        feature2: "5份个性化项目指南报告（每份含10个硕士项目）",
                                        feature3: "申请材料生成上限为10份简历、10份个人陈述和10封推荐信",
                                        feature4: "30分钟的个人咨询服务",
                                },
                                premium: {
                                        feature1: "包含优选套餐的所有功能",
                                        feature2: "10份个性化项目指南报告（每份含10个硕士项目）",
                                        feature3: "申请材料生成上限为20份简历、20份个人陈述和20封推荐信",
                                        feature4: "30分钟的个人咨询服务",
                                },
                                vip: {
                                        feature1: "全方位服务，包含高级套餐的所有功能",
                                        feature2: "10份个性化项目指南报告（每份含10个硕士项目）",
                                        feature3: "申请材料生成上限为20份简历、20份个人陈述和20封推荐信",
                                        feature4: "全程个人指导服务",
                                },
                        },
                        currency: "元",
                        total: "总计",
                        confirmButton: "确认",
                        payment: "支付方式",
                        paymentMethods: {
                                wechat: "微信",
                                alipay: "支付宝",
                                visa: "信用卡Visa",
                                paypal: "Paypal",
                        },
                        pay: "支付",
                        confirmInfo: "请确认您的方案",
                        wechatInfo: "请扫描二维码使用微信支付",
                        timeLeft: "剩余时间",
                        quitTitle: "您确定要退出支付吗？",
                        quitButton: "退出支付",
                        quitCancel: "继续支付",
                        result: {
                                "successTitle": "支付成功",
                                "failureTitle": "支付失败",
                                "successMessage": "感谢您升级为VIP套餐！\n您的付款已成功处理。\n进入个人空间享受您的独家功能。",
                                "failureMessage": "我们无法处理您的付款。请检查您的付款详情并重试。如果问题仍然存在，请联系客户支持以获取帮助。",
                                "successButton": "转到您的仪表板",
                                "failureButton": "返回定价页面",
                                "redirectMessage": "在",
                                "redirectMessage2": "秒内重定向..."
                        }
                },
                login: {
                        welcome: "欢迎",
                        email: "邮箱",
                        password: "密码",
                        login: "登录",
                        noAccount: "还没有账户？在此",
                        haveAccount: "已有账户？在此",
                        forgotPassword: "忘记密码？点此",
                        signup: "注册",
                        createAccount: "创建属于您的账户",
                        resetPassword: "重置密码",
                        firstName: "名字",
                        lastName: "姓氏",
                        nextStep: "下一步",
                        verifyCodeInfo: "已发送验证码至邮箱，请确认后在下方输入",
                        verifyCode: "验证码",
                        passwordWarning: "密码需以字母开头，长度在6~18之间，只能包含字母、数字和下划线",
                        accountCredentials: "设置您的账户凭据",
                        submitSignUp: "提交注册",
                        submitReset: "提交重置",
                        agree: "我同意",
                        terms: "用户协议",
                        and: "和",
                        privacy: "隐私协议",
                        promotion: '注册邮件，获取 Apply U 的产品更新、优惠信息和您的福利。'

                },
                dashboard: {
                        home: "主页",
                        AIwriting: "AI文书助手",
                        findProgram: "智能选校",
                        tutor: "人工导师",
                        workplace: "工作台",
                        calendar: "日历",
                        message: "消息",
                        setting: "设置",
                        language: "切换语言",
                        payment: "支付",
                        help: "帮助",
                        logout: "退出登录",
                        welcome: "欢迎回来",
                        welcomeSentence1: "每天都是成长和进步的新机会。",
                        welcomeSentence2: "一步一步来",
                        recentFile: "最近使用",
                        services: "常用功能",
                        cvgeneration: "CV生成器",
                        psgeneration: "PS生成器",
                        rlgeneration: "RL生成器",
                        programReport: "智能选校报告",
                        more: "更多",
                        select: "进入",
                        cv: "简历",
                        ps: "个人陈述信",
                        rl: "推荐信",
                        remainFile: "剩余份额",
                        programGuide: "智能选校",
                        cv: "简历",
                        ps: "个人陈述",
                        rl: "推荐信",

                },
                AIwriting: {
                        AIwrite: "AI文书助手",
                        content: "简历生成器",
                        quick: "开始",
                        dragdrop: "上传",
                        upload: "从本地上传已有cv （pdf格式）",
                        start: "新建简历",
                        create: "从已有模版快速新建简历",
                        prevFile: "最近使用文件",
                        insert: "插入行",
                        translate: "翻译",
                        rewriting: "改写",
                        polishing: "润色",
                        generator: "生成",
                        cvtool: "工具栏",
                        inputText: "请输入：",
                        description: "请输入详细描述…",
                        discard: "放弃更改",
                        translateText: "翻译后的文本",
                        example: "示例输出",
                        autoFill: "自动填充",
                        rewriteHere: "请粘贴您想改写的内容:",
                        rewriteContent: "改写",
                        enterMajorRequired: "为了更精准润色您的经历，请在此输入您想要申请的专业:",
                        enterMajorDescription: "请输入您想申请的专业描述",
                        describeExperience: "用2-3句话描述您的相关背景",
                        generatePoints: "生成要点",
                        enterMainText: "在此输入您的申请专业描述...",
                        save: "保存",
                        download: "下载",
                        prev: "上一步",
                        next: "下一步",
                        targetLanguage: "目标语言",
                        submit: "提交",


                },
                psGeneration: {
                        ps: "PS生成器",
                        createStatement: "两分钟生成个性化PS",
                        targetSchool: "您想申请的目标学校是：",
                        targetMajor: "您想申请的目标研究生项目是：",
                        describeMajor: "请用2-3句话描述您的目标专业。",
                        emphasizeAspect: "您在个人陈述中最希望强调哪个方面？",
                        statementOption1: "学术兴趣与研究经验",
                        statementOption2: "实习与实践经验",
                        statementOption3: "领导力与团队协作技能",
                        statementOption4: "跨学科背景与创新思维",
                        storyline: "故事线",
                        chooseStoryline: "请选择您最喜欢的故事线",
                        personalDetail: "定制化",
                        tellExperience: "请告诉我们一些您的个人经历：",
                        exOption1: "实习经历",
                        exOption2: "项目经历",
                        exOption3: "学术科研经历",
                        exOption4: "其他",
                        addQuestion: "添加问题",
                        Q1: "请描述一个难忘的经历或者遇到的困难和挑战",
                        Q2: "是什么经历促使你申请这个专业呢？",
                        futurePlan: "您选择该硕士项目的未来计划是什么？",
                        exAnswer: "选择在UCL攻读机器学习硕士项目，我计划深入研究深度学习和计算机视觉领域。毕业后，我希望加入一家领先的科技公司，将这些前沿技术应用于自动驾驶汽车系统的开发中，从而推动智能交通解决方案的进步",
                        selectMaximumWord: "请选择个人陈述的最大字数",
                        step1: "1. 选择简历",
                        step2: "2. 故事线",
                        step3: "3. 定制化",
                        step4: "4. 预览"
                },
                rlGeneration: {
                        createLetter: "两分钟生成个性化推荐信",
                        recommenderInfo: "1. 推荐人信息",
                        overview: "2. 总览",
                        title1: "请告诉我们一些关于您推荐人的信息：",
                        title2: "请选择推荐人的职位",
                        title3: "请介绍您和推荐人共事的经历：",
                        personalInfo: "2. 个人信息",
                        tellExperiences: "请告诉我们一些您的个人经历：",
                        skillTags: "您想添加到推荐信的能力标签是：",
                        tag1: "分析能力",
                        tag2: "创新思维",
                        tag3: "团队合作",
                        tag4: "领导力",
                        tag5: "解决问题的能力",
                        tag6: "沟通能力",
                        tag7: "组织能力",
                        tag8: "适应能力",
                        tag9: "批判性思维",
                        tag10: "研究技能",
                        tag11: "教学能力",
                        tag12: "技术熟练度",
                        tag13: "创造力",
                        tag14: "时间管理",
                        tag15: "跨文化交流",
                        targetSchool: "您想申请的目标学校是：",
                        targetMajor: "您想申请的目标研究生项目是：",
                        wordCount: "请选择推荐信字数：",
                        other: "其他:"


                },
                findProgram: {
                        resources: "硕士项目库",
                        guide: "智能选校",
                        search: "搜索",
                        filter: "筛选器",
                        clear: "清空所有",
                        applysearch: "应用搜索",
                        apply: "应用筛选器",
                        major: "专业选择",
                        region: "地区选择",
                        ranking: "大学排名",
                        fullPart: "全日制/非全日制",
                        fullPartOption: {
                                "Full Time": "全日制",
                                "Part Time": "非全日制"
                        },
                        country: {
                                "AU": "澳大利亚",
                                "NZ": "新西兰",
                                "SG": "新加坡",
                                "UK": "英国",
                                "HK": "香港",
                                "US": "美国",
                                "HK SAR": "香港特别行政区",
                                "CA": "加拿大",
                                "DK": "丹麦",
                                "DE": "德国",
                                "CH": "瑞士",
                                "SE": "瑞典",
                                "IE": "爱尔兰",
                                "FI": "芬兰",
                                "MO": "澳门特别行政区"
                        },
                        confirmInfo: {
                                schoolName: "学校名称",
                                degree: "学位",
                                degreeType: "学位类型",
                                duration: "Duration",
                                gpa: "绩点",
                                gpaScale: "评分量表",
                                AvgScore: "均分",
                                graduactionDate: "毕业时间",
                                module: "课程选择"
                        },
                        preference: "职业偏好",
                        wishlist: "项目心愿单",
                        duration: "时长",
                        location: "位置",
                        ranking: "排名",
                        fee: "学费/每学年",
                        overview: "总览",
                        learning: "学习/研究方向",
                        career: "就业方向",
                        admission: "入学要求",
                        rankin: "排名",
                        date: "添加日期",
                        manage: "管理",
                        name: "项目名称",
                        startGuide: "从以下方式进入智能选校",
                        match: "为我匹配",
                        matchForMe: "已有目标硕士项目，为我匹配",
                        step1: "选择项目",
                        step2: "上传简历",
                        step3: "个性化指标选定",
                        recommend: "为我择校",
                        chooseForMe: "没有目标项目，请为我推荐。",
                        uploadCV: "上传简历",
                        path: "个性化指标选定",
                        chooseFromWishlist: "从项目心愿单中选择",
                        programResource: "前往硕士项目库",
                        selected: "已选择",
                        personalization: "个性化定制",
                        targetMajor: "您的目标专业是什么？",
                        careerPlan: "您的职业计划是什么？（可选）",
                        studyAbroad: "您为什么想出国留学？",
                        universityRanking: "您期望的大学排名是多少？",
                        whichlocation: "您想去哪个国家留学？",
                        selectAnswer: "请选择：",
                        forPhD: "申请博士",
                        findJob: "找工作",
                        governmentJob: "寻找行政机关部门职位",
                        improvement: "背景提升",
                        searchPlaceholder: "请输入您想搜索的学校",
                        searchPlaceholder2: "请输入您想搜索的项目",
                },
                tutor: {
                        tutorResource: "导师资源",
                        connect: "联系导师",
                        student: "学生",
                        description: "导师简介",
                        country: "专精国家"
                },
                home: {
                        slogan1: "AI赋能留学",
                        slogan2: "一站式搞定申请全流程",
                        subtitle: "全球首个AI留学助手，让梦想不再遥远",
                        ctaButton: "立即开启留学之旅",
                        func0: {
                                "tip": "步骤1 背景建档",
                                "title": "一键导入个人信息，迅速生成个人学术档案",
                                "tipTitle": "提供AI智能生成和优化CV、PS、RL等留学文书",
                                "description": "AI优化：AI辅助智能优化个人简历（CV），提升专业度与竞争力。\n精准匹配：让你的简历更符合申请院校的需求，增加录取机会。\n个性定制：根据个人背景，量身打造专属简历。",
                                "button": "开始使用",
                        },
                        func1: {
                                "tip": "步骤2 智能选校",
                                "title": "2 分钟定制你的专属择校方案",
                                "tipTitle": "覆盖全球30,000+学校项目",
                                "description": "双重推荐：“为我推荐”和“为我匹配”：既可探索适合你的院校，又能查看与你的梦校匹配度。\n数据支持：基于真实案例，助力明智申请决策。\n职业规划：了解各项目的就业前景，打通择校与就业的双向通道。\n多维分析：从五个维度评估个人匹配度，提前规划，赢在起点。",
                                "button": "开始使用"
                        },
                        func2: {
                                "tip": "步骤3 AI写作辅助",
                                "title": "5 分钟打造高质量申请文书，成功更近一步！",
                                "tipTitle": "基于100,000+成功录取案例",
                                "description": "质量保障： 结合 10 万+ 优秀文书范例，确保内容贴合顶尖院校录取偏好。\n个性化定制： 基于个人背景和申请目标，量身定制专属文书。\n一站式服务： 提供 AI 智能生成和优化个人陈（PS）、推荐信（RL）等各类留学申请文书。\n多语言支持： 支持中文和英文的文书写作和翻译，确保语言地道专业。",
                                "button": "开始使用"
                        },
                        func3: {
                                "tip": "步骤4 真人辅导全程支持",
                                "title": "AI+人工 = dream offer",
                                "tipTitle": "由经验丰富的顾问提供个性化指导",
                                "description": "专家团队： 签约多位经验丰富的专业留学机构老师，覆盖各个留学热门国家和专业。\n定制化服务： 根据用户需求，提供针对性的申请策略和规划。\n多渠道沟通： 支持在线聊天、电话、视频会议等多种咨询方式。\n零费用享受专业指导： 免费提供高质量的一对一咨询服务。\n全流程支持： 从选校、申请、签证到入学，全程陪伴。\n答疑解惑： 及时解决用户在申请过程中遇到的各种问题。",
                                "button": "开始使用"
                        },
                        data1: "全球范围内的学术项目",
                        data2: "录取案例支持",
                        data3: "申请速度提升10倍",
                        company: "我们的公司",
                        service: "专业服务",
                        review: "客户评价",
                        reviewTitle: "客户对我们的价值有着深刻认识",
                        faq0: "常见问题",
                        faqTitle: "您可能想了解的问题",
                        faq: {
                                "question1": {
                                        "questionText": "ApplyU的AI写作工具是如何提高申请文书质量的？",
                                        "answer": "- 我们的AI写作工具专为留学申请打造，支持个人简历、个人陈述和推荐信的智能生成与优化。仅需5分钟，快速生成高质量文书，减轻写作负担。\n1. 个人简历（CV）：利用AI算法综合分析学术背景、兴趣特长和职业目标，智能匹配合适的学校和专业，并结合多维数据和信息库，确保内容精准专业。\n2. 个人陈述（PS）：ApplyU通过AI算法分析学生背景与目标，生成符合申请要求的个性化内容。平台根据学生背景创建独特故事线，扩展成完整PS，确保文书风格独特、避免千篇一律。自动优化内容精准契合目标院校和专业需求。\n3. 推荐信（RL）：基于申请者的多维度数据与职业发展需求，自动生成推荐信，提升推荐效果。"
                                },
                                "question2": {
                                        "questionText": "ApplyU如何帮助我选择最合适的学校？",
                                        "answer": "我们的智能选校系统具备双通道功能，既可以根据用户提供的目标院校计算匹配度，也可以根据用户自身条件推荐最合适的学校。平台依托丰富的项目库和强大的推荐算法，实现更精准的匹配，确保学生获得最佳建议。通过学术与职业的双向互联，用户可根据未来职业导向选择方向。同时，系统设计了个性化标签，适用于各种背景的学生，包括跨专业申请。"
                                },
                                "question3": {
                                        "questionText": "ApplyU的人工加AI合作模式是如何运作的？",
                                        "answer": "ApplyU采用人工与AI结合的模式，以应对留学生在使用AI时可能存在的顾虑。我们的AI工具用于高效分析和生成申请文书，但更重要的是，我们的AI套餐内包含免费人工咨询。经验丰富的辅导老师会全程跟踪指导，确保申请过程的每一步都得到专业支持。这种人机协作模式，不仅提升了申请效率，还让学生在整个过程中感到安心和有保障。"
                                },
                                "question4": {
                                        "questionText": "使用ApplyU的服务有什么具体步骤？",
                                        "answer": "- 使用ApplyU的服务主要分为以下四个步骤：\n1. 注册并创建个人档案：用户输入个人信息，生成专属的“留学档案”。\n2. AI辅助完成文书：使用AI写作工具，快速生成并优化个人陈述、简历及推荐信等申请文书，提升文书质量和个性化。\n3. 智能选校与专业推荐：启动智能选校系统，获取与用户背景匹配的学校和专业列表，帮助学生做出更明智的选择。\n4. 预约顾问 提交申请：与专业顾问沟通交流，完善申请材料，确保顺利提交。"
                                },
                                "question5": {
                                        "questionText": "ApplyU如何收费？",
                                        "answer": "ApplyU提供多种服务套餐（支持微信支付），以满足不同需求和预算：\n1. 入门套餐 – 898元\n                                                - 无限次项目资源搜索。\n  - 3份个性化项目指南报告（每份含10个硕士项目）。\n  - 18份AI辅助生成的申请材料，包括6份简历、6份个人陈述和6封推荐信。\n  - 30分钟的个人咨询服务。\n                2. 优选套餐 – 1380元\n                        - 包含入门套餐的所有功能。\n  - 5份个性化项目指南报告（每份含10个硕士项目）。\n  - 申请材料生成上限为10份简历、10份个人陈述和10封推荐信。\n- 30分钟的个人咨询服务。\n3. 高级套餐 – 2480元\n        - 包含优选套餐的所有功能。\n- 10份个性化项目指南报告（每份含10个硕士项目）。\n- 申请材料生成上限为20份简历、20份个人陈述和20封推荐信。\n- 30分钟的个人咨询服务。\n4. VIP套餐 – 5888元\n        - 全方位服务，包含VIP套餐的所有功能。\n - 10份个性化项目指南报告（每份含10个硕士项目。\n - 申请材料生成上限为20份简历、20份个人陈述和20封推荐信。\n- 全程个人指导服务。"
                                },
                                "question6": {
                                        "questionText": "如果我在使用过程中遇到问题，如何获取帮助？",
                                        "answer": "您可以通过电子邮件或微信与我们的支持团队联系，我们将尽快回复您。"
                                },
                                "question7": {
                                        "questionText": "如何保证我的个人信息安全？",
                                        "answer": "ApplyU严格遵守数据保护法规，使用最新的安全技术来保护用户的个人信息。我们承诺不会在未经您明确同意的情况下与任何第三方分享您的数据。"
                                }
                        }

                },
                footnav: {
                        slogan1: "全面专业快捷",
                        slogan2: "留学路上不再迷茫",
                        ul1: {
                                "il1": "申请准备",
                                "il2": "学习支持",
                                "il3": "职业发展",
                        },
                        ul2: {
                                "il1": "关于我们",
                                "il2": "服务价格",
                                "il3": "合作伙伴",
                        },
                        ul3: {
                                "il1": "联系我们",
                                "il2": "服务条款",
                                "il3": "隐私政策",
                        }
                },
                review: {
                        review1: "Personal Tutor真的很会帮学生找到合适的辅导，特别贴心，效果也很明显！",
                        review2: "ApplyU的项目指南让找硕士项目变得超简单，是指导学生的必备工具！",
                        review3: "ApplyU真的太棒了！信息齐全又快速，还全在一个平台上，节省了我很多时间！",
                        review4: "ApplyU的推荐帮我找到需要提升的地方，比如建议我去实习，效果很好！",
                        review5: "ApplyU的工具让咨询变得更轻松，快速又详细，真的帮我更好地指导学生！",
                        review6: "ApplyU真的很准，帮我找到了完美的AI硕士项目，强烈推荐！",
                },
        }
}

