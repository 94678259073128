import { useState } from "react"
import RequireText from "../../assets/i18n/TextDefiner";
import { useTranslation } from "react-i18next";

export default function FAQ() {

    const qustionAnswer = [
        {
            questionText: RequireText("home.faq.question1.questionText"),
            answer: RequireText("home.faq.question1.answer")
        },
        {
            questionText: RequireText("home.faq.question2.questionText"),
            answer: RequireText("home.faq.question2.answer")
        },
        {
            questionText: RequireText("home.faq.question3.questionText"),
            answer: RequireText("home.faq.question3.answer")
        },
        {
            questionText: RequireText("home.faq.question4.questionText"),
            answer: RequireText("home.faq.question4.answer")
        },
        {
            questionText: RequireText("home.faq.question5.questionText"),
            answer: RequireText("home.faq.question5.answer")
        },
        {
            questionText: RequireText("home.faq.question6.questionText"),
            answer: RequireText("home.faq.question6.answer")
        },
        {
            questionText: RequireText("home.faq.question7.questionText"),
            answer: RequireText("home.faq.question7.answer")
        }
    ];

    const QuestionSection_zh = ({ }) => {
        const [open, setOpen] = useState(null);

        return (
            <>
                <div className="questionCardContainer" onClick={() => open !== 0 ? setOpen(0) : setOpen(null)} style={open === 0 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: ApplyU的AI写作工具是如何提高申请文书质量的？ </span>
                        {open === 0 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 0 ? { display: "flex", flexDirection: "column", gap: "10px", justifyContent: "start" } : { display: "none" }}>
                        <span>A: 我们的AI写作工具专为留学申请打造，支持个人简历、个人陈述和推荐信的智能生成与优化。仅需5分钟，快速生成高质量文书，减轻写作负担。</span>

                        <ol style={{ alignSelf: 'flex-start', paddingLeft: '19px' }}>
                            <li>
                                <strong>个人简历(CV)</strong>：利用AI算法综合分析学术背景、兴趣特长和职业目标，智能匹配合适的学校和专业，并结合多维数据和信息库，确保内容精准专业。
                            </li>
                            <li>
                                <strong>个人陈述(PS)</strong>：ApplyU通过AI算法分析学生背景与目标，生成符合申请要求的个性化内容。平台根据学生背景创建独特故事线，扩展成完整PS，确保文书风格独特、避免千篇一律。自动优化内容精准契合目标院校和专业需求。
                            </li>
                            <li>
                                <strong>推荐信(RL)</strong>：基于申请者的多维度数据与职业发展需求，自动生成推荐信，提升推荐效果。
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 1 ? setOpen(1) : setOpen(null)} style={open === 1 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: ApplyU如何帮助我选择最合适的学校？</span>
                        {open === 1 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 1 ? { display: "block" } : { display: "none" }} >
                        A: 我们的智能选校系统具备<strong>双通道功能</strong>，既可以根据用户提供的目标院校计算匹配度，也可以根据用户自身条件推荐最合适的学校。平台依托<strong>丰富的项目库</strong>和<strong>强大的推荐算法</strong>，实现更精准的匹配，确保学生获得最佳建议。通过<strong>学术与职业的双向互联</strong>，用户可根据未来职业导向选择方向。同时，系统设计了<strong>个性化标签</strong>，适用于各种背景的学生，包括跨专业申请。
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 2 ? setOpen(2) : setOpen(null)} style={open === 2 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: ApplyU的人工加AI合作模式是如何运作的？</span>
                        {open === 2 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 2 ? { display: "block" } : { display: "none" }} >
                        A: ApplyU采用人工与AI结合的模式，以应对留学生在使用AI时可能存在的顾虑。我们的AI工具用于高效分析和生成申请文书，但更重要的是，我们的AI套餐内<strong>包含免费人工咨询</strong>。经验丰富的辅导老师会全程跟踪指导，确保申请过程的每一步都得到专业支持。这种人机协作模式，不仅提升了申请效率，还让学生在整个过程中感到安心和有保障。
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 3 ? setOpen(3) : setOpen(null)} style={open === 3 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: 使用ApplyU的服务有什么具体步骤？</span>
                        {open === 3 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 3 ? { display: "flex", flexDirection: "column", gap: "10px", justifyContent: "start" } : { display: "none" }}>
                        <span>A: 使用ApplyU的服务主要分为以下四个步骤：</span>

                        <ol style={{ paddingLeft: '20px' }}>
                            <li>
                                <strong>注册并创建个人档案</strong>：用户输入个人信息，生成专属的“留学档案”。
                            </li>
                            <li>
                                <strong>AI辅助完成文书</strong>：使用AI写作工具，快速生成并优化个人陈述、简历及推荐信等申请文书，提升文书质量和个性化。
                            </li>
                            <li>
                                <strong>智能选校与专业推荐</strong>：启动智能选校系统，获取与用户背景匹配的学校和专业列表，帮助学生做出更明智的选择。
                            </li>
                            <li>
                                <strong>预约顾问 提交申请</strong>：与专业顾问沟通交流，完善申请材料，确保顺利提交。
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 4 ? setOpen(4) : setOpen(null)} style={open === 4 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: ApplyU如何收费？</span>
                        {open === 4 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 4 ? { display: "block" } : { display: "none" }} >
                        <p>ApplyU 提供多种服务套餐（支持微信支付），以满足不同需求和预算：</p>

                        <ol style={{ paddingLeft: "20px" }}>
                            <li>
                                <strong>入门套餐 – 898元</strong>
                                <ul style={{ paddingLeft: "20px" }}>
                                    <li>无限次项目资源搜索。</li>
                                    <li>3份个性化项目指南报告（每份含10个硕士项目）。</li>
                                    <li>申请材料生成上限为6份简历、6份个人陈述和6封推荐信。</li>
                                    <li>30分钟的个人咨询服务。</li>
                                </ul>
                            </li>

                            <li>
                                <strong>优选套餐 – 1380元</strong>
                                <ul style={{ paddingLeft: "20px" }}>
                                    <li>包含入门套餐的所有功能。</li>
                                    <li>5份个性化项目指南报告（每份含10个硕士项目）。</li>
                                    <li>申请材料生成上限为10份简历、10份个人陈述和10封推荐信。</li>
                                    <li>30分钟的个人咨询服务。</li>
                                </ul>
                            </li>

                            <li>
                                <strong>高级套餐 – 2480元</strong>
                                <ul style={{ paddingLeft: "20px" }}>
                                    <li>包含优选套餐的所有功能。</li>
                                    <li>10份个性化项目指南报告（每份含10个硕士项目）。</li>
                                    <li>申请材料生成上限为20份简历、20份个人陈述和20封推荐信。</li>
                                    <li>30分钟的个人咨询服务。</li>
                                </ul>
                            </li>

                            <li>
                                <strong>VIP套餐 – 5888元</strong>
                                <ul style={{ paddingLeft: "20px" }}>
                                    <li>全方位服务，包含高级套餐的所有功能。</li>
                                    <li>10份个性化项目指南报告（每份含10个硕士项目）。</li>
                                    <li>申请材料生成上限为20份简历、20份个人陈述和20封推荐信。</li>
                                    <li>全程私人顾问支持。</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 5 ? setOpen(5) : setOpen(null)} style={open === 5 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: 如果我在使用过程中遇到问题，如何获取帮助？</span>
                        {open === 5 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 5 ? { display: "block" } : { display: "none" }} >
                        A: 您可以通过电子邮件或微信与我们的支持团队联系，我们将尽快回复您。
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 6 ? setOpen(6) : setOpen(null)} style={open === 6 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: 如何保证我的个人信息安全？</span>
                        {open === 6 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 6 ? { display: "block" } : { display: "none" }} >
                        A: ApplyU严格遵守数据保护法规，使用最新的安全技术来保护用户的个人信息。我们承诺不会在未经您明确同意的情况下与任何第三方分享您的数据。
                    </div>
                </div>
            </>
        )
    }

    const QuestionSection_en = ({ }) => {
        const [open, setOpen] = useState(null);

        return (
            <>
                <div className="questionCardContainer" onClick={() => open !== 0 ? setOpen(0) : setOpen(null)} style={open === 0 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q:  How does ApplyU’s AI writing improve the quality of application documents?   </span>
                        {open === 0 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 0 ? { display: "flex", flexDirection: "column", gap: "10px", justifyContent: "start" } : { display: "none" }}>
                        <span>A: ApplyU’s AI writing tool is purpose-built for master’s application support, offering intelligent generation and optimization of resumes, personal statements, and recommendation letters. It produces high-quality documents within just five minutes, reducing the writing workload for users.</span>

                        <ol style={{ alignSelf: 'flex-start', paddingLeft: '19px' }}>
                            <li>
                                <strong>Resume (CV)</strong>：Utilizes AI algorithms to comprehensively analyze academic background, personal interests, and career goals, intelligently matching suitable schools and majors. It combines multidimensional data and an extensive information database to ensure content accuracy and professionalism.
                            </li>
                            <li>
                                <strong>Personal Statement (PS)</strong>：ApplyU's AI algorithm analyzes students’ backgrounds and goals to create personalized content that meets application requirements. The platform crafts three storylines based on the student's background, expanding it into a complete PS to ensure a distinct writing style and avoid uniformity. The content is optimized to match the specific requirements of target schools and programs.
                            </li>
                            <li>
                                <strong>Recommendation Letter (RL)</strong>：Automatically generates recommendation letters based on the applicant's multidimensional data and career development needs, enhancing the quality and effectiveness of the recommendation.
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 1 ? setOpen(1) : setOpen(null)} style={open === 1 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: How does ApplyU help me choose the most suitable programs?  </span>
                        {open === 1 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 1 ? { display: "block" } : { display: "none" }} >
                        <p>A: ApplyU’s program guide offers a <strong>dual-channel approach:</strong> users can either calculate compatibility with specific wishlist programs or receive recommendations tailored to their personal profile. Our platform leverages a robust project database and advanced recommendation algorithms to deliver precise matches, ensuring students receive optimal advice. </p>
                        <p>- By integrating academic and career pathways, ApplyU allows users to make informed decisions based on future career aspirations. The system’s personalized tagging system accommodates a wide range of student backgrounds, including those pursuing cross-disciplinary applications.</p>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 2 ? setOpen(2) : setOpen(null)} style={open === 2 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: How does ApplyU’s 'AI + human expert' collaboration model work?  </span>
                        {open === 2 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 2 ? { display: "block" } : { display: "none" }} >
                        A: ApplyU adopts a collaborative model that combines AI and human expert to address potential concerns students may have when using AI. While our AI tool efficiently optimizes application materials and personalized program guide, the highlight of our service is the inclusion of <strong>free expert consultation within the AI package</strong>. Experienced experts provide comprehensive guidance throughout the process, ensuring every step is supported by expert oversight.
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 3 ? setOpen(3) : setOpen(null)} style={open === 3 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: What are the specific steps for using ApplyU's services?  </span>
                        {open === 3 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 3 ? { display: "flex", flexDirection: "column", gap: "10px", justifyContent: "start" } : { display: "none" }}>
                        <span>A: The process of using ApplyU's services is divided into four main steps: </span>

                        <ol style={{ paddingLeft: '20px' }}>
                            <li>
                                <strong>Register and create a personal profile</strong>：Enter personal information to generate a personalized "study abroad profile."。
                            </li>
                            <li>
                                <strong>AI writing preparation</strong>：Use the AI writing tool to quickly generate and optimize personal statements, resumes, and recommendation letters, enhancing document quality and personalization.
                            </li>
                            <li>
                                <strong>Program guide:</strong>：Activate the intelligent school selection system to obtain a list of schools and majors that match the user's background, helping students make informed choices.
                            </li>
                            <li>
                                <strong>Consult with a personal tutor</strong>：Communicate with a human expert, refine application materials, and ensure a smooth submission.
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 4 ? setOpen(4) : setOpen(null)} style={open === 4 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: How does ApplyU charge for its services? </span>
                        {open === 4 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 4 ? { display: "block" } : { display: "none" }} >
                        <span>A: ApplyU offers a range of service packages to suit different needs and budgets:</span>

                        <ol style={{ alignSelf: 'flex-start', paddingLeft: '20px' }}>
                            <li>
                                <strong>Entry Package – 898 RMB</strong>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li>Unlimited program resource search.</li>
                                    <li>3 personalized program guide reports (each contains 10 master programs).</li>
                                    <li>18 AI-augmented application materials, including 6 resumes, 6 personal statements, and 6 recommendation letters.</li>
                                    <li>30 minutes of personal consultation.</li>
                                </ul>
                            </li>

                            <li>
                                <strong>Prime Package – 1380 RMB</strong>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li>Includes all features in the Entry Package.</li>
                                    <li>5 personalized program guide reports (each contains 10 master programs).</li>
                                    <li>AI-augmented application materials for up to 10 resumes, 10 personal statements, and 10 recommendation letters.</li>
                                    <li>30 minutes of personal consultation.</li>
                                </ul>
                            </li>

                            <li>
                                <strong>Premium Package – 2480 RMB</strong>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li>Includes all features in the Prime Package.</li>
                                    <li>10 personalized program guide reports (each contains 10 master programs).</li>
                                    <li>AI-augmented application materials for up to 20 resumes, 20 personal statements, and 20 recommendation letters.</li>
                                    <li>30 minutes of personal consultation.</li>
                                </ul>
                            </li>

                            <li>
                                <strong>VIP Package – 5888 RMB</strong>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li>All-inclusive service with every feature in the Premium Package.</li>
                                    <li>10 personalized program guide reports (each contains 10 master programs).</li>
                                    <li>AI-augmented application materials for up to 20 resumes, 20 personal statements, and 20 recommendation letters.</li>
                                    <li>Full-process personal guidance.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 5 ? setOpen(5) : setOpen(null)} style={open === 5 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: What should I do if I encounter issues while using the service?  </span>
                        {open === 5 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 5 ? { display: "block" } : { display: "none" }} >
                        A: You can contact our support team via email or WeChat, we will response to you as soon as possible.
                    </div>
                </div>
                <div className="questionCardContainer" onClick={() => open !== 6 ? setOpen(6) : setOpen(null)} style={open === 6 ? { backgroundColor: "#F0F6FF" } : { backgroundColor: "transparent" }} >
                    <div className="questionContainer" >
                        <span className="question">Q: How is my personal information kept secure?  </span>
                        {open === 6 ? <i className="bi bi-chevron-left arrow"></i> : <i className="bi bi-chevron-down arrow"></i>}
                    </div>
                    <div className="answerContainer" style={open === 6 ? { display: "block" } : { display: "none" }} >
                        A: ApplyU strictly adheres to data protection regulations and uses the latest security technologies to safeguard users' personal information. We commit not sharing your data with any third party without your explicit consent.
                    </div>
                </div>
            </>
        )
    }

    const { i18n } = useTranslation();

    return (
        <>
            <div className="d-x-center faq">
                <div className="tip">{RequireText("home.faq0")}</div>
                <div className="title">{RequireText("home.faqTitle")}</div>
                <div className="faqContainer">
                    {i18n.language === 'en' ? <QuestionSection_en /> : <QuestionSection_zh />}
                </div>

            </div>
        </>
    )
}