import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SendCode, SignUpToUser, VerifyCodeToServer } from "../../assets/func/user";
import { Spin, Modal } from "antd";
import { PostToServerWithJson, PutToServerWithJson } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { encryptString } from "../Utilities/encoding";
import RequireText from "../../assets/i18n/TextDefiner";
import { useTranslation } from "react-i18next";

export function ResetComponent() {
    const navito = useNavigate()
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    //发送验证码
    const CreateUserNode = () => {
        const [username, setUsername] = useState("")
        const [usernameError, setUsernameError] = useState("")
        // const [firstName, setFirstName] = useState("")
        // const [firstNameError, setFirstNameError] = useState("")
        // const [lastName, setLastName] = useState("")
        // const [lastNameError, setLastNameError] = useState("")
        const [modalOpen, setModalOpen] = useState(false);
        const [modelMessage, setModalMessage] = useState("");
        const [loading, setLoading] = useState(false);



        // Regex patterns
        const patternEmail = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        // const patternName = /^[a-zA-Z\u4e00-\u9fa5]+$/;

        function ClickNext() {
            // Validate email and names
            const isEmailValid = patternEmail.test(username);
            // const isFirstNameValid = patternName.test(firstName);
            // const isLastNameValid = patternName.test(lastName);

            if (isEmailValid) {
                // if (checkValue) {
                // Assuming SendCode is defined elsewhere
                setLoading(true);
                const api = NetworkApi.user.userSendCodeForReset
                PostToServerWithJson(api, { "email": username, "code": "" }, (re) => {
                    if (re.success === true) {
                        navito(`/user/verifyreset`, { state: { id: username } });
                    }
                    else {
                        setModalOpen(true);
                        setModalMessage(re.message.cn);
                    }
                })

                // } else {
                //     setShowCheckError(true);
                // }
            } else {

                if (!isEmailValid) { currentLanguage === 'zh' ? setUsernameError("请输入正确的邮箱") : setUsernameError("Please enter a valid email address") }
                // if (!isFirstNameValid) { currentLanguage === 'zh' ? setFirstNameError("请输入正确的名字") : setFirstNameError("Please enter a valid name") }
                // if (!isLastNameValid) { currentLanguage === 'zh' ? setLastNameError("请输入正确的名字") : setLastNameError("Please enter a valid name") }
            }
        }

        // Clear error when input changes
        const handleInputChange = (setter, errorSetter) => (e) => {
            setter(e.currentTarget.value);
            errorSetter("");
        };

        const handleOk = () => {
            setModalOpen(false);
            setLoading(false);
        };

        return (
            <div className="create" >
                <div className="main" >
                    <div className="title" >{RequireText("login.resetPassword")}</div>
                    <div className="inputGroup" >
                        <div className="emailInput" >
                            {/* <TextField
                                required
                                className="inputBox"
                                onChange={handleInputChange(setLastName, setLastNameError)}
                                variant="outlined"
                                label={RequireText("login.lastName")}
                                color="primary"
                                focused
                                fullWidth
                                error={lastNameError !== ""}
                                helperText={lastNameError || ""}
                            />
                            <TextField
                                required
                                className="inputBox"
                                onChange={handleInputChange(setFirstName, setFirstNameError)}
                                variant="outlined"
                                label={RequireText("login.firstName")}
                                color="primary"
                                focused
                                fullWidth
                                error={firstNameError !== ""}
                                helperText={firstNameError || ""}
                            /> */}
                            <TextField
                                required
                                className="inputBox"
                                onChange={handleInputChange(setUsername, setUsernameError)}
                                variant="outlined"
                                label={RequireText("login.email")}
                                color="primary"
                                focused
                                fullWidth
                                error={usernameError !== ""}
                                helperText={usernameError || ""}
                            />
                        </div>
                        {/* <div className="passwordInput">
                            <TextField className="inputBox" name="signup-input-password" variant="outlined" label="密码" fullWidth></TextField>
                        </div> */}
                        {/* <ComfirmAgreement></ComfirmAgreement> */}
                        <div className="verifyBtn" >
                            <Button onClick={ClickNext} variant="contained" color="info" fullWidth disabled={loading} >{loading ? <Spin /> : "Next"}</Button>
                            <div className="tips">{RequireText("login.haveAccount")}<Button onClick={() => navito('/user/login')}>{RequireText("login.login")}</Button></div>
                        </div>


                    </div>
                </div>
                {modalOpen &&
                    <Modal title="Message" style={{ top: 400 }} open={modalOpen} footer={[
                        <Button key='closeModal' type="primary" onClick={handleOk}>
                            Close
                        </Button>
                    ]}>
                        <p>{modelMessage ? modelMessage : "服务器错误，请联系管理员寻求帮助"}</p>
                    </Modal>
                }
            </div>
        )
    }
    return (
        <div className="resetNode" style={{ width: "100%" }}>
            <CreateUserNode></CreateUserNode>
        </div>
    )
}

//确认验证码
export function VerifyCodeForRest() {
    const [verifyValue, setVerifyValue] = useState("")
    const [countdown, setCountdown] = useState(0);
    const [loading, setLoading] = useState(false);
    const [nextStepLoading, setNextStepLoading] = useState(false);
    const [codeError, setCodeError] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modelMessage, setModelMessage] = useState("");
    const location = useLocation()
    const navito = useNavigate()
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    var username = ""
    var firstName = ""
    var lastName = ""
    var promotionCheck = true;

    useEffect(() => {
        if ((location.state === null)) {
            navito("/user/setpassword")
        }
        else if (!("id" in location.state)) {
            navito("/user/setpassword")
        } else {
            username = location.state.id
            // firstName = location.state.firstName
            // lastName = location.state.lastName
            // promotionCheck = location.state.promotionCheck
        }
    });

    const handleOk = () => {
        setModalOpen(false);
        setLoading(false);
    };

    //设计验证码1只能发送一次
    useEffect(() => {
        let timer = null;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [countdown]);

    function ClickToVerifyForReset(value) {
        // VerifyCodeToServer(username, value, () => navito(`/user/setpassword`, { state: { id: username, firstName: firstName, lastName: lastName } }))
        if (value) {
            setNextStepLoading(true)
            //console.log(`username:${username},firstname:${firstName},lastname:${lastName}`)
            const api = NetworkApi.user.userVerifyCodeForReset
            const userData = {
                "email": username,
                "code": value
            }
            PostToServerWithJson(api, userData, (re) => {
                if (re.success === true) {
                    navito(`/user/rsetpassword`, { state: { id: username, firstName: firstName, lastName: lastName, promotionCheck: promotionCheck, code: value } })
                } else {
                    if (re.message.en === "Wrong verification code") {
                        currentLanguage === 'zh' ? setCodeError(re.message.cn) : setCodeError(re.message.en);
                    } else {
                        setModalOpen(true);
                        currentLanguage === 'zh' ? setModelMessage(re.message.cn) : setModelMessage(re.message.en);
                    }
                }
                setNextStepLoading(false);
            })
        } else {
            currentLanguage === 'zh' ? setCodeError("请输入验证码") : setCodeError("Please enter the verification code");
        }
    }


    const resendVerifyCode = () => {
        setLoading(true)
        SendCode(username, () => {
            setLoading(false)
            setCountdown(60)
        }, (re) => {
            setLoading(false)
            setModalOpen(true);
            setModelMessage(re.message.cn);
        }
        )
    }


    return (
        <div className="verify" >
            <div className="main" >
                <div className="title" >{RequireText('login.verifyCodeInfo')}</div>
                <div className="inputGroup" >
                    <div className="emailInput" >
                        <TextField
                            className="inputBox"
                            onChange={(e) => setVerifyValue(e.currentTarget.value)}
                            name="signup-input-email"
                            variant="outlined" label={RequireText('login.verifyCode')}
                            color="primary"
                            error={codeError !== ""}
                            helperText={codeError}
                            focused
                            fullWidth></TextField>
                    </div>
                    <div className="verifyBtn" >
                        {currentLanguage === 'zh' ?
                            <Button onClick={() => ClickToVerifyForReset(verifyValue)} variant="contained" color="info" fullWidth disabled={nextStepLoading}>{nextStepLoading ? <Spin /> : "下一步"}</Button> :
                            <Button onClick={() => ClickToVerifyForReset(verifyValue)} variant="contained" color="info" fullWidth disabled={nextStepLoading}>{nextStepLoading ? <Spin /> : "NEXT"}</Button>
                        }
                        {currentLanguage === 'zh' ?
                            <Button onClick={() => resendVerifyCode()} variant="contained" color="info" fullWidth disabled={countdown > 0 || loading} >{loading ? <Spin /> : countdown > 0 ? `${countdown}秒后重新发送` : '重新发送'}</Button> :
                            <Button onClick={() => resendVerifyCode()} variant="contained" color="info" fullWidth disabled={countdown > 0 || loading} >{loading ? <Spin /> : countdown > 0 ? `Resend after ${countdown} seconds` : 'RESEND'}</Button>
                        }
                    </div>
                </div>
            </div>
            {modalOpen &&
                <Modal title="Message" style={{ top: 400 }} open={modalOpen} footer={[
                    <Button key='closeModal' type="primary" onClick={handleOk}>
                        Close
                    </Button>
                ]}>
                    <p>{modelMessage ? modelMessage : "服务器错误，请联系管理员寻求帮助"}</p>
                </Modal>
            }
        </div>

    )
}

//设置用户密码
export function ReSetPassword() {
    const location = useLocation();
    const navito = useNavigate();
    const [passwordValue, setPasswordValue] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [username, setUsername] = useState("");
    const [code, setCode] = useState("");
    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [promotionCheck, setPromotionCheck] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modelMessage, setModelMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (location.state === null || !("id" in location.state)) {
            navito("/user/resetpassword");
        } else {
            setUsername(location.state.id);
            // setFirstName(location.state.firstName);
            // setLastName(location.state.lastName);
            // setPromotionCheck(location.state.promotionCheck);
            setCode(location.state.code);
        }
    }, [location, navito]);

    const handleOk = () => {
        setModalOpen(false);
        if (success) {
            navito(`/user/login?username=${username}`)
        }
    }

    function FinishReset() {
        const pattern = /^[a-zA-Z][a-zA-Z0-9_@#$\-!]{5,17}$/

        if (!pattern.test(passwordValue)) {
            setPasswordError(currentLanguage === 'zh'
                ? "密码需以字母开头，长度在6~18之间，只能包含字母、数字、下划线和如下特殊字符：@#$\-!"
                : "Password must start with a letter, length between 6~18, and can only contain letters, numbers, underscores and special characters: @#$\-!");
            return;
        }

        if (passwordValue !== confirmPasswordValue) {
            setConfirmPasswordError(currentLanguage === 'zh'
                ? "两次输入的密码不一致"
                : "Passwords do not match");
            return;
        }

        setPasswordError(""); // Clear any previous error
        setConfirmPasswordError(""); // Clear any previous error

        const api = NetworkApi.user.userResetPassword;
        const email_encrypt = encryptString(passwordValue);
        const userdata = {
            "email": username,
            "password_enter_first": email_encrypt,
            "password_enter_second": email_encrypt,
            // "account_type_id": 1,
            // "brand_id": -1,
            // "reg_mode_id": 0,
            // "country_id": -1,
            // "email": username,
            // "phone": username,
            // "first_name": firstName,
            // "last_name": lastName,
            // consent_to_receive_email: promotionCheck,
            code: code
        };

        PutToServerWithJson(api, userdata, (re) => {
            if (re.success === true) {
                setModalOpen(true);
                currentLanguage === 'zh'
                    ? setModelMessage("修改密码成功！")
                    : setModelMessage("Password reset successfully!");
                setSuccess(true);
            } else {
                setModalOpen(true);
                setModelMessage(re.message.cn);
            }
        });
    }

    return (
        <div className="setPassword" >
            <div className="main" >
                <div className="title" >{RequireText('login.accountCredentials')}</div>
                <div className="inputGroup" >
                    <div className="emailInput" >
                        <TextField
                            className="inputBox"
                            name="signup-input-email"
                            variant="outlined"
                            label=""
                            color="primary"
                            value={username}
                            focused
                            fullWidth
                            disabled
                        />
                    </div>
                    <div className="passwordInput" >
                        <TextField
                            className="inputBox"
                            name="signup-input-password"
                            variant="outlined"
                            label={RequireText('login.password')}
                            color="primary"
                            onChange={(e) => {
                                setPasswordValue(e.currentTarget.value);
                                setPasswordError(""); // Clear error on change
                            }}
                            error={passwordError !== ""}
                            helperText={passwordError}
                            focused
                            fullWidth
                        />
                    </div>
                    <div className="passwordInput" >
                        <TextField
                            className="inputBox"
                            name="signup-input-password"
                            variant="outlined"
                            label={RequireText('login.password')}
                            color="primary"
                            onChange={(e) => {
                                setConfirmPasswordValue(e.currentTarget.value);
                                setConfirmPasswordError("");  // Clear error on change
                            }}
                            error={confirmPasswordError !== ""}
                            helperText={confirmPasswordError}
                            focused
                            fullWidth
                        />
                    </div>
                    <div className="verifyBtn" >
                        <Button onClick={FinishReset} variant="contained" color="info" fullWidth >
                            {RequireText('login.submitReset')}
                        </Button>
                    </div>
                </div>
            </div>
            {modalOpen &&
                <Modal title="Message" style={{ top: 400 }} open={modalOpen} footer={[
                    <Button key='closeModal' type="primary" onClick={handleOk}>
                        Close
                    </Button>
                ]}>
                    <p>{modelMessage ? modelMessage : "服务器错误，请联系管理员寻求帮助"}</p>
                </Modal>
            }
        </div>
    );
}