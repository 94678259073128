import { mergeAttributes, Node } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Heading } from '@tiptap/extension-heading';
import { Paragraph } from '@tiptap/extension-paragraph';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Mark } from '@tiptap/core';
import { Icon } from '@iconify/react/dist/iconify.js';
import { convertHTMLToJSON } from './CVHTMLToJSON';
//##########################################################################################################
// Self define html elements 
//##########################################################################################################
export const CustomSpan = Mark.create({
    name: 'span',

    inline: true,

    group: 'inline',

    parseHTML() {
        return [
            {
                tag: 'span',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            class: {
                default: null,
            },
        };
    },
});
export const CustomBulletList = BulletList.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            class: {
                default: null,
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['ul', HTMLAttributes, 0];
    },
});

export const CustomParagraph = Paragraph.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            class: {
                default: null,
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['p', HTMLAttributes, 0];
    },
});

export const CustomHeading = Heading.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            class: {
                default: null,
            },
        };
    },

    renderHTML({ node, HTMLAttributes }) {
        const level = this.options.levels.includes(node.attrs.level)
            ? node.attrs.level
            : this.options.levels[0];

        return [`h${level}`, HTMLAttributes, 0];
    },
});

export const Section = Node.create({
    name: 'section',

    group: 'block',

    content: 'block+',

    atom: false,

    parseHTML() {
        return [
            {
                tag: 'section',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['section', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            class: {
                default: null,
            },
        };
    },
});

export const Div = Node.create({
    name: 'div',

    group: 'block',

    content: 'block+',

    parseHTML() {
        return [
            {
                tag: 'div',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            class: {
                default: null,
            },
        };
    },
});

//################################################
//Define add new experience button and nodes
//#################################################
const AddExperienceButton = ({ editor, getPos, node }) => {
    const insertExperience = node.attrs.section === 'education' ? `
    <div class="education-item">
          <h6 class="school-info">
            <strong><span class="school-name">School Name <delete-item></delete-item></span></strong>
            <span class="school-location">City, State</span>
        </h6>
        <h6 class="degree-info">
            <em><span class="degree-type">Degree Type</span></em>
            <span class="graduation-date">Graduation Date: MM/YYYY</span>
        </h6>
        <ul class="education-details">
            <li>Course or Detail 1</li>
            <li>Course or Detail 2</li>
            <li>Course or Detail 3</li>
        </ul>
    </div>`.replace(/\n\s+/g, '') : node.attrs.section === 'work' ?
        `<div class="work-item">
        <h6 class="company-info">
            <strong><span class="company-name">Company Name <delete-item></delete-item></span></strong>
            <span class="company-location">City, State</span>
        </h6>
        <h6 class="position-info">
            <em><span class="job-title">Job Title</span></em>
            <span class="work-dates float-right">MM/YYYY - MM/YYYY</span>
        </h6>
        <ul class="work-details">
            <li>Job Detail 1</li>
            <li>Job Detail 2</li>
            <li>Job Detail 3</li>
        </ul>
    </div>`.replace(/\n\s+/g, '') : node.attrs.section === 'project' ?
            `<div class="project-item" style="margin-bottom: 20px;">
        <h6 class="project-info">
            <strong><span class="project-name">Project Name <delete-item></delete-item></span></strong>
            <span class="project-location float-right">City, State</span>
        </h6>
        <h6 class="project-role-info">
            <em><span class="project-role">Project Role</span></em>
            <span class="project-dates float-right">MM/YYYY - MM/YYYY</span>
        </h6>
        <ul class="project-details">
            <li>Project Detail 1</li>
            <li>Project Detail 2</li>
            <li>Project Detail 3</li>
        </ul>
    </div>`.replace(/\n\s+/g, '') : node.attrs.section === 'activity' ?
                `<div class="activity-item">
        <h6 class="organisation-info">
            <strong><span class="organisation-name">Organisation Name <delete-item></delete-item></span></strong>
            <span class="organisation-position float-right">Position</span>
        </h6>
        <h6 class="activity-role-info">
            <em><span class="activity-role">Activity Role</span></em>
            <span class="activity-dates float-right">MM/YYYY - MM/YYYY</span>
        </h6>
        <ul class="activity-details">
            <li>Activity Detail 1</li>
            <li>Activity Detail 2</li>
            <li>Activity Detail 3</li>
        </ul>
    </div>`.replace(/\n\s+/g, '') : node.attrs.section === 'publication' ?
                    `<div class="publication-item">
        <h6 class="publication-info">
            <strong><span class="publication-name">Publication Title <delete-item></delete-item></span></strong>
            <span class="publication-date">Date: MM/YYYY</span>
        </h6>
        <p class="publication-url"><a href="Publication URL">Publication URL</a></p>
    </div>`.replace(/\n\s+/g, '') : node.attrs.section === 'skill' ? `<li class="skill-title">More skills: ... </li>`.replace(/\n\s+/g, '') : `<li>Award Name - Date</li>`.replace(/\n\s+/g, '');

    const handleClick_Experience = () => {
        const pos = getPos(); // This gives you the position of the node in the document
        editor.chain().focus().insertContentAt(pos, insertExperience).run(); // Insert content right after the button
    };

    return (
        <NodeViewWrapper className="AddNewButton">
            <div className="addButtonContainer">
                <button
                    onClick={handleClick_Experience}
                    type="button"  // Adding a button attribute (e.g., type="button")
                    data-section={node.attrs.section}  // Adding custom data attributes
                >
                    + Add Experience
                </button>
            </div>
        </NodeViewWrapper>
    )
};

export const AddExperienceNode = Node.create({
    name: 'AddExperience',
    group: 'block',
    atom: true,
    parseHTML() {
        return [
            {
                tag: 'AddExperience',
            },
        ]
    },

    addAttributes() {
        return {
            section: {
                default: "education",
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['AddExperience', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(AddExperienceButton)
    },
});
//################################################
//Define add new section button
//#################################################
const AddSectionButton = ({ editor, getPos }) => {

    const insertSection = `
        <section class="custom-section">
    <h3 class="section-title">Custom Section <delete-section></delete-section></h3>
    <hr class="section-divider" />
    <p></p>
    </section>`.replace(/\n\s+/g, '');

    const handleClick_Section = () => {
        const pos = getPos(); // This gives you the position of the node in the document

        editor.chain().focus().insertContentAt(pos, insertSection).run(); // Insert content right after the button
        const json = convertHTMLToJSON(editor.getHTML());
    };

    return (
        <NodeViewWrapper className="AddNewButton">
            <div className="addButtonContainer">
                <button onClick={handleClick_Section}>
                    + Add Section
                </button>
            </div>
        </NodeViewWrapper>
    )
};

export const AddSectionNode = Node.create({
    name: 'AddSection',
    group: 'block',
    atom: true,
    parseHTML() {
        return [
            {
                tag: 'AddSection',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['AddSection', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(AddSectionButton)
    },
});

//################################################
//Define delete section button
//#################################################
const DeleteSectionButton = ({ editor }) => {
    const handleClick_Section = () => {

        editor.chain().focus().deleteNode('section').run();
        const html = editor.getHTML();
    };

    return (
        <NodeViewWrapper className='DeleteSectionButton'> 
            <button className="delete-button" onClick={handleClick_Section}>
                <Icon icon={"solar:trash-bin-2-outline"} className="icon" />
            </button>
        </NodeViewWrapper>
    );
};

export const DeleteSectionNode = Node.create({
    name: 'deleteSection',

    inline: true,

    group: 'inline',

    atom: true,

    parseHTML() {
        return [{ tag: 'delete-section' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['delete-section', mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(DeleteSectionButton);
    },
});

//################################################
//Define delete item button
//#################################################
const DeleteItemButton = ({ editor }) => {
    const handleClick_Section = () => {

        editor.chain().focus().deleteNode('div').run();

        const html = editor.getHTML();
    };

    return (
        <NodeViewWrapper className='DeleteItemButton'> 
            <button className="delete-button" onClick={handleClick_Section}>
                <Icon icon={"solar:trash-bin-2-outline"} className="icon" />
            </button>
        </NodeViewWrapper>
    );
};

export const DeleteItemNode = Node.create({
    name: 'deleteItem',

    inline: true,

    group: 'inline',

    atom: true,

    parseHTML() {
        return [{ tag: 'delete-item' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['delete-item', mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(DeleteItemButton);
    },
});