import { TutorCard } from "./TutorCard";
import { TutorFilter } from "./TutorFilter";
import { TutorPop } from "./TutorPop";
import { useEffect, useState } from "react";
import { PostToServerWithJson, GetToServer } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { Pagination } from "../ProgramResourceComponents/Pagination";
import RequireText from "../../assets/i18n/TextDefiner";
import { ModalComponent } from "../Utilities/ModalComponent";

export const TutorPage = () => {

    const [data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [tutorPop, setTutorPop] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [filter, setFilter] = useState({
        "page_num": 1,
        "major": ["all"],
        "specialized_country": ["all"],
    });
    const [selectedTutor, setSelectedTutor] = useState();
    const [modalContent,setModalContent] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    //进入页面时候加载
    useEffect(() => {
        const body = {
            "page_num": 0,
            "major": [
                "all"
            ],
            "specialized_country": [
                "all"
            ],
        }
        PostToServerWithJson(NetworkApi.tutor.total_tutor_page, body, (re) => {
            if (re.success === true) {
                setData(re.first_page_tutors);
                setTotalPages(re.pages_count);
            } else {
                setModalContent(re.message.cn);
                setModalOpen(true);
            }
            setLoading(false);
        });
    }, [])

    //筛选条件改变时候加载
    useEffect(() => {
        const body = filter
        setLoading(true)
        PostToServerWithJson(NetworkApi.tutor.show_tutor, body, (re) => {
            //console.log(re)
            if (re.success === true && re.message.en.includes("Matched tutors found")) {
                const myDiv = document.getElementsByClassName('TutorResourceCardContainer');
                if (myDiv.length > 0) {
                    myDiv[0].scrollTo({ top: 0, behavior: "smooth" });
                }
                setData(re.tutors)
            } else if (re.tutor_rows === 0) {
                setData([])
            }
            setLoading(false)
        });
        PostToServerWithJson(NetworkApi.tutor.total_tutor_page, body, (re) => {
            if (re.success===true) {
                setTotalPages(re.pages_count)
                //alert("Get First page: success")
            } else {
                //错误处理
                setModalContent(re.message.cn);
                setModalOpen(true);
            }
            setLoading(false)
        });

    }, [filter])

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setFilter({ ...filter, page_num: page });
        }
    };

    const handleOk = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className="TutorResourcePage">
                <div className="TutorResourceHead">
                    <label className="TutorResourceLabel">{RequireText("tutor.tutorResource")}</label>
                </div>
                <div className="TutorResourceBody">
                    <TutorFilter filter={filter} setFilter={setFilter} />
                    <div className="TutorResourceCardContainer">
                        {data.length > 0 && data.map((tutor, idx) => (
                            <TutorCard key={"Tutor" + idx} data={tutor} setTutorPop={setTutorPop} setSelectedTutor={setSelectedTutor} />
                        ))}
                        {data.length > 0 && <Pagination
                            currentPage={filter.page_num}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />}
                        {Loading === false && data.length === 0 && <div className="noProgram"> Sorry, No Tutor Found!</div>}
                    </div>
                </div>
            </div>
            {tutorPop && <TutorPop setTutorPop={setTutorPop} selectedTutor={selectedTutor} setModalOpen={setModalOpen} setModalContent={setModalContent} />}
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
        </>
    )
}