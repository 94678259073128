import { Icon } from '@iconify/react/dist/iconify.js';
import RequireText from '../../../assets/i18n/TextDefiner';

const FloatingMenu = ({ editor, menuPosition, setOpen, setPage,type }) => {
    const handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div className="floating-menu" style={menuPosition} onMouseDown={handleMouseDown}>
            <div
                className='floating-menu-translator'
                onClick={() => {
                    // Your translator action
                    setOpen(true);
                    setPage(0);
                    editor.chain().focus().run();
                }}
            >
                <Icon icon={'carbon:ibm-watson-language-translator'} />
                <span>AI {RequireText("AIwriting.translate")}</span>
            </div>
            {type==='cv' &&<div
                className='floating-menu-rewrite'
                onClick={() => {
                    // Your AI rewriting action
                    setOpen(true);
                    setPage(1);
                    editor.chain().focus().run();
                }}
            >
                <Icon icon={'mdi:cursor-text'} />
                <span>AI {RequireText("AIwriting.rewriting")}</span>
            </div>}
            {type==='cv' &&
            <div
                className='floating-menu-generator'
                onClick={() => {
                    // Your AI generator action
                    setOpen(true);
                    setPage(2);
                    editor.chain().focus().run();
                }}
            >
                <Icon icon={'mdi:edit'} />
                <span>AI {RequireText("AIwriting.generator")}</span>
            </div>}
        </div>
    );
};

export default FloatingMenu;