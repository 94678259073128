import { Detect } from "../DashboardComponent/AIprogress";
import { EditorPage } from "../EditorComponent/_EditorPage";
import { GetRL } from "./_rlProgressData";
import { convertToHTML } from "./_rlProgressData";

export function RLoverview() {
    const UniversityLetterhead = "[University Letterhead]"
    const date = "[Insert Date]"
    const UniversityName = "[University Name]"
    const UniversityAddress = "[University Address]"
    const city = "[city]"
    const postalCode = "[postal code]"
    const country = "[Country]"
    const name = "[Name]"
    const content =
        `<h1>${UniversityLetterhead} </h1>
    <p>${date}</p>
    <p></p>
    <p>Admission Committee</p>
    <p>${UniversityName}</p>
    <p>${UniversityAddress}</p>
    <p>${city}, ${postalCode}</p>
    <p>${country}</p>
    <p></p>
    <p></p>
    <h1>Subject: Recommendation for ${name} </h1>
    <p>To Whom It May Concern,</p>
    <p>I am writing to highly recommend [Name] for [position or program]. I had the pleasure of working closely with [Name] at [Company/Institution Name], where [he/she/they] served as [Job Title]. During this time, [Name] consistently demonstrated exceptional skills in [specific area, e.g., software development, project management, etc.], making [him/her/them] an invaluable member of our team.</p>
    <p>One of the most impressive aspects of [Name]'s work is [his/her/their] ability to [mention a specific skill or attribute, e.g., innovate and solve complex problems with creativity and precision]. For example, during [mention a specific project or task], [Name] [describe the achievement or contribution, e.g., led the development of a new feature that significantly improved user engagement by 30%]. This not only showcased [his/her/their] technical expertise but also [his/her/their] leadership and collaborative spirit.</p>
    <p>In addition to [his/her/their] professional capabilities, [Name] is a person of integrity and dedication. [He/She/They] consistently go above and beyond to ensure that the work is not only completed but done to the highest standard. [Name] has a unique ability to balance multiple tasks while maintaining a positive attitude, which greatly contributed to the overall success of our projects.</p>
    <p>I am confident that [Name] will bring the same level of excellence, dedication, and innovation to any future endeavor. I wholeheartedly recommend [Name] and believe [he/she/they] will be a tremendous asset to your organization.</p>
    <p></p>
    <p></p>
    <p></p>
    <p>Sincerely,</p>
    <p></p>
    <p>[Your Name] </p>
    <p>[Your Role] </p>
    <p>[Your Department/ Company]</p>
    <p>[Your Contact Information] </p>
    `
    function StringToP(str) {
        let result = str
        result = result.replace(/\n\n/g, "</p><p>")
        let newResult = `<p>${result}</p>`
        return newResult
    }
    const newContent = convertToHTML(GetRL())
    Detect("/dashboard/rl")
    return (
        <div className="rlOverview">
            <EditorPage type={"rl"} content={newContent} pageType={'progress'}></EditorPage>
        </div>
    )
}