export function convertHTMLToJSON(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    let json = {
        "CV": {
            "Template_Name": "Template 1",  // or extract dynamically
            "HTML": "",
            "Experience_Summary": {
                "content": "None"
            },
            "Name": "",
            "contact": "",
            "Modules": {
                "content": [
                    "none"
                ]
            },
            "Education": {},
            "Work_section": {},
            "Project_section": {},
            "Activity_section": {},
            "Publication_section": {},
            "Skill_section": {},
            "Award_section": {}
        }
    };

    //Store all HTML in HTML section
    json.CV.HTML = html;

    // Helper function to safely trim text
    const safeTrim = (text) => {
        return text ? text.trim() : "";
    };

    // name Section
    const name = safeTrim(doc.querySelector('.full-name').textContent);
    json.CV.Name = name;

    //contact Section
    const contact = safeTrim(doc.querySelector('.contact-info').textContent);
    json.CV.contact = contact;

    // Education Section
    const educationItems = doc.querySelectorAll('.education-item');
    educationItems.forEach((item, index) => {
        json.CV.Education[index] = {
            "School": safeTrim(item.querySelector('.school-name')?.textContent),
            "Degree": safeTrim(item.querySelector('.degree-type')?.textContent),
            "Degree_Type": safeTrim(item.querySelector('.degree-type')?.textContent),
            "Location": safeTrim(item.querySelector('.school-location')?.textContent),
            "Start_date": "",  // Not available in the HTML structure
            "End_date": safeTrim(item.querySelector('.graduation-date')?.textContent.split(': ')[1]),
            "Grades": "",  // Not available in the HTML structure
            "Avg_Score": "",
            "GPA_Scale": "",
            "Details": {}
        };

        const details = item.querySelectorAll('.education-details li p');
        details.forEach((detail, i) => {
            json.CV.Education[index].Details[i] = safeTrim(detail.textContent);
        });
    });

    // Work Section
    const workItems = doc.querySelectorAll('.work-item');
    workItems.forEach((item, index) => {
        json.CV.Work_section[index] = {
            "Company": safeTrim(item.querySelector('.company-name')?.textContent),
            "Position": safeTrim(item.querySelector('.job-title')?.textContent),
            "Location": safeTrim(item.querySelector('.company-location')?.textContent),
            "Start_date": safeTrim(item.querySelector('.work-dates')?.textContent.split(' - ')[0]),
            "End_date": safeTrim(item.querySelector('.work-dates')?.textContent.split(' - ')[1]),
            "Details": {}
        };

        const details = item.querySelectorAll('.work-details li p');
        details.forEach((detail, i) => {
            json.CV.Work_section[index].Details[i] = safeTrim(detail.textContent);
        });
    });

    // Project Section
    const projectItems = doc.querySelectorAll('.project-item');
    projectItems.forEach((item, index) => {
        json.CV.Project_section[index] = {
            "Project_name": safeTrim(item.querySelector('.project-name')?.textContent),
            "Project_Type": safeTrim(item.querySelector('.project-role')?.textContent),
            "Start_date": safeTrim(item.querySelector('.project-dates')?.textContent.split(' - ')[0]),
            "End_date": safeTrim(item.querySelector('.project-dates')?.textContent.split(' - ')[1]),
            "Details": {}
        };

        const details = item.querySelectorAll('.project-details li p');
        details.forEach((detail, i) => {
            json.CV.Project_section[index].Details[i] = safeTrim(detail.textContent);
        });
    });

    // Activity Section
    const activityItems = doc.querySelectorAll('.activity-item');
    activityItems.forEach((item, index) => {
        json.CV.Activity_section[index] = {
            "Orgnisation": safeTrim(item.querySelector('.organisation-name')?.textContent),
            "Position": safeTrim(item.querySelector('.organisation-position')?.textContent),
            "Start_date": safeTrim(item.querySelector('.activity-dates')?.textContent.split(' - ')[0]),
            "End_date": safeTrim(item.querySelector('.activity-dates')?.textContent.split(' - ')[1]),
            "Details": {}
        };

        const details = item.querySelectorAll('.activity-details li p');
        details.forEach((detail, i) => {
            json.CV.Activity_section[index].Details[i] = safeTrim(detail.textContent);
        });
    });

    // Publication Section
    const publicationItems = doc.querySelectorAll('.publication-item');
    publicationItems.forEach((item, index) => {
        json.CV.Publication_section[index] = {
            "publication_name": safeTrim(item.querySelector('.publication-name')?.textContent),
            "publication_url": safeTrim(item.querySelector('.publication-url')?.textContent),
            "date": safeTrim(item.querySelector('.publication-date')?.textContent.split(': ')[1])
        };
    });

    // Skill Section
    const skillItems = doc.querySelectorAll('.skill-item li p');
    skillItems.forEach((item, index) => {
        const skillTitle = safeTrim(item.querySelector('strong')?.textContent.replace(':', ''));
        json.CV.Skill_section[skillTitle] = safeTrim(item.textContent.split(':')[1]).split(', ');
    });

    // Award Section
    const awardItems = doc.querySelectorAll('.award-item li p');
    awardItems.forEach((item, index) => {
        const [awardName, awardDate] = safeTrim(item.textContent).split(' - ');
        json.CV.Award_section[index] = {
            "award_name": safeTrim(awardName),
            "award_data": safeTrim(awardDate)
        };
    });

    return json;
}