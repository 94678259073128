import { ServerIP } from "./NetworkApi"

const MainIp = ServerIP

//基本get方法，参数已json（）
export function GetToServer(url = "", callbackFunc = () => { }) {

    var totalUrl = MainIp + url
    fetch(totalUrl, {
        method: "GET",
        credentials: "include",

    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )
}
//post,结果已json()
export async function PostToServerWithJson(url = "", data = {}, callbackFunc = () => { }) {
    try {
        //console.log("PostToServerWithJson", url, data);
        const totalUrl = MainIp + url;

        const response = await fetch(totalUrl, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        // Handle specific status codes
        if (response.status === 429) {
            // Too Many Requests
            callbackFunc({ success: false, message: { en: 'You are making requests too frequently. Please wait a moment.' } });
            return; // Exit early as we have handled the 429 case
        }

        // Check if the response status is okay (200-299)
        if (!response.ok) {
            throw new Error(`Server responded with status: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        callbackFunc(result);
    } catch (error) {
        console.error("Error in PostToServerWithJson:", error);
    }
}

export function PostToServerWithFile(url = "", data, callbackFunc = () => { }) {

    var totalUrl = MainIp + url
    fetch(totalUrl, {

        method: "POST",
        credentials: "include",
        headers: {
            'accept': 'application/json',
        },
        body: data,
    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )
}

export function DeleteToServerWithJson(url = "", data = {}, callbackFunc = () => { }) {

    var totalUrl = MainIp + url
    fetch(totalUrl, {

        method: "DELETE",
        credentials: "include",

        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )
}

export function PutToServerWithJson(url = "", data = {}, callbackFunc = () => { }) {

    var totalUrl = MainIp + url
    fetch(totalUrl, {

        method: "PUT",
        credentials: "include",

        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )
}

export function PostToServerByFormData(url = "", data = {}, callbackFunc = () => { }) {
    var totalUrl = MainIp + url
    fetch(totalUrl, {

        method: "POST",
        credentials: "include",

        headers: {
            "Content-Type": "multipart/form-data boundary=------WebKitFormBoundaryig8AnaqBAFgAyqhC"

        },

        body: data,
    })
        .then(
            (re) => re.json()
        )
        .then(
            (re) => callbackFunc(re)
        )
        .catch(
            (re) => callbackFunc(re)
        )
}