import { ContentHome } from "./Content_home";
import { ContentCV } from "./Content_CV";
import { ContentPS } from "./Content_PS";
import { ContentRL } from "./Content_RL";
import { ContentProgramResource } from "./Content_PR";
import { ContentProgramGuide } from "./Content_PG";
import { Icon } from "@iconify/react/dist/iconify.js";
import { IconUrl } from "../../assets/media/IconUrl";
import { useLocation, useNavigate } from "react-router-dom";

const RouteUnit = ({ name, iconUrl, url }) => {
    const navito = useNavigate()

    return (
        <div onClick={()=>navito(url)} className="routeUnit">
            <Icon className="icon" icon={iconUrl}></Icon>
            <div className="text">{name}</div>
        </div>
    )
}
export const Struct_DashContent = {
    "home": {
        "jsx": <ContentHome />,
        "route": [],
        "url": "/"
    },
    "AIwrite": {
        "content_cv": {
            "jsx": <ContentCV />,
            "route": ["AIwrite", "content_cv"],
            "url": "/cv"
        },
        "content_ps": {
            "jsx": <ContentPS />,
            "route": ["AIwrite", "content_ps"],
            "url": "/ps"
        },
        "content_rl": {
            "jsx": <ContentRL />,
            "route": ["AIwrite", "content_rl"],
            "url": "/rl"
        },
    },
    "Program": {
        "content_programResource": {
            "jsx": <ContentProgramResource />,
            "route": ["Program", "content_programResource"],
            "url": "/browseporgram"
        },
        "content_programGuide": {
            "jsx": <ContentProgramGuide />,
            "route": ["Program", "content_programGuide"],
            "url": "/programguide"
        },
    },
    "tutor": {
        "jsx": <></>,
        "route": ["Tutor"],
        "url": "/tutor"
    }
};

export const RouteBar = ({ routeArray = [] }) => {
    
    const NameToRouteUnit = ({ name }) => {
        var returnJSX = null
        switch (name) {
            case "AIwrite": returnJSX = <RouteUnit name={"AIwrite"} iconUrl={IconUrl.AIwriting} url={'/dashboard'}></RouteUnit>; break;
            case "content_cv": returnJSX = <RouteUnit name={"content_cv"} iconUrl={IconUrl.cv} url={'/dashboard/cv'}></RouteUnit>; break;
            case "content_ps": returnJSX = <RouteUnit name={"content_ps"} iconUrl={IconUrl.ps} url={'/dashboard/ps'}></RouteUnit>; break;
            case "content_rl": returnJSX = <RouteUnit name={"content_rl"} iconUrl={IconUrl.rl} url={'/dashboard/rl'}></RouteUnit>; break;
            case "Program": returnJSX = <RouteUnit name={"Find program"} iconUrl={IconUrl.rl} url={'/dashboard/programguide'}></RouteUnit>; break;
            case "content_programResource": returnJSX = <RouteUnit name={"Program Resource"} iconUrl={IconUrl.findProgram} url={'/dashboard/browseporgram'}></RouteUnit>; break;
            case "content_programGuide": returnJSX = <RouteUnit name={"Program Guide"} iconUrl={IconUrl.findProgram} url={'/dashboard/programguide'}></RouteUnit>; break;
            case "Tutor": returnJSX = <RouteUnit name={"Tutor"} iconUrl={IconUrl.tutor} url={'/dashboard/tutor'}></RouteUnit>; break;

        }
        return returnJSX
    }
    return (
        <div className="routeText">
            {routeArray.map((item) => (
                <NameToRouteUnit key={item} name={item}>
                </NameToRouteUnit>
            ))}
        </div>
    )
}