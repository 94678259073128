import RequireText from "../../assets/i18n/TextDefiner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const data_en = [
    { name: "David", score: "4.8", comment: "Personal Tutor really knows how to connect students with the right help. It’s super personalized and gets results!" },
    { name: "Jessica Wang", score: "4.7", comment: "ApplyU's Program Guide makes finding the right master's program easy. It’s a must-have for guiding students!" },
    { name: "XinTong Li", score: "4.9", comment: "ApplyU is a game-changer! It’s quick, efficient, and has all the info I need in one place. Saved me so much time!" },
    { name: "Lily Hu", score: "4.6", comment: "ApplyU’s recommendations helped me focus on what I needed. It suggested an internship, and it worked out great!" },
    { name: "Chris Wilson", score: "4.7", comment: "ApplyU’s tool makes advising easier. It’s fast, detailed, and really helps me guide students better!" },
    { name: "George Li", score: "4.9", comment: "ApplyU nailed it! Found the perfect AI master’s program for my career. Highly recommended!" },
];

const data_zh = [
    { name: "David", score: "4.8", comment: "ApplyU的个人导师真的给了我很大帮助，针对我的需求提供了精准指导，找到适合的学习方向。整个体验很贴心，推荐给所有正在准备申请的同学！" },
    { name: "Jessica Wang", score: "4.7", comment: "项目指南功能让我省去了很多找项目的时间和精力，每个推荐的学校和专业都非常符合我的背景，真的是留学申请的好帮手！" },
    { name: "XinTong Li", score: "4.9", comment: "ApplyU平台信息完整且高效，让我的留学申请过程轻松了很多，不再需要切换不同平台，一站式搞定！" },
    { name: "Lily Hu", score: "4.6", comment: "ApplyU的建议很有帮助，发现了我在申请材料中的不足，比如缺乏相关实习经历。按照平台的指导去补充后，申请效果显著提升了！" },
    { name: "Chris Wilson", score: "4.7", comment: "ApplyU的工具让我的咨询过程变得高效且详细，清晰的内容指导帮助我为学生提供更专业的建议，工作省心了不少！" },
    { name: "George Li", score: "4.9", comment: "ApplyU的推荐特别精准，为我量身定制了AI硕士项目的选择，最终成功拿到理想学校的offer，非常感谢这个平台！" },
];

export default function UserReview() {
    const { i18n } = useTranslation();

    const ReviewCard = ({ name, comment, score }) => {
        return (
            <div className="cardContainer" style={{height:'fit-content'}}>
                <div className="cardContent" style={{height:'fit-content'}}>
                    <div className="d-x-between" style={{ fontSize: "16px", fontWeight: "bold" }}>
                        <div>{name}</div>
                        <div>{score}/5.0</div>
                    </div>
                    <div style={{ fontSize: "15px",marginTop: "10px", height: "80%", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 8, WebkitBoxOrient: "vertical" }}>
                        {comment}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="d-x-center userReview">
            <div className="tip">{RequireText("home.review")}</div>
            <div className="title">{RequireText("home.reviewTitle")}</div>
            <div className="reviewArea x-scroll-continous mask-transition-x">
                <div className="scroll-container">
                    {(i18n.language === 'en' ? data_en : data_zh).map((item, index) => (
                        <ReviewCard key={index} name={item.name} comment={item.comment} score={item.score} />
                    ))}
                </div>
            </div>
            <div className="reviewArea x-scroll-continous mask-transition-x">
                <div className="scroll-container-reverse">
                    {(i18n.language === 'en' ? data_en : data_zh).map((item, index) => (
                        <ReviewCard key={index} name={item.name} comment={item.comment} score={item.score} />
                    ))}
                </div>
            </div>
        </div>
    );
}
