import { EditorPage } from "../EditorComponent/_EditorPage";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer } from "../../assets/network/NetWork";
import { useEffect, useState } from "react";
import { convertToHTML } from "./_rlProgressData";
import { LoadingComponent } from "../Utilities/Loading";
import { ModalComponent } from "../Utilities/ModalComponent";


export function RlEditor() {
    const [rlContent, setRlContent] = useState("");
    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState("")

    useEffect(() => {
        var rlID = localStorage.getItem('rl_id');
        if (rlID !== "") {
            GetToServer(NetworkApi.rl.getSingleRl.concat("/", rlID), (re) => {
                if (re.success === true) {
                    setRlContent(convertToHTML(re.data.letter));
                } else {
                    setModalContent(re.message.cn);
                    setModalOpen(true);
                }
            }
            )
        }
    }, [])

    const handleOk = () => {
        setModalOpen(false)
    } 


    return (
        <div>
            {rlContent ? <EditorPage content={rlContent} type="rl" /> : <LoadingComponent />}
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
        </div>
    );
};