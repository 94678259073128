import { useState } from "react";
import majors from '../ProgramResourceComponents/Major.json';
import { LongCheckListComponent, ShortChecklistComponent } from "../ProgramResourceComponents/ProgramFilter";
import { Icon } from "@iconify/react/dist/iconify.js";
import RequireText from "../../assets/i18n/TextDefiner";

const RegionEnum = {
    "CHINA": 0,
    "UNITED_KINGDOM": 1,
    "UNITED_STATES": 2,
    "CANADA": 3,
    "AUSTRALIA": 4,
    "NEW_ZEALAND": 5,
    "HONG_KONG_CHINA": 6,
    "MACAU_CHINA": 7,
    "SWITZERLAND": 8,
    "DENMARK": 9,
    "FINLAND": 10,
    "GERMANY": 11,
    "SWEDEN": 12,
    "NORWAY": 13,
    "NETHERLANDS": 14,
    "BELGIUM": 15,
    "FRANCE": 16,
    "ITALY": 17,
    "SPAIN": 18,
    "PORTUGAL": 19,
    "AUSTRIA": 20,
    "IRELAND": 21,
    "JAPAN": 22,
    "SOUTH_KOREA": 23,
    "SINGAPORE": 24,
    "SAUDI_ARABIA": 25
};

function getCountryCode(countryName) {
    const countryKey = countryName.toUpperCase().replace(/ /g, '_');
    return RegionEnum.hasOwnProperty(countryKey) ? String(RegionEnum[countryKey]) : null;
}

export const TutorFilter = ({ filter, setFilter }) => {

    return (
        <>
            <div className='programFilter'>
                <TutorFilterComponent filter={filter} setFilter={setFilter} />
            </div>
        </>
    )
}

const TutorSearchComponent = ({ filter, setFilter }) => {

    const [search, setSearch] = useState('');

    const handleSearch = () => {
        console.log(search)
    }

    return (
        <div className='TutorFilter-searchSection'>
            <label>Search</label>
            <div className='searchComponent'>
                <input
                    className='searchInput'
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className='searchIcon-container' onClick={() => handleSearch()}>
                    <Icon icon="material-symbols:search" id='searchIcon' />
                </div>
            </div>
        </div>
    )
}

const TutorFilterComponent = ({ filter, setFilter }) => {
    const [selectedAnswer, setSelectedAnswer] = useState({
        major: [],
        region: []
    });

    // Apply filters (update selectedAnswer with locally managed filters)
    const applyFilters = () => {
        const specialized_country = selectedAnswer.region.map(region => getCountryCode(region)).filter(code => code !== null);
        setFilter({
            ...filter,
            "page_num": 1,
            "major": selectedAnswer.major.length === 0 ? ['all'] : selectedAnswer.major,
            "specialized_country": selectedAnswer.region.length === 0 ? ['all'] : specialized_country,
        })
    };


    const handleClearAll = () => {
        setSelectedAnswer({
            major: [],
            region: []
        })
        setFilter({
            ...filter,
            "page_num": 1,
            "major": ["all"],
            "specialized_country": ["all"],
        })
    }

    return (
        <div className='programFilter-filterSection'>
            <div className='filterTitle'>
                <label>{RequireText("findProgram.filter")}</label>
                <div className='ClearAllContainer' onClick={handleClearAll}>
                    <span className='ClearAllSpan'>{RequireText("findProgram.clear")}</span>
                    <Icon icon="solar:trash-bin-2-linear" id='clearAllIcon' />
                </div>
            </div>
            <div className='filterBody'>
                <LongCheckListComponent
                    data={majors}
                    icon={'bx:book'}
                    title={'Major'}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    title2={RequireText("findProgram.major")}
                />
                <ShortChecklistComponent
                    data={['AU', 'NZ', 'UK', 'HK', 'SG', 'US', 'CA', 'DK', 'DE', 'CH', 'SE', 'IE', 'FI', 'MO']}
                    // data={['Australia', 'New Zealand', 'United Kingdom', 'Hong Kong', 'Singapore']}
                    icon={'mingcute:location-line'}
                    title={'Region'}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    title2={RequireText("tutor.country")}
                    category={'country'}
                />
            </div>
            <button className='filterSubmit' onClick={applyFilters}>{RequireText("findProgram.apply")}</button>
        </div>
    );
}