import { Button, TextField } from "@mui/material";
// import { LoginToUser } from "../../assets/func/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetToServer, PostToServerWithJson } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { Spin } from "antd";
import { encryptString, decryptString } from "../Utilities/encoding";
import RequireText from "../../assets/i18n/TextDefiner"
import { useTranslation } from "react-i18next";


// 导入Button和TextField组件，LoginToUser函数，useNavigate和useSearchParams钩子，useEffect和useState钩子，GetToServer函数和NetworkApi对象

export function LoginComponent() {
    // 定义loginState状态，用于控制登录状态
    const [loginState, setLoginState] = useState(false)
    // 定义params状态，用于获取URL参数
    const [params, setParams] = useSearchParams()
    // 定义loading状态，用于控制加载状态
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("");

    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // 获取URL参数中的username
    const defaultUsername = params.get("username")

    // 导航钩子
    const navito = useNavigate()

    // 点击登录按钮时触发
    function ClickLogin() {
        // 获取输入框中的用户名和密码
        const username = document.getElementById("login-input-username")?.value;
        const password = document.getElementById("login-input-password")?.value;


        if (!username || !password) {
            currentLanguage === 'zh' ? setError("用户名或密码不能为空") : setError("Username or password cannot be empty");
            return;
        } else {
            setLoading(true);
            //api参数
            const api = NetworkApi.user.userLogin
            // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
            const userdata = {
                "user_name": username,
                "password": encryptString(password),
                "user_timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
            //console.log(decryptString(encryptString(password)))
            // 调用LoginToUser函数进行登录
            PostToServerWithJson(api, userdata, (re) => {
                setLoading(false)
                if (re.success === true) {
                    navito("/dashboard");
                } else {
                    if (re.message.cn.includes("密码或用户名错误")) {
                        currentLanguage === 'zh' ? setError("密码或用户名错误") : setError("Password or username is incorrect")
                    } else if ("没有用户名") {
                        currentLanguage === 'zh' ? setError("没有该用户名") : setError("Cannot found your account")
                    } else {
                        currentLanguage === 'zh' ? setError("服务器错误，请联系管理员寻求帮助") : setError("Internal server error, please contact the administrator for help")
                    };
                }
            })
        }

    }

    // 点击注册按钮时触发
    function ClickToSignup() {
        // 导航到注册页面
        navito("/user/signup")
    }

    function ClickToReset() {
        navito("/user/resetpassword")
    }

    // 组件挂载时触发
    useEffect(() => GetToServer(
        NetworkApi.user.userGetStats,
        (re) => {
            if (re.success) {
                localStorage.setItem('user_name', re.user_profile.first_name);
            } else {
                if (localStorage.getItem('user_name')) localStorage.removeItem('user_name');
            }
        }
    ), [])

    // 如果登录状态为true，则导航到dashboard页面
    if (loginState) {
        navito("/dashboard")
    }
    // 组件挂载时，如果URL参数中有username，则将其赋值给输入框
    useEffect(() => {
        if (defaultUsername) {
            document.getElementById("login-input-username").value = defaultUsername
        }
    }, [])

    const handleInputChange = (errorSetter) => (e) => {
        errorSetter("");
    };

    return (
        <div className="loginNode">

            <div className="title">{RequireText("login.welcome")}</div>
            <div className="inputGroup">
                <div className="emailInput">
                    <TextField id="login-input-username" className="inputBox" name="login-input-email" variant="outlined" label={RequireText("login.email")} color="primary" focused onChange={handleInputChange(setError)} error={error !== ""} fullWidth></TextField>
                </div>
                <div className="passwordInput">
                    <TextField id="login-input-password" type="password" name="login-input-password" label={RequireText("login.password")} fullWidth focused error={error !== ""} helperText={error} onChange={handleInputChange(setError)}></TextField>
                </div>
                <div >
                    <Button onClick={ClickLogin} variant="contained" color="info" fullWidth disabled={loading}>{loading ? <Spin /> : "LOGIN"}</Button>
                </div>
            </div>
            <div>
                <div className="tips">{RequireText("login.forgotPassword")}<Button onClick={ClickToReset}>{RequireText("login.resetPassword")}</Button></div>
                <div className="tips">{RequireText("login.noAccount")}<Button onClick={ClickToSignup}>{RequireText("login.signup")}</Button></div>
            </div>


        </div>
    )
}