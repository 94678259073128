import { Modal, Button} from "antd";

export const ModalComponent = ({ content, modalOpen, onOk }) => {
    return (
        <Modal title="Message" style={{ top: 400 }} open={modalOpen} onOk={onOk} onCancel={onOk} footer={[
            <Button key='closeModal' type="primary" onClick={onOk}>
                Close
            </Button>
        ]}>
            <p>{content ? content : "Internal Server Error, please contact staff for help; 服务器错误，请联系管理员寻求帮助"}</p>
        </Modal>
    );
}