import { logRoles } from "@testing-library/react"
import { PostToServerWithJson } from "../../assets/network/NetWork"
import { NetworkApi } from "../../assets/network/NetworkApi"
import { useNavigate } from "react-router-dom"

export const MatchProgressData = [
    { id: 0, text: "1.  选择项目", url: "programGuide/match/step1" },
    { id: 1, text: "2.  CV", url: "programGuide/match/step2" },
    { id: 2, text: "3.  定制化", url: "programGuide/match/step3" },
]

export const RecomProgressData = [
    { id: 0, text: "1.  CV", url: "programGuide/recom/step1" },
    { id: 1, text: "2.  定制化", url: "programGuide/recom/step2" },
]

//Store Data
export function StoreCVIDinPS(value) {
    localStorage.setItem("current_PG_CV_ID", value)
}
export function ClearCVIDinPS() {
    localStorage.removeItem("current_PG_CV_ID")
}

export function PgStep1(callback = () => { }, failCall = () => { }) {
    const pgwishlist = localStorage.getItem('pgwishlist');

    if (pgwishlist === null) {
        // No wishlist found
        failCall("Please Choose Wishlist first");
        return;
    }

    try {
        const parsedWishlist = JSON.parse(pgwishlist);

        // Check if parsedWishlist is an object and has at least one key
        if (!parsedWishlist || Object.keys(parsedWishlist).length === 0) {
            failCall("Please Choose Wishlist first");
            return;
        }

        // If everything is okay, invoke the callback with success status
        const re = { status: "success" };
        callback(re);
    } catch (e) {
        // If parsing fails, treat it as an empty wishlist
        failCall("Please Choose Wishlist first");
    }
}


export function PgStep2(callback = () => { }, failCall = () => { }) {
    const modules = JSON.parse(localStorage.getItem('PgModules') || '[]');
    // console.log(typeof modules);
    // console.log(modules);

    // Check if CV is provided
    if (document.getElementById("uploadCVInput")?.files[0] === undefined && localStorage.getItem('pg_next') === 'false') {
        failCall("Please Choose A CV First");
        return;
    }

    // Check if education data is filled
    const pgEducation = JSON.parse(localStorage.getItem('PgEducation') || '[]');
    if (!pgEducation || hasEmptyValues(pgEducation)) {
        failCall("Please Fill All Education Information");
        return;
    }

    // Check if modules are filled properly
    if (Array.isArray(modules)) {
        if (modules.length === 0) {
            failCall("Please Fill Modules Information");
            return;
        }
    } else if (typeof modules === 'object' && modules.content === "") {
        
        failCall("Please Fill Modules Information");
        return;
        
    }

    // If all checks pass, invoke the callback with success status
    const re = { status: "success" };
    callback(re);
}

//PG Submit API
export const PGMatchSubmitAPI = (callback = () => { }, failCall = () => { }) => {
    var indicator = JSON.parse(localStorage.getItem("PgIndicators"));
    if (allIndicatorsNotEmpty(indicator) === false) {
        failCall('Please Fill All Indicators')
        return
    }


    var wishlist = JSON.parse(localStorage.getItem("pgwishlist"));

    if (wishlist === null) {
        wishlist = {};
    }

    for (var key in wishlist) {
        if (wishlist.hasOwnProperty(key)) { // Ensure the key belongs to the object
            wishlist[key] = wishlist[key].program_id; // Replace the object with the course_code
        }
    }

    var cvId = localStorage.getItem("current_PG_CV_ID");

    var education = JSON.parse(localStorage.getItem("PgEducation"));
    var modules = JSON.parse(localStorage.getItem("PgModules") || '{}');

    // Ensure modules has the desired format: { content: [...] }
    if (Array.isArray(modules)) {
        // If modules is already an array, convert it to an object with content as key
        modules = { content: modules };
    } else if (modules && typeof modules === 'object' && !Array.isArray(modules.content)) {
        if (typeof modules.content === 'string') {
            // If content is a comma-separated string, split it into an array
            modules.content = modules.content.split(',');
        } else {
            // If content is not an array or string, initialize it as an empty array
            modules.content = [];
        }
    } else if (!modules.content) {
        // If modules is not in the expected format, initialize it correctly
        modules = { content: [] };
    }



    Object.entries(indicator).forEach(([key, value]) => {
        if (key === "purpose") {
            if (Array.isArray(value)) {
                indicator[key] = value.map((v) => getPurposeKeyFromValue(v) || v);
            } else {
                indicator[key] = getPurposeKeyFromValue(value) || value;
            }
        } else if (key === "location") {
            if (Array.isArray(value)) {
                indicator[key] = value.map((v) => getCountryKeyFromValue(v) || v);
            } else {
                indicator[key] = getCountryKeyFromValue(value) || value;
            }
        }
    });

    var language = navigator.language || navigator.userLanguage;

    var body = {
        "cv_id": cvId,
        "indicator": indicator,
        "wishlist_items": wishlist,
        modules: modules,
        education: education,
        "additional_lang_list": language === "zh-CN" ? ["zh"] : [],
    }


    PostToServerWithJson(NetworkApi.programGuide.submitReport, body, (re)=>{
        if (re.success===true){
            //alert("提交成功");
            localStorage.removeItem("PgIndicators");
            localStorage.removeItem("PgEducation");
            localStorage.removeItem("PgModules");
            localStorage.removeItem("pgwishlist");
            localStorage.removeItem('pg_next')
            failCall("提交成功!");
        }else{
            //alert(`提交失败：${re.message.cn}`)
            callback(re)
        }
    })

};

export const JsonToList = (json) => {
    var list = [];
    Object.entries(json).forEach(([key, value]) => {
        list.push(value);
    });
    return list;
}

// Program Reports functions
export const GetPorgramList = (json) => {
    var overalllist = [];
    Object.entries(json).forEach(([key, value]) => {
        var list = [];
        list.push(value.course_detail);
        list.push(value.report["Total score"].toFixed(1));
        overalllist.push(list);
    });
    return overalllist;
}


function getCountryKeyFromValue(value) {
    const countryMapping = {
        'UK': 'United Kingdom',
        'US': 'United States',
        'HK': 'Hong Kong SAR',
        'CA': 'Canada',
        'DK': 'Denmark',
        'SG': 'Singapore',
        'AU': 'Australia',
        'DE': 'Germany',
        'CH': 'Switzerland',
        'SE': 'Sweden',
        'IE': 'Ireland',
        'FI': 'Finland',
        'NZ': 'New Zealand',
        'MO': 'Macau SAR'
    };

    return Object.keys(countryMapping).find(key => countryMapping[key] === value);
}



function getPurposeKeyFromValue(value) {
    const purposeDict = {
        "improvement": "Improvement",
        "find_a_job": "Find a Job",
        "apply_for_phd": "Apply for a PhD",
    };

    return Object.keys(purposeDict).find(key => purposeDict[key] === value);
}

function allIndicatorsNotEmpty(indicators) {
    return Object.entries(indicators)
        .filter(([key]) => key !== 'career') // Exclude 'career'
        .every(([, indicator]) => Array.isArray(indicator) && indicator.length > 0);
}

function hasEmptyValues(education) {
    // Loop over each value in the education object
    return Object.values(education).some(edu => {
        // Check if any field in the education record is empty
        return Object.entries(edu).some(([key, value]) => value === "" || value === undefined || value === null);
    });
}
