import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import html2pdf from 'html2pdf.js';
import { NetworkApi } from "../../assets/network/NetworkApi";
import { GetToServer, DeleteToServerWithJson } from "../../assets/network/NetWork";
import RequireText from "../../assets/i18n/TextDefiner";
import { ModalComponent } from "../Utilities/ModalComponent";
import { LoadingComponent } from "../Utilities/Loading";


export const FileCard = ({ fileData, setFiles, setRename, setLoading, setOpenModal, setModalContent }) => {
    const [drop, setDrop] = useState(false)
    const navito = useNavigate()

    //点击文件名
    const handleClickFile = () => {
        //add functionality
        localStorage.setItem("cv_id", fileData.cv_id)
        localStorage.setItem("cv_name", fileData.file_name)
        navito("/dashboard/cv/cvEditor")
    }

    const handleDelete = () => {
        setLoading(true)
        DeleteToServerWithJson(NetworkApi.cv.deleteSingleCV.concat('/', fileData.cv_id), {}, (res) => {
            if (res.success === true) {
                setFiles(prev => {
                    const newFiles = prev.filter(item => item.cv_id !== fileData.cv_id);
                    return newFiles;
                });
            } else {
                setModalContent(res.message.cn)
                setOpenModal(true)
            }
        })
        setDrop(!drop); //关闭下拉菜单
        setLoading(false);
    };

    const handleRename = () => {
        setRename(true);
        setDrop(!drop);
        localStorage.setItem("cv_id", fileData.cv_id)
    }


    const handleDownload = () => {
        setLoading(true);

        GetToServer(NetworkApi.cv.getSingleCV.concat("/", fileData.cv_id), (re) => {
            if (re.success === true) {
                const element = re.data.HTML;
                html2pdf()
                    .from(element)
                    .set({
                        margin: 1,
                        filename: `${re.cv.file_name}.pdf`,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
                    })
                    .save();
            }
            // else {
            //     setModalOpen(true);
            //     setModalMessage(re.message.cn);
            // }
        })

        // GetToServer(NetworkApi.cv.getAllCV, (re) => {
        //     if (re.success === true) {
        //         const cv_id = fileData.cv_id;

        //         const element = re.data.HTML;
        //         html2pdf()
        //             .from(element)
        //             .set({
        //                 margin: 1,
        //                 filename: `${re.cv.file_name}.pdf`,
        //                 image: { type: 'jpeg', quality: 0.98 },
        //                 html2canvas: { scale: 2 },
        //                 jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        //             })
        //             .save();

        //     } else {
        //         setModalContent(re.message.cn)
        //         setOpenModal(true)
        //     }
        // })
        setDrop(!drop); //关闭下拉菜单
        setLoading(false);
    };



    return (
        <div className="CvPrevFile-fileCard" >
            <div className="CvPrevFile-fileCard-container">
                <div className="fileCard-row1">
                    <div className="fileCard-CvIconContainer">
                        <Icon id="cvIcon" icon={"tabler:file-cv"} />
                    </div>
                </div>
                <div className="fileCard-row2">
                    <div className="fileCard-filename"
                        style={{ fontSize: fileData.file_name.length > 20 ? "14px" : "16px" }}
                        onClick={handleClickFile}>{fileData.file_name}</div>
                    <div className="fileCard-fileDate">{fileData.update_time}</div>
                </div>
                <div className="fileCard-row3">
                    <Icon id="optionIcon" icon={"mage:dots"} onClick={() => setDrop(!drop)} />
                </div>
            </div>
            {drop &&
                <div className="dropDown-list">
                    <div className="dropDown-item" onClick={handleDelete}>
                        <Icon icon={'material-symbols:delete-outline'} />
                        <span>Delete</span>
                    </div>
                    {/* <div className="dropDown-item" onClick={handleDownload}>
                        <Icon icon={'material-symbols:download'} />
                        <span>Download</span>
                    </div> */}
                    <div className="dropDown-item" onClick={handleRename}>
                        <Icon icon={'gg:rename'} />
                        <span>Rename</span>
                    </div>
                </div>
            }
        </div>
    );
};

export function CVPrevFile({ rename, setRename }) {

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [modelContent, setModalContent] = useState("");

    const navito = useNavigate()

    useEffect(() => {
        GetToServer(NetworkApi.cv.getAllCV, (re) => {
            if (re.success === true) {
                setFiles(re.cv)
            } else {
                setModalContent(re.message.cn)
                setOpenModal(true)
            }
        })
        setLoading(false)
    }, [rename])

    const handleOk = () => {
        setOpenModal(false);
    }

    return (
        <>
            <div className="CvPrevFile">
                <label className="CvPrevFile-label">{RequireText("AIwriting.prevFile")}</label>
                {loading ? <LoadingComponent /> :
                    <div className="CvPrevFile-container">
                        {files.length > 0 &&
                            files.map((file, index) => {
                                return (
                                    <FileCard
                                        fileData={file}
                                        key={`fileCard${index}`}
                                        setFiles={setFiles}
                                        setRename={setRename}
                                        setLoading={setLoading}
                                        setModalContent={setModalContent}
                                        setOpenModal={setOpenModal}
                                    />
                                )
                            })
                        }
                    </div>}
            </div>
            {openModal && <ModalComponent modalOpen={openModal} content={modelContent} onOk={handleOk} />}
        </>
    )
}