import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from "@iconify/react/dist/iconify.js";
import { GetToServer } from '../../assets/network/NetWork';
import { NetworkApi } from '../../assets/network/NetworkApi';
import { useNavigate } from 'react-router-dom';
import { ModalComponent } from '../Utilities/ModalComponent';
import { LoadingComponent } from '../Utilities/Loading';
import { Card, Col, Row, Statistic } from 'antd';

import './ContentHome.scss';

// 导入图片
import {
  welcomeImage,
  DailyArticles1,
  DailyArticles2,
  DailyArticles3,
  DailyArticles4,
  CV_img,
  PS_img,
  Report_img
} from '../../static/Image/ContentHome/';
import RequireText from '../../assets/i18n/TextDefiner';

// WelcomeBanner
const WelcomeBanner = React.memo(({ name, usage }) => (
  <div className="welcome-banner">
    <div className="welcome-text">
      <h1>{RequireText("dashboard.welcome")} <span className="username">{name}</span></h1>
      <div className="usage">
        <span>{RequireText("dashboard.welcomeSentence2")}</span>
      </div>
      <p>{RequireText("dashboard.welcomeSentence1")}</p>
    </div>
    <div className="welcome-image" style={{ backgroundImage: `url(${welcomeImage})`, boxShadow: 'none' }}></div>
  </div>
));

WelcomeBanner.propTypes = {
  name: PropTypes.string.isRequired,
  usage: PropTypes.string.isRequired,
};

// FileCard
const FileCard = React.memo(({ file }) => {
  const [stared, setStared] = useState(false);

  const handleStarClick = (e) => {
    e.stopPropagation();
    setStared(!stared);
  };

  return (
    <div className="file-card">
      <div className="star-icon" onClick={handleStarClick}>
        {/* <Icon icon={stared ? "fluent:star-48-filled" : "fluent:star-48-regular"} /> */}
      </div>
      <div className="file-icon">
        <Icon icon="tabler:file-cv" />
      </div>
      <div className="file-info">
        <h2 style={{ fontSize: file.name.length > 20 ? "14px" : "16px" }}>{file.name}</h2>
        <p>{file.date}</p>
      </div>
      <div className="options-icon">
        {/* <Icon icon="mage:dots" /> */}
      </div>
    </div>
  );
});

FileCard.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

// RecentFiles
const RecentFiles = React.memo(() => {
  const files = [
    { name: 'CV_Cynthia_1', date: '2024/01/01', type: 'cv' },
    { name: 'Report_Cynthia_1', date: '2024/01/01', type: 'report' },
    { name: 'CV_Cynthia_2', date: '2024/01/01', type: 'cv' },
    { name: 'CV_Cynthia_3', date: '2024/01/01', type: 'cv' },
  ];

  return (
    <div className="recent-files">
      <div className="files-header">
        <h2>{RequireText("dashboard.recentFile")}</h2>
        <span>{RequireText("dashboard.more")}</span>
      </div>
      <div className="files-grid">
        {files.map((file, index) => (
          <FileCard key={index} file={file} />
        ))}
      </div>
    </div>
  );
});

const services = [
  {
    title: "dashboard.cvgeneration",
    description: 'Streamline the process of creating a tailored professional resume, ensuring all essential information and relevant experience are accurately presented to highlight your key qualifications and notable achievements.',
    image: CV_img,
    link: '/dashboard/cv'
  },
  {
    title: "dashboard.programReport",
    description: 'Provides a comprehensive and structured document, meticulously detailing methodologies, findings, discussions, and conclusions to facilitate informed decision-making and insights.',
    image: Report_img,
    link: '/dashboard/programGuide'
  },
  {
    title: "dashboard.psgeneration",
    description: 'Craft a compelling narrative that articulates your background, experiences, motivations, and goals, effectively showcasing your suitability for the desired opportunity.',
    image: PS_img,
    link: '/dashboard/ps'
  },
];

const ServiceCard = React.memo(({ service, navito }) => (
  <div className="service-card">
    <div className="text-container">
      <h3>{RequireText(service.title)}</h3>
      <p>{service.description}</p>
      <button onClick={() => navito(service.link)}>{RequireText("dashboard.select")}</button>
    </div>
    <div
      className="image-placeholder"
      style={{ backgroundImage: `url(${service.image})` }}>
    </div>
  </div>
));

ServiceCard.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

// ApplicationServices
const ApplicationServices = React.memo(({navito}) => (
  <div className="application-services">
    <div className="services-header">
      <h2>{RequireText("dashboard.services")}</h2>
      <span>{RequireText("dashboard.more")}</span>
    </div>
    <div className="services-grid">
      {services.map((service, index) => (
        <ServiceCard key={index} service={service} navito={navito}/>
      ))}
    </div>
  </div>
));

// Daily Articles
const articles = [
  { title: 'Harvard Task Forces Find…', time: 'Est: 1500 words, 1 min', image: DailyArticles1 },
  { title: 'Climate of Bias for Both Jewish and Muslim Groups', time: 'Est: 1500 words, 1 min', image: DailyArticles2 },
  { title: 'OpenCV Algorithm Modules Overview', time: 'Est: 1500 words, 1 min', image: DailyArticles3 },
  { title: 'Advancements in AI and Machine Learning', time: 'Est: 1800 words, 2 min', image: DailyArticles4 },
];

const ArticleCard = React.memo(({ article }) => (
  <div className="article-card">
    <div
      className="article-image"
      style={{ backgroundImage: `url(${article.image})` }}
    >

    </div>
    <div className="article-text">
      <h3>{article.title}</h3>
      <p>{article.time}</p>
    </div>
  </div>
));

ArticleCard.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

// DailyArticles
const DailyArticles = React.memo(() => (
  <div className="daily-articles">
    <div className="articles-header">
      <h2>Daily Articles for You</h2>
      <span>{RequireText("dashboard.more")}</span>
    </div>
    <div className="articles-grid">
      {articles.map((article, index) => (
        <ArticleCard key={index} article={article} />
      ))}
    </div>
  </div>
));

//quota display
const QuotaDisplay = ({ quato }) => (
  <div className='quota-display'>
    <div className="quota-header">
      <h2>{RequireText('dashboard.remainFile')}</h2>
    </div>
    <div className='quota-content'>
      <Card bordered={false} className='quota-card'>
        <Statistic
          title={RequireText('dashboard.programGuide')}
          value={quato.program_guide}
          valueStyle={{
            color: 'black',
          }}
        />
      </Card>
      <Card bordered={false} className='quota-card'>
        <Statistic
          title={RequireText('dashboard.cv')}
          value={quato.cv}
          valueStyle={{
            color: 'black',
          }}
        />
      </Card>
      <Card bordered={false} className='quota-card'>
        <Statistic
          title={RequireText('dashboard.ps')}
          value={quato.ps}
          valueStyle={{
            color: 'black',
          }}
        />
      </Card>
      <Card bordered={false} className='quota-card'>
        <Statistic
          title={RequireText('dashboard.rl')}
          value={quato.rl}
          valueStyle={{
            color: 'black',
          }}
        />
      </Card>
    </div>
  </div>
);


// ContentHome
export function ContentHome() {
  const [name, setName] = useState('User');
  const [usage] = useState('↑ 2.1%');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [quato, setQuota] = useState({});

  const handleOk = () => {
    setModalContent(null)
    navito('/user/login')
  };


  const navito = useNavigate();

  useEffect(() => {
    GetToServer(NetworkApi.user.userProfile, (re) => {
      if (re.message.en.includes('Successfully') || re.message.cn.includes('成功')) {
        setName(re.user_profile.first_name)
        localStorage.setItem('user_name', re.user_profile.first_name);
      } else {
        localStorage.removeItem('user_name');
        setModalContent(re.message.cn)
        setModalOpen(true);
      }
    })

    GetToServer(NetworkApi.user.userQuota, (re) => {
      if (re.success === true) {
        setQuota(re.quota)

      } else {
        setModalContent(re.message.cn)
        setModalOpen(true);
      }
      setLoading(false)
    })
  }, [])

  return (
    <div className="content-home">
      {loading ? <LoadingComponent /> : !modalOpen ?
        <>
          <WelcomeBanner name={name} usage={usage} />
          <div className="main-content">
            <QuotaDisplay quato={quato} />
            <ApplicationServices navito={navito}/>
            {/* <DailyArticles /> */}
          </div>
        </> :
        <ModalComponent modalOpen={modalOpen} content={modalContent} onOk={handleOk} />}
    </div>
  );
}
