import { Icon } from "@iconify/react/dist/iconify.js";
import { IconUrl } from "../../assets/media/IconUrl";
import { useNavigate } from "react-router-dom";
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import RequireText from "../../assets/i18n/TextDefiner";
import {
    SmileTwoTone,
    LoadingOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { GetCVIDinPS } from "../PSComponent/_psProgressData";
import { Alert, Spin } from "antd";
import { ModalComponent } from "../Utilities/ModalComponent";

export const NextableContext = createContext(false)

export function AIprogress({ children, nowStep = 0, keyStep = 99, progressData = {}, backcall = () => { }, nextcall = null, type = "", waitingTime }) {
    // 从0起算
    const [currentStep, setCurrentStep] = useState(nowStep)
    const [canNext, SetCanNext] = useState(false)
    const [loading, SetLoading] = useState(false)
    const [canBack, SetCanBack] = useState(true)
    const [nextAble, SetNextAble] = useState(true)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [pgNextable, setPgNextable] = useState(true);
    const [pgSubmit, setTotalPages] = useState(true)
    const navito = useNavigate()
    const preUrl = "/dashboard/"

    const handleOk = () => {
        setModalOpen(false);
        if(type === 'pg' && modalContent.includes('成功')){
            navito('/dashboard/programguide')
        }
    }

    //点击下一步
    function StepNext() {
        if (nextcall !== null) {
            //console.log(1)
            if (type !== 'pg') {
                SetLoading(true)
            }
            nextcall(
                (re) => {
                    SetLoading(false)
                    if (re.status === "success") {
                        if (currentStep < progressData.length - 1) {
                            setCurrentStep(currentStep + 1)
                            navito(preUrl + progressData[currentStep + 1].url)
                        };
                    } else {
                        setModalContent(re.message.cn);
                        setModalOpen(true);
                    }
                },
                (text) => {
                    //console.log("failBack excute")
                    SetLoading(false);
                    setModalContent(text);
                    setModalOpen(true);
                }
            );
        } else {
            if (currentStep < progressData.length - 1) {
                setCurrentStep(currentStep + 1)
                navito(preUrl + progressData[currentStep + 1].url)
            };
        }
    }

    //点击上一步
    function StepBack() {
        if (currentStep > 0) {
            if (currentStep - 1 === keyStep) return;
            setCurrentStep(currentStep - 1)
            navito(preUrl + progressData[currentStep - 1].url)
        }
    }

    const StepProgressBar = ({ data = progressData }) => {
        const StepUnit = ({ name, id }) => {
            return (
                <div id={id} className="stepUnit">
                    <div className="bullet">
                        <Icon className="circle" icon={IconUrl.circle}></Icon>
                        <Icon className="circleDone" icon={IconUrl.circleDone}></Icon>

                    </div>
                    <div className="text">{RequireText(name)}</div>
                </div>
            )
        }
        const Divider = () => {
            return (
                <div className="arrowR">
                    <Icon icon={IconUrl.arrowR}></Icon>
                </div>
            )
        }
        const StepCombine = ({ id, name }) => {
            return (
                <>
                    <Divider></Divider>
                    <StepUnit id={id} name={name}></StepUnit>
                </>
            )
        }

        const stepFollowedItems = data.filter(item => item.id !== 0)

        useEffect(() => {
            const preID = "progress-step-"
            const maxStep = data.length
            for (var i = 0; i < maxStep; i++) {
                let totalID = preID + i
                let ele = document.getElementById(totalID)
                if (ele) {
                    if (i < currentStep) {
                        ele.classList.toggle("done", true)
                    }
                    if (i === currentStep) {
                        ele.classList.toggle("ing", true)
                    }
                }
            }
            document.getElementById("DashboardContent")?.scrollTo(0, 0)
        }, [data.length])
        return (
            <div className="stepProgressBar">
                <StepUnit id="progress-step-0" name={progressData[0].text}></StepUnit>
                {stepFollowedItems.map(followedItem =>
                    <StepCombine key={followedItem.id} id={"progress-step-" + followedItem.id} name={followedItem.text}>

                    </StepCombine>
                )}

            </div>
        )
    }

    useEffect(() => {

    }, [nextAble])

    useEffect(() => {
        let tag = true
        if (currentStep === 0) { tag = false }
        if (currentStep - 1 === keyStep) { tag = false }
        SetCanBack(tag)
        if (currentStep === progressData.length - 1 && type !== 'pg') {
            SetCanNext(false)
        } else {
            SetCanNext(true)
        }
    }, [currentStep])

    //切换文件已上传
    function handleNextAble(event) {
        if (event.key === "ps_next") {
            var tag = false
            tag = event.newValue === true
            SetNextAble(tag)
        }
    }

    function handlePGNextAble(event) {
        if (event.key === "pg_next") {
            var tag = false
            tag = event.newValue === true
            setPgNextable(tag)
        }
    }

    useLayoutEffect(() => {
        let oldSet = localStorage.setItem;
        localStorage.setItem = function (key, newValue) {
            let setItemEvent = new Event("setItemEvent")
            setItemEvent.key = key
            setItemEvent.newValue = newValue
            window.dispatchEvent(setItemEvent)
            oldSet.apply(this, [key, newValue])
        }
        window.addEventListener("setItemEvent", (event) => handleNextAble(event))

        return () => window.removeEventListener("setItemEvent", handleNextAble)
    }, [])

    useLayoutEffect(() => {
        let oldSet = localStorage.setItem;
        localStorage.setItem = function (key, newValue) {
            let setItemEvent = new Event("setItemEvent")
            setItemEvent.key = key
            setItemEvent.newValue = newValue
            window.dispatchEvent(setItemEvent)
            oldSet.apply(this, [key, newValue])
        }
        window.addEventListener("setItemEvent", (event) => handlePGNextAble(event))

        return () => window.removeEventListener("setItemEvent", handlePGNextAble)
    }, [])

    const prev = RequireText("AIwriting.prev");
    const next = RequireText(currentStep === keyStep ? "AIwriting.submit" : "AIwriting.next");
    const pgnext = RequireText(currentStep === keyStep ? "AIwriting.submit" : "AIwriting.next");
    


    return (
        <NextableContext.Provider value={{ nextAble, SetNextAble }}>
            <div id="AIProgress" className="AIProgress">
                <div className="stepProgress">
                    <div className="stepProgressBar">
                        <StepProgressBar></StepProgressBar>
                    </div>
                </div>
                <div className="lineDivider"></div>
                <div id="AIProgressContent" className="content">
                    {children}
                </div>
                {loading ? <LoadingOutlined></LoadingOutlined> : <></>}
                {type !== 'pg' && currentStep !== progressData.length - 1 &&
                    <div id="operateBar" className="operateBar" style={loading === true ? { display: "none" } : {}}>
                        <div onClick={StepBack} className="stepLeftBtn" style={{ zIndex: !canBack ? -10 : 10, opacity: !canBack ? 0 : 1 }}>
                            <div className="text">{prev}</div>
                            <div className="tag">
                                <Icon icon={IconUrl.arrowL}></Icon>
                            </div>
                        </div>

                        <div onClick={StepNext} className="stepRightBtn" >
                            <div className="text">{next}</div>
                            <div className="tag">
                                {currentStep === keyStep ? <></> : <Icon icon={IconUrl.arrowR}></Icon>}
                            </div>
                        </div>
                    </div>}
                {type === 'pg' &&
                    <div id="operateBar" className="operateBar" style={loading === true ? { display: "none" } : {}}>
                        <div onClick={StepBack} className="stepLeftBtn" style={{ zIndex: !canBack ? -10 : 10, opacity: !canBack ? 0 : 1 }}>
                            <div className="text">{prev}</div>
                            <div className="tag">
                                <Icon icon={IconUrl.arrowL}></Icon>
                            </div>
                        </div>

                        <div onClick={StepNext} className="stepRightBtn" >
                            <div className="text">{pgnext}</div>
                            <div className="tag">
                                {currentStep === keyStep ? <></> : <Icon icon={IconUrl.arrowR}></Icon>}
                            </div>
                        </div>
                    </div>}
                {loading ? <div className="fullFillPage">
                    <Spin size="large"></Spin>
                    <Alert message={`Generating...(about ${waitingTime || "30s"}). Please don't refresh`} type="info"></Alert>
                </div> : <></>}
                {modalOpen && <ModalComponent modalOpen={modalOpen} content={modalContent} onOk={handleOk} />}
            </div>
        </NextableContext.Provider>


    )
}

export function Detect(backUrl = "/dashboard") {
    let tag = false
    // console.log(localStorage.getItem("ps_next"))

    if (localStorage.key("ps_next")) {
        if (localStorage.getItem("ps_next") === true || localStorage.getItem("ps_next") === "true") {
            tag = true
        }
    }

    if (!tag) {
        window.location.href = backUrl
    }
}