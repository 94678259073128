import { ProgramFilter } from "./ProgramFilter";
import { ProgramDetailCard } from "./ProgramDetailCard";
import { Pagination } from "./Pagination";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useState, useEffect } from "react";
import { PostToServerWithJson, GetToServer } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import { useNavigate } from "react-router-dom";
import RequireText from "../../assets/i18n/TextDefiner";
import { ModalComponent } from "../Utilities/ModalComponent";


export function ProgramResourcePage() {

    const [data, setData] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState();
    const [schoollist, setSchoollist] = useState(["Loading..."]);
    const [filter, setFilter] = useState({
        "page_num": 1,
        "universty": "all",
        "major": ["all"],
        "career": ["all"],
        "course_mode": ["all"],
        "region": ["all"],
        "sort_by_ranking": [0],
        "rank_sequence": 1
    });
    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState("")

    const navito = useNavigate()
    function ClickStart() {
        navito("/dashboard/browseporgram/wishlist")
    }


    //打开页面时候加载
    useEffect(() => {
        //Get School list
        GetToServer(NetworkApi.programResource.show_Uni, (re) => {
            //console.log(re)
            if (re.success === true) {
                setSchoollist(re.universities)
            } else {
                setModalContent(re.message.cn);
            }
        });

        //Get total Page and first page univeristies
        const body = {
            "page_num": 1,
            "universty": "all",
            "major": ["all"],
            "career": ["all"],
            "course_mode": ["all"],
            "region": ["all"],
            "sort_by_ranking": [0],
            "rank_sequence": 1
        }
        PostToServerWithJson(NetworkApi.programResource.total_Program_Page, body, (re) => {
            //console.log(re)
            if (re.success === true) {
                setData(re.first_page_programs);
                setTotalPages(re.pages_count);
                setLoading(false);
                //alert("Get First page: success")
            } else {
                setModalContent(re.message.cn);
                setModalOpen(true);
            }
        });
    }, [])

    //每次改变filter或者页面的时候重新获取数据
    useEffect(() => {
        const body = filter;

        // Handle 429 responses and other cases in the first API call
        PostToServerWithJson(NetworkApi.programResource.show_Programs, body, (re) => {
            if (re.success === false && re.message.en === 'You are making requests too frequently. Please wait a moment.') {
                setModalContent(re.message.en);  // Show a rate-limit message to the user
                setModalOpen(true);              // Open the modal to display the message
                setLoading(false);               // Stop any loading indicator
            } else if (re.success === true && re.message.en.includes("Matched projects found")) {
                const myDiv = document.getElementsByClassName('programResourceCardContainer');
                if (myDiv.length > 0) {
                    myDiv[0].scrollTo({ top: 0, behavior: "smooth" });
                }
                setData(re.programs);
                setLoading(false);
            } else if (re.message.en.includes("Sorry, no related project matched")) {
                setData([]);
                setLoading(false);
            } else {
                setModalContent(re.detail.message.cn); // Display any other error
                setModalOpen(true);
                setLoading(false);
            }
        });

        // Handle 429 responses and other cases in the second API call
        PostToServerWithJson(NetworkApi.programResource.total_Program_Page, body, (re) => {
            if (Object.keys(re).includes("pages_count")) {
                setTotalPages(re.pages_count);
            } else if (re.message.en === 'You are making requests too frequently. Please wait a moment.') {
                setModalContent(re.message.en);  // Show rate-limit message
                setModalOpen(true);
                setLoading(false);
            } else {
                setModalContent(re.message.cn); // Display any other error
                setModalOpen(true);
                setLoading(false);
            }
        });
    }, [filter]);


    //到时候引入API获取的数据
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setFilter({ ...filter, page_num: page });
        }
    };

    const handleOk = () => {
        if (String(modalContent).includes("会话失效")) {
            navito('/user/login');
        } else {
            setModalOpen(false);
            return;
        }
        setModalOpen(false);
    };

    return (
        <>
            <div className="programResourcePage">
                <div className="programResourceHead">
                    <label className="programResourceLabel">{RequireText("findProgram.resources")}</label>
                    <div className='programResourceWishlist'>
                        <label onClick={ClickStart}>{RequireText("findProgram.wishlist")}</label>
                        <Icon icon={'f7:bookmark'} className="bookmarkIcon" />
                    </div>
                </div>
                <div className="programResourceBody">
                    <ProgramFilter schoollist={schoollist} filter={filter} setFilter={setFilter} />
                    <div className="programResourceCardContainer">
                        {data.length > 0 && data.map((program, index) => (
                            <ProgramDetailCard key={index} data={program} type={'resource'} setModalContent={setModalContent} setModalOpen={setModalOpen} />
                        ))}
                        {data.length > 0 && <Pagination
                            currentPage={filter.page_num}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />}
                        {loading === false && data.length === 0 && <div className="noProgram"> Sorry, No Program Found!</div>}
                    </div>
                </div>
            </div>
            {modalOpen && <ModalComponent modalOpen={modalOpen} onOk={handleOk} content={modalContent} />}
        </>
    );
};