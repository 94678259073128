import HeadNav from "../components/HomeComponent/HeadNav";
import { ImageUrl } from "../assets/media/ImageUrl";
import RequireText from "../assets/i18n/TextDefiner";


const ContactUs = () => {
    return (
        <>
            <HeadNav></HeadNav>
            <div className="contact-us-page">
                <div className="contact-content">
                    <h2>{RequireText('contact.contactinfo')}</h2>
                    <p>
                    {RequireText('contact.description')}
                    </p>
                    <br></br>
                    <p>
                        <strong>{RequireText('contact.email')}</strong> service@apply-u.com
                    </p>
                    <p>
                        <strong>{RequireText('contact.operatingHours')}</strong> {RequireText('contact.workinghours')}
                    </p>
                </div>

                <div className="contact-form">
                    <h2>{RequireText('contact.contactUs')}</h2>
                    <img src={ImageUrl.wechatChannel} width={'100%'} />

                </div>
            </div>
        </>
    );
};

export default ContactUs;
