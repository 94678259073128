import HeadNav from "../components/HomeComponent/HeadNav"
import FootNav from "../components/HomeComponent/FootNav"
import TitleCenter from "../components/HomeComponent/TitleCenter"
import { Icon } from "@iconify/react/dist/iconify.js"
import RequireText from "../assets/i18n/TextDefiner"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const getDefaultData = (lang) => ({
    entry: {
        titleText: RequireText(`pricing.plans.entry`, lang),
        price: <span>898<span className="tagText">{RequireText(`pricing.features.priceRMB`, lang)}</span></span>,
        funcUnitGroup: [
            { icon: "solar:pin-list-linear", title: RequireText(`pricing.features.programResources`, lang), text: RequireText(`pricing.features.unlimitedSearch.entry`, lang), bool: true },
            { icon: "solar:clipboard-list-linear", title: RequireText(`pricing.features.programGuide.text`, lang), text: RequireText(`pricing.features.programGuide.entry`, lang), bool: true },
            { icon: "tabler:file-cv", title: RequireText(`pricing.features.cvGenerator.text`, lang), text: RequireText(`pricing.features.cvGenerator.entry`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.personalStatementGenerator.text`, lang), text: RequireText(`pricing.features.personalStatementGenerator.entry`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.recommendLetterGenerator.text`, lang), text: RequireText(`pricing.features.recommendLetterGenerator.entry`, lang), bool: true },
            { icon: "bi:people-fill", title: RequireText(`pricing.features.personalTutoring.text`, lang), text: RequireText(`pricing.features.personalTutoring.entry`, lang), bool: true }
        ]
    },
    premium: {
        titleText: RequireText(`pricing.plans.premium`, lang),
        price: <span>2480<span className="tagText">{RequireText(`pricing.features.priceRMB`, lang)}</span></span>,
        funcUnitGroup: [
            { icon: "solar:pin-list-linear", title: RequireText(`pricing.features.programResources`, lang), text: RequireText(`pricing.features.unlimitedSearch.premium`, lang), bool: true },
            { icon: "solar:clipboard-list-linear", title: RequireText(`pricing.features.programGuide.text`, lang), text: RequireText(`pricing.features.programGuide.premium`, lang), bool: true },
            { icon: "tabler:file-cv", title: RequireText(`pricing.features.cvGenerator.text`, lang), text: RequireText(`pricing.features.cvGenerator.premium`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.personalStatementGenerator.text`, lang), text: RequireText(`pricing.features.personalStatementGenerator.premium`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.recommendLetterGenerator.text`, lang), text: RequireText(`pricing.features.recommendLetterGenerator.premium`, lang), bool: true },
            { icon: "bi:people-fill", title: RequireText(`pricing.features.personalTutoring.text`, lang), text: RequireText(`pricing.features.personalTutoring.premium`, lang), bool: true }
        ]
    },
    prime: {
        titleText: RequireText(`pricing.plans.prime`, lang),
        price: <span>1380<span className="tagText">{RequireText(`pricing.features.priceRMB`, lang)}</span></span>,
        funcUnitGroup: [
            { icon: "solar:pin-list-linear", title: RequireText(`pricing.features.programResources`, lang), text: RequireText(`pricing.features.unlimitedSearch.prime`, lang), bool: true },
            { icon: "solar:clipboard-list-linear", title: RequireText(`pricing.features.programGuide.text`, lang), text: RequireText(`pricing.features.programGuide.prime`, lang), bool: true },
            { icon: "tabler:file-cv", title: RequireText(`pricing.features.cvGenerator.text`, lang), text: RequireText(`pricing.features.cvGenerator.prime`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.personalStatementGenerator.text`, lang), text: RequireText(`pricing.features.personalStatementGenerator.prime`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.recommendLetterGenerator.text`, lang), text: RequireText(`pricing.features.recommendLetterGenerator.prime`, lang), bool: true },
            { icon: "bi:people-fill", title: RequireText(`pricing.features.personalTutoring.text`, lang), text: RequireText(`pricing.features.personalTutoring.prime`, lang), bool: true }
        ]
    },
    vip: {
        titleText: RequireText(`pricing.plans.vip`, lang),
        price: <span>5888<span className="tagText">{RequireText(`pricing.features.priceRMB`, lang)}</span></span>,
        funcUnitGroup: [
            { icon: "solar:pin-list-linear", title: RequireText(`pricing.features.programResources`, lang), text: RequireText(`pricing.features.unlimitedSearch.vip`, lang), bool: true },
            { icon: "solar:clipboard-list-linear", title: RequireText(`pricing.features.programGuide.text`, lang), text: RequireText(`pricing.features.programGuide.vip`, lang), bool: true },
            { icon: "tabler:file-cv", title: RequireText(`pricing.features.cvGenerator.text`, lang), text: RequireText(`pricing.features.cvGenerator.vip`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.personalStatementGenerator.text`, lang), text: RequireText(`pricing.features.personalStatementGenerator.vip`, lang), bool: true },
            // { icon: "tabler:file-cv", title: RequireText(`pricing.features.recommendLetterGenerator.text`, lang), text: RequireText(`pricing.features.recommendLetterGenerator.vip`, lang), bool: true },
            { icon: "bi:people-fill", title: RequireText(`pricing.features.personalTutoring.text`, lang), text: RequireText(`pricing.features.personalTutoring.vip`, lang), bool: true }
        ]
    }
});


// const DefaultData1 = {
//     titleText: "Entry",
//     price: <span>898<span className="tagText">RMB</span></span>,
//     funcUnitGroup: [
//         { icon: "solar:pin-list-linear", title: "Program Resources", text: "Unlimited search", bool: true }
//         ,
//         { icon: "solar:clipboard-list-linear", title: "Program Guide", text: "3 reports in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "CV Generator", text: "6 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Personal Statement Generator", text: "6 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Recommend Letter Generator", text: "6 files in total", bool: true }
//         ,
//         { icon: "bi:people-fill", title: "Personal Tutoring", text: "30 Minutes", bool: true }

//     ]
// }
// const DefaultData2 = {
//     titleText: "Premium",
//     price: <span>2480<span className="tagText">RMB</span></span>,
//     funcUnitGroup: [
//         { icon: "solar:pin-list-linear", title: "Program Resources", text: "Unlimited search", bool: true }
//         ,
//         { icon: "solar:clipboard-list-linear", title: "Program Guide", text: "10 reports in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "CV Generator", text: "20 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Personal Statement Generator", text: "20 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Recommend Letter Generator", text: "20 files in total", bool: true }
//         ,
//         { icon: "bi:people-fill", title: "Personal Tutoring", text: "30 Minutes", bool: true }

//     ]
// }
// const DefaultData3 = {
//     titleText: "Prime",
//     price: <span>1380<span className="tagText">RMB</span></span>,
//     funcUnitGroup: [
//         { icon: "solar:pin-list-linear", title: "Program Resources", text: "Unlimited search", bool: true }
//         ,
//         { icon: "solar:clipboard-list-linear", title: "Program Guide", text: "5 reports in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "CV Generator", text: "10 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Personal Statement Generator", text: "10 files in total ", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Recommend Letter Generator", text: "10 files in total ", bool: true }
//         ,
//         { icon: "bi:people-fill", title: "Personal Tutoring", text: "30 Minutes", bool: true }

//     ]
// }
// const DefaultData4 = {
//     titleText: "VIP",
//     price: <span>5888<span className="tagText">RMB</span></span>,
//     funcUnitGroup: [
//         { icon: "solar:pin-list-linear", title: "Program Resources", text: "Unlimited search", bool: true }
//         ,
//         { icon: "solar:clipboard-list-linear", title: "Program Guide", text: "10 reports in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "CV Generator", text: "20 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Personal Statement Generator", text: "20 files in total", bool: true }
//         ,
//         { icon: "tabler:file-cv", title: "Recommend Letter Generator", text: "20 files in total", bool: true }
//         ,
//         { icon: "bi:people-fill", title: "Personal Tutoring", text: "Throughout the Entire Process", bool: true }
//     ]
// }
const PricingCard = ({ data }) => {
    const navito = useNavigate();
    const FunctionUnit = ({ iconUrl = "solar:pin-list-linear", title = "Program Resources", text = "5 programs each search", grayTag = true }) => {
        return (
            <div className={grayTag ? "functionUnit" : "functionUnit functionUnit-gray"}>
                <div className="icon"><Icon icon={iconUrl}></Icon></div>
                <div className="group ">
                    {/* <div className="title" >{title}</div> */}
                    <div className="text">{text}</div>
                </div>
            </div>
        )
    }
    return (
        <div className="pricingCard">
            <div className="titleGroup">
                <div className="icon"><Icon icon={"lucide:hexagon"} /></div>
                <div className="text">{data.titleText}</div>
            </div>
            <div className="priceGroup">{data.price}</div>
            {/* <ul className="featureGroup">
                <li>{RequireText('pricing.feature1')}</li>
                <li>{RequireText('pricing.feature2')}</li>
            </ul> */}
            <div className="functionGroup">
                {data.funcUnitGroup.map((item) => <FunctionUnit key={item.title} iconUrl={item.icon} title={item.title} text={item.text} grayTag={item.bool}></FunctionUnit>)}
            </div>
            <div className="btnStart" onClick={() => navito("/payment")} >{RequireText('pricing.startButton')}</div>
        </div>
    )
}
const CardContainer = ({ lang }) => {
    const { entry, premium, prime, vip } = getDefaultData(lang);

    return (
        <div className="cardContainer">
            <PricingCard data={entry} />
            <PricingCard data={prime} />
            <PricingCard data={premium} />
            <PricingCard data={vip} />
        </div>
    );
};

export default function PricingPage() {
    const { i18n } = useTranslation();
    const lang = i18n.language || "en"; // Default to English if no language is set

    return (
        <div className="bgpage layout-home" style={{ minHeight: "120vh" }}>
            <HeadNav></HeadNav>
            <TitleCenter />
            <CardContainer lang={lang} />
            <FootNav></FootNav>
        </div>
    )
}