import * as mui from "@mui/material"
import { useEffect, useState } from "react"
import { Input, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import { Get_RL_Input_Highlights, Store_RL_Input_Highlights } from "../RLComponent/RL_personal_info"
import { Store_RL_Input_Recommender_Title } from "../RLComponent/_rlProgressData"

export const InputTextSingle = ({ id, question = "请输入……" }) => {
    return (
        <div className="antdQAinput">
            <div className="antdQuestion">
                {question}
            </div>
            <div>
                <Input id={id} className="antdInputBox" placeholder="请输入" allowClear maxLength={100}></Input>
            </div>
        </div>
    )
}

const defaultSelectData = [{ value: "0", text: "example0" }, { value: "1", text: "example1" }, { value: "other", text: "other" },]
export const SelectWithOther = ({ id, question = "xxxx", questionOther = "Other Title", optionData = defaultSelectData }) => {
    const [valueInSelect, setValueInSelect] = useState(optionData[0].value)
    const otherID = id + "-other"
    useEffect(() => {
        Store_RL_Input_Recommender_Title(valueInSelect)
    }, [valueInSelect])
    return (
        <div className="selectWithOther" id={id}>
            <div className="question">
                {question}
            </div>
            <div className="selectInput">
                <mui.Select className="selectInputBox" fullWidth id={id} value={valueInSelect} onChange={(event) => setValueInSelect(event.target.value)}>
                    {optionData.map((item) => <mui.MenuItem key={item.value} value={item.value}>{item.text}</mui.MenuItem>)}
                </mui.Select>
            </div>
            <div className="otherInSelect">
                {valueInSelect === "Other" ? <>
                    <div className="question">
                        {questionOther}
                    </div>
                    <InputBoxSimple id={otherID} />
                </> : <></>}
            </div>
        </div>
    )
}

export const InputBoxSimple = ({ id }) => {
    return (
        <mui.TextField id={id} label="other" variant="outlined"></mui.TextField>
    )
}

const defaultSelectMultipleData = [{ name: "ex1", value: "ex1" }, { name: "ex2", value: "ex2" }, { name: "ex3", value: "ex3" },]
export const SelectMultiple = ({ id = "", question = "请问……", optionData = defaultSelectMultipleData }) => {

    return (
        <div className="selectMultiple antdQAinput">
            <div className="antdQuestion">
                {question}
            </div>
            <div className="selectArea">
                <Select
                    id={id}
                    mode="multiple"
                    className="antdInputBox"
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="请输入"
                    options={optionData}
                    onChange={(value) => {
                        Store_RL_Input_Highlights(value)
                    }}
                    defaultValue={Get_RL_Input_Highlights()}
                >
                </Select>
            </div>
        </div>

    )
}
export const InputTextArea = ({ id = "", maxlen = 500 }) => {
    return (
        <TextArea id={id} rows={5} placeholder="" maxLength={maxlen} />
    )
}


