import { useState, useEffect } from 'react';
import majors from './Major';
import career from './Career.json'
import { Range } from 'react-range';
import Select from 'react-select/creatable';
import { Icon } from '@iconify/react/dist/iconify.js';
import RequireText from '../../assets/i18n/TextDefiner';
import { useTranslation } from 'react-i18next';


//搜索栏元素
const ProgramSearchComponent = ({ schoollist, filter, setFilter }) => {
    // Transform the university list into the format react-select expects
    const universityOptions = schoollist.map(uni => ({
        value: uni,
        label: uni
    }));

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption2, setSelectedOption2] = useState(null);

    const handleChange = (newValue) => {
        setSelectedOption(newValue);
    };

    const handleChange2 = (newValue) => {
        setSelectedOption2(newValue);
    };

    useEffect(() => {
        if (selectedOption === null && selectedOption2 === null) {
            setFilter({
                ...filter,
                "page_num": 1,
                "university": "all",
                "course_name": "all",
                "sort_by_ranking": [0],
            });
        }
    }, [selectedOption, selectedOption2])


    const handleSearch = () => {
        if (selectedOption === null && selectedOption2 === null) {
            setFilter({
                ...filter,
                "page_num": 1,
                "university": "all",
                "course_name": "all",
                "sort_by_ranking": [0],
            });
        } else {
            setFilter({
                ...filter,
                "page_num": 1,
                "university": selectedOption !== null ? selectedOption.label : "all",
                "course_name": selectedOption2 !== null ? selectedOption2 : "all",
                "sort_by_ranking": [0],
            });
        }

    };

    return (
        <div className='programFilter-searchSection'>
            <label>{RequireText("findProgram.search")}</label>
            <div className='searchComponent'>
                <Select
                    className='searchInput'
                    value={selectedOption}
                    onChange={handleChange}
                    options={universityOptions}
                    placeholder={RequireText("findProgram.searchPlaceholder")}
                    isClearable
                />
                {/* <Select
                    className='searchInput'
                    value={selectedOption2}
                    onChange={handleChange2}
                    // options={universityOptions}
                    placeholder={RequireText("findProgram.searchPlaceholder2")}
                    isClearable
                /> */}

                <input
                    type="text"
                    className="searchTextInput"
                    value={selectedOption2}
                    onChange={(e) => handleChange2(e.target.value)}
                    placeholder={RequireText("findProgram.searchPlaceholder2")}
                />
                <button className='filterSubmit' onClick={handleSearch}>
                    {RequireText("findProgram.applysearch")}
                </button>
            </div>
        </div>
    );
};

//过滤器元素
const ProgramFilterComponent = ({ filter, setFilter }) => {

    const [selectedAnswer, setSelectedAnswer] = useState({
        major: [],
        career: [],
        course_type: [],
        ranking: [1, 200],
        region: []
    });

    // Apply filters (update selectedAnswer with locally managed filters)
    const applyFilters = () => {
        //console.log("Filters applied:", selectedAnswer);
        setFilter({
            ...filter,
            "page_num": 1,
            "major": selectedAnswer.major.length === 0 ? ['all'] : selectedAnswer.major,
            "career": selectedAnswer.career.length === 0 ? ['all'] : selectedAnswer.career,
            "region": selectedAnswer.region.length === 0 ? ['all'] : selectedAnswer.region,
            "course_mode": selectedAnswer.course_type.length === 1 ? selectedAnswer.course_type : ['all'],
            "sort_by_ranking": filter.university === "all" ? (selectedAnswer.ranking[1] === 500 ? [0] : selectedAnswer.ranking) : [0],
        })
    };


    const handleClearAll = () => {
        setSelectedAnswer({
            major: [],
            career: [],
            course_type: [],
            ranking: [1, 200],
            region: []
        })
        setFilter({
            ...filter,
            "page_num": 1,
            "major": ["all"],
            "career": ["all"],
            "course_mode": ["all"],
            "region": ["all"],
            "sort_by_ranking": [0],
        })
    }

    return (
        <div className='programFilter-filterSection'>
            <div className='filterTitle'>
                <label>{RequireText("findProgram.filter")}</label>
                <div className='ClearAllContainer' onClick={handleClearAll}>
                    <span className='ClearAllSpan'>{RequireText("findProgram.clear")}</span>
                    <Icon icon="solar:trash-bin-2-linear" id='clearAllIcon' />
                </div>
            </div>

            <div className='filterBody'>
                <LongCheckListComponent
                    data={majors}
                    icon={'bx:book'}
                    title={'Major'}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    title2={RequireText("findProgram.major")}
                />
                <LongCheckListComponent
                    data={career}
                    icon={'mdi:work-outline'}
                    title={'Career'}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    title2={RequireText("findProgram.preference")}
                />
                {filter.university === "all" ?
                    <SliderComponent
                        min={1}
                        max={500}
                        value={100}
                        icon={'icon-park-outline:ranking'}
                        title={'University Ranking'}
                        selectedAnswer={selectedAnswer}
                        setSelectedAnswer={setSelectedAnswer}
                        title2={RequireText("findProgram.ranking")}
                    />
                    :
                    <div style={{ display: "none" }}>
                        <SliderComponent
                            min={1}
                            max={500}
                            value={100}
                            icon={'icon-park-outline:ranking'}
                            title={'University Ranking'}
                            selectedAnswer={selectedAnswer}
                            setSelectedAnswer={setSelectedAnswer}
                            title2={RequireText("findProgram.ranking")}
                        />
                    </div>
                }
                <ShortChecklistComponent
                    data={['Full Time', 'Part Time']}
                    icon={'mingcute:time-duration-line'}
                    title={'Full Time/Part Time'}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    title2={RequireText("findProgram.fullPart")}
                    category={'fullPartOption'}
                />

                {filter.university === "all" ?
                    <ShortChecklistComponent
                        data={['AU', 'NZ', 'UK', 'HK', 'SG', 'US', 'CA', 'DK', 'DE', 'CH', 'SE', 'IE', 'FI', 'MO']}
                        // data={['Australia', 'New Zealand', 'United Kingdom', 'Hong Kong', 'Singapore']}
                        icon={'mingcute:location-line'}
                        title={'Region'}
                        selectedAnswer={selectedAnswer}
                        setSelectedAnswer={setSelectedAnswer}
                        title2={RequireText("findProgram.region")}
                        category={'country'}
                    />
                    :
                    <div style={{ display: "none" }}>
                        <ShortChecklistComponent
                            data={['AU', 'NZ', 'UK', 'HK', 'SG', 'US', 'CA', 'DK', 'DE', 'CH', 'SE', 'IE', 'FI', 'MO']}
                            // data={['Australia', 'New Zealand', 'United Kingdom', 'Hong Kong', 'Singapore']}
                            icon={'mingcute:location-line'}
                            title={'Region'}
                            selectedAnswer={selectedAnswer}
                            setSelectedAnswer={setSelectedAnswer}
                            title2={RequireText("findProgram.region")}
                            category={'country'}
                        />
                    </div>
                }
            </div>

            <button className='filterSubmit' onClick={applyFilters}>
                {RequireText("findProgram.apply")}
            </button>
        </div>
    );
};

// LongCheckListComponent with local state management ---- major and career
export const LongCheckListComponent = ({ icon, title, data, selectedAnswer, setSelectedAnswer, title2 }) => {
    const [expand, setExpand] = useState(false);
    const [localSelectedAnswer, setLocalSelectedAnswer] = useState(selectedAnswer[title.toLowerCase()] || []);

    var language = title2.match(/[\u3400-\u9FBF]/) ? "zh" : "en";


    const handleChange = (e) => {
        //console.log(e.target)
        const { name, checked } = e.target;
        if (language === "en") {
            var value_list = name.split("_");
            setLocalSelectedAnswer((prevState) => {
                if (checked) {
                    return [...prevState, value_list[1]];
                } else {
                    return prevState.filter((item) => item !== value_list[1]);
                }
            });
        } else {
            //always set the value in the list in English
            var value_list = name.split("_");
            const matchValue = data[value_list[0]].zh[value_list[1]];
            const matchKey = Object.keys(data[value_list[0]].en).find((key) => data[value_list[0]].en[key] === matchValue);
            setLocalSelectedAnswer((prevState) => {
                if (checked) {
                    return [...prevState, matchKey];
                } else {
                    return prevState.filter((item) => item !== matchKey);
                }
            });
        }
    };


    useEffect(() => {
        setSelectedAnswer((prev) => ({
            ...prev,
            [title.toLowerCase()]: localSelectedAnswer
        }));
    }, [localSelectedAnswer, setSelectedAnswer, title]);

    useEffect(() => {
        setLocalSelectedAnswer(selectedAnswer[title.toLowerCase()] || []);
    }, [selectedAnswer, title]);


    return (
        <div className='MajorComponent'>
            <div className='MajorComponent-title' onClick={() => setExpand(!expand)}>
                <Icon icon={icon} />
                <span>{title2}</span>
                {localSelectedAnswer.length > 0 && <div className='countingBlock'>{localSelectedAnswer.length}</div>}
            </div>
            {expand && (
                <div className='MajorComponent-body'>
                    {Object.entries(data).map(([key, value]) => (
                        <div key={`majors_${key}`}>
                            <label className='majorTypelabel'>{language === "en" ? value.en_name : value.zh_name}</label>
                            <div>
                                {language === "en" ? (Object.keys(value.en).map((disciplineKey) => (
                                    <div className='majorOption' key={disciplineKey}>
                                        <label className='majorOption-Name'>
                                            <input
                                                type='checkbox'
                                                id={disciplineKey}
                                                name={`${key}_${disciplineKey}`}
                                                onChange={handleChange}
                                                checked={localSelectedAnswer.includes(disciplineKey)}
                                            />
                                            {disciplineKey}
                                        </label>
                                    </div>
                                ))) : (Object.keys(value.zh).map((disciplineKey) => (
                                    <div className='majorOption' key={disciplineKey}>
                                        <label className='majorOption-Name'>
                                            <input
                                                type='checkbox'
                                                id={disciplineKey}
                                                name={`${key}_${disciplineKey}`}
                                                onChange={handleChange}
                                                checked={localSelectedAnswer.includes(
                                                    // For Chinese, find the corresponding English key
                                                    Object.keys(data[key].en).find((enKey) => data[key].en[enKey] === data[key].zh[disciplineKey])
                                                )}
                                            />
                                            {disciplineKey}
                                        </label>
                                    </div>
                                )))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export const ShortChecklistComponent = ({ icon, title, data, selectedAnswer, setSelectedAnswer, title2, category }) => {
    const [expand, setExpand] = useState(false);
    const [localSelectedAnswer, setLocalSelectedAnswer] = useState(title === 'Region' ? selectedAnswer.region : selectedAnswer.course_type);
    const { t } = useTranslation();

    const handleShortChecklistChange = (e) => {
        const { name, checked } = e.target;
        setLocalSelectedAnswer((prevState) => {
            if (checked) {
                return [...prevState, name];
            } else {
                return prevState.filter((item) => item !== name);
            }
        });
    };

    useEffect(() => {
        setSelectedAnswer((prev) => ({
            ...prev,
            [title === "Region" ? 'region' : 'course_type']: localSelectedAnswer
        }));
    }, [localSelectedAnswer, setSelectedAnswer, title]);

    useEffect(() => {
        setLocalSelectedAnswer(title === 'Region' ? selectedAnswer.region : selectedAnswer.course_type);
    }, [selectedAnswer, title]);


    return (
        <div className='MajorComponent'>
            <div className='MajorComponent-title' onClick={() => setExpand(!expand)}>
                <Icon icon={icon} />
                <span>{title2}</span>
                {localSelectedAnswer.length > 0 && <div className='countingBlock'>{localSelectedAnswer.length}</div>}

            </div>
            {expand && (
                <div className='MajorComponent-body' style={{ gap: '3px' }}>
                    {data.map((item) => (
                        <div className='majorOption' key={item}>
                            <label className='majorOption-Name'>
                                <input
                                    type='checkbox'
                                    id={item}
                                    name={item}
                                    onChange={handleShortChecklistChange}
                                    checked={localSelectedAnswer.includes(item)}
                                />
                                {t(`findProgram.${category}.${item}`)}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const SliderComponent = ({ icon, title, min, max, selectedAnswer, setSelectedAnswer, title2 }) => {
    const [expand, setExpand] = useState(false);
    const [rankvalues, setRankvalues] = useState(selectedAnswer.ranking || [1, 200]);

    const handleSliderChange = (value) => {
        setRankvalues(value);
    };

    useEffect(() => {
        setSelectedAnswer((prev) => ({
            ...prev,
            ranking: rankvalues
        }));
    }, [rankvalues, setSelectedAnswer]);


    useEffect(() => {
        setRankvalues(selectedAnswer.ranking || [1, 200]);
    }, [selectedAnswer]);


    return (
        <div className='SliderComponent'>
            <div className='SliderComponent-title' onClick={() => setExpand(!expand)}>
                <Icon icon={icon} />
                <span>{title2}</span>
            </div>
            {expand && (
                <div className='SliderComponent-body'>
                    <div className="slider-container">
                        <Range
                            values={rankvalues}
                            step={1}
                            min={min}
                            max={max}
                            onChange={handleSliderChange}
                            renderTrack={({ props, children }) => (
                                <div {...props} className="track">
                                    {children}
                                </div>
                            )}
                            renderThumb={({ index, props }) => (
                                <div {...props} className="program-thumb">
                                    <div className="program-thumb-label">{rankvalues[index]}</div>
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};



export const ProgramFilter = ({ schoollist, filter, setFilter }) => {

    return (
        <div className='programFilter'>
            <ProgramSearchComponent schoollist={schoollist} filter={filter} setFilter={setFilter} />
            <ProgramFilterComponent filter={filter} setFilter={setFilter} />
        </div>
    );
};

