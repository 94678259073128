import { NetworkApi } from "../../assets/network/NetworkApi";
import { PutToServerWithJson} from "../../assets/network/NetWork";
import { useState } from 'react'
import { ModalComponent } from "../Utilities/ModalComponent";
import { Spin } from "antd";

export const RenamePopup = ({ setRename, type }) => {
    const [cvName, setCvName] = useState('');
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleCancel = () => {
        setRename(false)
    }

    const handleOk = () => {
        setRename(false);
    }

    const handleRename = () => {
        // rename
        if (type === 'cv') {
            const body = {
                'cv_id': localStorage.getItem('cv_id'),
                new_filename: cvName
            };
            PutToServerWithJson(NetworkApi.cv.cvRename, body, (re) => {
                if (re.success === true) {
                    setRename(false);
                } else {
                    setModalContent(re.message.cn);
                    setOpenModal(true);
                }
            })
        } else if (type === 'ps') {
            const body = {
                'ps_id': Number(localStorage.getItem('ps_id')),
                new_filename: cvName
            };
            PutToServerWithJson(NetworkApi.ps.renamePS, body, (re) => {
                if (re.success === true) {
                    setRename(false);
                } else {
                    setModalContent(re.message.cn);
                    setOpenModal(true);
                }
            })
        }else if(type === 'pg'){
            const eventid = localStorage.getItem('pgfilename')
            const urlwithP = NetworkApi.programGuide.renameReport + `?event_id=${eventid}&new_file_name=${cvName}`
            PutToServerWithJson(urlwithP, {}, (re) => {
                if (re.success === true) {
                    setRename(false);
                } else {
                    setModalContent(re.message.cn);
                    setOpenModal(true);
                }
            })
        } 
        else{
            const body = {
                'rl_id': localStorage.getItem('rl_id'),
                new_filename: cvName
            };
            PutToServerWithJson(NetworkApi.rl.renameRl, body, (re) => {
                if (re.success === true) {
                    setRename(false);
                } else {
                    setModalContent(re.message.cn);
                    setOpenModal(true);
                }
            })
        }
    }

    return (
        <div className="RenamePopup">
            <div className='RenamePopup-window'>
                <label>Rename</label>
                <input className="rename-input" type="text" onChange={(e) => setCvName(e.target.value)}/>
                <div className="rename-buttons">
                    <button className="button-item" onClick={handleCancel}>Cancel</button>
                    <button className="button-item" onClick={handleRename}>Save</button>
                </div>
            </div>
            {openModal && <ModalComponent modalOpen={openModal} content={modalContent} onOk={handleOk}/>}
        </div>
    )
}