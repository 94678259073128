import { ImageUrl } from "../../assets/media/ImageUrl"
import { Icon } from "@iconify/react/dist/iconify.js"
import { Rate } from "antd"
import RequireText from "../../assets/i18n/TextDefiner"

export const TutorCard = ({ data, setTutorPop, setSelectedTutor }) => {

    const LableBubble = ({ text }) => {
        return (
            <div className="TutorDetailCard_info_labelarea_bubble">
                <span>{text}</span>
            </div>
        )
    }

    var labelList = [];
    labelList.push(data.major);
    labelList.push(data.specialized_field);



    return (
        <>
            <div className="TutorDetailCard">
                <div className="TutorDetailCard_pic">
                    <img src={data.avatar_path} />
                    {/* <img src="./static_b/tutor_default_icons/tutor_m_1.jpg" /> */}
                </div>
                <div className="TutorDetailCard_info">
                    <label className="TutorDetailCard_info_name">
                        {data.tutor_name}
                    </label>
                    <div className="TutorDetailCard_info_row2">
                        <div className="TutorDetailCard_info_row2_location">
                            <Icon icon={'mingcute:location-line'} />
                            <span>{data.specialized_country}</span>
                        </div>
                        <div className="dot"></div>
                        <div className="TutorDetailCard_info_row2_rate">
                            <Rate className="rate-star" disabled allowHalf defaultValue={data.overall_score} />
                            <span className="ratescore">{data.overall_score}</span>
                        </div>
                        <div className="dot"></div>
                        <label className="studentNumber">{data.guidance_number} {RequireText("tutor.student")}</label>
                        {/* <div className="expandButton">More Details</div> */}
                    </div>
                    <div className="TutorDetailCard_info_labelarea">
                        {labelList.length > 0 && labelList.map((item, index) => (
                            <LableBubble text={item} index={data.tutor_name + "label" + index} />
                        ))}
                    </div>
                    <div className="TutorDetailCard_info_button">
                        <button onClick={() => { setTutorPop(true); setSelectedTutor(data) }}>{RequireText("tutor.connect")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}