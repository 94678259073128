import { Icon } from "@iconify/react/dist/iconify.js";
import { FunctionCard } from "../Utilities/DashboardItems";
import { IconUrl } from "../../assets/media/IconUrl";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RequireText from "../../assets/i18n/TextDefiner";
import { useState, useEffect } from "react";
import { GetToServer, PostToServerByFormData, PostToServerWithFile } from "../../assets/network/NetWork";
import { NetworkApi } from "../../assets/network/NetworkApi";
import {
    SmileTwoTone,
    LoadingOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { ClearCVIDinPS, StoreCVIDinPS } from "./ProgramGuideProgressData";
import Select from 'react-select';
import schoollist from './combined_names_en_categorized.json'
import { ModalComponent } from "../Utilities/ModalComponent";

const schoolSelectList = Object.entries(schoollist).map(([key, value]) => ({
    value: key,
    label: value,
}));

export function PGcv() {
    const navito = useNavigate()
    const [now, setNow] = useState(0);
    const [filename, SetFilename] = useState("")
    const [parse, setParse] = useState(null)
    const getStoredData = (key, defaultValue) => {
        try {
            const data = JSON.parse(localStorage.getItem(key));
            return data || defaultValue;
        } catch (e) {
            console.error(`Error parsing localStorage key "${key}":`, e);
            return defaultValue;
        }
    };

    const [education, setEducation] = useState(getStoredData('PgEducation', {}));
    const [modules, setModules] = useState(getStoredData('PgModules', { content: "" }));
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loading, SetLoading] = useState(false);
    const [uploaded, SetUploaded] = useState(localStorage.getItem('pg_next') === 'true' ? true : false);

    // Save the education and modules data to local storage whenever they change
    useEffect(() => {
        localStorage.setItem('PgEducation', JSON.stringify(education));
    }, [education]);

    useEffect(() => {
        localStorage.setItem('PgModules', JSON.stringify(modules));
    }, [modules]);

    //提前获取form的title
    const schoolName = RequireText('findProgram.confirmInfo.schoolName');
    const degreeName = RequireText('findProgram.confirmInfo.degree');
    const degreeType = RequireText('findProgram.confirmInfo.degreeType');
    const gpa = RequireText('findProgram.confirmInfo.gpa');
    const graduactionDate = RequireText('findProgram.confirmInfo.graduactionDate');
    const gpaScale = RequireText('findProgram.confirmInfo.gpaScale');
    const avgScore = RequireText('findProgram.confirmInfo.AvgScore');
    const modulesTitle = RequireText('findProgram.confirmInfo.module');

    // Handle changes in input fields for Education section
    const handleEducationChange = (key, field, value) => {
        setEducation((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [field]: value
            }
        }));
    };

    // Handle changes in the Modules textarea
    const handleModulesChange = (value) => {
        setModules({
            content: value
        });
    };

    // Add a new education item
    const addEducationItem = () => {
        const newKey = Object.keys(education).length;
        setEducation((prevState) => ({
            ...prevState,
            [newKey]: {
                School: "",
                Degree: "",
                Degree_Type: "",
                GPA: "",
                Avg_Score: "",
                GPA_Scale: "",
                Graduation_Date: ""
            }
        }));
    };

    const removeAndReorderEducationItem = (keyToRemove) => {
        const keys = Object.keys(education);
        if (keys.length > 1) { // Ensure there is at least one entry remaining
            if (education[keyToRemove] !== undefined) { // Check if the key exists
                // Remove the specified key
                const updatedEducation = Object.keys(education)
                    .filter(key => key !== keyToRemove) // Exclude the key to remove
                    .map(Number) // Convert keys to numbers
                    .sort((a, b) => a - b) // Sort keys in ascending order
                    .reduce((acc, currentKey, index) => {
                        acc[index] = education[currentKey]; // Reassign values to new sequential keys
                        return acc;
                    }, {});
                
                setEducation(updatedEducation);
            } else {
                setModalContent("The specified key does not exist.");
                setModalOpen(true);
            }
        } else {
            setModalContent("You must keep at least one education entry.");
            setModalOpen(true);
        }
    };

    const handleOk = () => {
        setModalOpen(false);
    }

    //上传cv
    function UploadCV() {
        const ele = document.getElementById("uploadCVInput")
        GetToServer(NetworkApi.user.userQuota, (re) => {
            if (re.success === false) {
                setModalContent(re.message.cn);
                setModalOpen(true);
            } else {
                if (re.quota.program_guide === 0) {
                    setModalContent("Your program guide quota has been used up.");
                    setModalOpen(true);
                } else {
                    if (ele) {
                        const file = ele.files[0]
                        if (file) {
                            SetFilename(file.name)
                            const pattern = /.pdf/
                            //扩展名判断是否为pdf

                            if (!pattern.test(file.name)) {
                                setModalContent("Please upload a PDF file.");
                                setModalOpen(true);
                                ele.value = null
                                SetFilename("")
                            } else if (file.size > 1.5 * 1024 * 1024) {
                                setModalContent("File size should be less than 1.5MB")
                                setModalOpen(true);
                                ele.value = null;
                                SetFilename("");
                            } else {
                                ClearCVIDinPS();
                                let formdata = new FormData();
                                formdata.append('resumeFile', file);
                                setNow(10);

                                const urlWithQuery = `${NetworkApi.cv.uploadParseCV}?resume_upload_type=program_guide`;

                                PostToServerWithFile(urlWithQuery, formdata, (re) => {
                                    if (re !== undefined) {
                                        if (re.success === false) {
                                            setModalContent(re.message.cn);
                                            setModalOpen(true);
                                        }
                                        if (re.cv_id !== undefined) {
                                            StoreCVIDinPS(re.cv_id);
                                            setNow(100);
                                            setParse(re.parsed_cv);
                                            var temp = re.parsed_cv.Education
                                            Object.entries(temp).map(([key, item]) => {
                                                item.School = schoolSelectList.find((x) => x.label.includes(item.School))?.value
                                            })
                                            setEducation(temp);
                                            setModules(re.parsed_cv.Modules);  // This should be setModules
                                            SetUploaded(true)
                                        }
                                        else {
                                            setModalContent("Something Wrong,refresh again")
                                            setModalOpen(true);
                                            //document.location.reload()
                                        }

                                    } else {
                                        setModalContent("Server Error")
                                        setModalOpen(true);
                                    }

                                })
                            }
                        }
                    }
                }

            }
        }
        )
    }

    useEffect(() => {
        localStorage.setItem("pg_next", uploaded)
    }, [uploaded])

    return (
        <div className="psCV">
            <div className="uploadCV">
                <div className="title">
                    {RequireText("AIwriting.upload")}
                    <span className="tag"> *</span>
                </div>
                <div className="boxGroup">
                    <div className="box">
                        {/* 上传已有cv文件 */}
                        <FunctionCard isUpload onupload={UploadCV} title={RequireText("AIwriting.dragdrop")} tips={RequireText("AIwriting.upload")}></FunctionCard>
                    </div>
                    <div className="textDivider">OR</div>
                    {/* 跳转到cv */}
                    <div onClick={() => {
                        localStorage.setItem("dashboard_route_array", JSON.stringify(["AIwrite", "content_cv"]))
                        navito("/dashboard/cv")
                    }} className="box">
                        <FunctionCard title={RequireText("AIwriting.start")} tips={RequireText("AIwriting.create")}></FunctionCard>
                    </div>
                </div>
            </div>
            {filename === "" ? <></> : <div className="uploadProgress">
                <div className="bgBar">
                    <div className="icon"><Icon icon={IconUrl.cv}></Icon></div>
                    <div className="content">
                        <div className="title">
                            {filename}
                            {now === 100 ? <SmileTwoTone /> : <SyncOutlined spin />}
                        </div>
                        <div className="progressBar">
                            <ProgressBar now={now} label={`${now}%`} animated style={{ height: 12 }} />
                        </div>
                    </div>
                </div>
            </div>}
            {(parse !== null || Object.keys(education).length > 0) &&
                <form className="cvForm">
                    <label className="cvForm-title">Please Confirm Required Information Below: </label>
                    <div className="cvForm-container">
                        {Object.entries(education).map(([key, item]) => {
                            return (
                                <>
                                    <div className="cvForm-container-row1">
                                        <label className="field-title">{schoolName} - {(Number(key) + 1).toString()}</label>
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderRadius: "10px",
                                                    fontSize: "14px",
                                                    fontFamily: "Poppins",
                                                    fontWeight: "400",
                                                    lineHeight: "22px",
                                                    padding: '0 0',
                                                    backgroundColor: "#FAFAFA",
                                                })
                                            }}
                                            options={schoolSelectList}
                                            value={schoolSelectList.find((temp) => temp.value === item.School) || null}
                                            onChange={(e) => handleEducationChange(key, "School", e.value)}

                                        />
                                    </div>
                                    <div className="cvForm-container-row1">
                                        <label className="field-title">{degreeName}</label>
                                        <input value={item.Degree || ""}
                                            onChange={(e) => handleEducationChange(key, "Degree", e.target.value)}
                                        />
                                    </div>
                                    <div className="cvForm-container-row2">
                                        <div className="cvForm-container-row2-item">
                                            <label className="field-title">{degreeType}</label>
                                            <select className="cvFormSelect2" value={item.Degree_Type || ""} onChange={(e) => handleEducationChange(key, "Degree_Type", e.target.value)}>
                                                <option value=""></option>
                                                <option value="Bachelor's Degree">Bachelor's Degree</option>
                                                <option value="Master's Degree">Master's Degree</option>
                                                <option value="Doctoral Degree">Doctoral Degree</option>
                                                <option value="Professional Degree">Professional Degree</option>
                                                <option value='Diploma'>Diploma</option>
                                                <option value='Certificate'>Certificate </option>
                                                <option value='Others'>Others</option>
                                            </select>
                                        </div>
                                        <div className="cvForm-container-row2-item">
                                            <label className="field-title">{graduactionDate}</label>
                                            <input value={item.Graduation_Date || ""}
                                                onChange={(e) => handleEducationChange(key, "Graduation_Date", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="cvForm-container-row3">
                                        <div className="cvForm-container-row3-item">
                                            <label className="field-title">{gpa}</label>
                                            <input value={item.GPA || ""}
                                                onChange={(e) => handleEducationChange(key, "GPA", e.target.value)}
                                            />
                                        </div>
                                        <div className="cvForm-container-row3-item">
                                            <label className="field-title">{gpaScale}</label>
                                            <select className="cvFormSelect2" value={item.GPA_Scale || ""} onChange={(e) => handleEducationChange(key, "GPA_Scale", e.target.value)}>
                                                <option value="4.0 Scale">4.0 Scale</option>
                                                <option value="4.3 Scale">4.3 Scale</option>
                                                <option value="5.0 Scale">5.0 Scale</option>
                                                <option value="10.0 Scale">10.0 Scale</option>
                                                <option value="100-Point Scale">100-Point Scale</option>
                                                <option value="Letter Scale (A, B, C, D, F)">Letter Scale (A, B, C, D, F)</option>
                                                <option value="Degree Class (UK)">Degree Class (UK)</option>
                                            </select>
                                        </div>
                                        <div className="cvForm-container-row3-item">
                                            <label className="field-title">{avgScore}</label>
                                            <input value={item.Avg_Score || ""}
                                                onChange={(e) => handleEducationChange(key, "Avg_Score", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button className="button-item" type="button" onClick={() => removeAndReorderEducationItem(key)}>
                                        - Remove Experience
                                    </button>
                                    <br />
                                </>
                            )
                        })}
                        <div className="button-container">
                            <button className="button-item" type="button" onClick={addEducationItem}>
                                + Add Experience
                            </button>
                            
                        </div>
                        <br />
                        <div className="cvForm-container-row1">
                            <label className="field-title">{modulesTitle}</label>
                            <textarea
                                value={modules.content}
                                onChange={(e) => handleModulesChange(e.target.value)}
                                placeholder={`Split Your Modules with Comma or Semicolon`}
                                defaultValue={modules}
                            ></textarea>
                        </div>
                    </div>
                </form>}

            {modalOpen && <ModalComponent modalOpen={modalOpen} content={modalContent} onOk={handleOk} />}
        </div>
    )
}
