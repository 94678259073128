import { useNavigate } from "react-router-dom"
import { ImageUrl } from "../../assets/media/ImageUrl"
import { Image } from "react-bootstrap"
import { Button } from "antd"
import { Icon } from "@iconify/react/dist/iconify.js"
import { IconUrl } from "../../assets/media/IconUrl"
export const BrandNode = () => {
    const navito=useNavigate()

    return (
        <div onClick={()=>navito("/")} className="brandNode">
            <div className="img">
                <Image  src={ImageUrl.brandIcon}></Image>
            </div>

        </div>
    )
}
export const ButtonBlue=({text,callback})=>{
    return(
        <Button onClick={callback} type="primary" shape="round" size="large">{text}</Button>
    )
}
export const ChooseCard=({data})=>{
    return(
        <div className="chooseCard">
            <div className="content">
                <div className="icon"><Icon icon={data.iconUrl}></Icon></div>
                <div className="text">{data.text}</div>
            </div>
            <div className="tag"><div className="icon"><Icon icon={IconUrl.checkTag}></Icon></div></div>
        </div>
    )
}